import axios from "axios";
import { ServerBaseURLENV } from "../../_helpers/Constant";

const createProfiling = async (payload) => {
  const response = await axios.post(`${ServerBaseURLENV}/validations/create`, payload);
  return response?.data;
};

const getDataProfileList = async () => {
  const response = await axios.post(`${ServerBaseURLENV}/data-profiler/list`);
  return response?.data;
};

const executeDataProfile = async (id) => {
  const response = await axios.get(`${ServerBaseURLENV}/data-profiler/execute/${id}`);
  return response?.data;
};

const multiExecuteDataProfile = async (data) => {
  const response = await axios.post(`${ServerBaseURLENV}/data-profiler/execute`, data);
  return response?.data;
};

const profilingExecutionList = async (data) => {
  const response = await axios.post(`${ServerBaseURLENV}/data-profiler/executions/${data?.ruleId[0]}`, data);
  return response?.data;
};

const profilingExecutionResult = async (id) => {
  const response = await axios.get(`${ServerBaseURLENV}/data-profiler/execution/results/${id}`);
  return response?.data;
};

export const profilingServices = {
  createProfiling,
  getDataProfileList,
  executeDataProfile,
  profilingExecutionList,
  profilingExecutionResult,
  multiExecuteDataProfile,
};
