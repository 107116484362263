/* eslint-disable no-mixed-operators */
import { select, scaleLinear, scaleBand, axisBottom, format } from "d3";
import React, { useEffect, useRef } from "react";
import useResizeObserver from "./useResizeObserver";

const PercentageBar = ({ data, max, count }) => {
  const svgRef = useRef();
  const wrapperRef = useRef();

  const dimensions = useResizeObserver(wrapperRef);

  const draw = () => {
    const svg = select(svgRef.current);

    const { width, height } = dimensions || wrapperRef.current.getBoundingClientRect();

    const xScale = scaleLinear().domain([0, max]).range([0, width]);

    const yScale = scaleBand()
      .domain(data.map((d) => d))
      .range([0, height])
      .padding(0.1);

    svg.selectAll("*").remove();

    const bars = svg.selectAll(".bar").data(data);

    bars
      .enter()
      .append("rect")
      .attr("class", "bar")
      .merge(bars)
      .attr("fill", "#E19937")
      .attr("x", 0)
      .transition()
      .attr("y", (d, i) => yScale(i))
      .attr("width", (d) => xScale(d))
      .attr("height", yScale.bandwidth())
      .attr("rx", 4)
      .attr("ry", 4);

    bars.exit().remove();

    const xAxis = axisBottom(xScale).tickFormat(format(".1%"));

    const Xlabels = svg.selectAll(".Xlabel").data(data);

    Xlabels.enter()
      .append("text")
      .attr("class", "Xlabel")
      .merge(Xlabels)
      .style("font-size", 13)
      .attr("x", () => width / 2 - 10)
      .attr("y", (d) => yScale(d) + yScale.bandwidth() / 2 + 5)
      .text((d) => ((d / count) * 100 < 0.1 ? "< 0.1%" : xAxis.tickFormat()(d / count)));

    Xlabels.exit().remove();
  };

  useEffect(() => {
    draw();
  }, [data, dimensions]);

  return (
    <React.Fragment>
      <div ref={wrapperRef}>
        <svg ref={svgRef} width={"100%"} height={"3.2vh"} />
      </div>
    </React.Fragment>
  );
};

export default PercentageBar;
