import { useState } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  Box,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
  TableContainer,
  Table,
  FormControl,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogTitle,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import { useTranslation } from "react-i18next";
import SkeletonLoader from "../../SkeletonLoader";
import DataCleansingContainer from "../../Data Cleansing/DataCleansingContainer";
import { useLocation, useSearchParams } from "react-router-dom";

const PreviewFileConnection = ({ filePreviewData, previewFileLoading, closePreview, onChangeRowsToShow }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const tables = filePreviewData.result?.rows || [];
  const file = filePreviewData.fileDetails || {};
  const totalRows = filePreviewData.result?.totalRows;
  const totalColumns = filePreviewData.result?.totalColumns;
  const [searchParams] = useSearchParams();
  const numberOfRows = searchParams.get("numberOfRows");
  const [selectedCleanData, setSelectedCleanData] = useState(false);
  let columns = [];
  if (file?.firstRowisHeader) {
    const firstTable = tables?.[0] || [];
    columns = [...firstTable];
  } else {
    // Generate an array of column headers based on the number of columns
    const numColumns = tables[0]?.length || 0;
    columns = Array.from({ length: numColumns }, (_, index) => `Column ${index + 1}`);
  }
  const handleCleanDataClick = (e) => {
    e.stopPropagation();
    setSelectedCleanData(columns);
  };
  const handleRowsToshow = (e) => {
    onChangeRowsToShow(e.target.value);
  };
  return (
    <Box className="previewConnection" sx={{ maxWidth: "100%", position: "relative" }}>
      <DialogTitle className="previewConTitleContainer" component="div">
        <IconButton onClick={closePreview} size="small" color="error" className="prevConCloseIcon">
          <CancelOutlinedIcon />
        </IconButton>
        <Grid container>
          <Grid size={2}>
            <Typography variant="h6">{t("Preview")} : </Typography>
          </Grid>
          <Grid size={3} className="titleItem">
            <Typography>{t("Data Source Name")}: </Typography>
            <Typography>{file?.connectionName ? file?.connectionName : file?.name}</Typography>
          </Grid>
          <Grid size={2} className="titleItem">
            <Typography>{t("Total Rows")} : </Typography>
            <Typography> {totalRows || "--"}</Typography>
          </Grid>
          <Grid size={2} className="titleItem">
            <Typography>{t("Total Columns")} : </Typography>
            <Typography> {totalColumns || "--"} </Typography>
          </Grid>
          <Grid size={3}>
            <Box className="v-center" gap={2}>
              {location.pathname?.includes("data-cleansing") && (
                <Button
                  size="small"
                  variant="outlined"
                  sx={{ pointerEvents: "auto", ml: "auto" }}
                  onClick={handleCleanDataClick}>
                  {t("Clean Data")}
                </Button>
              )}
              {numberOfRows && (
                <FormControl
                  fullWidth
                  sx={{
                    maxWidth: 100,
                    mr: 2,
                    ...(!location.pathname?.includes("data-cleansing") && { ml: "auto" }),
                  }}
                  size="small">
                  <InputLabel>{t("Records Shown")}</InputLabel>
                  <Select
                    label="Records Shown"
                    defaultValue={numberOfRows}
                    value={numberOfRows}
                    onChange={handleRowsToshow}
                    size="small">
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <Box p={2}>
        {previewFileLoading ? (
          <SkeletonLoader />
        ) : (
          <Grid container>
            <Grid size={12} sx={{ p: 0, display: "grid" }}>
              <TableContainer>
                <Table aria-label="custom pagination table">
                  <TableHead>
                    {file?.firstRowisHeader &&
                      tables?.map((item, key) => (
                        <tr key={`filesrow-${key}`}>
                          {key === 0 && item.map((td, i) => <TableCell key={`filesrowth-${i}`}>{t(td)}</TableCell>)}
                        </tr>
                      ))}
                    {!file?.firstRowisHeader &&
                      tables?.map((item, key) => (
                        <tr key={`filesrow-${key}`}>
                          {key === 0 &&
                            item.map((td, i) => <TableCell key={`filesrowth-${i}`}>Column {i + 1}</TableCell>)}
                        </tr>
                      ))}
                  </TableHead>
                  <TableBody sx={{ "& tr": { verticalAlign: "top" } }}>
                    {!file?.firstRowisHeader &&
                      tables?.map((item, key) => (
                        <TableRow key={key}>
                          {item?.map((td, i) => (
                            <TableCell key={i}>{td}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    {file?.firstRowisHeader &&
                      tables?.slice(1).map((item, key) => (
                        <TableRow key={key}>
                          {item?.map((td, i) => (
                            <TableCell key={i}>{td}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </Box>
      <DataCleansingContainer
        closeCleanData={() => setSelectedCleanData(false)}
        Connectiondetails={file}
        columns={selectedCleanData}
        open={selectedCleanData}
      />
    </Box>
  );
};

export default PreviewFileConnection;
