import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useContext, useEffect, useState } from "react";

import CustomExpandableTableRow from "../../Tables/CustomExpandableTableRow";
import CustomHeaderAgGrid from "../../AgGrid/CustomHeaderAgGrid";
import ApiService from "../../../services/app.service";
import SkeletonLoader from "../../SkeletonLoader";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../../App";
import { useLocation } from "react-router-dom";
const FailedResultsRecords = ({ Result, item }) => {
  const location = useLocation();

  const { setSnack } = useContext(SnackbarContext);

  const [resultResData, setResultResData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [loadingResults, setLoadingResults] = useState(false);

  const onClickArrow2 = async (data) => {
    if (showResults) {
      setShowResults(false);
    } else {
      setLoadingResults(true);
      try {
        const response = await ApiService.getFailedRecords({ validationId: data?._id, createdAt: data?.createdAt });
        setResultResData(response?.data?.results);
        setShowResults(true);
      } catch (e) {
        setSnack({ message: e?.response?.data?.message ?? e.message, open: true, colour: "error" });
      }
      setLoadingResults(false);
    }
  };

  const getComponent = () => {
    if (loadingResults) {
      return <SkeletonLoader />;
    } else if (resultResData?.FailedRecords?.length) {
      if (resultResData?.FailedRecordsWithPath?.length > 0) {
        return (
          <CustomHeaderAgGrid
            data={resultResData?.FailedRecordsWithPath}
            errorColumn={{ columns: "FailedValue", color: "red" }}
          />
        );
      }
      return (
        <CustomHeaderAgGrid
          data={resultResData?.FailedRecords}
          errorColumn={{ columns: item.ColumnName?.split(","), color: "red" }}
        />
      );
    }
    return (
      <Typography textAlign="center" variant="bold">
        No Records Found
      </Typography>
    );
  };

  return (
    <TableBody>
      <CustomExpandableTableRow
        data={item}
        showAccordian={showResults || loadingResults}
        onClickArrow={onClickArrow2}
        className="recordsRows"
        //   autoScroll={autoScroll}
        showData={<span style={{ color: "red", fontWeight: 600 }}>{Result?.TotalFailed}</span>}
        expandComponent={
          <TableCell className="expandableRow" sx={{ width: "12%" }} colSpan="7">
            <Box textAlign="center">{getComponent()}</Box>
          </TableCell>
        }>
        {/* <TableCell sx={{ width: "20%" }}>{item.TableName}</TableCell> */}
        {!location?.pathname.includes("file-watcher") && <TableCell sx={{ width: "30%" }}>{item.ColumnName}</TableCell>}
        <TableCell sx={{ width: "30%" }}>{item?.CombinedDQCheckName || item?.ValidationDisplayName || "--"}</TableCell>
        {/* <TableCell sx={{ width: "16%" }}>
        {Result?.TotalPassed + Result?.TotalFailed}
      </TableCell> */}
        <TableCell sx={{ width: "20%", color: "green", fontWeight: 600 }}>{Result?.TotalPassed}</TableCell>
      </CustomExpandableTableRow>
    </TableBody>
  );
};
const ExecutionFailedRecords = ({ validations }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { setSnack } = useContext(SnackbarContext);

  const [rows, setRows] = useState([]);
  const [loadRecord, setLoadRecord] = useState(false);

  const getChecks = async () => {
    setLoadRecord(true);
    try {
      const response = await ApiService.ValidationChecksByResultId({
        validationResultId: validations?._id,
      });
      setRows(response.data.checks);
      setLoadRecord(false);
    } catch (e) {
      setLoadRecord(false);
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
  };

  useEffect(() => {
    getChecks();
  }, [validations?._id]);

  return (
    <Box component={Paper} sx={{ maxHeight: "75vh", overflow: "auto", width: "100%", mt: 1 }}>
      {loadRecord ? (
        <SkeletonLoader />
      ) : (
        <>
          <Box p={1.5}>
            {rows[0]?.TableName && (
              <Typography variant="detailTitle">
                {t("Table Name")} :
                <Typography variant="detailValue" component={"span"}>
                  {rows[0]?.TableName}
                </Typography>
              </Typography>
            )}

            <Typography variant="detailTitle">
              {t("Validate Records")} :{" "}
              <Typography component="span" sx={{ variant: "detailValue" }}>
                {rows[0]?.Result?.TotalFailed + rows[0]?.Result?.TotalPassed || ""}
              </Typography>
            </Typography>
          </Box>

          <Grid container sx={{ my: 0 }}>
            <Grid size={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow className="recordsHeader">
                      {!location?.pathname.includes("file-watcher") && (
                        <TableCell align="left">{t("Column")}</TableCell>
                      )}
                      <TableCell align="left">{t("DQ Check")}</TableCell>
                      <TableCell align="left">{t("Passed")}</TableCell>
                      <TableCell align="center">{t("Failed")}</TableCell>
                    </TableRow>
                  </TableHead>
                  {rows?.map((item) =>
                    item?.ApiMultiResult
                      ? item?.ApiMultiResult
                      : [item?.Result]?.map((Result, indx) => (
                          <FailedResultsRecords key={indx} Result={Result} indx={indx} item={item} />
                        )),
                  )}
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default ExecutionFailedRecords;
