import { useEffect, useState, useRef } from "react";
import { Box, Typography, Paper, Checkbox, Dialog, DialogActions, DialogTitle, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";

export default function Output({ outPut, data, file1, file2, setOutPut }) {
  const [checkBoxes, setCheckBoxes] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const myCanvas1 = useRef();
  const myCanvas2 = useRef();
  const [imgProps1, setImgProps1] = useState({ width: 0, height: 0 });
  const [imgProps2, setImgProps2] = useState({ width: 0, height: 0 });
  let context = null;

  useEffect(() => {
    context = myCanvas1.current.getContext("2d");
    const image = new Image();
    image.src = URL.createObjectURL(file1[0]);
    image.onload = () => {
      context.drawImage(image, 0, 0);
      const props = { width: image.width, height: image.height };
      setImgProps1(props);
      outPut.Object_Differences.forEach((obj) => {
        const r1Style = { borderColor: data.color, borderWidth: 3 };
        drawRect(obj, context, r1Style);
      });
    };

    const context2 = myCanvas2.current.getContext("2d");
    const image2 = new Image();
    image2.src = URL.createObjectURL(file2[0]);
    image2.onload = () => {
      context2.drawImage(image2, 0, 0);
      const props = { width: image2.width, height: image2.height };
      setImgProps2(props);
      outPut.Object_Differences.forEach((obj) => {
        const r1Style = { borderColor: data.color, borderWidth: 3 };
        drawRect(obj, context2, r1Style);
      });
    };
    myCanvas2.current.scrollIntoView({ behavior: "smooth" });
  }, [outPut, file1]);

  const drawRect = (info, drawRectcontext, style = {}) => {
    const { x, y, width, height, Label } = info;
    const { borderColor, borderWidth } = style;

    drawRectcontext.beginPath();
    drawRectcontext.lineWidth = borderWidth;

    if (data.Highlight_Differences === true) {
      context.fillStyle = borderColor;
      context.fillRect(x, y, width, height);
    } else {
      context.strokeStyle = borderColor;
      context.rect(x, y, width, height);
    }
    context.font = "bold 30px serif";
    drawRectcontext.fillStyle = "#000000";
    // eslint-disable-next-line no-mixed-operators
    drawRectcontext.fillText(Label, x + width / 2, y);
    drawRectcontext.stroke();
  };

  const checkCoordinates = (e) => {
    const arr = [...checkBoxes];
    if (e.target.checked) {
      arr.push(Number.parseInt(e.target.value));
    } else {
      const index = arr.indexOf(Number.parseInt(e.target.value));
      if (index > -1) {
        arr.splice(index, 1);
      }
    }
    setCheckBoxes(arr);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const isSelected = (index) => checkBoxes.indexOf(index) !== -1;

  const resetData = () => {
    const obj = { ...outPut };
    const someArray = obj.Object_Differences.filter((x) => !checkBoxes.includes(x.Label - 1));
    someArray.forEach((obj1, index) => {
      obj1.Label = index + 1;
    });
    obj.Object_Differences = someArray;
    setOutPut(obj);
    setCheckBoxes([]);
    handleCloseDialog();
  };

  return (
    <Box>
      <Paper className="imgComOutput">
        <Box className="innerSubHead">
          <Grid container alignItems="center">
            <Grid size={12} textAlign="center">
              <Typography variant="h6">Output</Typography>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={1} textAlign="center" mt="16px">
            <Grid size={4}>
              <Typography>
                Base Image <AddPhotoAlternateOutlinedIcon />
              </Typography>
            </Grid>
            <Grid size={4}>
              <Typography>
                App URL Image <AddPhotoAlternateOutlinedIcon />
              </Typography>
            </Grid>
            <Grid size={4}></Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={4}>
              <Box className="imgOutput">
                <canvas ref={myCanvas1} width={imgProps1.width} height={imgProps1.height} />
              </Box>
            </Grid>
            <Grid size={4}>
              <Box className="imgOutput">
                <canvas ref={myCanvas2} width={imgProps2.width} height={imgProps2.height} />
              </Box>
            </Grid>
            <Grid size={4}>
              <Box className="ignoreCordinates" mt="16px">
                <Box className="innerSubHead">
                  <Grid container alignItems="center">
                    <Grid size={8}>
                      <Typography variant="h6">
                        Differences & Coordinates [
                        {outPut && outPut?.Object_Differences ? outPut?.Object_Differences?.length : 0}]
                      </Typography>
                    </Grid>
                    <Grid size={4} textAlign="right">
                      {checkBoxes.length > 0 && (
                        <Button
                          className="exSmall"
                          onClick={() => {
                            setOpenDialog(true);
                          }}
                          variant="contained"
                          size="small"
                          color="error">
                          Reject
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Box>
                <Box className="cordinatesBody">
                  <Box className="cordinatesSec">
                    {outPut &&
                      outPut?.Object_Differences?.length > 0 &&
                      outPut?.Object_Differences.map((obj, index) => {
                        const isItemSelected = isSelected(index);
                        return (
                          <Box key={index}>
                            <Typography>
                              <b>{index + 1}.</b>
                              <Checkbox
                                size="small"
                                value={index}
                                onChange={checkCoordinates}
                                checked={isItemSelected}
                              />
                              <span>X:{obj.x}</span>
                              <span>Y:{obj.y}</span>
                              <span>Width:{obj.width}</span>
                              <span>Height:{obj.height}</span>
                              <span>Unit: px </span>
                            </Typography>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
                <Box className="innerSubHead">
                  <Grid container alignItems="center">
                    <Grid size={6}>
                      <Typography variant="h6">Image 1</Typography>
                    </Grid>
                    <Grid size={6}>
                      <Typography variant="h6">Image 2</Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box className="imageCordinates">
                  <Grid container>
                    <Grid size={6}>
                      <Typography> Width: {imgProps1.width} px</Typography>
                      <Typography> Height: {imgProps1.height} px</Typography>
                      <Typography>Size: {(file1[0].size * 0.001).toFixed(1)} KB</Typography>
                    </Grid>
                    <Grid size={6}>
                      <Typography> Width: {imgProps2.width} px</Typography>
                      <Typography> Height: {imgProps2.height} px</Typography>
                      <Typography>Size: {(file2[0].size * 0.001).toFixed(1)} KB</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Dialog
        className="dialogCus"
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle variant="h6" id="alert-dialog-title">
          {"Are you sure you want to reject the selected coordinates?"}
        </DialogTitle>

        <DialogActions>
          <Button variant="outlined" color="error" size="small" onClick={handleCloseDialog}>
            No
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              resetData();
            }}
            autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
