import { Box, Button, Typography, IconButton, Tooltip, Pagination, TextField, MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { InfoOutlined as InfoOutlinedIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import DataProfilingResultTile from "./DataProfilingResultTile";
import SelectDateRange from "../../CustomComponents/SelectDateRange";
import SkeletonLoader from "../../SkeletonLoader";
import ProfilingReports from "../ProfilingReports";
import { useEffect, useState } from "react";
import { totalExecutionsPerPage } from "../../../_helpers/utils";
import { profilingServices } from "../../../Redux/services/profiling.services";
import { showToast } from "../../../Redux/reducers/toastSlice";
import { useDispatch } from "react-redux";

const DataProfilingExecutionList = ({ scrollRef }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const startDate = new Date(searchParams.get("startDate"));
  const endDate = new Date(searchParams.get("endDate"));
  const ruleId = searchParams.get("ruleId");
  const sortBy = searchParams.get("sortBy");
  const testName = searchParams.get("testName");
  const reportType = searchParams.get("reportType");
  const currentPage = JSON.parse(searchParams.get("currentPage"));
  const [executions, setExecutions] = useState([]);
  const [totalExecutionsCount, setTotalExecutionsCount] = useState(0);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [executionReportsLoading, setExecutionReportsLoading] = useState(false);

  useEffect(() => {
    if (ruleId) {
      getResultsData();
    }
  }, [ruleId]);

  const getResultsData = async (activePage, activeSortBy) => {
    setResultsLoading(true);
    try {
      const response = await profilingServices.profilingExecutionList({
        ruleId: [ruleId],
        sort: activeSortBy || sortBy,
        pageSize: totalExecutionsPerPage,
        page: activePage || currentPage,
        startDate: startDate,
        endDate: endDate,
        isScheduler: false,
      });

      setExecutions(response?.results);
      setTotalExecutionsCount(response?.recordsCount);
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
    setResultsLoading(false);
  };

  const handleDateRangeChange = (newDates) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("startDate", newDates?.startDate);
      newState.set("endDate", newDates?.endDate);
      return newState;
    });
  };

  const handleRefresh = () => {
    getResultsData();
  };

  const handlePageChange = (event, page) => {
    if (currentPage !== page) {
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("currentPage", page);
        return newState;
      });

      try {
        getResultsData(page);
      } catch (error) {
        console.error("Error fetching results:", error);
      }
    }
  };

  const onClickExecutionCard = async (result) => {
    setExecutionReportsLoading(true);
    try {
      const defaultResponse = await profilingServices.profilingExecutionResult(result._id);
      setReportData(defaultResponse || null);
    } catch (error) {
      setReportData(null);
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
    setExecutionReportsLoading(false);
  };

  const handleSortBy = (sortData) => {
    sortData &&
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("sortBy", sortData);
        return newState;
      });
    getResultsData(currentPage, sortData);
  };

  const renderExecutionsList = () => {
    return (
      <>
        {executions?.length ? (
          <Grid container className="DQRuleGridresultList">
            {executions.map((obj, index) => (
              <DataProfilingResultTile key={index} executionResult={obj} onClickCard={onClickExecutionCard} />
            ))}
            <Grid size={12}>
              <Pagination
                sx={{ my: 1.5, marginLeft: "auto", float: "right" }}
                count={Math.ceil(totalExecutionsCount / totalExecutionsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
              />
            </Grid>
          </Grid>
        ) : (
          <Box className="noExecutionsFound">
            <Typography variant="h6" sx={{ opacity: 0.5 }}>
              {t("No Executions Found")}
            </Typography>
          </Box>
        )}
      </>
    );
  };

  return (
    <Box ref={scrollRef} className="DQRulesResultsContainer" sx={{ mt: 2 }}>
      <Grid container position="relative" className="DQRuleExecutionDetails">
        <Grid size={4}>
          <Typography variant="detailTitle">
            {"Test Name"} :{" "}
            <Typography component="span" variant="detailValue">
              {testName}
            </Typography>
          </Typography>
        </Grid>
        <Grid size={4}>
          <Typography variant="detailTitle">
            {t("Report Type")} :{" "}
            <Typography component="span" variant="detailValue">
              {reportType}
            </Typography>
          </Typography>
        </Grid>
        <Grid size={4}>
          <Typography variant="detailTitle">
            {t("Number of Executions")} :{" "}
            <Typography component="span" variant="detailValue">
              {totalExecutionsCount}
            </Typography>
          </Typography>
        </Grid>
        <IconButton
          onClick={() => setSearchParams({})}
          sx={{ position: "absolute", top: 0, right: 0 }}
          size="small"
          color="error">
          <CancelIcon />
        </IconButton>
      </Grid>
      <Box className="resultActionsToolbar" display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">{t("Execution List")}</Typography>

        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="body1">
            <strong>{t("Start Date")}:</strong> {startDate.toDateString()}
          </Typography>
          <Typography variant="body1">
            <strong>{t("End Date")}:</strong> {endDate.toDateString()}
          </Typography>
          <SelectDateRange
            setSelectedDateRange={handleDateRangeChange}
            selectedDateRange={{ startDate, endDate, key: "selection" }}
            onClickSubmit={handleRefresh}
          />
          <Button onClick={handleRefresh} variant="outlined" color="warning" size="small">
            {t("Refresh")}
          </Button>
          <TextField
            className="DQRuleSortBy"
            value={sortBy}
            select
            size="small"
            label={t("sort_by")}
            onChange={(e) => handleSortBy(e.target.value)}>
            <MenuItem value="ASC">{t("Asc")}</MenuItem>
            <MenuItem value="DESC">{t("Desc")}</MenuItem>
          </TextField>
          <Tooltip arrow placement="left" title={t("Execution Tool Tip")}>
            <IconButton>
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {resultsLoading ? <SkeletonLoader /> : renderExecutionsList()}

      {(reportData || executionReportsLoading) && (
        <ProfilingReports
          executionReportsLoading={executionReportsLoading}
          reportData={reportData}
          setReportData={setReportData}
        />
      )}
    </Box>
  );
};

export default DataProfilingExecutionList;
