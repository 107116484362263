import { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Barchart from "../../Barchart";
import StackedBarChart from "../../Barchart/StackedBarchart";
import CustomTabs from "../../CustomTabs";
import CustomPaginationTable from "../../Table/CustomPaginationTable";
import { VariableOverview, VariableStatistics } from "./VariableComponents";
import { DataProfilingListItem, roundToX } from "../../ProfillingListItems";

const CharCategories = ({ count, catCounts, categories, script, block }) => {
  let result1;
  let result2;

  if (script) {
    result1 = "scripts";
    result2 = "script";
  } else if (block) {
    result1 = "blocks";
    result2 = "block";
  } else {
    result1 = "categories";
    result2 = "category";
  }

  return (
    <>
      <Typography variant="h4" sx={{ fontSize: "18px", p: "8px", marginTop: "8px" }}>
        Most occurring {result1}
      </Typography>
      <CustomPaginationTable rows={catCounts} count={count} />
      <Typography variant="h4" sx={{ fontSize: "18px", p: "8px", marginTop: "8px" }}>
        Most frequent character per {result2}
      </Typography>
      {Object.keys(catCounts).map((category, i) => {
        const cat = category?.replace(/ /g, "_");
        return (
          <CustomPaginationTable key={i} rows={categories?.[cat]} count={catCounts?.[category]} heading={category} />
        );
      })}
    </>
  );
};

export default function ExpandAccordion({ data, column }) {
  const [columnType, setColumnType] = useState(data?.type);
  const [columnData, setColumnData] = useState({});
  const [tabList, setTabList] = useState([]);

  useEffect(() => {
    setColumnType(data?.type);
    setColumnData(data ? data : undefined);
  }, [data]);

  useEffect(() => {
    const tabListOptions = {
      Numeric: ["Statistics", "Histogram", "Common Values", "Extreme Values"],
      Categorical: ["Overview", "Categories", "Words", "Characters"],
      Boolean: ["Common Values (Table)", "Common Values (Plot)"],
      Unsupported: ["Categories"],
    };

    setTabList(tabListOptions[columnType] || []);
  }, [columnType]);
  const componentList =
    Object.keys(columnData)?.length > 0
      ? {
          Histogram: <Barchart data={columnData} />,
          Statistics: <VariableStatistics data={columnData} />,
          "Common Values": (
            <CustomPaginationTable
              rows={columnData?.value_counts_without_nan}
              count={columnData?.n}
              missing={columnData?.n_missing}
            />
          ),
          "Extreme Values": (
            <CustomTabs
              tabList={["Minimum 10 Values", "Maximum 10 Values"]}
              componentList={{
                "Minimum 10 Values": (
                  <CustomPaginationTable
                    rows={columnData?.value_counts_index_sorted}
                    count={columnData?.n}
                    head10={true}
                  />
                ),
                "Maximum 10 Values": (
                  <CustomPaginationTable
                    rows={columnData?.value_counts_index_sorted}
                    count={columnData?.n}
                    tail10={true}
                  />
                ),
              }}
            />
          ),
          Overview: <VariableOverview data={columnData} />,
          Categories: (
            <Grid container rowGap={1}>
              <Grid size={6} p={1}>
                <Typography variant="h4" sx={{ fontSize: "18px", px: "1px", marginBottom: "8px" }}>
                  Common Values
                </Typography>
                <CustomPaginationTable
                  rows={columnData?.value_counts_without_nan}
                  perpagerows={5}
                  count={columnData?.n}
                  missing={columnData?.n_missing}
                />
              </Grid>
              <Grid size={6} p={1}>
                <Typography variant="h4" sx={{ fontSize: "18px", px: "1px", marginBottom: "8px" }}>
                  Length
                </Typography>
                <Barchart data={columnData} chart="vertical" />
              </Grid>
              {columnType === "Categorical" &&
                columnData?.value_counts_without_nan &&
                Object.keys(columnData?.value_counts_without_nan).length <= 10 && (
                  <Grid size={12} p={1}>
                    <Typography variant="h4" sx={{ fontSize: "18px", px: "1px", marginBottom: "8px" }}>
                      Category Frequency Plot
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box sx={{ width: "55%" }}>
                        <StackedBarChart
                          rows={[columnData?.value_counts_without_nan]}
                          allKeys={Object.keys(columnData?.value_counts_without_nan)}
                          total={Object.values(columnData?.value_counts_without_nan).reduce((sum, e) => sum + e, 0)}
                        />
                      </Box>
                    </Box>
                  </Grid>
                )}
            </Grid>
          ),
          Words: <CustomPaginationTable rows={columnData?.word_counts} count={columnData?.count} />,
          Characters: (
            <CustomTabs
              tabList={["Characters", "Categories", "Scripts", "Blocks"]}
              componentList={{
                Characters: (
                  <>
                    <Typography variant="h4" sx={{ fontSize: "18px", p: "8px", marginTop: "8px" }}>
                      Most occurring characters
                    </Typography>
                    <CustomPaginationTable rows={columnData?.character_counts} count={columnData?.n_characters} />
                  </>
                ),
                Categories: (
                  <CharCategories
                    count={columnData?.n_characters}
                    catCounts={columnData?.category_alias_counts}
                    categories={columnData?.category_alias_char_counts}
                  />
                ),
                Scripts: (
                  <CharCategories
                    count={columnData?.n_characters}
                    catCounts={columnData?.script_counts}
                    categories={columnData?.script_char_counts}
                    script={true}
                  />
                ),
                Blocks: (
                  <CharCategories
                    count={columnData?.n_characters}
                    catCounts={columnData?.block_alias_counts}
                    categories={columnData?.block_alias_char_counts}
                    block={true}
                  />
                ),
              }}
            />
          ),
          "Common Values (Table)": (
            <CustomPaginationTable
              rows={columnData?.value_counts_without_nan}
              count={columnData?.n}
              missing={columnData?.n_missing}
            />
          ),
          "Common Values (Plot)": (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box sx={{ width: "55%" }}>
                <StackedBarChart
                  rows={[columnData?.value_counts_without_nan]}
                  allKeys={columnData?.value_counts_without_nan && Object.keys(columnData?.value_counts_without_nan)}
                  total={
                    columnData?.value_counts_without_nan &&
                    Object.values(columnData?.value_counts_without_nan).reduce((sum, e) => sum + e, 0)
                  }
                />
              </Box>
            </Box>
          ),
        }
      : undefined;

  return (
    <Grid size={12} sx={{ p: 2 }}>
      {componentList && (
        <Accordion>
          <AccordionSummary sx={{ pointerEvents: "none" }}>
            <Grid container sx={{ paddingX: 2, alignItems: "center", justify: "center" }}>
              <Grid size={8}>
                <Typography color="primary" sx={{ fontSize: "18px" }}>
                  {column}
                </Typography>
                <Typography sx={{ fontSize: "15px", color: "grey" }}>{columnType}</Typography>
              </Grid>
              <Grid size={4}>
                <Button
                  sx={{
                    pointerEvents: "auto",
                    float: "right",
                  }}>
                  more details
                </Button>
              </Grid>
              <Grid size={4}>
                <DataProfilingListItem title="Distinct" value={columnData?.n_distinct} />
                <DataProfilingListItem title="Distinct (%)" value={`${roundToX(columnData?.p_distinct, 1)}%`} />
                <DataProfilingListItem title="Missing" value={columnData?.n_missing} />
                <DataProfilingListItem title="Missing (%)" value={`${roundToX(columnData?.p_missing, 1)}%`} />
                <DataProfilingListItem title="Memory Size" value={columnData?.memory_size} />
                {columnType === "Unsupported" && (
                  <>
                    <DataProfilingListItem title="Unique" value={columnData?.n_unique} />
                    <DataProfilingListItem title="Unique (%)" value={`${roundToX(columnData?.p_unique, 1)}%`} />
                  </>
                )}
                {columnType === "Numeric" && (
                  <>
                    <DataProfilingListItem title="Infinite" value={columnData?.n_infinite} />
                    <DataProfilingListItem title="Infinite (%)" value={`${roundToX(columnData?.p_infinite, 1)}%`} />
                  </>
                )}
              </Grid>
              {columnType === "Numeric" && (
                <Grid size={4}>
                  <DataProfilingListItem title="Zeros" value={columnData?.n_zeros} />
                  <DataProfilingListItem title="Zeros (%)" value={`${roundToX(columnData?.p_zeros, 1)}%`} />
                  <DataProfilingListItem title="Negative" value={columnData?.n_negative} />
                  <DataProfilingListItem title="Negative (%)" value={`${roundToX(columnData?.p_negative, 1)}%`} />
                  <DataProfilingListItem title="Minimum" value={columnData?.min} />
                  <DataProfilingListItem title="Maximum" value={columnData?.max} />
                  <DataProfilingListItem title="Mean" value={columnData?.mean} />{" "}
                </Grid>
              )}
              <Grid size={columnType === "Numeric" ? 4 : 8}>
                <Barchart data={columnData} />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Paper className="overviewContainer">
              <CustomTabs tabList={tabList} componentList={componentList} />
            </Paper>
          </AccordionDetails>
        </Accordion>
      )}
    </Grid>
  );
}
