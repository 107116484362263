import axios from "axios";
import { ProductCode, userLocalSession } from "../_helpers/Constant";
import { decryptData } from "../helpers/encryptionUtils";
import { authServices } from "../Redux/services/auth.services";
import { setUserDetails } from "../Redux/reducers/authSlice";
export const setupAxiosInterceptors = async (user_details, dispatch, navigate, setSnack) => {
  if (!user_details?.Token) {
    delete axios.defaults.headers.common.Authorization;
    axios.interceptors.request.eject(axios.interceptors.request.handlers[0]);
    axios.interceptors.response.eject(axios.interceptors.response.handlers[0]);

    return;
  }

  const { Token, UserId, SessionId } = user_details;
  let isLogoutInProgress = false;

  axios.defaults.headers.common.Authorization = `Bearer ${Token}`;

  const handleRequestSuccess = (config) => config;

  const handleRequestError = (error) => Promise.reject(error);

  const handleResponseSuccess = (response) => response;

  const handleResponseError = async (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === 401) {
      if (originalRequest._retry) {
        return Promise.reject(error);
      }

      if (error?.response?.data?.error === "jwtExpired") {
        originalRequest._retry = true;

        try {
          const tokenData = { token: Token, ProductCode };
          const refreshTokenResponse = await authServices.getRefreshToken(tokenData);
          const newToken = refreshTokenResponse?.data?.ResponseObject?.Token;

          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          const updatedUserDetails = { ...user_details, Token: newToken };

          axios.defaults.headers.common.Authorization = `Bearer ${newToken}`;
          localStorage.setItem(userLocalSession, JSON.stringify(updatedUserDetails));
          dispatch(setUserDetails(updatedUserDetails));
          return axios(originalRequest);
        } catch (refreshError) {
          return Promise.reject(refreshError);
        }
      }

      if (!isLogoutInProgress) {
        isLogoutInProgress = true;

        await authServices.logoutUser({ UserId, SessionId }, dispatch);
        navigate("/login");
      }
    }

    if (error?.response?.status === 402 && !isLogoutInProgress) {
      isLogoutInProgress = true;

      try {
        const dc = await decryptData(error.response.data);
        navigate(`/license/${dc?.ExpiryType}`);
      } catch (error) {
        setSnack({ message: error?.response?.data?.message || error.message, colour: "error", open: true });
      }
    }

    return Promise.reject(error);
  };

  axios.interceptors.request.use(handleRequestSuccess, handleRequestError);
  axios.interceptors.response.use(handleResponseSuccess, handleResponseError);
};
