import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { roleAttrs } from "./DQGUser_Constants";
import { useContext, useLayoutEffect, useState } from "react";
import { SnackbarContext } from "../../App";
import { Box } from "@mui/material";
import { usersService } from "../../Redux/services/users.service";

const StyledButton = styled(Button)(({ isSelected }) => ({
  width: "175px",
  fontWeight: isSelected ? "bold" : "normal",
  transition: "background-color 0.3s ease-in-out",
}));

const SelectUserRole = ({ selected, setSelected }) => {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const [roleList, setRoleList] = useState([]);

  const handleButtonClick = (value) => {
    setSelected(value.Id);
  };

  const fetchRoles = async () => {
    try {
      const roleList = await usersService.getUserRoles();
      setRoleList(roleList?.ResponseObject);
    } catch (error) {
      setSnack({ message: error?.response?.data?.message ?? error.message, open: true, colour: "error" });
    }
  };

  useLayoutEffect(() => {
    fetchRoles();
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
      {roleList?.map((role, i) => {
        const isSelected = role?.Id === selected;

        return (
          <StyledButton
            key={i}
            isSelected={isSelected}
            sx={{
              border: "none",
              borderRadius: 2,
              boxShadow: "none",
              backgroundColor: isSelected ? roleAttrs[role?.Name]?.color : "none",
              color: isSelected ? "#fff" : roleAttrs[role?.Name]?.color,
              "&:hover": { bgcolor: roleAttrs[role?.Name]?.color, color: "#fff", boxShadow: "none", border: "none" },
            }}
            startIcon={roleAttrs[role?.Name]?.icon}
            size="medium"
            variant={isSelected ? "contained" : "outlined"}
            onClick={() => handleButtonClick(role)}>
            {t(role?.Name)}
          </StyledButton>
        );
      })}
    </Box>
  );
};

export default SelectUserRole;
