import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

export const roleAttrs = {
  "Regular user": {
    icon: <ManageAccountsIcon />,
    color: "#d9ab2b",
  },
  "Privileged user": {
    icon: <VerifiedUserIcon />,
    color: "#4990B3",
  },
  Admin: {
    icon: <AdminPanelSettingsIcon />,
    color: "#6BA259",
  },
};
