import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstConnectionDetails: null,
  secondConnectionDetails: null,
  selectedSource1Table: null,
  selectedSource2Table: null,
  source1OrderByColumns: null,
  source2OrderByColumns: null,
  source1Columns: null,
  source2Columns: null,
  source1SqlInput: null,
  source2SqlInput: null,
  source1QueryResult: null,
  source2QueryResult: null,

  columnsToValidate: null,
};

const rowComparisonSlice = createSlice({
  name: "rowComparison",
  initialState,
  reducers: {
    setFirstConnectionDetails(state, action) {
      state.firstConnectionDetails = action.payload;
    },
    setSecondConnectionDetails(state, action) {
      state.secondConnectionDetails = action.payload;
    },
    setSelectedSource1Table(state, action) {
      state.selectedSource1Table = action.payload;
    },
    setSelectedSource2Table(state, action) {
      state.selectedSource2Table = action.payload;
    },
    setSource1OrderByColumns(state, action) {
      state.source1OrderByColumns = action.payload;
    },
    setSource2OrderByColumns(state, action) {
      state.source2OrderByColumns = action.payload;
    },
    setSource1Columns(state, action) {
      state.source1Columns = action.payload;
    },
    setSource2Columns(state, action) {
      state.source2Columns = action.payload;
    },
    setSource1QueryResult(state, action) {
      state.source1QueryResult = action.payload;
    },
    setSource2QueryResult(state, action) {
      state.source2QueryResult = action.payload;
    },

    setSource1QueryInput(state, action) {
      state.source1SqlInput = action.payload;
    },
    setSource2QueryInput(state, action) {
      state.source2SqlInput = action.payload;
    },

    setColumnsToValidate(state, action) {
      state.columnsToValidate = action.payload;
    },

    resetComparisonData(state) {
      state.firstConnectionDetails = null;
      state.secondConnectionDetails = null;
      state.selectedSource1Table = null;
      state.selectedSource2Table = null;
      state.source1OrderByColumns = null;
      state.source2OrderByColumns = null;
      state.source1Columns = null;
      state.source2Columns = null;
      state.source1SqlInput = null;
      state.source2SqlInput = null;
      state.source1QueryResult = null;
      state.source2QueryResult = null;
      state.columnsToValidate = null;
    },
    resetComparativeDetails(state) {
      state.selectedSource1Table = null;
      state.selectedSource2Table = null;
      state.source1OrderByColumns = null;
      state.source2OrderByColumns = null;
      state.source1Columns = null;
      state.source2Columns = null;
      state.source1SqlInput = null;
      state.source2SqlInput = null;
      state.source1QueryResult = null;
      state.source2QueryResult = null;
      state.columnsToValidate = null;
    },

    updateRowComparisonFields(state, action) {
      const updates = action.payload;
      for (const [field, value] of Object.entries(updates)) {
        if (field in state) {
          state[field] = value;
        } else {
          console.error(`Field "${field}" does not exist in the state.`);
        }
      }
    },
  },
});

export const {
  setFirstConnectionDetails,
  setSecondConnectionDetails,
  setSelectedSource1Table,
  setSelectedSource2Table,
  setSource1OrderByColumns,
  setSource2OrderByColumns,
  setSource1Columns,
  setSource2Columns,
  resetComparisonData,
  updateRowComparisonFields,
  setSource1QueryResult,
  setSource2QueryResult,
  setSource1QueryInput,
  setSource2QueryInput,
  setColumnsToValidate,
  resetComparativeDetails,
} = rowComparisonSlice.actions;

export default rowComparisonSlice.reducer;
