import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";

const DropBox = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <Grid size={12}>
      <TextField
        fullWidth
        size="small"
        name="accessToken"
        label={t("Access Token")}
        minRows={2}
        maxRows={4}
        multiline
        error={Boolean(errors.accessToken)}
        helperText={errors.accessToken ? errors.accessToken.message : null}
        {...register("accessToken", { required: true })}
      />
    </Grid>
  );
};

export default DropBox;
