import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import PandasReport from "./PandasReport";
import PlotlyReport from "./PlotlyReport";
import SimpleProfiling from "./SimpleProfiling";
import { useSearchParams } from "react-router-dom";
import CustomLoader from "../../CustomComponents/CustomLoader";

const ProfilingReports = ({ executionReportsLoading, reportData, setReportData }) => {
  const [searchParams] = useSearchParams();
  const reportType = searchParams.get("reportType");
  const handleClose = () => {
    setReportData(null);
  };

  const renderReport = () => {
    if (executionReportsLoading) {
      return <CustomLoader />;
    }
    if (reportType === "DQG") {
      return <SimpleProfiling reportData={reportData} />;
    } else if (reportType === "Pandas" && reportData?.analysis) {
      return <PandasReport data={reportData} />;
    } else if (reportData?.data) {
      return <PlotlyReport data={reportData} />;
    }
    return null;
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xl"}
      open={Boolean(reportData || executionReportsLoading)}
      onClose={handleClose}
      scroll="paper">
      <IconButton
        sx={{ position: "absolute", top: 0, right: 0 }}
        onClick={() => handleClose(false)}
        color="error"
        size="small">
        <CancelOutlinedIcon />
      </IconButton>
      <DialogTitle sx={{ p: 1.5, py: 1, borderBottom: "1px solid #ccc" }}>Profiling Report</DialogTitle>
      <DialogContent dividers sx={{ background: "#F4F1FF", minHeight: 400 }} className="profilingReports">
        {renderReport()}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProfilingReports;
