import { useContext, useState } from "react";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";

import Fuzzyinputs from "./Fuzzyinputs";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../../App";
import APIServices from "../../../services/app.service";
import CleaningResultModal from "../CleaningResultModal";
import SkeletonLoader from "../../SkeletonLoader";
import CustomLoadingButton from "../../CustomComponents/CustomsButtons/CustomLoadingButton";
import { useSearchParams } from "react-router-dom";
const inputs = { Columnvalue: "", masterData: "", Similarity: 0 };

const FuzzyReplace = ({ columnsLoading, Connectiondetails, tableName, columnNames, toggleDrawer, algorithms }) => {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const [searchParams] = useSearchParams();
  const schema = searchParams.get("schema");
  const [showResult, setShowResult] = useState(false);
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);
  const [disableDataCleansing, setDisableDataCleansing] = useState(false);

  const [inputParams, setInputParams] = useState([inputs]);
  const handleCleanData = async () => {
    setLoading(true);
    try {
      const data = {
        connectionId: Connectiondetails?.id,
        TableName: tableName,
        OperationName: "replaceColumnSimilarities",
        algos: algorithms.map((inp) => inp.value),
        ColumnNames: inputParams,
        schema,
      };
      const res = await APIServices.dataCleaning(data);
      setResult(res?.data?.SavedReqId);
      setShowResult(true);
    } catch (e) {
      setSnack({ message: e?.response?.data?.message ?? e.message, open: true, colour: "error" });
    }
    setLoading(false);
  };

  return (
    <Box>
      {!columnsLoading && (
        <Fuzzyinputs
          inputParams={inputParams}
          setInputParams={setInputParams}
          inputs={inputs}
          columnNames={columnNames}
          disableDataCleansing={disableDataCleansing}
          setDisableDataCleansing={setDisableDataCleansing}
        />
      )}

      {columnsLoading && <SkeletonLoader />}
      <Box
        sx={{
          mt: 2,
          textAlign: "center",
          "& .MuiButton-root": { "&:nth-of-type(1)": { mr: 1 } },
        }}>
        <Grid container sx={{ m: 1 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid size={6} textAlign="left">
            <Button variant="outlined" color="error" size="small" onClick={toggleDrawer}>
              {t("Cancel")}
            </Button>
          </Grid>
          <Grid size={6} textAlign="right">
            <CustomLoadingButton
              onClick={handleCleanData}
              sx={{ mr: 1 }}
              type="submit"
              color="success"
              disabled={loading || disableDataCleansing}
              loading={loading}>
              {t("Clean Data")}
            </CustomLoadingButton>
          </Grid>
        </Grid>
      </Box>
      {showResult && <CleaningResultModal Result={result} setShowResult={setShowResult} />}
    </Box>
  );
};

export default FuzzyReplace;
