import { Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useState } from "react";
import { DropDownData } from "../../../DropDown";
import ExpandAccordian from "./ExpandAccordion";

export default function PandasVariables({ columns }) {
  const [selectedColumn, setSelectedColumn] = useState("");

  return (
    <Paper sx={{ py: 1, px: 2, mt: 2 }} className="sampleContainer">
      <Grid container>
        <Grid size={6} sx={{ py: 2 }}>
          <Typography variant="h5" color="primary">
            Variables
          </Typography>
        </Grid>
        <Grid size={6}>
          <DropDownData
            name={"column"}
            placeholder={"Select Column"}
            options={Object.keys(columns)}
            value={selectedColumn}
            setValue={setSelectedColumn}
          />
        </Grid>
        {selectedColumn ? (
          <React.Fragment>
            <ExpandAccordian data={columns[selectedColumn]} column={selectedColumn} />
          </React.Fragment>
        ) : (
          Object.keys(columns).map((col, i) => {
            return <ExpandAccordian data={columns[col]} column={col} key={i} />;
          })
        )}
      </Grid>
    </Paper>
  );
}
