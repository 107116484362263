/* eslint-disable no-mixed-operators */
import { select, scaleLinear, scaleBand, axisLeft, max } from "d3";
import React, { useEffect, useRef } from "react";
import useResizeObserver from "./useResizeObserver";

const HorizontalBarChart = ({ data }) => {
  const svgRef = useRef();
  const wrapperRef = useRef();

  const margin = { top: 0, right: 0, bottom: 10, left: 250 };

  const dimensions = useResizeObserver(wrapperRef);

  const draw = () => {
    const svg = select(svgRef.current);

    const { width, height } = dimensions || wrapperRef.current.getBoundingClientRect();

    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const xScale = scaleLinear()
      .domain([0, max(data, (d) => d.count)])
      .range([0, innerWidth]);

    const yScale = scaleBand()
      .domain(data.map((d) => d.word))
      .range([0, innerHeight])
      .padding(0.1);

    const yAxis = axisLeft(yScale).tickSize(0);

    svg.selectAll("*").remove();

    const g = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`);

    g.append("g")
      .attr("class", "y-axis")
      .style("font-size", 13)
      .call(yAxis)
      .call((a) => a.select(".domain").remove());

    const Xlabels = svg.selectAll(".Xlabel").data(data);

    Xlabels.enter()
      .append("text")
      .attr("class", "Xlabel")
      .merge(Xlabels)
      .style("font-size", 13)
      .attr("x", (d) =>
        xScale(d.count) + 5 < innerWidth / 2 ? margin.left + xScale(d.count) + 5 : margin.left + xScale(d.count) / 2,
      )
      .attr("y", (d) => yScale(d.word) + yScale.bandwidth() / 2 + margin.top + 5)
      .text((d) => d.count);

    Xlabels.exit().remove();

    g.selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .transition()
      .attr("fill", "#E19937")
      .attr("y", (d) => yScale(d.word))
      .attr("width", (d) => xScale(d.count))
      .attr("height", yScale.bandwidth())
      .attr("rx", 3)
      .attr("ry", 3);
  };

  useEffect(() => {
    draw();
  }, [data, dimensions]);

  return (
    <React.Fragment>
      <div ref={wrapperRef}>
        <svg ref={svgRef} width={"100%"} height={"100%"} />
      </div>
    </React.Fragment>
  );
};

export default HorizontalBarChart;
