import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";

const AzureBlob = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid size={12}>
        <TextField
          fullWidth
          size="small"
          name="Description"
          label={t("Description")}
          minRows={2}
          maxRows={4}
          multiline
          {...register("Description")}
        />
      </Grid>
      <Grid size={12}>
        <TextField
          fullWidth
          // disabled={isEdit}
          {...register("containerName", {
            required: true,
            minLength: {
              value: 4,
              message: t("Minimum length 4 char"),
            },
            maxLength: {
              value: 32,
              message: t("Maximum length 32 char"),
            },
          })}
          error={Boolean(errors.containerName)}
          helperText={errors?.containerName?.message || null}
          label={t("Container Name")}
          variant="outlined"
          required
          size="small"
          onChange={(e) => (e.target.value = e.target.value.replace(/\s{2,}/g, " "))}
        />
      </Grid>

      <Grid size={12}>
        <TextField
          // disabled={isEdit}
          fullWidth
          {...register("accountName", {
            required: true,
            minLength: {
              value: 4,
              message: t("Minimum length 4 char"),
            },
            maxLength: {
              value: 32,
              message: t("Maximum length 32 char"),
            },
          })}
          error={Boolean(errors.accountName)}
          helperText={errors?.accountName?.message || null}
          label={t("Account Name")}
          variant="outlined"
          required
          size="small"
          onChange={(e) => (e.target.value = e.target.value.replace(/\s{2,}/g, " "))}
        />
      </Grid>

      <Grid size={12}>
        <TextField
          // disabled={isEdit}
          fullWidth
          {...register("accountKey", {
            required: true,
            minLength: {
              value: 4,
              message: t("Minimum length 4 char"),
            },
          })}
          error={Boolean(errors.accountKey)}
          helperText={errors?.accountKey?.message || null}
          label={t("Account Key")}
          variant="outlined"
          required
          size="small"
          onChange={(e) => (e.target.value = e.target.value.replace(/\s{2,}/g, " "))}
        />
      </Grid>
    </>
  );
};

export default AzureBlob;
