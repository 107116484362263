import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Paper, Tab, Typography } from "@mui/material";
import { useState } from "react";
import SVGExtractor from "../SVGExtractor";

const Interactions = ({ data }) => {
  const firstTableList = data && Object.keys(data);

  const [firstTab, setFirstTab] = useState(firstTableList[0]);

  const secondTableList = data?.[firstTab] && Object.keys(data?.[firstTab]);
  const [secondTab, setSecondTab] = useState(secondTableList[0]);

  const handleChangeFirstTabList = (event, newValue) => {
    setFirstTab(newValue);
    setSecondTab(Object.keys(data?.[newValue])[0]);
  };

  const handleChangeSecondTablist = (event, newValue) => {
    setSecondTab(newValue);
  };

  return (
    <Paper sx={{ py: 1, px: 2, mt: 2 }} className="sampleContainer">
      <Typography variant="h5" color="primary" mb={2}>
        Interactions
      </Typography>
      <Box className={firstTableList.length > 10 ? "h-center row" : ""}>
        <TabContext value={firstTab}>
          <TabList
            className="overviewSections"
            orientation={firstTableList.length > 10 ? "vertical" : "horizontal"}
            variant="scrollable"
            onChange={handleChangeFirstTabList}
            textColor="primary"
            indicatorColor="primary"
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}>
            {firstTableList.map((tab, indx) => {
              return <Tab key={indx} value={tab} label={tab} />;
            })}
          </TabList>
          {firstTableList.map((tab, indx) => {
            return (
              <TabPanel sx={{ py: 0, px: 0 }} key={indx} value={tab}>
                <Box className={firstTableList.length > 10 ? "h-center row" : ""}>
                  <TabContext value={secondTab}>
                    <TabList
                      className="overviewSections"
                      orientation={firstTableList.length > 10 ? "vertical" : "horizontal"}
                      variant="scrollable"
                      onChange={handleChangeSecondTablist}
                      textColor="primary"
                      indicatorColor="primary"
                      aria-label="Vertical tabs example"
                      sx={{ borderRight: 1, borderColor: "divider" }}>
                      {secondTableList.map((item, i) => (
                        <Tab key={i} value={item} label={item} />
                      ))}
                    </TabList>
                    {secondTableList.map((item, i) => (
                      <TabPanel sx={{ py: 0, px: 0 }} key={i} value={item}>
                        <SVGExtractor xmlString={data?.[firstTab]?.[secondTab]} />
                      </TabPanel>
                    ))}
                  </TabContext>
                </Box>
              </TabPanel>
            );
          })}
        </TabContext>
      </Box>
    </Paper>
  );
};

export default Interactions;
