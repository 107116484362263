import { Paper, Typography, FormHelperText } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "../../../App";
import ApiService from "../../../services/app.service";
import InnerHeader from "../../Layout/InnerHeader";
import ValidateResultModal from "../../Validations/ValidateResultModal";
import CreateOrUpdateDQRule from "../RowComparison/CreateOrUpdateDQRule";
import FileValidations from "../SingleFile/FileValidations";
import SingleDataSource from "../SingleFile/SingleDataSource";
import { useTranslation } from "react-i18next";
import CustomBackButton from "../../CustomComponents/CustomsButtons/CustomBackButton";
import CustomLoadingButton from "../../CustomComponents/CustomsButtons/CustomLoadingButton";

const steps = ["Single API", "Validate"];

export default function SingleAPI() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ScrollRef = useRef();
  const { setSnack } = useContext(SnackbarContext);
  const [source1, setSource1] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [finalValidation, setfinalValidation] = useState([]);
  const [validationsResult, setValidationsResult] = useState([]);
  const [validationsResultShow, setValidationsResultShow] = useState(false);
  const [inputParams, setinputParams] = useState([{ Name: "", Value: "" }]);
  const [requestBody, setrequestBody] = useState();
  const [file, setfile] = useState();
  const [createLoading, setCreateLoading] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);
  const [isPrivate, setIsPrivate] = useState("private");

  const autoScroll = () => {
    setTimeout(() => {
      ScrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 600);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  useEffect(() => {
    setfinalValidation([]);
  }, [source1]);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    activeStep === 0 ? navigate("/DataQualityRule") : setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const createValidation = async (ruleName) => {
    setCreateLoading(true);
    const data = FormatRequestData(ruleName);
    try {
      await ApiService.createValidation({
        ...data,
        isPrivate: isPrivate === "private",
      });
      setSnack({ message: "DQ Rule Created", open: true, colour: "success" });
      navigate("/DataQualityRule");
    } catch (error) {
      setSnack({
        message: error?.response?.data?.TestName?.message || error.message,
        open: true,
        colour: "error",
      });
    }
    setCreateLoading(false);
  };

  const FormatRequestData = (ruleName) => {
    const data = {
      TestName: ruleName,
      TestType: "Single File",
      ConnectionType: "Single API",
      requestBody: file ? undefined : requestBody,
      file: file,
      Params: file || Object.values(inputParams[0]).toString().trim().length === 1 ? undefined : inputParams,
      Tables: [
        {
          TableName: source1?.fileName,
          columns: finalValidation,
        },
      ],
      ConnectionId: source1.id,
    };
    const formData = new FormData();
    Object.keys(data).forEach((key2) => {
      const value = key2 === "file" ? data[key2] : JSON.stringify(data[key2]);
      formData.append(key2, value);
    });
    return formData;
  };

  const ValidationCheck = async () => {
    setValidateLoading(true);
    const data = FormatRequestData("");
    try {
      const response = await ApiService.checkValidation(data);
      setValidateLoading(false);
      setValidationsResult(response?.data?.response?.ResponseObject?.Validations);
      setValidationsResultShow(true);
    } catch (e) {
      setValidateLoading(false);
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
  };

  const getComponent = () => {
    if (activeStep === steps.length - 1) {
      return (
        finalValidation.length > 0 && (
          <Grid container>
            <Grid size={6} sx={{ textAlign: "left", mt: 1 }}>
              <Button
                color="error"
                size="small"
                variant="outlined"
                onClick={() => {
                  navigate("/DataQualityRule");
                }}>
                {t("Cancel")}
              </Button>
            </Grid>
            <Grid size={6} sx={{ textAlign: "right", mt: 1 }}>
              {validationsResultShow && (
                <ValidateResultModal
                  Validations={validationsResult}
                  model={true}
                  returnValue={(value) => {
                    setValidationsResultShow(value);
                    setValidationsResult([]);
                  }}
                />
              )}
              <CustomLoadingButton
                onClick={() => ValidationCheck()}
                sx={{ mr: 1 }}
                color="success"
                disabled={validateLoading || createLoading}
                loading={validateLoading}>
                {t("Preview")}
              </CustomLoadingButton>
              <CreateOrUpdateDQRule
                isPrivate={isPrivate}
                createLoading={createLoading}
                validateLoading={validateLoading}
                setIsPrivate={setIsPrivate}
                createValidation={createValidation}
              />
            </Grid>
          </Grid>
        )
      );
    }
    return (
      <Button disabled={!source1} variant="contained" onClick={handleNext}>
        {t("Next")}
      </Button>
    );
  };

  return (
    <Box sx={{ width: "100%" }} ref={ScrollRef}>
      <InnerHeader name={"Create Data Quality Rule - Single API"} />
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>{t("Reset")}</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <CustomBackButton sx={{ mb: 1 }} onClick={handleBack}>
            {t("Back")}
          </CustomBackButton>
          <Paper>
            <Box>
              <div style={{ display: activeStep === 0 ? "block" : "none" }}>
                <SingleDataSource
                  activeStep={activeStep}
                  setSource1={setSource1}
                  type="APIS"
                  sourceType={{ type: "APIS" }}
                />
              </div>

              <div style={{ display: activeStep === 1 ? "block" : "none" }}>
                <FileValidations
                  fileId={source1?.id}
                  finalValidation={finalValidation}
                  setfinalValidation={setfinalValidation}
                  inputParams={inputParams}
                  setinputParams={setinputParams}
                  requestBody={requestBody}
                  setrequestBody={setrequestBody}
                  setfile={setfile}
                  autoScroll={autoScroll}
                />
              </div>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", p: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              {getComponent()}
            </Box>
          </Paper>
        </React.Fragment>
      )}
      <Grid size={12} mt="16px">
        <FormHelperText>{t("Note")} : </FormHelperText>
        <FormHelperText>{t("note5")}</FormHelperText>{" "}
      </Grid>
    </Box>
  );
}
