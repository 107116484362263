import { useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Button, IconButton, Tooltip, Typography, FormHelperText } from "@mui/material";
import Grid from "@mui/material/Grid2";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import CustomBackButton from "../../CustomComponents/CustomsButtons/CustomBackButton";
import CustomLoadingButton from "../../CustomComponents/CustomsButtons/CustomLoadingButton";
import InnerHeader from "../../Layout/InnerHeader";
import SelectComparisonSources from "./SelectComparisonSources";
import Comparative from "./Comparative";
import CreateOrUpdateDQRule from "./CreateOrUpdateDQRule";
import { SnackbarContext } from "../../../App";
import APIServices from "../../../services/app.service";
import { isAzure } from "../../../_helpers/Constant";
import { useDispatch, useSelector } from "react-redux";
import { resetComparisonData } from "../../../Redux/reducers/rowComparisonSlice";
import ComparativeValidatePreview from "../ComparativeValidatePreview";

const NoMaxWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: { maxWidth: "50vw" },
});

const RowComparison = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const scrollRef = useRef();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { setSnack } = useContext(SnackbarContext);

  const firstConn = useSelector((state) => state.rowComparison.firstConnectionDetails);
  const secondConn = useSelector((state) => state.rowComparison.secondConnectionDetails);
  const selectedSource1Table = useSelector((state) => state.rowComparison.selectedSource1Table);
  const selectedSource2Table = useSelector((state) => state.rowComparison.selectedSource2Table);
  const columnsToValidate = useSelector((state) => state.rowComparison.columnsToValidate);

  const source1SqlInput = useSelector((state) => state.rowComparison.source1SqlInput);
  const source2SqlInput = useSelector((state) => state.rowComparison.source2SqlInput);

  const [validationsResult, setValidationsResult] = useState([]);
  const [validationsResultShow, setValidationsResultShow] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);
  const [sourcesLoading, setSourcesLoading] = useState(false);
  const isCustomQuery = JSON.parse(searchParams.get("isCustomQuery"));
  const isPrivate = JSON.parse(searchParams.get("isPrivate"));
  const isUnMatched = JSON.parse(searchParams.get("isUnMatched"));
  const isFullScan = JSON.parse(searchParams.get("isFullScan"));
  const firstSourceOrderBy = searchParams.get("firstSourceOrderBy");
  const secondSourceOrderBy = searchParams.get("secondSourceOrderBy");
  const firstSourceConId = searchParams.get("firstSourceConId");
  const secondSourceConId = searchParams.get("secondSourceConId");
  const firstDSFileName = searchParams.get("firstDSFileName");
  const secondDSFileName = searchParams.get("secondDSFileName");
  const ruleId = searchParams.get("ruleId");

  const validation = { row_count_matching: false, row_data_matching: true };

  const generateFormattedData = (ruleName) => {
    const is_row_data_matching = validation.row_data_matching;

    const firstConnectionType = firstConn?.connectionType;
    const secondConnectionType = secondConn?.connectionType;
    const formattedData = {
      TestDescription: "Test Description comes here",
      TestType: "Comparison",
      comparissonValidations: [
        {
          FirstDataSource: columnsToValidate.source1,
          SecondDataSource: columnsToValidate.source2,
          ValidationDisplayName: "Row Comparison",
          ValidationId: "22",
          ValidationName: "RowComparison",
        },
      ],
      TestName: ruleName,

      ...(!isCustomQuery && {
        FirstTableName: selectedSource1Table?.table_name || "",
        SecondTableName: selectedSource2Table?.table_name || "",
        FirstSourceSchema: selectedSource1Table?.table_schema || "",
        SecondSourceSchema: selectedSource2Table?.table_schema || "",
        firstSourceOrderBy: firstSourceOrderBy || "",
        secondSourceOrderBy: secondSourceOrderBy || "",
        firstDSFileName,
      }),

      ...(isCustomQuery && {
        FirstDatasourceSql: source1SqlInput,
        SecondDatasourceSql: source2SqlInput,
      }),

      ValidationName: is_row_data_matching ? "RowComparison" : "RowCount",
      ValidationId: is_row_data_matching ? "22" : "21",
      ValidationDisplayName: is_row_data_matching ? "Row Comparison" : "Row Count Match",

      FirstDatasourceId: firstConn?.connectionName ? firstConn?.id : firstConn?._id,
      FirstDatasourceName:
        firstConn?.dataBase || firstConn?.fileName || firstConn?.catalogName || firstConn?.connectionName,
      FirstDatasourceType: firstConn?.fileName || isAzure(firstConnectionType) ? "File" : "Database",
      SecondDatasourceId: secondConn?.id,
      SecondDatasourceName:
        secondConn?.dataBase ||
        secondConn?.fileName ||
        secondConn?.catalogName ||
        secondDSFileName ||
        secondConn.connectionName,
      SecondDatasourceType: secondConn?.fileName || isAzure(secondConnectionType) ? "File" : "Database",
    };

    return formattedData;
  };

  const createOrUpdateValidation = async (ruleName, createNew) => {
    setCreateLoading(true);
    const data = generateFormattedData(ruleName);
    const updatedData = { ...data, isPrivate: isPrivate, isFullScan: isFullScan, isUnMatched: isUnMatched };
    try {
      await (ruleId && !createNew
        ? APIServices.updateValidation({ ...data, isFullScan: isFullScan, isUnMatched: isUnMatched }, ruleId)
        : APIServices.createValidation(updatedData));

      setSnack({ message: "DQ Rule Created", open: true, colour: "success" });
      navigate("/DataQualityRule");
    } catch (error) {
      setSnack({ message: error?.response?.data?.TestName?.message || error.message, open: true, colour: "error" });
    }
    setCreateLoading(false);
  };

  const previewValidation = async () => {
    if (columnsToValidate?.source1?.length !== columnsToValidate?.source2?.length) {
      const msg = "Column mapping has unmapped columns. Please fix to create validation.";
      setSnack({ message: msg, open: true, colour: "warning" });
      return;
    }
    setValidateLoading(true);
    const data = generateFormattedData("");
    try {
      const response = await APIServices.checkValidation({ ...data, isFullScan: isFullScan, isUnMatched: isUnMatched });
      setValidationsResult(response?.data?.response?.ResponseObject?.Validations);
      setValidationsResultShow(true);
    } catch (e) {
      setSnack({ message: e?.response?.data?.message ?? e.message, open: true, colour: "error" });
    } finally {
      setValidateLoading(false);
    }
  };

  const autoScroll = () => {
    setTimeout(() => {
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, 600);
  };

  const handleBack = () => {
    if (ruleId || !firstSourceConId) {
      navigate("/DataQualityRule");
    } else {
      // columnsToValidate?.length && setFinalValidation([]);
      dispatch(resetComparisonData(null));

      navigate("/DataQualityRule/row-comparison/create?firstDataSourceType=database&secondDataSourceType=database");
    }
  };

  return (
    <>
      <InnerHeader>
        <Box width="100%" className="space-between">
          <Box className="v-center" gap={2}>
            <CustomBackButton onClick={handleBack}>{t("Back")}</CustomBackButton>
            <Typography variant="h6">{t("createComparisionHeading")}</Typography>
          </Box>
          <IconButton>
            <NoMaxWidthTooltip width={"100%"} title={t("note9")} placement="left-start" arrow>
              <InfoOutlinedIcon />
            </NoMaxWidthTooltip>
          </IconButton>
        </Box>
      </InnerHeader>

      <Box className="comparisonContainer pt74" ref={scrollRef}>
        {!(ruleId || (firstSourceConId && secondSourceConId)) ? (
          <SelectComparisonSources />
        ) : (
          <Comparative setSourcesLoading={setSourcesLoading} sourcesLoading={sourcesLoading} autoScroll={autoScroll} />
        )}

        {firstSourceConId && !sourcesLoading && (
          <Grid container pt={2}>
            <Grid size={12} sx={{ textAlign: "right" }}>
              <Button
                sx={{ mr: 2 }}
                color="error"
                size="small"
                variant="outlined"
                onClick={() => navigate("/DataQualityRule")}>
                {t("Cancel")}
              </Button>

              <CustomLoadingButton
                onClick={previewValidation}
                sx={{ mr: 2 }}
                className="PreviewButton"
                disabled={validateLoading || createLoading || !columnsToValidate?.source1?.length}
                loading={validateLoading}>
                {t("Preview")}
              </CustomLoadingButton>
              <CreateOrUpdateDQRule
                createLoading={createLoading}
                validateLoading={validateLoading}
                createValidation={createOrUpdateValidation}
              />
            </Grid>

            <Grid size={12}>
              <FormHelperText>{t("Note")} : </FormHelperText>
              <FormHelperText>{t("note5")}</FormHelperText>
              <FormHelperText>
                <FormHelperText>{t("note6")}</FormHelperText>
              </FormHelperText>
            </Grid>
          </Grid>
        )}

        {validationsResultShow && (
          <ComparativeValidatePreview
            validationsResult={validationsResult}
            handleClose={() => {
              setValidationsResultShow(false);
              setValidationsResult([]);
            }}
          />
        )}
      </Box>
    </>
  );
};

export default RowComparison;
