import { FormControl, FormLabel } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CustomAutoComplete from "../CustomComponents/CustomAutoComplete";

export function DropDownData(props) {
  const { placeholder, options, heading, value, setValue, loader = false } = props;

  return (
    <Grid size={12} sx={{ p: 2 }}>
      {heading && (
        <Grid size={12}>
          <FormControl>
            <FormLabel>{heading}:</FormLabel>
          </FormControl>
        </Grid>
      )}
      <Grid size={12}>
        <CustomAutoComplete
          disablePortal
          disableClearable={!value}
          groupBy={(option) => option.table_type}
          getOptionLabel={(option) => (option ? option : "")}
          loading={loader}
          options={options}
          value={value}
          label={placeholder}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={(e, val) => setValue(val)}
        />
      </Grid>
    </Grid>
  );
}
