import { createSlice } from "@reduxjs/toolkit";
import { usersService } from "../services/users.service";

const initialState = { loadingExistingUsers: false, existingUsers: [] };

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(usersService.fetchExistingUsers.pending, (state) => {
        state.loadingExistingUsers = true;
      })
      .addCase(usersService.fetchExistingUsers.fulfilled, (state, action) => {
        state.existingUsers = action.payload;
        state.loadingExistingUsers = false;
      })
      .addCase(usersService.fetchExistingUsers.rejected, (state) => {
        state.loadingExistingUsers = false;
      });
  },
});

export default usersSlice.reducer;
