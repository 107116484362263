import TestScheduler from "./TestScheduler";
import CustomDrawer from "../../CustomComponents/CustomDrawer";

export default function CreateScheduledTest({
  testData = {},
  selectedRules,
  setSchduleTestOpen,
  onSuccessCreateScheduled,
}) {
  const now = new Date();
  // eslint-disable-next-line no-mixed-operators
  const fiveMinutesAhead = new Date(now.getTime() + 5 * 60000);
  const oneDayAhead = new Date(fiveMinutesAhead.getTime());
  oneDayAhead.setDate(oneDayAhead.getDate() + 1);
  const scheduleData = {
    Title: testData.Title || "",
    RepeatType: testData.RepeatType || false,
    Description: testData.Description || "",
    FromDate: testData.FromDate || fiveMinutesAhead,
    ExecuteParallel: testData.ExecuteParallel || false,
    TestType: testData.TestType || "Validation Test",
    Frequency: testData.Frequency || 2,
    Each: testData.Each || 1,
    ToDate: testData.ToDate || oneDayAhead,
  };

  return (
    <>
      <CustomDrawer onClose={() => setSchduleTestOpen(false)} title={"Scheduled DQ Rules"}>
        <TestScheduler
          scheduledId={testData?._id}
          selectedRules={selectedRules}
          scheduleData={scheduleData}
          setSchduleTestOpen={setSchduleTestOpen}
          onSuccessCreateScheduled={onSuccessCreateScheduled}
        />
      </CustomDrawer>
    </>
  );
}
