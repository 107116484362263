import {
  Box,
  Card,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomLoader from "../../CustomComponents/CustomLoader";
import { useSearchParams } from "react-router-dom";
import CollapsibleSearch from "../../CustomComponents/CollapsibleSearch";
import { useMemo, useState } from "react";

const DataProfileSelectTables = ({
  selectedConnection,
  loading,
  filteredColumns,
  tableList,
  handleOnClickTable,
  selectedTable,
  columnsLoading,
}) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const orderBy = searchParams.get("orderBy");
  const sortBy = searchParams.get("sortBy");

  const [searchInput, setSearchInput] = useState("");

  const onChangeSearchInput = (value) => {
    setSearchInput(value);
  };

  const filteredTableList = useMemo(() => {
    const lowerSearchInput = searchInput?.toLowerCase() || "";

    return tableList?.filter((each) => {
      const tableName = each?.table_name?.toLowerCase() || "";
      const tableSchema = each?.table_schema?.toLowerCase() || "";

      return (
        tableName.startsWith(lowerSearchInput) ||
        tableName.includes(lowerSearchInput) ||
        tableSchema.includes(lowerSearchInput)
      );
    });
  }, [tableList, searchInput]);

  const handleOnChangeOrderBy = (event) => {
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set("orderBy", event.target.value);
      return params;
    });
  };

  const handleOnChangeSortBy = (event) => {
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set("sortBy", event.target.value);
      return params;
    });
  };

  return (
    <Box sx={{ background: "#F4F1FF" }}>
      <Box className="space-between container-heading">
        <Box className="v-center" sx={{ gap: 1 }}>
          <Typography variant="h6">
            Select {selectedConnection?.connectionType === "Azure Blob" ? t("File") : t("Table")}
          </Typography>

          <CollapsibleSearch
            placeholderText="Search for Table..."
            queryValue={searchInput}
            onQueryChange={onChangeSearchInput}
            maxWidth="500px"
            initialExpandedState={true}
          />
        </Box>

        {(orderBy || columnsLoading) && (
          <Box className="v-center" sx={{ gap: 1 }}>
            <FormControl required sx={{ minWidth: 120 }} size="small">
              <InputLabel id="demo-select-small-label">Order By</InputLabel>
              <Select
                labelId="demo-select-small-label"
                value={orderBy}
                label="Order By"
                disabled={columnsLoading}
                onChange={handleOnChangeOrderBy}>
                {filteredColumns?.map((each) => (
                  <MenuItem key={each} value={each}>
                    {each}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              required
              className="DQRuleSortBy"
              sx={{ minWidth: 88 }}
              disabled={columnsLoading}
              value={sortBy}
              select
              size="small"
              label={t("sort_by")}
              onChange={handleOnChangeSortBy}>
              <MenuItem value="ASC">{t("Asc")}</MenuItem>
              <MenuItem value="DESC">{t("Desc")}</MenuItem>
            </TextField>
          </Box>
        )}
      </Box>
      <Box className="list-container">
        {loading && <CustomLoader />}
        {!loading && !filteredTableList?.length ? (
          <Typography variant="h6" className="preview-text">
            {selectedConnection ? "No tables available" : "Please Select Connection"}
          </Typography>
        ) : (
          !loading && (
            <Box className="space-between list-headers">
              <Typography variant="body1">
                {t("Schema")} . {t("Table Name")}
              </Typography>
              <Typography sx={{ display: "none" }} variant="body1">
                {t("No. of Columns")}
              </Typography>
            </Box>
          )
        )}

        {filteredTableList?.map((table, index) => (
          <Card
            className="card-styles"
            key={`table-${index}`}
            onClick={() => handleOnClickTable(table)}
            sx={{ backgroundColor: selectedTable?.table_name === table?.table_name ? "#BCE0FF" : "#F4F1FF" }}>
            <Box className="space-between" sx={{ "& p": { color: "#365E7D", fontWeight: 600 } }}>
              <Typography variant="body1">
                {table?.table_schema ? `${table?.table_schema} . ${table?.table_name}` : table?.table_name}
              </Typography>
              {columnsLoading && selectedTable?.table_name === table?.table_name ? (
                <CircularProgress size={18} />
              ) : (
                <Typography sx={{ display: "none" }} variant="body1">
                  2
                </Typography>
              )}
            </Box>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default DataProfileSelectTables;
