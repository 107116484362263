import { Tooltip, Typography } from "@mui/material";

const customTooltip = (props) => (
  <Tooltip
    className="ellipsis"
    title={<Typography className="ellipsisSql">{props?.value}</Typography>}
    placement="top-end">
    {props?.value}
  </Tooltip>
);

export const comaparisionHeaders = [
  {
    field: "S.No",
    headerName: "S.No",
    valueGetter: "node.rowIndex + 1",
    sortable: false,
    suppressColumnsToolPanel: true,
    // suppressHeaderMenuButton: true,
    filter: false,
  },
  {
    field: "ruleName",
    headerName: "Rule Name",
    sortable: true,
    filter: true,
    // suppressColumnsToolPanel: true,
  },

  {
    field: "sourceA",
    headerName: "Source A",
    sortable: false,
    suppressColumnsToolPanel: true,

    filter: false,
    cellRenderer: customTooltip,
  },

  {
    field: "selectedColumnInSourceA",
    headerName: "Selected Columns In Source A",
    sortable: false,
    suppressColumnsToolPanel: true,

    filter: false,
    cellRenderer: customTooltip,
  },
  {
    field: "orderBySourceA",
    headerName: "Order By Source A",
    sortable: false,
    suppressColumnsToolPanel: true,

    filter: false,
  },
  {
    field: "sourceB",
    headerName: "Source B",
    sortable: false,
    suppressColumnsToolPanel: true,

    filter: false,
    cellRenderer: customTooltip,
  },
  {
    field: "selectedColumnsInSourceB",
    headerName: "Selected Columns In Source B",
    sortable: false,
    suppressColumnsToolPanel: true,

    filter: false,
    cellRenderer: customTooltip,
  },
  {
    field: "orderBySourceB",
    headerName: "Order By Column In Source B",
    sortable: false,
    suppressColumnsToolPanel: true,

    filter: false,
  },
  {
    field: "totalRecordsSourceA",
    headerName: "Total Records In Source A",
    sortable: false,
    suppressColumnsToolPanel: true,

    filter: false,
  },
  {
    field: "totalRecordsSourceB",
    headerName: "Total Records In Source B",
    sortable: false,
    suppressColumnsToolPanel: true,

    filter: false,
  },
  {
    field: "totalRecordsCompared",
    headerName: "Total Records Compared",
    sortable: false,
    suppressColumnsToolPanel: true,

    filter: false,
  },
  {
    field: "totalRecordsMatched",
    headerName: "Total Records In Matched",
    sortable: false,
    suppressColumnsToolPanel: true,

    filter: false,
  },
  {
    field: "result",
    headerName: "Result",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: true,
  },
  {
    field: "executionDate",
    headerName: "Execution Date",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: false,
    width: 300,
  },
];
