import { customComparator } from "../AgGrid/CustomSort";
import { formatDate } from "../../_helpers/utils";

export const masterdataheadCells = [
  // {
  //   sortable: false,
  //   headerCheckboxSelection: true,
  //   checkboxSelection: true,
  //   lockPosition: "left",
  //   cellClass: "locked-col",
  //   maxWidth: 50,
  //   suppressColumnsToolPanel: true,
  //   suppressHeaderMenuButton: true,
  //   suppressMovable: true,
  //   filter: false,
  // },
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
  },
  {
    field: "description",
    headerName: "Description",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
  },
  {
    field: "createdAt",
    headerName: "Created Date",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: "agDateColumnFilter",
    cellRenderer: createdDate,
  },
  {},
];

function createdDate(props) {
  return <>{formatDate(props.data.createdAt)}</>;
}
