import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import ApiService from "../../../../services/app.service";
import ComparisonSource from "./ComparisonSource";
// import CompareFinalValidation from "../CompareFinalValidation";
// import ColumnMapping from "../ColumnMapping";

import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../../Redux/reducers/toastSlice";
import SelectComparisonTable from "./SelectComparisonTable";
import { resetComparisonData, updateRowComparisonFields } from "../../../../Redux/reducers/rowComparisonSlice";
import SkeletonLoader from "../../../SkeletonLoader";

import ComparativeHeader from "./ComparativeHeader";
import { Paper } from "@mui/material";

const Comparative = ({ sourcesLoading, setSourcesLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const firstConn = useSelector((state) => state.rowComparison.firstConnectionDetails);
  const secondConn = useSelector((state) => state.rowComparison.secondConnectionDetails);

  const source1Columns = useSelector((state) => state.rowComparison.source1Columns);
  const source2Columns = useSelector((state) => state.rowComparison.source2Columns);

  const source1QueryResult = useSelector((state) => state.rowComparison.source1QueryResult);
  const source2QueryResult = useSelector((state) => state.rowComparison.source2QueryResult);

  const [searchParams, setSearchParams] = useSearchParams();

  const firstDataSourceType = searchParams.get("firstDataSourceType");
  const secondDataSourceType = searchParams.get("secondDataSourceType");

  const ruleId = searchParams.get("ruleId");

  const [validationsToDelete, setValidationsToSelected] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      setSourcesLoading(true);
      try {
        const response = await ApiService.editValidation(ruleId, { signal });

        // const source1AzureBlob =
        //   response?.data?.ConnectionDetails?.FirstDatasourceDetails?.connectionType === "Azure Blob";
        // const source2AzureBlob =
        //   response?.data?.ConnectionDetails?.SecondDatasourceDetails?.connectionType === "Azure Blob";

        const defaultSource1TableDetails = {
          table_name:
            response?.data?.FirstTableName || response?.data?.comparissonValidations[0]?.FirstDataSource[0]?.Table,
          table_schema:
            response?.data?.FirstSourceSchema || response?.data?.ConnectionDetails?.FirstDatasourceDetails?.schema,
          table_type: "Tables",
        };

        const defaultSource2TableDetails = {
          table_name:
            response?.data?.SecondTableName || response?.data?.comparissonValidations[0]?.SecondDataSource[0]?.Table,
          table_schema:
            response?.data?.SecondSourceSchema || response?.data?.ConnectionDetails?.SecondDatasourceDetails?.schema,
          table_type: "Tables",
        };

        dispatch(
          updateRowComparisonFields({
            selectedSource1Table: defaultSource1TableDetails,
            selectedSource2Table: defaultSource2TableDetails,
            firstConnectionDetails: response?.data?.ConnectionDetails?.FirstDatasourceDetails,
            secondConnectionDetails: response?.data?.ConnectionDetails?.SecondDatasourceDetails,
            ...(response?.data?.ConnectionDetails?.FirstDatasourceDetails && {
              source1SqlInput: response?.data?.FirstDatasourceSql,
              source2SqlInput: response?.data?.SecondDatasourceSql,
            }),
            columnsToValidate: {
              source1: response?.data?.comparissonValidations[0]?.FirstDataSource,
              source2: response?.data?.comparissonValidations[0]?.SecondDataSource,
            },
          }),
        );

        setSearchParams((prev) => {
          const newState = new URLSearchParams(prev);
          newState.set("firstSourceConId", response?.data?.ConnectionDetails?.FirstDatasourceDetails?.id);
          newState.set("secondSourceConId", response?.data?.ConnectionDetails?.SecondDatasourceDetails?.id);
          newState.set("firstDataSourceType", response?.data?.FirstDatasourceType.toLowerCase());
          newState.set("secondDataSourceType", response?.data?.SecondDatasourceType.toLowerCase());
          newState.set("isCustomQuery", Boolean(response?.data?.FirstDatasourceSql));
          newState.set("isPrivate", response?.data?.isPrivate);
          newState.set("isUnMatched", response?.data?.isUnMatched || false);
          newState.set("firstSourceOrderBy", response?.data?.firstSourceOrderBy);
          newState.set("secondSourceOrderBy", response?.data?.secondSourceOrderBy);
          newState.set("isFullScan", response?.data?.isFullScan);
          newState.set("ruleName", response?.data?.TestName);
          newState.set("firstDSFileName", response?.data?.FirstDatasourceName);
          newState.set("secondDSFileName", response?.data?.SecondDatasourceName);
          newState.set("isExistsExecutions", response?.data?.noOfExecutions > 0 || false);
          return newState;
        });
      } catch (e) {
        dispatch(showToast({ message: e?.response?.data?.message ?? e.message, type: "error" }));
        navigate("/DataQualityRule");
      } finally {
        setSourcesLoading(false);
      }
    };

    if (ruleId) {
      fetchData();
    }

    return () => {
      controller.abort();
      dispatch(resetComparisonData(null));
    };
  }, []);

  if (sourcesLoading || !firstDataSourceType || !secondDataSourceType || (ruleId && !(firstConn || secondConn))) {
    return <SkeletonLoader />;
  }

  return (
    <>
      <SelectComparisonTable />

      <ComparativeHeader
        validationsToDelete={validationsToDelete}
        setValidationsToSelected={setValidationsToSelected}
      />
      <Paper>
        <Grid container sx={{ border: "1px solid #D9D9D9" }}>
          <ComparisonSource
            connectionDetails={firstConn}
            isFirstSource={true}
            columnsData={source1Columns}
            queryResult={source1QueryResult}
            setValidationsToSelected={setValidationsToSelected}
          />

          <ComparisonSource
            connectionDetails={secondConn}
            isFirstSource={false}
            columnsData={source2Columns}
            queryResult={source2QueryResult}
          />
        </Grid>
      </Paper>
    </>
  );
};

export default Comparative;
