import { useContext, useState } from "react";
import { Button, FormLabel, Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../../../App";

import { ModalFilePreview } from "../../ModalFilePreview";
import CreateNewFile from "../../../DataSources/CreateFile/CreateNewFile";
import CreateConnection from "../../../DataSources/CreateDS/CreateConnection";
import CustomRadioGroup from "../../../CustomComponents/CustomRadioGroup";
import CustomAutoComplete from "../../../CustomComponents/CustomAutoComplete";

const SelectDatasource = ({
  heading,
  filesOptions,
  databaseOptions,
  setSelectedConnection,
  dataSourceType,
  loading,
  setLoading,
  fetchConnectionByType,
  onChangeDataSourceType,
  selectedConnection,
}) => {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);

  const [addNewConnection, setAddNewConnection] = useState(false);
  const [state, setState] = useState(false);

  const labelDetails =
    dataSourceType === "database"
      ? { label: "Select Connection", placeholder: "Filter connection list" }
      : { label: "Select File", placeholder: "Filter file list" };
  const dataSourceOptions = dataSourceType === "database" ? databaseOptions : filesOptions;

  const handleDataSourceChange = async (e) => {
    const newDataSourceType = e.target.value;
    setLoading(true);
    setSelectedConnection(null);
    onChangeDataSourceType(newDataSourceType);
    if (newDataSourceType === "file" && !filesOptions.length) {
      await fetchConnectionData("Files");
    }
    setLoading(false);
  };

  const fetchConnectionData = async (conType) => {
    setLoading(true);
    try {
      await fetchConnectionByType(conType);
    } catch (error) {
      setSnack({ message: error?.response?.data?.message || error.message, open: true, colour: "error" });
    }
    setLoading(false);
  };

  const onSuccessCreateConnection = (val) => {
    fetchConnectionData("database");
    setSelectedConnection(val);
  };

  const onSuccessCreateFile = (val) => {
    fetchConnectionData("Files");
    setSelectedConnection(val);
  };

  const renderSelectedOptionDetails = () => {
    if (!selectedConnection || Object.keys(selectedConnection).length === 0) {
      return null;
    }

    return dataSourceType === "database" ? (
      <Typography>
        <b>{t("Database Type")}:</b> {selectedConnection?.connectionType} |{" "}
        {selectedConnection?.server && (
          <>
            <b>{t("Server")}:</b> {selectedConnection?.server} |{" "}
          </>
        )}
        {selectedConnection?.dataBase && (
          <>
            <b>{t("Database")}:</b> {selectedConnection?.dataBase} |{" "}
          </>
        )}
        {selectedConnection?.serverHostname && (
          <>
            <b>{t("Server Host Name")}:</b> {selectedConnection?.serverHostname} |{" "}
          </>
        )}
        {selectedConnection?.catalogName && (
          <>
            <b>{t("Catalog Name")}:</b> {selectedConnection?.catalogName} |{" "}
          </>
        )}
        {selectedConnection?.accountName && (
          <>
            <b>{t("Account Name")}:</b> {selectedConnection?.accountName} |{" "}
          </>
        )}
        {selectedConnection?.schemas && (
          <>
            <b>{t("Schemas")}:</b>{" "}
            {Array.isArray(selectedConnection?.schemas)
              ? selectedConnection?.schemas.join(", ")
              : selectedConnection?.schemas}
          </>
        )}
      </Typography>
    ) : (
      <Typography>
        <b>{t("Type")}:</b> {selectedConnection?.ext} | <b>{t("Size")}:</b>{" "}
        {(selectedConnection?.size * 0.001).toFixed(1)}KB
      </Typography>
    );
  };

  const onChangeDataSource = (e, newValue) => {
    setSelectedConnection(newValue);
  };

  return (
    <>
      <Grid size={6} sx={{ px: 1 }}>
        <Grid container className="comparisonDS">
          <Grid size={12}>
            <CustomRadioGroup
              value={dataSourceType}
              onChange={handleDataSourceChange}
              options={[
                { value: "database", label: "Database" },
                { value: "file", label: "File" },
              ]}
              label={`${heading} : `}
            />
          </Grid>

          <Grid size={12} className="selectConnection">
            <Grid container sx={{ alignItems: "center" }}>
              <Grid size={12} sx={{ mb: 1 }}>
                <FormLabel>{t(labelDetails.label)}:</FormLabel>
              </Grid>

              <Grid size={7}>
                <CustomAutoComplete
                  loading={loading}
                  options={dataSourceOptions}
                  label={t(labelDetails.label)}
                  placeholder={t(labelDetails.placeholder)}
                  value={selectedConnection}
                  getOptionLabel={(option) => {
                    if (option.connectionName) {
                      return `${option.connectionName} -- ( ${option.connectionType} )`;
                    } else if (option.fileName) {
                      return option.fileName;
                    }
                    return option;
                  }}
                  getOptionIcon={(opt) => (
                    <img
                      src={`/assets/uploads/${opt?.connectionType?.toLowerCase()}.png`}
                      alt={opt.connectionType}
                      style={{ width: 25, height: 25, marginRight: 5 }}
                    />
                  )}
                  onChange={onChangeDataSource}
                />
              </Grid>

              <Grid size={5}>
                <Box className="v-center" gap={1}>
                  <Button
                    size="small"
                    sx={{ ml: 1 }}
                    variant="contained"
                    onClick={() => (dataSourceType === "file" ? setState(true) : setAddNewConnection(true))}>
                    {t("New")}
                  </Button>

                  {dataSourceType === "file" && (
                    <ModalFilePreview selectedOption={selectedConnection} heading={heading} />
                  )}
                </Box>
              </Grid>
            </Grid>

            <Grid container sx={{ mt: 1 }}>
              <Grid size={12}>{renderSelectedOptionDetails()}</Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {state && <CreateNewFile onSuccessCreate={onSuccessCreateFile} onClose={() => setState(false)} />}

      {addNewConnection && (
        <CreateConnection
          type="Add"
          handleSuccessCreate={onSuccessCreateConnection}
          onClose={() => setAddNewConnection(false)}
        />
      )}
    </>
  );
};

export default SelectDatasource;
