import { useState, useContext } from "react";
import { Box, Divider, Pagination, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ApiService from "../../../services/app.service";
import { SnackbarContext } from "../../../App";
import { formatDateWithTime, totalExecutionsPerPage } from "../../../_helpers/utils";
import ResultsList from "../../Validations/Executions/ResultsList";
import SkeletonLoader from "../../SkeletonLoader";
import ResultDetailView from "../../Validations/Executions/ResultDetailView";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import CustomGradientButton from "../../CustomComponents/CustomsButtons/CustomGradientButton";
import CustomBackButton from "../../CustomComponents/CustomsButtons/CustomBackButton";
import { RESULTS_PER_PAGE } from "../../../_helpers/Constant";

const ExecutionDetailItem = ({ title, value, divider = false }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ fontWeight: "bold" }}>
      <strong>{t(title)} : </strong>
      <span style={{ fontWeight: "normal" }}>{value}</span>
      {divider && (
        <Divider
          orientation="vertical"
          flexItem
          variant="middle"
          sx={{ borderRightWidth: 2.5, ml: "10px", display: "inline" }}
        />
      )}
    </Box>
  );
};
const CollectionResults = ({
  testType,
  totalexecutions,
  selectedCollection,
  executions,
  resultsLoading,
  setExecutions,
  getResultsData,
  fetchList,
}) => {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = JSON.parse(searchParams.get("currentPage"));
  const totalPages = Math.ceil(totalexecutions / totalExecutionsPerPage);
  const viewType = searchParams.get("viewType");
  const expanded = JSON.parse(searchParams.get("expanded"));

  const [resultValidation, setResultValidation] = useState([]);
  const [selectedExecution, setSelectedExecution] = useState({});
  const [batchesCurrentPage, setBatchesCurrentPage] = useState(1);
  const [executeResultsLoading, setExecuteResultsLoading] = useState(false);
  const comparisionTabValue = testType ? "Comparison" : null;

  const [resultType, setResultType] = useState(comparisionTabValue || "Comparison");
  const getExecutionResults = async (executionId, currentPageNumber) => {
    try {
      setExecuteResultsLoading(true);
      const response = await ApiService.getTestExecutionsResults({
        executionId: executionId,
        pageSize: RESULTS_PER_PAGE,
        page: currentPageNumber,
      });
      setResultValidation(response?.data);
      setExecuteResultsLoading(false);
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
  };

  const onClickExecutionCard = (obj) => {
    setSelectedExecution(obj);
    getExecutionResults(obj?._id, 1);
  };
  const executionResultsPageChange = (event, page) => {
    if (batchesCurrentPage !== page) {
      setBatchesCurrentPage(page);
      const executionId = selectedExecution?._id;
      getExecutionResults(executionId, page);
    }
  };
  const handlePageChange = (event, page) => {
    const newPage = totalPages < page ? Number(totalPages) : Number(page);
    if (currentPage !== page) {
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("currentPage", Number(newPage));
        return newState;
      });

      getResultsData(selectedCollection, newPage);
    }
  };

  const handleSortBy = (sortData) => {
    sortData &&
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("sortBy", sortData);
        return newState;
      });
    getResultsData(selectedCollection);
  };
  const handleOnSubmitDateRange = () => {
    getResultsData(selectedCollection);
  };
  const handleOnChangeReslutType = (newValue) => {
    setResultType(newValue);
  };

  const comparisionRowsCount = executions?.filter((each) => each?.validationDetails?.TestType === "Comparison")?.length;
  const nonComparisonRows = executions?.filter((each) => each?.validationDetails?.TestType !== "Comparison")?.length;
  const showPagination = resultType === "Comparison" ? Boolean(comparisionRowsCount) : Boolean(nonComparisonRows);

  const getResultComponent = () => (
    <Box className="DQRulesResultsContainer">
      <Grid container className="DQRuleExecutionDetails">
        <Grid size={5}>
          <CustomGradientButton
            active={resultType === "Comparison"}
            onClick={() => handleOnChangeReslutType("Comparison")}
            label={t("Row Comparision Results")}
          />

          <CustomGradientButton
            active={resultType === "validation"}
            onClick={() => handleOnChangeReslutType("validation")}
            label={t("Data Quality Checks")}
            sx={{ ml: 2 }}
          />
        </Grid>
        <Grid size={7}>
          <Box className="v-center" gap={2}>
            <Typography variant="detailTitle">
              {t(`${testType}Name`)} :{" "}
              <Typography variant="detailValue" component={"span"}>
                {selectedCollection?.title}
              </Typography>
            </Typography>

            <Typography variant="detailTitle">
              {`${t("DQ Rules")}`} :{" "}
              <Typography variant="detailValue" component={"span"}>
                {selectedCollection?.ruleIds?.length}
              </Typography>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <ResultsList
        resultsLoading={resultsLoading}
        resultType={resultType}
        testType={testType}
        executions={executions}
        setExecutions={setExecutions}
        handleSortBy={handleSortBy}
        onClickExecutionCard={onClickExecutionCard}
        handleOnSubmitDateRange={handleOnSubmitDateRange}
        fetchDataValidations={fetchList}
      />
      {viewType === "grid" && showPagination && (
        <Pagination
          sx={{ my: 1.5, marginLeft: "auto", float: "right" }}
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
        />
      )}
    </Box>
  );

  const getResultDetailView = () => {
    if (executeResultsLoading) {
      return <SkeletonLoader />;
    }
    return (
      <ResultDetailView
        selectedExecution={selectedExecution}
        resultValidation={resultValidation}
        batchesCurrentPage={batchesCurrentPage}
        executionResultsPageChange={executionResultsPageChange}
      />
    );
  };

  const totalRecordsValue =
    selectedExecution?.validationDetails?.TestType !== "Comparison"
      ? selectedExecution?.totalRecordsProcessed
      : selectedExecution?.totalRecordsCount;

  return (
    <Box sx={{ mt: 2 }} className="collectionResult">
      {!expanded ? (
        getResultComponent()
      ) : (
        <Box className="DQRulesResultsContainer">
          <CustomBackButton
            onClick={() => {
              setSearchParams((prev) => {
                const newState = new URLSearchParams(prev);
                newState.set("expanded", false);
                return newState;
              });
              setBatchesCurrentPage(1);
            }}>
            {t("Back")}
          </CustomBackButton>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              background: "#F2F2F2",
              bordeRadius: "5px",
              p: 1,
              gap: 2,
            }}>
            <ExecutionDetailItem divider title="Executed By" value={selectedExecution?.ExecutedBy} />
            <ExecutionDetailItem
              divider
              title="Start Time"
              value={formatDateWithTime(selectedExecution?.startDateTime)}
            />
            <ExecutionDetailItem
              divider
              title="End Time"
              value={formatDateWithTime(selectedExecution?.finishDateTime)}
            />
            <ExecutionDetailItem divider title="Total Records" value={totalRecordsValue} />
            <ExecutionDetailItem divider title="Batches" value={selectedExecution?.totalBatchCount} />
            <ExecutionDetailItem title="Checks" value={selectedExecution?.Validations?.length} />
          </Box>
          {getResultDetailView()}
        </Box>
      )}
    </Box>
  );
};

export default CollectionResults;
