import { formatDateWithTime } from "../../../_helpers/utils";
import { customComparator } from "../../AgGrid/CustomSort";

const ExecutionDate = (props) => <>{formatDateWithTime(props.data.LastExecution)}</>;

export const dataValidationsListHeadCells = [
  // {
  //   sortable: false,
  //   lockPosition: "left",
  //   cellClass: "locked-col",
  //   suppressColumnsToolPanel: true,
  //   maxWidth: 50,
  //   suppressHeaderMenuButton: true,
  //   suppressMovable: true,
  //   filter: false,
  // },
  {
    field: "ConnectionName",
    headerName: "Data Source Name",
    sortable: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
  },
  {
    field: "TestName",
    headerName: "DQ Rule Name",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
    headerTooltip: "DQ Rule Name",
  },
  {
    field: "ConnectionType",
    headerName: "Data Source Type",
    sortable: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
    headerTooltip: "Rule Type",
  },
  {
    field: "NoOfValidations",
    headerName: "Data Quality Checks",
    sortable: true,
    filter: "agTextColumnFilter",
    suppressColumnsToolPanel: true,
    suppressMovable: true,
    headerTooltip: "Data Quality Checks",
  },
  {
    field: "LastExecution",
    headerName: "Last Executed On",
    filter: false,
    suppressMovable: true,
    headerTooltip: "Last Executed On",
    cellRenderer: ExecutionDate,
  },
  {},
];
