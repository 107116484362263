import React from "react";
import { Checkbox, TextField, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const GetControllerFromInput = React.memo(({ input, inputsHandler, checkBoxHandler }) => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const validateCheck = searchParams.get("validateCheck");

  // if (input?.ControlType === "Dropdown") {
  //   return (
  //     <TextField
  //       name={input.StateName}
  //       select
  //       sx={{ width: "100px" }}
  //       size="small"
  //       value={input.SelectedValue}
  //       onChange={(e) => inputsHandler(e, columnIndex, keyName)}
  //     >
  //       {input.ControlProperties.Values.map((item) => (
  //         <MenuItem key={item} value={item}>
  //           {item}
  //         </MenuItem>
  //       ))}
  //     </TextField>
  //   );
  // }

  if (input?.ControlType === "CheckBox") {
    return (
      <FormControlLabel
        name={input.StateName}
        onChange={checkBoxHandler}
        sx={{ py: 0.5, pr: 0.5, mr: 0, "& span": { pr: 0.3 } }}
        control={
          <Checkbox
            sx={{
              height: "24px",
              "& input": { p: 0.5 },
              "& label": { m: 0 },
            }}
            checked={input.ControlProperties?.IsChecked ? JSON.parse(input.ControlProperties?.IsChecked) : false}
            size="small"
          />
        }
        label={t(input.DisplayName)}
      />
    );
  }
  if (["Text", "Integer"].includes(input?.ControlType)) {
    const isNumberInput = input.ControlType === "Integer";
    const inputProps = isNumberInput ? { inputProps: { min: "1", step: "1" } } : {};

    return (
      <TextField
        sx={{
          width: "-webkit-fill-available",
          flexGrow: 1,
          ...(isNumberInput && { mt: 0.4 }),
          "& input": { p: 0.5 },
          "& label": { transform: "translate(14px, 4px) scale(1)" },
        }}
        type={isNumberInput ? "number" : "text"}
        name={input.StateName}
        value={input?.SelectedValue || ""}
        onChange={inputsHandler}
        size="small"
        placeholder={t(input.DisplayName)}
        variant="outlined"
        InputProps={inputProps}
        error={validateCheck && !input?.SelectedValue}
      />
    );
  }

  return null;
});
GetControllerFromInput.displayName = "GetControllerFromInput";
export default GetControllerFromInput;
