import React, { createContext, useLayoutEffect, useState } from "react";
import { CssBaseline, Snackbar, ThemeProvider } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import "./App.css";
import { useTranslation } from "react-i18next";

import { setupAxiosInterceptors } from "./services/axios-interceptor";
import SessionTimeout from "./services/SessionTimeout";
import { toasterMessages } from "./_helpers/utils";

import { ColorModeContext, useMode } from "./theme/theme";
import RouteManager from "./components/RouteManager";
import { sessionTimeout, userLocalSession } from "./_helpers/Constant";
import CustomAlert from "./components/CustomComponents/CustomAlert";
import { addConnectionsList } from "./Redux/reducers/connectionSlice";
import { useDispatch, useSelector } from "react-redux";
import APIServices from "./services/app.service";
import { authServices } from "./Redux/services/auth.services";
import { setUserDetails } from "./Redux/reducers/authSlice";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export const SnackbarContext = createContext({});

const initialState = { message: "", colour: "", open: false };

const App = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [theme, colorMode] = useMode();
  const { user_details, token } = useSelector((state) => state.auth);

  const [snack, setSnack] = useState(initialState);

  let snackMessage;

  if (typeof snack.message === "string" && (snack.message.includes(" 500") || snack.message.includes(" 400"))) {
    snackMessage = "Oops!! There was a glitch. Please try in a while.";
  } else {
    snackMessage =
      typeof snack.message === "string"
        ? toasterMessages[snack?.message] || snack?.message
        : "Oops!! There was a glitch. Please try in a while.";
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnack({ ...snack, open: false });
  };

  const logOut = async () => {
    authServices.logoutUser(user_details, dispatch);
  };

  const handleSessionTimeout = () => {
    if (user_details?.SessionId) {
      setSnack({ message: "Your session has expired. Please log in again.", open: true, colour: "error" });
      logOut();
    }
  };

  const fetchConnections = async () => {
    try {
      const response = await APIServices.getConnectionsByType({ type: "database" });
      dispatch(addConnectionsList({ type: "database", connectionsList: response?.data }));
    } catch (error) {
      setSnack({ message: error?.response?.data?.message || error.message, open: true, colour: "error" });
    }
  };

  useLayoutEffect(() => {
    const userData = JSON.parse(localStorage.getItem(userLocalSession));
    dispatch(setUserDetails(userData));
    setupAxiosInterceptors(userData, dispatch, navigate, setSnack);
    if (token) {
      fetchConnections();
    }
  }, [token]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {token && <SessionTimeout timeout={sessionTimeout} onTimeout={handleSessionTimeout} />}
        <SnackbarContext.Provider value={{ snack, setSnack }}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snack.open}
            autoHideDuration={5000}
            onClose={handleClose}>
            <Alert onClose={handleClose} severity={snack.colour || "success"}>
              {t(snackMessage)}
            </Alert>
          </Snackbar>
          <RouteManager />
          <CustomAlert />
          {/* {user_details?.Token && <CustomChatBot />} */}
        </SnackbarContext.Provider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
