// CustomGradientButton.jsx
import { Button } from "@mui/material";

const CustomGradientButton = ({ active, onClick, label, ...props }) => {
  return (
    <Button
      className={active ? "gradientActiveButton" : "gradientButton"}
      onClick={onClick}
      size="small"
      variant="outlined"
      {...props}>
      {label}
    </Button>
  );
};

export default CustomGradientButton;
