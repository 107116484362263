import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./Redux/store";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/Layout/ErrorFallback";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(error, info) => {
          console.error("Error logged:", error, info);
        }}>
        <App />
      </ErrorBoundary>
    </Router>
  </Provider>,
);

// Start measuring performance
reportWebVitals();
