import { useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CustomDrawer from "../Drawer";
import { headCells } from "../Drawer/Modal";
import D3PieChart from "./D3PieChart";

const DPGraphFooterComponent = ({ title, value }) => {
  return (
    <Grid size={4}>
      <Box className="DPGraphFooter">
        <Typography>{title}</Typography>
        <Typography variant="bold">{value}</Typography>
      </Box>
    </Grid>
  );
};
function PieChartComponent({ columnData }) {
  const [drawerData, setDrawerData] = useState([]);
  const [drawerState, setDrawerState] = useState(false);
  const [tableCells, setTableCells] = useState([]);
  const pieChartDataSource = ["NullCount", "NotNullCount", "DistinctCount", "DuplicateCount"];

  const formatData = (columnRes) => {
    return pieChartDataSource.map((obj) => {
      let details;

      if (obj === "DistinctCount") {
        details = columnRes.DistinctValues;
      } else if (obj === "DuplicateCount") {
        details = columnRes.DuplicateValues;
      } else {
        details = undefined;
      }

      return { key: obj, value: columnRes[obj], details: details };
    });
  };

  const handlePropertySelection = (selectedPropertyDetails) => {
    if (selectedPropertyDetails.details) {
      const updatedHeadCells = [...headCells];

      if (selectedPropertyDetails.details[0]?.Count) {
        updatedHeadCells.push({
          field: "Count",
          headerName: "Count",
          sortable: true,
          filter: false,
          suppressMenu: true,
        });
      }

      setTableCells(updatedHeadCells);
      setDrawerData(selectedPropertyDetails);
      setDrawerState(true);
    }
  };

  return (
    <>
      {columnData.map((column, index) => {
        const formattedData = formatData(column);
        return (
          <Grid container key={index} alignItems="center" sx={{ padding: "8px 0", background: "#F4F1FF", mb: 0.5 }}>
            <Grid size={2} alignItems="center">
              {column.Name?.length > 24 ? (
                <Tooltip title={column.Name} arrow position="top">
                  <Typography
                    sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                    className="colName">
                    {column.Name}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography className="colName">{column.Name}</Typography>
              )}
            </Grid>
            <Grid size={10}>
              <Box>
                <Grid container spacing={0.5} alignItems="center">
                  <Grid size={7}>
                    <Box position="relative">
                      <Grid container spacing={0.5}>
                        <DPGraphFooterComponent title="Min Value" value={column.MinValue || "NA"} />
                        <DPGraphFooterComponent title="Max Value" value={column.MaxValue || "NA"} />
                        <DPGraphFooterComponent title="Avg Value" value={column.AvgValue || "NA"} />
                        <DPGraphFooterComponent title="Min Value Length" value={column.MinValueLength} />
                        <DPGraphFooterComponent title="Max Value Length" value={column.MaxValueLength} />
                        <DPGraphFooterComponent title="Average Value Length" value={column.AverageValueLength} />
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid size={5}>
                    <D3PieChart data={formattedData} onPointClick={handlePropertySelection} />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        );
      })}
      <CustomDrawer
        sx={{ zIndex: 1300 }}
        state={drawerState}
        setState={setDrawerState}
        drawerData={drawerData}
        headCells={tableCells}
      />
    </>
  );
}

export default PieChartComponent;
