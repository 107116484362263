import { useEffect, useState } from "react";
import ApiService from "../../../../services/app.service";
import SkeletonLoader from "../../../SkeletonLoader";
import CustomJsonTree from "./CustomJsonTree";

export default function CustomizedTreeView(props) {
  const { ScrollRef, returnVal, connection } = props;

  const [responseData, setResponseData] = useState();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    (async () => {
      setLoader(true);
      const response = await ApiService.GetFilesData({
        id: connection,
        numberOfRows: 5,
      });
      setResponseData(response?.data);
      setLoader(false);
      setTimeout(() => {
        ScrollRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }, 500);
    })();
  }, [connection]);

  return (
    <>
      {responseData && <CustomJsonTree response={responseData} returnVal={returnVal} showCross={true} />}
      {loader && <SkeletonLoader />}
    </>
  );
}
