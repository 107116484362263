import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  TextField,
  DialogContentText,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { FileDownloadOutlined as FileDownloadOutlinedIcon } from "@mui/icons-material";
import { useContext, useState } from "react";
import ApiService from "../../../../services/app.service";
import moment from "moment";
import { SnackbarContext } from "../../../../App";
import { useSelector } from "react-redux";

const DownloadModal = ({ executionResult, type, setShowModal, setLoadingCsv }) => {
  const { user_details } = useSelector((state) => state.auth);
  const { setSnack } = useContext(SnackbarContext);

  const [userInput, setUserInput] = useState("");

  const [isResponsible, setIsResponsible] = useState(true);

  const onClickDownloadButton = (e) => {
    e.stopPropagation();
    if (type === "passedFilePath") {
      window.open(executionResult.passedFilePath, "_blank", "noopener,noreferrer");
      setShowModal(false);
      return "";
    }
    if (type === "failedFilePath") {
      window.open(executionResult.failedFilePath, "_blank", "noopener,noreferrer");
      setShowModal(false);
      return "";
    }
    if (userInput === user_details?.Email && isResponsible) {
      downloadCSVFile();
      setShowModal(false);
    }
    return "";
  };

  const downloadCSVFile = async () => {
    setLoadingCsv(true);

    try {
      const today = moment().format("DD-MMM-YYYY-hh:mm");

      const downloadType = type === "validationRequest" ? "failed_records" : type;
      const response = await ApiService.DownloadCSV({
        executionId: executionResult._id,
        downloadType: type,
      });

      const fileName = `${executionResult?.validationDetails?.TestName} ${downloadType}_${today}.zip`;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message,
        open: true,
        colour: "error",
      });
    } finally {
      setLoadingCsv(false);
    }
  };

  const isValid = userInput === user_details?.Email && isResponsible;

  const handleClose = (e) => {
    e.stopPropagation();
    setShowModal(false);
  };
  return (
    <Dialog
      onClick={(e) => e.stopPropagation()}
      fullWidth={true}
      maxWidth={"xs"}
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle sx={{ pb: 0 }} variant="h6" id="alert-dialog-title">
        {"Are you sure you want to Download?"}

        <DialogContentText color={"black"}>
          The file you are downloading may contain sensitive PHI/PII data. Please provide your consent by entering your
          email.
        </DialogContentText>
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <TextField
          sx={{ my: 1 }}
          size="small"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          id="outlined-basic"
          label="Enter Your Email"
          variant="outlined"
          fullWidth
        />
        <FormControlLabel
          size="small"
          control={
            <Checkbox
              sx={{ height: "20px" }}
              size="small"
              checked={isResponsible}
              onChange={(e) => setIsResponsible(e.target.checked)}
            />
          }
          label="I am responsible for this download and my consent will be recorded for audit purposes."
        />
      </DialogContent>

      <DialogActions sx={{ pt: 0 }}>
        <Button variant="outlined" size="small" onClick={onClickDownloadButton} autoFocus disabled={!isValid}>
          Yes
        </Button>
        <Button variant="outlined" color="error" size="small" onClick={handleClose}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DownloadCsvModal = ({ executionResult, type }) => {
  const [showModel, setShowModal] = useState(false);

  const [loadingCsv, setLoadingCsv] = useState(false);

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
        }}
        sx={{ p: 0, ml: 0.6 }}
        color="primary"
        size="small">
        {!loadingCsv ? (
          <FileDownloadOutlinedIcon fontSize="12px" color="primary" />
        ) : (
          <CircularProgress size={20} color="primary" />
        )}
      </IconButton>
      {showModel && (
        <DownloadModal
          executionResult={executionResult}
          type={type}
          setShowModal={setShowModal}
          setLoadingCsv={setLoadingCsv}
        />
      )}
    </>
  );
};

export default DownloadCsvModal;
