import { Stepper, Step, Box, Typography } from "@mui/material";

import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import { useSearchParams } from "react-router-dom";
import CustomStepIcon from "./CustomStepIcon";
import { ColorlibConnector } from "./StepperStyledComponents";
import CustomFolderIcon from "../../../CustomComponents/Icons/CustomFolderIcon";
import CustomXLSIcon from "../../../CustomComponents/Icons/CustomXLSIcon";
import CustomCSVIcon from "../../../CustomComponents/Icons/CustomCSVIcon";

const CustomStepper = ({ loadingConList, connectionsList, foldersList, onSelectConnection, loadingFoldersList }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const folderName = searchParams.get("folderName");
  const connectionId = searchParams.get("connectionId");
  const fileName = searchParams.get("fileName");
  const fileType = searchParams.get("fileType");

  const activeStep = JSON.parse(searchParams.get("activeStep")) || 0;
  const selectedConnection = connectionsList?.find((each) => each?.id === connectionId);

  const columnsCount = Number.parseInt(searchParams.get("columnsCount"), 10);

  const handleSelectFolder = (option, handleClose) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("folderName", option);
      !folderName && newState.set("activeStep", 2);
      folderName && newState.set("saveToSource", false);
      return newState;
    });
    handleClose();
  };

  const onClickConnection = async (option, handleClose) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      columnsCount && newState.delete("columnsCount");
      folderName && newState.delete("folderName");
      fileName && newState.delete("fileName");
      newState.set("connectionId", option?.id);
      newState.set("activeStep", 1);
      return newState;
    });

    onSelectConnection(option);
    handleClose();
  };

  return (
    <Box className="fileWatcherStepper">
      <Stepper
        sx={{
          "& .MuiStepLabel-iconContainer": { "& div": { height: 40, width: 40 } },
          "& .MuiStepConnector-horizontal": { top: "18px !important" },
          "& .MuiStepLabel-label": { mt: "8px !important", "& button": { px: 0 } },
          "& .MuiStep-horizontal ": { p: 0 },
        }}
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}>
        <Step sx={{ "& .MuiStepLabel-horizontal": { width: "fit-content", alignItems: "flex-start" } }}>
          <CustomStepIcon
            label="Select Connection"
            icon={<SettingsIcon />}
            menuItems={connectionsList}
            handleSelectOption={onClickConnection}
            isDisabled={false}
            loading={loadingConList}
            labelComponent={
              selectedConnection?.connectionName ? (
                <Box className="v-center" sx={{ gap: 0.5 }}>
                  <img
                    width={25}
                    height={25}
                    src={`/assets/uploads/${selectedConnection?.connectionType.toLowerCase()}.png`}
                    alt=""
                  />
                  <Typography>{selectedConnection?.connectionName}</Typography>
                </Box>
              ) : null
            }
            selectedItem={selectedConnection}
          />
        </Step>

        <Step sx={{ "& .MuiStepConnector-horizontal": { left: "calc(-100% + 20px)", right: "calc(50% + 20px)" } }}>
          <CustomStepIcon
            label="Select Folder"
            icon={<GroupAddIcon />}
            menuItems={foldersList}
            handleSelectOption={handleSelectFolder}
            isLocalPath={selectedConnection?.connectionType === "localdrive" ? folderName || true : ""}
            isDisabled={activeStep === 0}
            loading={loadingFoldersList}
            labelComponent={
              folderName ? (
                <Box className="v-center" sx={{ gap: 0.5 }}>
                  <CustomFolderIcon />
                  <Typography>{folderName}</Typography>
                </Box>
              ) : null
            }
          />
        </Step>
        <Step
          sx={{
            "& .MuiStepConnector-horizontal": {
              left: "calc(-50%  + 20px)",
              right: "calc(0%  + 20px)",
            },
            "& .MuiStepLabel-horizontal": { width: "fit-content", ml: "auto", alignItems: "flex-end" },
          }}>
          <CustomStepIcon
            label="Select File"
            icon={<VideoLabelIcon />}
            isDisabled={activeStep <= 1 || !folderName}
            labelComponent={
              activeStep === 3 && fileName ? (
                <Box className="v-center" sx={{ gap: 0.5 }}>
                  {fileType === "csv" ? (
                    <CustomCSVIcon height={20} width={20} />
                  ) : (
                    <CustomXLSIcon height={20} width={20} />
                  )}
                  <Typography>{fileName}</Typography>
                </Box>
              ) : null
            }
          />
        </Step>
      </Stepper>
    </Box>
  );
};

export default CustomStepper;
