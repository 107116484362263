import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Paper, Tab, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Heatmap from "../Heatmap";

const Formatdata = (data) => {
  if (data && data.length > 0) {
    const labels = Object.keys(data[0]);
    const formattedData = [];

    data.forEach((val, i) => {
      Object.entries(val).forEach(([key, value]) => {
        formattedData.push({ x: labels[i], y: key, value: value });
      });
    });

    return formattedData;
  }

  return [];
};

const PandasHistogram = ({ data }) => {
  const [histogramTabs, setHistogramTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState("0");

  useEffect(() => {
    setHistogramTabs(Object.keys(data));
  }, [data]);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Paper sx={{ py: 1, px: 2, mt: 2 }} className="sampleContainer">
      <Typography variant="h5" color="primary">
        Correlations
      </Typography>
      <TabContext value={selectedTab}>
        <TabList className="overviewSections" onChange={handleChangeTab} textColor="primary" indicatorColor="primary">
          {histogramTabs.map((tab, indx) => {
            return <Tab key={indx} value={indx.toString()} label={tab} />;
          })}
        </TabList>
        {histogramTabs.map((tab, indx) => {
          const Heatmapdata = Formatdata(data?.[tab]);
          return (
            <TabPanel sx={{ py: 1, px: 2 }} key={indx} value={indx.toString()}>
              <Heatmap data={Heatmapdata} type={tab} />
            </TabPanel>
          );
        })}
      </TabContext>
    </Paper>
  );
};

export default PandasHistogram;
