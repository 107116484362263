import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Typography, IconButton, Dialog, DialogContent, DialogTitle } from "@mui/material";

import CustomHeaderAgGrid from "../../AgGrid/CustomHeaderAgGrid";
const ComparativeResultModel = ({ tableData, selectedDataType, validateColumnName, open, handleClose }) => {
  let highLightColumn = [];
  if (selectedDataType?.type === "OnlyInFirstDatasource") {
    highLightColumn = validateColumnName?.columnOne;
  }
  if (selectedDataType?.type === "OnlyInSecondDatasource") {
    highLightColumn = validateColumnName?.columnTwo;
  }
  if (selectedDataType?.type === "DuplicatesOfFirstDatasource") {
    highLightColumn = validateColumnName?.columnOne;
  }
  if (selectedDataType?.type === "DuplicatesOfSecondDatasource") {
    highLightColumn = validateColumnName?.columnTwo;
  }
  if (selectedDataType?.type === "MatchedInFirstSource") {
    highLightColumn = validateColumnName?.columnOne;
  }
  if (selectedDataType?.type === "MatchedInSecondSource") {
    highLightColumn = validateColumnName?.columnTwo;
  }

  return (
    <Dialog fullWidth={true} maxWidth={"xl"} open={Boolean(open)} onClose={handleClose} scroll="paper">
      <IconButton
        sx={{ position: "absolute", top: 0, right: 0, background: "#fff" }}
        onClick={() => handleClose(false)}
        color="error"
        size="small">
        <CancelOutlinedIcon />
      </IconButton>
      <DialogTitle
        sx={{
          p: 1.5,
          py: 1,
          borderBottom: "1px solid #ccc",
          textAlign: "center",
          color: "white",
          background: "rgb(100,181,246)",
        }}>
        {selectedDataType?.name?.replace(/([A-Z])/g, " $1")?.trim()}
      </DialogTitle>
      <DialogContent>
        {tableData?.length !== 0 ? (
          <CustomHeaderAgGrid data={tableData} errorColumn={{ columns: highLightColumn, color: "red" }} />
        ) : (
          <Typography textAlign={"center"} variant="bold">
            {"No Records Found"}
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ComparativeResultModel;
