import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomLoadingButton from "./CustomsButtons/CustomLoadingButton";

const CustomDeleteModal = ({ loading, disabled, onClickDelete, handleClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog className="dialogCus" open={true} onClose={handleClose}>
      <DialogTitle variant="h6" id="alert-dialog-title">
        {t("delete_warning")}
      </DialogTitle>
      <DialogActions>
        <Button variant="outlined" color="error" size="small" onClick={handleClose}>
          {t("No")}
        </Button>

        <CustomLoadingButton variant="outlined" loading={loading} disabled={disabled} onClick={onClickDelete}>
          {t("Yes")}
        </CustomLoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDeleteModal;
