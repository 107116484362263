import { useContext, useLayoutEffect, useState } from "react";
import { Autocomplete, CircularProgress, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../App";
import { azureLogin } from "../../_helpers/Constant";
import SelectUserRole from "./SelectUserRole";
import { usersService } from "../../Redux/services/users.service";
import { useSelector } from "react-redux";
import CustomLoadingButton from "../CustomComponents/CustomsButtons/CustomLoadingButton";

const CreateEditDQGUser = ({ existingUsers, defaultdata = {}, handleClose, fetchList }) => {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const { user_details } = useSelector((state) => state.auth);

  const isEdit = Object.keys(defaultdata)?.length > 0;

  const [loadingUsers, setLoadingUsers] = useState(false);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState({
    firstName: defaultdata?.FirstName || "",
    lastName: defaultdata?.LastName || "",
    email: defaultdata?.Email || "",
    password: defaultdata?.Password || "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [userRole, setUserRole] = useState(defaultdata?.RoleId || 3);
  const [createLoading, setCreateLoading] = useState(false);

  const {
    control,
    formState: { errors },
    setValue,
    setError,
    trigger,
  } = useForm();

  useLayoutEffect(() => {
    if (!isEdit && azureLogin) {
      onChangeSearchInput("");
    }
  }, []);

  const passwordVisibilityIcon = showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />;

  const onInputChange = async (val, maxLength, field) => {
    let cleanedValue = field?.name === "password" ? val.replace(/\s/g, "") : val.replace(/\s{2,}/g, " ");
    if (cleanedValue?.length > maxLength) {
      cleanedValue = cleanedValue.substring(0, maxLength);
    }

    setValue(field.name, cleanedValue);
    await trigger(field.name);
    field.onChange(cleanedValue);
    setSelectedUser((prevState) => ({ ...prevState, [field.name]: cleanedValue }));
  };

  const onChangeSearchInput = async (val) => {
    setLoadingUsers(true);
    try {
      const payload = { searchText: val };
      const response = await usersService.getFilteredUsers(payload);
      const filteredUserList = response
        .filter((user) => user.firstName || user.lastName)
        .map((user) => ({ ...user, firstName: user.firstName || "", lastName: user.lastName || "" }))
        .filter((user) => user.email && !existingUsers.some((element) => element.Email === user.email));
      setUserList(filteredUserList);
    } catch (error) {
      setSnack({ message: error?.response?.data?.message || error.message, open: true, colour: "error" });
    } finally {
      setLoadingUsers(false);
    }
  };

  const createUser = async () => {
    setCreateLoading(true);
    const existingUserEmails = existingUsers.map((obj) => obj.Email);
    if (existingUserEmails.includes(selectedUser.email)) {
      setError("email", { type: "custom", message: t("Existing User Errormsg") });
      setCreateLoading(false);
    } else {
      const data = {
        firstName: selectedUser.firstName,
        lastName: selectedUser.lastName,
        email: selectedUser.email,
        password: selectedUser.password || "",
        productCode: "DQG",
        OrganisationId: user_details?.OrganisationId,
        roleId: userRole,
      };
      try {
        const response = await usersService.createDQGUser(data);
        setSnack({ message: response.Message, open: true, colour: "success" });
        fetchList();
        handleClose();
      } catch (error) {
        setSnack({ message: error?.response?.data?.message || error.message, open: true, colour: "error" });
      } finally {
        setCreateLoading(false);
      }
    }
  };

  const updateUser = async () => {
    setCreateLoading(true);
    const data = {
      BaseUserId: defaultdata.Id,
      oldRoleId: defaultdata.RoleId,
      newRoleId: userRole,
      oldPassword: defaultdata.Password,
      newPassword: selectedUser.password,
    };
    try {
      const response = await usersService.updateDQGUser(data);
      setSnack({ message: response.Message, open: true, colour: "success" });
      fetchList();
      handleClose();
    } catch (error) {
      setSnack({ message: error?.response?.data?.message || error.message, open: true, colour: "error" });
    } finally {
      setCreateLoading(false);
    }
  };

  return (
    <Grid container spacing={2} p={1} minWidth={!selectedUser?.email && "590px"}>
      {azureLogin && (
        <>
          <Grid size={2}>
            <Typography variant="h6">{t("User Name")}: </Typography>
          </Grid>
          <Grid size={9}>
            <Autocomplete
              disabled={isEdit}
              disablePortal
              disableClearable={!selectedUser}
              loading={loadingUsers}
              options={userList}
              value={selectedUser}
              getOptionLabel={(option) => `${option?.firstName || ""} ${option?.lastName}`.trim()}
              isOptionEqualToValue={(option, value) => option?.firstName === value?.firstName}
              onChange={(e, val) => setSelectedUser(val)}
              onInputChange={(e, val) => (isEdit ? null : onChangeSearchInput(val))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  size="small"
                  placeholder={t("Select User")}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingUsers && <CircularProgress size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </>
      )}
      {!azureLogin && (
        <>
          <Grid size={2}>
            <Typography variant="h6">{t("First Name")}: </Typography>
          </Grid>
          <Grid size={5} style={{ paddingLeft: ".5rem" }}>
            <Controller
              name="firstName"
              control={control}
              rules={{ required: true, minLength: { value: 4, message: t("Minimum length 4 characters") } }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  autoFocus
                  size="small"
                  placeholder={t("First Name")}
                  defaultValue={selectedUser?.firstName}
                  value={field?.value}
                  error={Boolean(fieldState?.error)}
                  helperText={fieldState?.error?.message}
                  onChange={(e) => onInputChange(e.target.value, 32, field)}
                />
              )}
            />
          </Grid>
          <Grid size={5} />
          <Grid size={2}>
            <Typography variant="h6">{t("Last Name")}: </Typography>
          </Grid>
          <Grid size={5} style={{ paddingLeft: ".5rem" }}>
            <Controller
              name="lastName"
              control={control}
              rules={{ required: true, minLength: { value: 4, message: t("Minimum length 4 characters") } }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  placeholder={t("Last Name")}
                  defaultValue={selectedUser?.lastName}
                  value={field?.value}
                  error={Boolean(fieldState?.error)}
                  helperText={fieldState?.error?.message}
                  onChange={(e) => onInputChange(e.target.value, 32, field)}
                />
              )}
            />
          </Grid>
          <Grid size={5} />
        </>
      )}
      {(azureLogin && selectedUser?.email) || !azureLogin ? (
        <>
          <Grid size={2}>
            <Typography variant="h6">{t("Email")}: </Typography>
          </Grid>
          <Grid size={9}>
            {azureLogin ? (
              <Typography>{selectedUser.email}</Typography>
            ) : (
              <Controller
                name="email"
                control={control}
                rules={{
                  required: true,
                  pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: t("Invalid Email") },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    disabled={isEdit}
                    fullWidth
                    size="small"
                    placeholder={t("User Email")}
                    defaultValue={selectedUser?.email}
                    value={field?.value}
                    error={Boolean(fieldState?.error)}
                    helperText={fieldState?.error?.message}
                    onChange={(e) => onInputChange(e.target.value, 50, field)}
                  />
                )}
              />
            )}
          </Grid>
          {!azureLogin && (
            <>
              <Grid size={2}>
                <Typography variant="h6">{t("Password")}: </Typography>
              </Grid>
              <Grid size={9}>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: true,
                    pattern: {
                      value: new RegExp("^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{6,16}$"),
                      message: (
                        <div style={{ whiteSpace: "pre-line" }}>
                          {t("PasswordRequirements")}
                          <br />
                          1. {t("Pwd minimum length")}
                          <br />
                          2. {t("Pwd characters")}
                          <br />
                          3. {t("Pwd Special characters")}
                        </div>
                      ),
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      placeholder={t("Password")}
                      type={showPassword ? "text" : "password"}
                      defaultValue={selectedUser?.password}
                      value={field.value}
                      error={Boolean(fieldState.error)}
                      helperText={fieldState?.error?.message}
                      inputProps={{ autoComplete: "new-password", form: { autoComplete: "off" } }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={() => setShowPassword(!showPassword)}>
                              {passwordVisibilityIcon}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => onInputChange(e.target.value, 16, field)}
                    />
                  )}
                />
              </Grid>
            </>
          )}

          <Grid size={3}>
            <Typography variant="h6">{t("User Role")}: </Typography>
          </Grid>
          <Grid size={12}>
            <SelectUserRole selected={userRole} setSelected={setUserRole} />
          </Grid>
          <Grid size={12} pt={2} textAlign="right">
            <CustomLoadingButton
              disabled={
                createLoading ||
                Object.keys(errors)?.length ||
                !selectedUser?.firstName ||
                !selectedUser?.lastName ||
                !selectedUser?.email ||
                (!azureLogin && !selectedUser?.password)
              }
              loading={createLoading}
              onClick={isEdit ? updateUser : createUser}>
              {isEdit ? `${t("update")} ${t("user")}` : `${t("Add")} ${t("user")}`}
            </CustomLoadingButton>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

export default CreateEditDQGUser;
