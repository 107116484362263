/* eslint-disable no-mixed-operators */
import { useEffect, useRef } from "react";
import * as d3 from "d3";

const D3PieChart = ({ data, onPointClick }) => {
  const chartRef = useRef();

  // Utility function to determine cursor style
  const getCursorStyle = (pointInfo) => (pointInfo.data?.details ? "pointer" : "default");

  useEffect(() => {
    const renderChart = () => {
      const container = chartRef.current;
      const width = container.offsetWidth;
      const height = 120; // Fixed height
      const radius = Math.min(width, height) / 2 - 24;

      // Clear existing chart
      d3.select(chartRef.current).select("svg").remove();

      // Create the SVG container
      const svg = d3
        .select(chartRef.current)
        .append("svg")
        .attr("width", "100%")
        .attr("height", height)
        .append("g")
        .attr("transform", `translate(${width / 2}, ${height / 2 + 4})`);
      // Define color scale
      const color = d3
        .scaleOrdinal()
        .domain(data.map((d) => d.key))
        .range(d3.schemeTableau10);

      // Define pie layout
      const pie = d3
        .pie()
        .value((d, index) => d.value || index * 0.12)
        .sort(null);

      // Define arc generators
      const arc = d3.arc().innerRadius(0).outerRadius(radius);
      const outerArc = d3
        .arc()
        .innerRadius(radius)
        .outerRadius(radius + 30);

      const pieData = pie(data);

      // Render pie slices
      svg
        .selectAll(".slice")
        .data(pieData)
        .enter()
        .append("path")
        .attr("d", arc)
        .attr("fill", (d) => color(d.data.key))
        .style("cursor", (d) => getCursorStyle(d))
        .on("click", (e, d) => {
          if (d.data.details) {
            onPointClick(d.data);
          }
        });

      // Render connector lines
      svg
        .selectAll(".polyline")
        .data(pieData)
        .enter()
        .append("polyline")
        .attr("stroke", (d) => color(d.data.key))
        .attr("stroke-width", 1)
        .attr("fill", "none")
        .attr("points", (d) => {
          const posA = arc.centroid(d);
          const posB = outerArc.centroid(d);
          const posC = [...outerArc.centroid(d)];
          const midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          posC[0] = radius * (midAngle < Math.PI ? 1.35 : -1.35);
          return [posA, posB, posC];
        });

      // Render labels
      const labelGroup = svg
        .selectAll(".label-group")
        .data(pieData)
        .enter()
        .append("g")
        .style("cursor", (d) => getCursorStyle(d))
        .on("click", (e, d) => {
          if (d.data.details) {
            onPointClick(d.data);
          }
        })
        .attr("transform", (d) => {
          const pos = [...outerArc.centroid(d)];
          const midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          pos[0] = radius * (midAngle < Math.PI ? 1.35 : -1.35);
          return `translate(${pos})`;
        });

      // Render label background rectangles
      labelGroup
        .append("rect")
        .attr("x", (d) => {
          const midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          return midAngle < Math.PI ? 0 : -108;
        })
        .attr("y", -8)
        .attr("width", (d) => `${d.data?.value}`?.length + 108)
        .attr("height", 16)
        .attr("fill", (d) => color(d.data.key))
        .attr("stroke-width", 0.5)
        .attr("rx", 4)
        .attr("ry", 4);

      // Render label text
      labelGroup
        .append("text")
        .attr("x", (d) => {
          const midAngle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          return midAngle < Math.PI ? 5 : -104;
        })
        .attr("y", 3)
        .attr("text-anchor", "start")
        .style("font-size", "10px")
        .style("fill", "white")
        .text((d) => `${d.data.key}: ${d.data.value}`);
    };

    renderChart();

    // Re-render chart on window resize
    window.addEventListener("resize", renderChart);
    return () => {
      window.removeEventListener("resize", renderChart);
    };
  }, [data, onPointClick]);

  return <div ref={chartRef} style={{ width: "100%" }}></div>;
};

export default D3PieChart;
