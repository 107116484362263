import { axisBottom, axisLeft, max, scaleBand, scaleLinear, select, format } from "d3";
import React, { useEffect, useRef, useState } from "react";
import useResizeObserver from "./useResizeObserver";

function VerticalBarChart({ data, type }) {
  const svgRef = useRef();
  const wrapperRef = useRef();
  const [properties, setProperties] = useState({ width: 0, height: 0 });

  const margin = { top: 5, right: 0, bottom: 50, left: 80 };

  const dimensions = useResizeObserver(wrapperRef);

  const draw = () => {
    const svg = select(svgRef.current);

    const { width, height } = dimensions || wrapperRef.current.getBoundingClientRect();

    setProperties({
      width: width > 600 ? 600 : width,
      height: width > 600 ? 400 : width / 2,
    });

    const innerWidth = (width > 600 ? 600 : width) - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    svg.selectAll("*").remove();

    const g = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`);

    const yScale = scaleLinear()
      .domain([0, max(data, (d) => d.count)])
      .range([innerHeight, 0]);

    const yAxis = axisLeft(yScale).tickSize(0).ticks(6);

    const xScale = scaleBand()
      .domain(data.map((d) => d.word))
      .range([0, innerWidth])
      .padding(0.15);

    const categories = data.map((d) => d.word);
    const tickCount = 6;
    const step = Math.floor(categories.length / (tickCount - 1));
    const tickValues = categories.filter((_, index) => index % step === 0);

    const xAxis = axisBottom(xScale)
      .tickSize(0)
      .tickValues(tickValues)
      .tickFormat(format(",.1r"))
      .tickFormat(format(".2s"));

    g.append("g")
      .attr("class", "x-axis")
      .style("font-size", 13)
      .attr("transform", `translate(15,${innerHeight + 5})`)
      .call(xAxis)
      .call((a) => a.select(".domain").remove());

    g.selectAll(".bar")
      .data(data)
      .join("rect")
      .attr("class", "bar")
      .attr("x", (d) => xScale(d.word))
      .attr("y", innerHeight) // Set the initial y position at the bottom
      .attr("width", xScale.bandwidth())
      .attr("fill", "#E19937")
      .transition()
      .attr("y", (d) => yScale(d.count)) // Transition to the correct y position
      .attr("height", (d) => innerHeight - yScale(d.count));

    if (width > 400 && height > 150) {
      g.append("g")
        .attr("class", "y-axis")
        .style("font-size", 13)
        .call(yAxis)
        .call((a) => a.select(".domain").remove());

      svg
        .append("text")
        .attr("x", margin.left - 10)
        .attr("y", height / 2)
        .attr("text-anchor", "middle")
        .text("Frequency")
        .style("font-size", 15)
        .style("font-weight", "bold")
        .attr("transform", `rotate(-90, ${margin.left - 50}, ${height / 2})`);

      svg
        .append("text")
        .attr("x", (width > 600 ? 600 : width) / 2)
        .attr("y", height - 10)
        .attr("text-anchor", "middle")
        .text(
          type === "Numeric"
            ? `Histogram with fixed size bins (bins=${data.length})`
            : "Histogram of lengths of the category",
        )
        .style("font-size", 15)
        .attr("fill", "#808080")
        .style("font-weight", "bold");
    }
  };

  // will be called initially and on every data change
  useEffect(() => {
    draw();
  }, [data, dimensions]);

  return (
    <React.Fragment>
      <div ref={wrapperRef} style={{ textAlign: "center" }}>
        <svg ref={svgRef} width={properties.width} height={properties.height} />
      </div>
    </React.Fragment>
  );
}

export default VerticalBarChart;
