import { Box, Card, Checkbox, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomLoader from "../../CustomComponents/CustomLoader";
import CollapsibleSearch from "../../CustomComponents/CollapsibleSearch";
import { useMemo, useState } from "react";
const GET_SELECTION_COUNT = { Piechart: 2, boxplot: 2, Barplot: 3, scatterplot: 3, Movablebarplot: 4, Histogram: 1 };
const attributeHelperText = {
  Piechart: "Select 2 Columns 1.Numerical and 2.Categorical",
  Movablebarplot: "Select 4 Columns",
  Barplot: "Select either 2 or 3 Columns",
  scatterplot: "Select either 2 or 3 Columns",
  boxplot: "Select 1 or 2 Columns",
  Histogram: "Select 1 Column",
};
const DataProfileSelectColumns = ({
  columnError,
  loading,
  reportType,
  columns,
  selectedColumns,
  handleOnChangeColumns,
}) => {
  const { t } = useTranslation();

  const [searchInput, setSearchInput] = useState("");

  const onChangeSearchInput = (value) => {
    setSearchInput(value);
  };

  const filteredConnections = useMemo(() => {
    return columns?.filter((each) => {
      const column = each?.toLowerCase() || "";
      const lowerSearchInput = searchInput?.toLowerCase() || "";
      return column.startsWith(lowerSearchInput) || column.includes(lowerSearchInput);
    });
  }, [columns, searchInput]);

  return (
    <Box sx={{ background: "#F4F1FF" }}>
      <Box className="space-between container-heading">
        <Box className="v-center" sx={{ gap: 1 }}>
          <Typography variant="h6">Columns</Typography>
          <CollapsibleSearch
            placeholderText="Search for Columns..."
            queryValue={searchInput}
            onQueryChange={onChangeSearchInput}
            maxWidth="500px"
            initialExpandedState={true}
          />
        </Box>
        <Typography sx={{ fontWeight: columnError ? 600 : 500 }} color={columnError ? "error" : "primary"}>
          {columns && attributeHelperText[reportType] ? t(attributeHelperText[reportType]) : null}
        </Typography>
      </Box>

      <Box className="list-container">
        {loading && <CustomLoader />}

        {!loading && !filteredConnections?.length && (
          <Typography variant="h6" className="preview-text">
            {filteredConnections ? " No columns available" : "Please Select Table"}
          </Typography>
        )}
        {filteredConnections?.map((column, index) => (
          <Card className="card-styles" key={`column-${index}`}>
            <Box className="v-center" sx={{ "& p": { color: "#365E7D", fontWeight: 600 } }}>
              <Checkbox
                sx={{ py: 0 }}
                size="small"
                disabled={
                  selectedColumns?.length === GET_SELECTION_COUNT[reportType] && !selectedColumns?.includes(column)
                }
                checked={selectedColumns?.includes(column)}
                onChange={(event) => handleOnChangeColumns(event, column)}
              />
              <Typography variant="body1">{column} </Typography>
            </Box>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default DataProfileSelectColumns;
