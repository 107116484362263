import {
  CustomEditIcon,
  CustomExecuteIcon,
  CustomPreviewIcon,
  CustomResultIcon,
} from "../../CustomComponents/IconButtons";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCheckAccess } from "../../../CustomHooks/useCheckAccess";
import { profilingServices } from "../../../Redux/services/profiling.services";
import { showToast } from "../../../Redux/reducers/toastSlice";

const DataProfilingListActionCells = ({ data, node, context }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { checkAccess } = useCheckAccess();

  const { user_details } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);

  const { UserId: currentUserId, RoleName: currentUserRole } = user_details || {};
  const {
    _id: ruleId,
    ConnectionId: connectionId,
    TestName: testName,
    ProfilingType,
    UserId: rowUserId,
    isExecutionRunning,
    ConnectionType,
    FirstDatasourceType,
  } = data || {};

  const showEdit = useMemo(() => {
    return rowUserId === currentUserId || currentUserRole === "Admin" ? "visible" : "hidden";
  }, [rowUserId, currentUserId, currentUserRole]);

  const isDQRuleExecutionRunning = useMemo(() => isExecutionRunning > 0, [isExecutionRunning]);

  const handleExecuteClick = async () => {
    try {
      dispatch(showToast({ message: "Execution Started", type: "success" }));
      setLoading(true);
      node.setRowSelectable(false);
      await profilingServices.executeDataProfile(ruleId);
      dispatch(showToast({ message: "Execution Completed", type: "success" }));
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    } finally {
      setLoading(false);
      node.setRowSelectable(true);
    }
  };

  const handleEditClick = () => {
    navigate(
      `/dataprofiler/create?connectionId=${connectionId}&connectionType=${ConnectionType}&dataSourceType=${FirstDatasourceType}&ruleId=${ruleId}&testName=${testName}&reportType=${ProfilingType}`,
    );
  };

  return (
    <Box className="v-center">
      <CustomEditIcon
        sx={{ visibility: showEdit }}
        title={t("Edit")}
        onClick={handleEditClick}
        disabled={loading || isDQRuleExecutionRunning}
      />

      {checkAccess("DataQualityRule", "Preview") && (
        <CustomPreviewIcon title={t("Preview")} onClick={() => context?.onClickPreviewDataProfile(data)} />
      )}

      {checkAccess("DataQualityRule", "Execute") && (
        <CustomExecuteIcon
          title={t("Execute")}
          onClick={handleExecuteClick}
          disabled={loading || isDQRuleExecutionRunning}
        />
      )}

      <CustomResultIcon
        title={t("Results")}
        onClick={() => context?.handleOnClickResults({ ProfilingType, ruleId, testName })}
      />
    </Box>
  );
};

export default DataProfilingListActionCells;
