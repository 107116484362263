import { Box, Card, Typography, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomLoader from "../../CustomComponents/CustomLoader";
import CollapsibleSearch from "../../CustomComponents/CollapsibleSearch";
import { useMemo, useState } from "react";

// Component to display selected connection details
const RenderConnectionDetails = ({ selectedConnection }) => {
  const { t } = useTranslation();

  const renderDetail = (label, value) =>
    value && (
      <>
        <Divider orientation="vertical" variant="middle" sx={{ height: 12, m: 0 }} flexItem />
        <Typography>
          <b>{t(label)}:</b> {value}
        </Typography>
      </>
    );

  const isFile = Boolean(selectedConnection?.fileName);

  return (
    <Box className="v-center" sx={{ gap: 0.5, "& p": { fontSize: "10px", color: "#365E7D" } }}>
      <Typography>
        <b>{t(isFile ? "Type" : "Database Type")}:</b>{" "}
        {isFile ? selectedConnection?.ext : selectedConnection?.connectionType}
        {isFile && (
          <>
            {" | "}
            <b>{t("Size")}:</b> {(selectedConnection?.size * 0.001).toFixed(1)} KB
          </>
        )}
      </Typography>
      {renderDetail("Server", selectedConnection?.server)}
      {renderDetail("Database", selectedConnection?.dataBase)}
      {renderDetail("Server Host Name", selectedConnection?.serverHostname)}
      {renderDetail("Catalog Name", selectedConnection?.catalogName)}
      {renderDetail("Account Name", selectedConnection?.accountName)}
      {renderDetail("Schema", selectedConnection?.schema)}
    </Box>
  );
};

const DataProfileSelectConnection = ({ loading, connections = [], selectedConnection, handleConnectionClick }) => {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState("");

  const onChangeSearchInput = (value) => {
    setSearchInput(value);
  };
  const selectedConnId = selectedConnection?.id || selectedConnection?._id;
  const filteredConnections = useMemo(() => {
    const lowerSearchInput = (searchInput || "").toLowerCase();
    return connections?.filter(({ connectionName, connectionType }) => {
      const name = (connectionName || "").toLowerCase();
      const isMatch = name.startsWith(lowerSearchInput) || name.includes(lowerSearchInput);
      const isAllowedType = !["Azure Blob"].includes(connectionType);
      return isMatch && isAllowedType;
    });
  }, [connections, searchInput]);

  return (
    <Box sx={{ background: "#F4F1FF" }}>
      <Box className="space-between container-heading">
        <Box className="v-center" sx={{ gap: 1 }}>
          <Typography variant="h6"> {t("Select Connection")}</Typography>

          <CollapsibleSearch
            placeholderText="Search for Connection..."
            queryValue={searchInput}
            onQueryChange={onChangeSearchInput}
            maxWidth="500px"
            initialExpandedState={true}
          />
        </Box>
      </Box>
      <Box className="list-container">
        {loading && <CustomLoader />}
        {!loading && !filteredConnections?.length ? (
          <Typography variant="h6" className="preview-text">
            {t("No connections available")}
          </Typography>
        ) : (
          !loading && (
            <Box className="space-between list-headers">
              <Typography variant="body1">{t("Connection Name")}</Typography>
              <Typography sx={{ display: "none" }} variant="body1">
                {t("No. of Tables")}
              </Typography>
            </Box>
          )
        )}

        {filteredConnections?.map((connection) => {
          const conId = connection?.id || connection?._id;
          return (
            <Card
              className="card-styles"
              key={conId}
              onClick={() => handleConnectionClick(connection)}
              sx={{ backgroundColor: selectedConnId === conId ? "#BCE0FF" : "#F4F1FF" }}>
              <Box className="space-between" sx={{ "& p": { color: "#365E7D", fontWeight: 600 } }}>
                <Box className="v-center" sx={{ gap: 1.5 }}>
                  <img
                    src={`/assets/uploads/${connection.connectionType?.toLowerCase()}.png`}
                    alt={connection.connectionType}
                    style={{ width: 24, height: 24 }}
                  />
                  <Typography variant="body1">{`${connection.connectionName} - ${connection.connectionType}`}</Typography>
                </Box>
                <Typography sx={{ display: "none" }} variant="body1">
                  2
                </Typography>
              </Box>
              {selectedConnId === conId && <RenderConnectionDetails selectedConnection={selectedConnection} />}
            </Card>
          );
        })}
      </Box>
    </Box>
  );
};

export default DataProfileSelectConnection;
