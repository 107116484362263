import { CustomAgGrid } from "../../AgGrid";
import { nonComparisionHeaders } from "./HeadCellsResults/nonComparisionHeaders";
import { comaparisionHeaders } from "./HeadCellsResults/comaparisionHeaders";

export default function ResultsListView({ gridRef, executions, onClickCard, filteredRows }) {
  const isComparisonTest = executions?.[0]?.validationDetails?.TestType === "Comparison";

  const onClickRow = (executionResult) => {
    const ExecutionObj = executions?.find((each) => each?._id === executionResult?._id);
    onClickCard(ExecutionObj);
  };

  const filtereHeadCells = isComparisonTest ? comaparisionHeaders : nonComparisionHeaders;
  return (
    <CustomAgGrid
      noBorder={true}
      isCursorEnabled={true}
      gridRef={gridRef}
      headCells={filtereHeadCells}
      rows={filteredRows}
      onClickRow={onClickRow}
      pagination={false}
    />
  );
}
