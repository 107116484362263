import { Box, Table, TableCell, TableBody, Tooltip, TableContainer, TableHead, TableRow } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";

import CustomHeaderAgGrid from "../AgGrid/CustomHeaderAgGrid";
// import DataCleaning from "../DataCleaning";
import ExpandableTableRow from "../Tables/ExpandableTableRow";
import CustomDialog from "../CustomComponents/CustomDialog";

const FailedResultTableRow = ({ item }) => {
  const Results = item?.ApiMultiResult ? item?.ApiMultiResult : [item?.Result];

  return (
    <>
      {Results.map((Result, indx) => (
        <TableBody key={indx}>
          <ExpandableTableRow
            disable={!Result?.TotalFailed}
            showData={<span style={{ color: "red", float: "left" }}>{Result?.TotalFailed}</span>}
            expandComponent={
              <TableCell className="expandableRow" sx={{ width: "12%" }} colSpan="7">
                <Box>
                  {Result?.FailedRecordsWithPath?.length > 0 ? (
                    <CustomHeaderAgGrid
                      data={Result?.FailedRecordsWithPath}
                      errorColumn={{ columns: "FailedValue", color: "red" }}
                    />
                  ) : (
                    <CustomHeaderAgGrid
                      data={Result?.FailedRecords}
                      errorColumn={{ columns: item.ColumnName?.split(","), color: "red" }}
                    />
                  )}
                </Box>
              </TableCell>
            }>
            {item?.TableName && (
              <TableCell sx={{ width: "20%" }}>
                <Tooltip title={item?.TableName} placement="top">
                  {item?.TableName.length > 20 ? `${item?.TableName.slice(0, 20)}...` : item?.TableName}
                </Tooltip>
              </TableCell>
            )}
            <TableCell sx={{ width: "20%" }}>{item.ColumnName}</TableCell>
            <TableCell sx={{ width: "20%" }}>{item?.CombinedDQCheckName}</TableCell>
            <TableCell sx={{ width: "16%" }}>{Result?.TotalPassed + Result?.TotalFailed}</TableCell>
            <TableCell sx={{ width: "12%", color: "green" }}>{Result?.TotalPassed}</TableCell>
          </ExpandableTableRow>
        </TableBody>
      ))}
    </>
  );
};

const DatabaseResultsPreview = ({ validations, handleClose }) => {
  const { t } = useTranslation();
  return (
    <CustomDialog open={true} title="Data Quality Rule Execution Result" onClose={handleClose} fullWidth={true}>
      <Box sx={{ maxHeight: "75vh", overflow: "auto", width: "100%" }}>
        <Grid container sx={{ my: 0 }}>
          <Grid size={12}>
            <TableContainer aria-label="simple table">
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#7A86A1" }}>
                    {validations?.[0]?.TableName && (
                      <TableCell sx={{ color: "white" }} align="left">
                        {t("Table Name")}
                      </TableCell>
                    )}
                    <TableCell sx={{ color: "white" }} align="left">
                      {t("Column")}
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="left">
                      {t("DQ Check")}
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="left">
                      {t("Validate Records")}
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="left">
                      {t("Passed")}
                    </TableCell>
                    <TableCell sx={{ color: "white" }} align="left">
                      {t("Failed")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                {validations?.map((item, ind) => (
                  <FailedResultTableRow key={`${ind}`} item={item} />
                ))}
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
};

export default DatabaseResultsPreview;
