import { Box, Button, SwipeableDrawer, Typography, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import FileUpload from "./FileUpload";

const CreateNewFile = ({ fileType = null, onSuccessCreate, onClose }) => {
  const { t } = useTranslation();

  return (
    <SwipeableDrawer anchor={"right"} open={true} onClose={onClose} onOpen={() => null}>
      <Box className="drawerFile" sx={{ width: 500 }}>
        <Typography variant="h6" sx={{ px: 2, py: 1 }}>
          {fileType ? `Add ${fileType} Connection` : t("Flat File")}
        </Typography>

        <Divider />
        <Box sx={{ p: 2 }}>
          <FileUpload fileType={fileType} onSuccessCreate={onSuccessCreate} onClose={onClose} />
          <Box sx={{ textAlign: "left", mt: 2, px: 1 }}>
            <Button variant="outlined" size="small" color="error" onClick={onClose}>
              {t("Cancel")}
            </Button>
          </Box>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default CreateNewFile;
