import { Tooltip, Typography } from "@mui/material";

const customTooltip = (props) => (
  <Tooltip
    className="ellipsis"
    title={
      <Typography component="span" className="ellipsisSql">
        {props?.value}
      </Typography>
    }
    placement="top-end">
    <span>{props?.value}</span>
  </Tooltip>
);

const DqCheckCondition = (props) => {
  return (
    <Tooltip
      className="ellipsis"
      title={props.data?.dqCheckCondition?.map((each, i) => (
        <Typography key={i}>
          {each?.ValidationName === "DatatypeCheck"
            ? each?.ValidationProperties[0]?.Key
            : `${each?.ValidationProperties[0]?.Key} (${each?.ValidationProperties[0]?.Value})`}
        </Typography>
      ))}
      placement="right">
      {props.data?.dqCheckCondition?.map((each) => (
        <>
          {each?.ValidationName === "DatatypeCheck"
            ? `${each?.ValidationProperties[0]?.Key}, `
            : `${each?.ValidationProperties[0]?.Key} (${each?.ValidationProperties[0]?.Value}), `}
        </>
      ))}
    </Tooltip>
  );
};

// const dqCheckValueFormatter = (params) => {
//   // Check if the value is an array
//   if (Array.isArray(params.value)) {
//     // Join the array elements into a string
//     return params.value.join(", ");
//   }
//   // If the value is not an array, return it as is
//   return params.value;
// };

export const nonComparisionHeaders = [
  {
    field: "S.No",
    headerName: "S.No",
    valueGetter: "node.rowIndex + 1",
    sortable: false,
    suppressColumnsToolPanel: true,
    // suppressHeaderMenuButton: true,
    filter: false,
  },
  {
    field: "ruleName",
    headerName: "Rule Name",
    sortable: true,
    filter: true,
    // suppressColumnsToolPanel: true,
  },
  {
    field: "executionNumber",
    headerName: "Execution Name",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: true,
  },

  {
    field: "table",
    headerName: "Table / File",
    sortable: false,
    suppressColumnsToolPanel: true,
    filter: false,
    cellRenderer: customTooltip,
  },
  {
    field: "column",
    headerName: "Column",
    sortable: false,
    suppressColumnsToolPanel: true,
    filter: false,
    cellRenderer: customTooltip,
  },

  {
    field: "dqCheckCondition",
    headerName: "DQ Check",
    sortable: false,
    suppressColumnsToolPanel: true,
    filter: false,
    cellRenderer: DqCheckCondition,
  },

  // {
  //   field: "dqCheckCondition",
  //   headerName: "DQ Check Condition",
  //   sortable: false,
  //   suppressColumnsToolPanel: true,
  //   filter: false,
  // },
  // {
  //   field: "dqCheckValue",
  //   headerName: "DQ Check Value",
  //   sortable: false,
  //   suppressColumnsToolPanel: true,
  //   filter: false,
  //   valueFormatter: dqCheckValueFormatter, // Assign the value formatter function
  // },
  {
    field: "totalFailed",
    headerName: "Total Failed",
    sortable: false,
    suppressColumnsToolPanel: true,

    filter: false,
  },
  {
    field: "totalPassed",
    headerName: "Total Passed",
    sortable: false,
    suppressColumnsToolPanel: true,
    filter: false,
  },

  {
    field: "result",
    headerName: "Result",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: true,
  },
  {
    field: "executionDate",
    headerName: "Execution Date",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: false,
    width: 300,
  },
];
