import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import CustomArrowIndicator from "../../CustomComponents/CustomArrowIndicator";
import ColumnChart from "./ColumnChart";
import { useContext, useEffect, useState } from "react";
import { formatDateString } from "../../../_helpers/utils";
import APIServices from "../../../services/app.service";
import { SnackbarContext } from "../../../App";

const InSights = ({ previewConData }) => {
  const { setSnack } = useContext(SnackbarContext);
  const [columnData, setColumnData] = useState([]);
  const [columnDataLoading, setColumnDataLoading] = useState(false);
  const handleOnClickLine = async (tableName) => {
    setColumnDataLoading(true);
    try {
      const today = new Date();
      const lastWeekStartDate = new Date(today);
      lastWeekStartDate.setDate(today.getDate() - 30);

      const startDate = formatDateString(lastWeekStartDate);
      const endDate = formatDateString(today);
      const { data } = await APIServices.getTableColumnsGraphData({
        connectionId: previewConData?.connectionId,
        tableName: tableName,
        startDate: startDate,
        endDate: endDate,
      });
      const processRowsCount = (data, countObject, propertyName) => {
        const result = {};

        data?.forEach((connection) => {
          const { createdAt, attributes } = connection;

          attributes.forEach((column) => {
            const tableName = column.Name;

            if (!result[tableName]) {
              result[tableName] = {
                name: tableName,
                values: [],
              };
            }

            result[tableName].values.push({
              date: createdAt,
              rows: column[propertyName],
            });
          });
        });

        return Object.values(result).sort((a, b) => b?.values?.length - a?.values?.length);
      };

      const NullRowsCount = processRowsCount(data, {}, "NullRowsCount");
      const NotNullRowsCount = processRowsCount(data, {}, "NotNullRowsCount");
      const UniqueRowsCount = processRowsCount(data, {}, "UniqueRowsCount");
      const DuplicateRowsCount = processRowsCount(data, {}, "DuplicateRowsCount");
      const rowData = data?.map((each) => ({
        date: each?.createdAt,
        rows: each?.tableRowsCount,
      }));
      const formatedData = {
        tableName: data?.[0]?.tableName,
        NullRowsCount,
        NotNullRowsCount: NotNullRowsCount,
        UniqueRowsCount: UniqueRowsCount,
        Rows: rowData,
        DuplicateRowsCount: DuplicateRowsCount,
      };
      setColumnData(formatedData);
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message || error.message,
        open: true,
        colour: "error",
      });
    }
    setColumnDataLoading(false);
  };

  useEffect(() => {
    handleOnClickLine(previewConData?.Name);
  }, []);
  return (
    <>
      <TableContainer sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S. No</TableCell>
              <TableCell>Column Name </TableCell>
              <TableCell>Duplicate</TableCell>
              <TableCell>Distinct</TableCell>
              <TableCell>Null</TableCell>
              <TableCell>Not Null</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {previewConData?.columns?.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row?.Name}</TableCell>

                <TableCell>
                  <Box display="flex" alignItems="center">
                    <Typography>{row?.DuplicateRowsCount}</Typography>
                    <CustomArrowIndicator value={row?.IncreasedDuplicateRowsCount} />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <Typography>{row?.UniqueRowsCount}</Typography>
                    <CustomArrowIndicator value={row?.IncreasedUniqueRowsCount} />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <Typography>{row?.NullRowsCount}</Typography>
                    <CustomArrowIndicator value={row?.IncreasedNullRowsCount} />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <Typography>{row?.NotNullRowsCount}</Typography>
                    <CustomArrowIndicator value={row?.IncreasedNotNullRowsCount} />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
            {previewConData?.columns?.length === 0 && (
              <TableRow>
                <TableCell sx={{ textAlign: "center" }} colSpan={8}>
                  No records to display
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {Object.keys(columnData)?.length > 0 && (
        <ColumnChart
          columnDataLoading={columnDataLoading}
          columnData={columnData}
          setColumnDataLoading={setColumnDataLoading}
        />
      )}
    </>
  );
};

export default InSights;
