import { memo } from "react";
import { Box, Card, CardActionArea, Typography, FormHelperText } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import apiImage from "../../../images/CreateDqRules/api.svg";
import compare from "../../../images/CreateDqRules/compare.svg";
import dataBaseImage from "../../../images/CreateDqRules/database.svg";
import fileImage from "../../../images/CreateDqRules/file.svg";
import JsonFileReaderForm from "../JsonFileReaderForm";
import CustomDrawer from "../../CustomComponents/CustomDrawer";

// Constants
const dqTypes = (t) => [
  { id: 1, title: t("API"), image: apiImage, navLink: "/DataQualityRule/singleapi" },
  { id: 2, title: t("File"), image: fileImage, navLink: "/DataQualityRule/create?testType=Files" },
  { id: 3, title: t("Database"), image: dataBaseImage, navLink: "/DataQualityRule/create?testType=database" },
  {
    id: 4,
    title: t("Comparison"),
    image: compare,
    navLink: "/DataQualityRule/row-comparison/create?firstDataSourceType=database&secondDataSourceType=database",
  },
];

const CreateDqRuleCard = memo(({ item }) => (
  <Card
    className="subcontainer"
    sx={{
      opacity: item.title === "API" ? 0.5 : 1,
      width: "88px",
      pointerEvents: item.title === "API" ? "none" : "auto",
    }}>
    <CardActionArea className="center column">
      <img height="60px" src={item.image} alt={item.title} />
      <Typography>{item.title}</Typography>
    </CardActionArea>
  </Card>
));

CreateDqRuleCard.displayName = "CreateDqRuleCard";

const CreateNewDqRule = ({ refreshData }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const createRule = JSON.parse(searchParams.get("createRule"));
  const importRule = searchParams.get("importRule");

  const handleDrawerClose = () => setSearchParams();

  return (
    <>
      {createRule && (
        <Box className="center column">
          <Box className="v-center" sx={{ gap: 2 }}>
            {dqTypes(t).map((type) => (
              <Box key={type.id}>
                {type.title !== "API" ? (
                  <Link style={{ textDecoration: "none" }} to={type.navLink}>
                    <CreateDqRuleCard item={type} />
                  </Link>
                ) : (
                  <CreateDqRuleCard item={type} />
                )}
              </Box>
            ))}
          </Box>
          <FormHelperText sx={{ color: "#a8a5a5", textAlign: "center", mt: 2 }}>{t("note3")}</FormHelperText>
        </Box>
      )}
      {importRule && (
        <CustomDrawer title={t("Import DQRule")} onClose={handleDrawerClose}>
          <JsonFileReaderForm toggleDrawer={handleDrawerClose} refreshData={refreshData} />
        </CustomDrawer>
      )}
    </>
  );
};

export default CreateNewDqRule;
