import { Box } from "@mui/material";
import HorizontalBarChart from "./HorizontalBarchart";
import VerticalBarChart from "./VerticalBarchart";

const Compresseddata = (data) => {
  const formatteddata = [];
  let count = 0;

  data &&
    Object.entries(data).forEach(([key, value]) => {
      if (formatteddata.length < 5) {
        formatteddata.push({ word: key, count: value });
      } else {
        count += value;
      }
    });

  if (count > 0) {
    formatteddata.push({ word: "Others...", count: count });
  }

  return formatteddata;
};

const Converteddata = (data) => {
  const temp = data;
  const formatteddata = [];

  Object.entries(temp).forEach(([key, value]) => {
    formatteddata.push({ word: key, count: value });
  });

  return formatteddata;
};

const Mapped_bin_edges_To_counts = (data) => {
  const formatteddata = [];

  data.counts.forEach((count, index) => {
    formatteddata.push({ word: data.bin_edges[index], count: count });
  });

  return formatteddata;
};

export default function Barchart({ data, chart }) {
  const type = data?.type;
  let formatteddata = [];

  if (type === "Numeric") {
    formatteddata = Mapped_bin_edges_To_counts(data?.histogram);
  } else if (type === "Categorical" && chart === "vertical") {
    formatteddata = Mapped_bin_edges_To_counts(data?.histogram_length);
  } else if (type === "Boolean") {
    formatteddata = Converteddata(data?.value_counts_without_nan);
  } else {
    formatteddata = Compresseddata(data?.value_counts_without_nan);
  }

  const chartType =
    chart === "vertical" ? (
      <VerticalBarChart data={formatteddata} type={type} />
    ) : (
      <HorizontalBarChart data={formatteddata} />
    );

  return (
    <Box sx={{ marginTop: "20px" }}>
      {type === "Numeric" ? <VerticalBarChart data={formatteddata} type={type} /> : chartType}
    </Box>
  );
}
