import React from "react";
import {
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const CustomChip = ({ label, ...props }) => (
  <Tooltip title={label} placement="top">
    <Chip
      sx={{ maxWidth: 150, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
      label={label}
      {...props}
    />
  </Tooltip>
);

const CompareFinalValidation = ({ testDetails, finalValidation, source1, source2 }) => {
  const { t } = useTranslation();
  const isCustomQuery = Boolean(testDetails?.FirstDatasourceSql);
  return (
    <TableContainer className="validationsPreviewTableContainer">
      <Table aria-label="validation comparison table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: !isCustomQuery ? "50%" : "45%" }}>
              <Box className="space-between">
                <Typography>{t("1st Data Source")}</Typography>

                <Typography>
                  {source1?.connectionType === "Azure Blob" || source1?.ext ? t("File") : t("Table")} :{" "}
                  {testDetails?.FirstTableName || testDetails?.FirstDatasourceName || "NA"}
                </Typography>
              </Box>
            </TableCell>
            {!isCustomQuery && (
              <TableCell sx={{ width: "10%" }}>
                <Typography>
                  <b>{t("Is Key Column ?")}</b>
                </Typography>
              </TableCell>
            )}
            <TableCell sx={{ width: !isCustomQuery ? "50%" : "45%" }}>
              <Box className="space-between">
                <Typography>{t("2nd Data Source")}</Typography>
                <Typography>
                  {source2?.connectionType === "Azure Blob" || source2?.ext ? t("File") : t("Table")} :{" "}
                  {testDetails?.SecondTableName || testDetails?.SecondDatasourceName || "NA"}
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {finalValidation.map((validation, index) => (
            <React.Fragment key={index}>
              {validation.source1.map((source1Data, i) => (
                <TableRow key={`source1-${i}`}>
                  <TableCell>
                    <CustomChip label={source1Data?.Column || "NA"} size="small" />
                  </TableCell>
                  {!isCustomQuery && (
                    <TableCell>
                      <CustomChip label={source1Data?.IsKey?.toString() || "False"} size="small" />
                    </TableCell>
                  )}
                  <TableCell>
                    <CustomChip label={validation.source2[i]?.Column || "NA"} size="small" />
                  </TableCell>
                </TableRow>
              ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CompareFinalValidation;
