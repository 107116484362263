import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const TestsChart = ({ dataSource }) => {
  const svgRef = useRef();
  const [chartWidth, setChartWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (svgRef.current) {
        setChartWidth(svgRef.current.clientWidth);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const margin = { top: 10, right: 20, bottom: 20, left: 50 };
    const height = 420 - margin.top - margin.bottom;

    if (chartWidth === 0) {
      return;
    }
    const width = chartWidth - margin.left - margin.right;

    svg.selectAll("*").remove();

    const chart = svg
      .attr("width", "100%")
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const stack = d3.stack().keys(["Passed", "Failed"]);
    const stackedData = stack(dataSource);

    const xScale = d3
      .scaleBand()
      .domain(dataSource.map((d) => d.Date))
      .range([0, width])
      .padding(0.2);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(stackedData[stackedData.length - 1], (d) => d[1])])
      .range([height, 0]);

    const colorScale = d3.scaleOrdinal().domain(["Passed", "Failed"]).range(["#50cd89", "#d32f2f"]);

    chart.append("g").attr("transform", `translate(0,${height})`).call(d3.axisBottom(xScale));
    chart.append("g").call(d3.axisLeft(yScale));

    chart
      .selectAll(".layer")
      .data(stackedData)
      .enter()
      .append("g")
      .attr("fill", (d) => colorScale(d.key))
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("x", (d) => xScale(d?.data?.Date))
      .attr("y", (d) => yScale(d[1]))
      .attr("height", (d) => yScale(d[0]) - yScale(d[1]))
      .attr("width", xScale.bandwidth());

    // Tooltip setup
    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("z-index", "10")
      .style("opacity", 0)
      .style("background-color", "#fff")
      .style("border", "1px solid #ccc")
      .style("padding", "5px")
      .style("border-radius", "3px");

    chart
      .selectAll("rect")
      .on("mouseover", (event, d) => {
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip
          .html(`Value: ${d[1] - d[0]}`)
          .style("left", `${event.pageX + 5}px`)
          .style("top", `${event.pageY - 28}px`);
      })
      .on("mouseout", () => {
        tooltip.transition().duration(500).style("opacity", 0);
      });
  }, [dataSource, chartWidth]);

  return <svg ref={svgRef} style={{ width: "100%" }}></svg>;
};

export default TestsChart;
