import { useState } from "react";
import { IconButton, InputBase, Box } from "@mui/material";
import { Search, Close } from "@mui/icons-material";

const CollapsibleSearch = ({
  placeholderText = "Search...",
  onSearch = () => null,
  queryValue = "",
  onQueryChange = () => null,
  maxWidth = "400px",
  height = 32,
  initialExpandedState = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(initialExpandedState);
  const [query, setQuery] = useState(queryValue);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    onSearch(query);
  };

  const handleCollapse = () => {
    !initialExpandedState && setIsExpanded(false);
    setQuery("");
    onQueryChange("");
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    onQueryChange(event.target.value);
  };

  const handleOpenSearchBar = () => {
    !initialExpandedState && setIsExpanded((prev) => !prev);
  };

  return (
    <Box
      component="form"
      onSubmit={handleFormSubmit}
      sx={{ position: "relative", maxWidth: maxWidth, width: "auto", transition: "all 0.5s ease-in-out" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          width: isExpanded ? "100%" : "40px",
          transition: "width 0.3s ease-in-out",
          borderRadius: isExpanded ? "20px" : "50%",
          background: isExpanded ? "#fff" : "transparent",
          boxShadow: isExpanded ? "0 2px 8px rgba(0,0,0,0.2)" : "none",
        }}>
        <IconButton
          onClick={handleOpenSearchBar}
          size="small"
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 10,
            height: height,
            width: 32,
            p: 0,
            backgroundColor: "transparent",
            ...(isExpanded && { "&:hover": { backgroundColor: "transparent" } }),
            color: isExpanded ? "#555" : "#000",
          }}>
          <Search fontSize="small" />
        </IconButton>

        <InputBase
          placeholder={placeholderText}
          autoFocus={isExpanded}
          sx={{
            height: height,
            pl: 5,
            width: isExpanded ? "100%" : "0px",
            opacity: isExpanded ? 1 : 0,
            cursor: isExpanded ? "default" : "pointer",
            border: "none",
            borderRadius: "20px",
            outline: "none",
            background: "transparent",
            transition: "width 0.3s ease-in-out, opacity 0.3s ease-in-out",
          }}
          value={query}
          onChange={handleQueryChange}
        />
      </Box>

      {isExpanded && query && (
        <IconButton onClick={handleCollapse} size="small" sx={{ position: "absolute", right: 0, top: 0, zIndex: 10 }}>
          <Close fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
};

export default CollapsibleSearch;
