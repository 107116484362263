/* eslint-disable no-nested-ternary */
import { Box, Button, TextField, Typography, MenuItem, IconButton, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  InfoOutlined as InfoOutlinedIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import SelectDateRange from "../../CustomComponents/SelectDateRange";
import ResultsListView from "./ResultsListView";
import { formatDate, isDateBeforeOneDay } from "../../../_helpers/utils";
import { useCallback, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import SkeletonLoader from "../../SkeletonLoader";
import CustomToggleButton from "../../CustomComponents/CustomsButtons/CustomToggleButton";
import ResultTile from "./ResultTile";
import { useCheckAccess } from "../../../CustomHooks/useCheckAccess";

const ResultsList = ({
  resultsLoading,
  resultType = "",
  executions,
  setExecutions,
  onClickExecutionCard,
  handleSortBy,
  handleOnSubmitDateRange,
  testType = "",
  fetchDataValidations,
}) => {
  const gridRef = useRef();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const sortBy = searchParams.get("sortBy");
  const startDate = new Date(searchParams.get("startDate"));
  const endDate = new Date(searchParams.get("endDate"));
  const viewType = searchParams.get("viewType");
  const { checkAccess } = useCheckAccess();

  const isComparisonTest = executions?.[0]?.validationDetails?.TestType === "Comparison";

  const exportToExcel = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
  }, [gridRef]);

  const onClickCard = (obj) => {
    onClickExecutionCard(obj);
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("expanded", true);
      return newState;
    });
  };

  const handleChange = (event) => {
    handleSortBy(event.target.value);
  };

  const onChangeToggleView = (newVal) => {
    newVal &&
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("viewType", newVal);
        return newState;
      });
  };

  const nonComparisonRows = executions
    ?.filter((each) => each?.validationDetails?.TestType !== "Comparison")
    ?.flatMap((item, index) =>
      item.Validations.map((each) => {
        const isFailed = each?.Result?.TotalFailed > 0 ? "FAIL" : "PASS";
        return {
          _id: item?._id,
          executionNumber: `Execution ${index + 1}`,
          ruleName: item?.validationDetails?.TestName,
          table: each?.TableName,
          column: each?.ColumnName,
          dqCheckCondition: each?.CombinedDQChecks,
          dqCheckValue: each?.ValidationProperties?.map((each) => each?.Value),
          totalFailed: each?.Result?.TotalFailed,
          totalPassed: each?.Result?.TotalPassed,
          result: item?.totalRecordsProcessed ? (isComparisonTest ? item?.summary?.finalResult : isFailed) : "--",
          executionDate: formatDate(item?.startDateTime),
        };
      }),
    );

  const comparisionRows = executions
    ?.filter((each) => each?.validationDetails?.TestType === "Comparison")
    .flatMap((item) => {
      const isFailed = item?.Result?.TotalFailed > 0 ? "FAIL" : "PASS";
      return {
        _id: item?._id,
        ruleName: item?.validationDetails?.TestName,
        sourceA: item?.FirstDatasourceName || item?.summary?.FirstDatasourceSql,
        selectedColumnInSourceA: item?.summary?.FirstDataSourceSelectedColumns,
        orderBySourceA: item?.summary?.firstSourceOrderBy,
        sourceB: item?.SecondDatasourceName || item?.summary?.SecondDatasourceSql,
        selectedColumnsInSourceB: item?.summary?.SecondDataSourceSelectedColumns,
        orderBySourceB: item?.summary?.secondSourceOrderBy,
        totalRecordsSourceA: item?.RowCountFirstDataSource,
        totalRecordsSourceB: item?.RowCountSecondDataSource,
        totalRecordsCompared: item?.summary?.totalRecordsCompared,
        totalRecordsMatched: item?.summary?.MatchedFirstDatasourceCount,
        result: item?.totalRecordsProcessed ? (isComparisonTest ? item?.summary?.finalResult : isFailed) : "--",
        executionDate: formatDate(item?.startDateTime),
      };
    });

  const filteredRows =
    (isComparisonTest || testType) && (resultType === "Comparison" || resultType === "")
      ? comparisionRows
      : nonComparisonRows;

  const filteredComparidionData = executions?.filter((each) => each?.validationDetails?.TestType === "Comparison");
  const filteredValidationData = executions?.filter((each) => each?.validationDetails?.TestType !== "Comparison");

  const getFilteredData = () => {
    if (!testType) {
      return executions;
    }
    return resultType === "Comparison" ? filteredComparidionData : filteredValidationData;
  };

  const filteredExecutions = getFilteredData()?.map((each) =>
    each?.status === 0 && isDateBeforeOneDay(each?.createdAt) ? { ...each, status: 4 } : each,
  );

  const getExecutionsList = () => {
    if (viewType === "grid") {
      return (
        <Grid container className="DQRuleGridresultList">
          {filteredExecutions?.map((obj, index) => (
            <Grid size={4} key={index}>
              <ResultTile
                executions={filteredExecutions}
                onClickCard={onClickCard}
                handleOnSubmitDateRange={handleOnSubmitDateRange}
                executionResult={obj}
                setExecutions={setExecutions}
                fetchDataValidations={fetchDataValidations}
              />
            </Grid>
          ))}
        </Grid>
      );
    }
    return (
      <ResultsListView
        gridRef={gridRef}
        filteredRows={filteredRows}
        executions={filteredExecutions}
        onClickCard={onClickCard}
      />
    );
  };

  const onChangeDateRange = (data) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("startDate", data?.startDate);
      newState.set("endDate", data?.endDate);
      return newState;
    });
  };

  return (
    <>
      <Box className="resultActionsToolbar">
        <Typography variant="sectionHeading">{t("Execution List")} : </Typography>
        <Box className="v-center" gap={2}>
          <Typography variant="body1">
            <strong>Start Date:</strong> {new Date(startDate).toDateString()}
          </Typography>
          <Typography variant="body1">
            <strong>End Date:</strong> {new Date(endDate).toDateString()}
          </Typography>

          <SelectDateRange
            setSelectedDateRange={onChangeDateRange}
            selectedDateRange={{ startDate, endDate, key: "selection" }}
            onClickSubmit={handleOnSubmitDateRange}
          />
          {checkAccess(testType || "DataQualityRule", "Download") && viewType === "list" && (
            <IconButton
              onClick={exportToExcel}
              color="primary"
              size="small"
              disabled={!executions?.length > 0}
              className="downloadDQRuleIcon">
              <FileDownloadOutlinedIcon fontSize="12px" color="primary" />
            </IconButton>
          )}

          <Button onClick={handleOnSubmitDateRange} variant="outlined" color="warning" size="small">
            {t("Refresh")}
          </Button>
          <TextField
            className="DQRuleSortBy"
            value={sortBy}
            select
            size="small"
            label={t("sort_by")}
            onChange={handleChange}>
            <MenuItem value="ASC">{t("Asc")}</MenuItem>
            <MenuItem value="DESC">{t("Desc")}</MenuItem>
          </TextField>

          <CustomToggleButton
            sx={{ display: "none" }}
            viewType={viewType}
            onChange={(event, newVal) => onChangeToggleView(newVal)}
          />

          <IconButton sx={{ p: 0 }}>
            <Tooltip arrow placement="left" title={t("execution_tool_tip")}>
              <InfoOutlinedIcon />
            </Tooltip>
          </IconButton>
        </Box>
      </Box>

      {resultsLoading ? (
        <SkeletonLoader />
      ) : filteredExecutions?.length > 0 ? (
        getExecutionsList()
      ) : (
        <Box className="noExecutionsFound">
          <Typography variant="sectionHeading" sx={{ opacity: 0.5 }}>
            {t("No Executions Found")}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default ResultsList;
