import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { Box, FormControl, IconButton, MenuItem, Paper, Select, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import APIServices from "../../services/app.service";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../App";
import CustomAgGridPagination from "./CustomAgGridPagination";
import { useSelector } from "react-redux";

const AgGridSSRM = ({
  gridRef,
  closePreview = () => null,
  setSelected = () => null,
  setSelectedRowId = () => null,
  apiMethod = "",
  headCells,
  context,
  payload = null,
  previewId = null,
  onClickRow = () => null,
}) => {
  const { t } = useTranslation();
  const { user_details } = useSelector((state) => state.auth);

  const { setSnack } = useContext(SnackbarContext);
  const [gridApi, setGridApi] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [resetStatus, setResetStatus] = useState([]);

  const defaultColDef = useMemo(() => ({ resizable: true, menuTabs: ["filterMenuTab", "columnsMenuTab"] }), []);

  const customLocaleText = useMemo(
    () => ({
      page: t("Page"),
      to: t("to"),
      of: t("of"),
      more: t("more"),
      first: t("First"),
      previous: t("Previous"),
      next: t("Next"),
      last: t("Last"),
    }),
    [t],
  );

  const gridOptions = useMemo(
    () => ({
      context,
      domLayout: "autoHeight",
      suppressCellFocus: true,
      rowHeight: 50,
      getRowId: (data) => data?.data?.id || data?.data?._id,

      localeText: customLocaleText,
    }),
    [context, user_details?.UserId, user_details?.RoleName, customLocaleText],
  );

  const rowClassRules = useMemo(() => ({ "preview-highlight": (params) => params?.data?.preview || false }), []);

  useEffect(() => {
    const api = gridRef?.current?.api;
    if (api) {
      api.forEachNode((rowNode) => {
        const data = rowNode.data;
        const isPreview = data?.id === previewId || data?._id === previewId;
        rowNode.setSelected(isPreview);
      });
    }
  }, [previewId, gridRef]);

  const onSelectionChanged = useCallback(() => {
    const gridApi = gridRef?.current?.api;
    if (!gridApi) {
      return;
    }

    const selectedNodes = gridApi.getRenderedNodes().filter((node) => node?.selected);
    const selectedRowIds = selectedNodes.map((node) => node?.data?.id || node?.data?._id);
    setSelectedRowId?.(selectedRowIds);
    const selectedData = selectedNodes.map((node) => node?.data).filter(Boolean);
    setSelected?.(selectedData);
  }, [gridRef, setSelected, setSelectedRowId]);

  const clearFilters = useCallback(() => {
    gridRef.current?.api.setFilterModel(null);
  }, [gridRef]);

  const onFilterChanged = useCallback(() => {
    const filterModel = gridRef.current?.api.getFilterModel();
    setResetStatus(Object.keys(filterModel));
    gridRef.current?.api.hideOverlay();
    gridRef.current?.api.deselectAll();
  }, [gridRef]);

  const onFirstDataRendered = useCallback(() => {
    gridRef?.current?.api?.sizeColumnsToFit();
  }, [gridRef]);

  const onPageSizeChanged = useCallback(
    (e) => {
      const value = e.target.value;
      setPageSize(value);
      // gridRef.current?.api?.paginationSetPageSize(Number(value));
    },
    [gridRef],
  );

  const onPaginationChanged = useCallback(() => {
    const api = gridRef.current.api;
    api.deselectAll();
    const current = api.paginationGetCurrentPage() + 1;
    setCurrentPage(current);
    const total = api.paginationGetTotalPages();
    setTotalPages(total);
  }, []);

  const translatedHeadCells = useMemo(
    () =>
      headCells.map((column) => ({ ...column, headerName: t(column.headerName), headerTooltip: t(column.headerName) })),
    [headCells, t],
  );

  const onRowClicked = useCallback(
    (e) => {
      onClickRow?.(e.data);
    },
    [onClickRow],
  );

  const onGridReady = useCallback(
    (params) => {
      gridRef?.current?.api?.sizeColumnsToFit();
      setGridApi(params.api);
    },
    [gridRef],
  );

  useEffect(() => {
    if (gridApi) {
      const datasource = {
        getRows(params) {
          closePreview?.();
          const { startRow, endRow, filterModel, sortModel } = params.request;
          let data = { ...payload, start: startRow, limit: endRow - startRow };

          if (sortModel?.length) {
            const { colId, sort } = sortModel[0];
            data = { ...data, sort: colId, order: sort };
          }

          if (Object.keys(filterModel).length) {
            data = { ...data, filter: filterModel };
          }

          APIServices[apiMethod](data)
            .then((response) => {
              const rowCount = response?.data?.rowCount || response?.data?.data?.length || 0;
              params.success({ rowData: response?.data?.data, rowCount });
              rowCount ? params.api.hideOverlay() : params.api.showNoRowsOverlay();
            })
            .catch((error) => {
              setSnack({ message: error?.response?.data?.message ?? error.message, open: true, colour: "error" });
              params.fail();
            });
        },
      };

      gridApi.setGridOption("serverSideDatasource", datasource);
    }
  }, [gridApi, payload]);

  const originalError = console.error;
  console.error = (...args) => {
    if (
      !args.some(
        (arg) =>
          (typeof arg === "string" || Array.isArray(arg)) &&
          (arg.includes("trial license") ||
            arg.includes("AG Grid Enterprise") ||
            arg.includes("License Key Not Found") ||
            arg.includes("it is not licensed for development")),
      )
    ) {
      originalError(...args);
    }
  };

  const rowSelection = useMemo(
    () => ({
      mode: "multiRow",
      isRowSelectable: (rowNode) => {
        if (rowNode?.data?.isExecutionRunning > 0) {
          return false;
        }
        const isUsedInTests = rowNode?.data?.UsedInTests?.length > 0;
        const isOwnedByUser = rowNode?.data?.UserId === user_details?.UserId || user_details?.RoleName === "Admin";
        return !isUsedInTests && isOwnedByUser;
      },
      // hideDisabledCheckboxes: true,
    }),
    [],
  );

  return (
    <>
      {resetStatus?.length > 0 && (
        <Box className="refreshBtn">
          <IconButton
            color="error"
            size="small"
            variant="outlined"
            onClick={clearFilters}
            disabled={!resetStatus.length}>
            <Tooltip title="Clear Filters" placement="right">
              {!resetStatus.length ? <FilterAltOffIcon fontSize="small" /> : <FilterAltIcon fontSize="small" />}
            </Tooltip>
          </IconButton>
        </Box>
      )}
      <Grid container>
        <Grid size={12}>
          <Box className="ag-theme-balham" component={Paper}>
            <Box position="relative">
              <AgGridReact
                ref={gridRef}
                rowHeight={38}
                suppressRowHoverHighlight={true}
                rowModelType="serverSide"
                cacheBlockSize={pageSize}
                maxConcurrentDatasourceRequests={1}
                infiniteInitialRowCount={0}
                onGridReady={onGridReady}
                onSelectionChanged={onSelectionChanged}
                suppressPaginationPanel={true}
                suppressScrollOnNewData={true}
                columnDefs={translatedHeadCells}
                animateRows={true}
                rowClassRules={rowClassRules}
                defaultColDef={defaultColDef}
                onFilterChanged={onFilterChanged}
                rowSelection={rowSelection}
                gridOptions={gridOptions}
                paginationPageSize={pageSize}
                pagination={true}
                onPaginationChanged={onPaginationChanged}
                onFirstDataRendered={onFirstDataRendered}
                tooltipShowDelay={10}
                onRowClicked={onRowClicked}
              />
              <Box>
                <FormControl className="rowsDisplay" fullWidth size="small">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={pageSize}
                    onChange={onPageSizeChanged}>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </FormControl>
                <CustomAgGridPagination gridRef={gridRef} currentPage={currentPage} totalPages={totalPages} />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AgGridSSRM;
