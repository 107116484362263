import ListItemWithColon from "../../CreateNewDqRule/Common/ListItemWithColon";
import CustomAutoComplete from "../../../CustomComponents/CustomAutoComplete";
import CustomLoadingButton from "../../../CustomComponents/CustomsButtons/CustomLoadingButton";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import {
  DriveFileRenameOutline as DriveFileRenameOutlineIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import { isAzure } from "../../../../_helpers/Constant";
import { useState } from "react";
import { useSelector } from "react-redux";

const CustomQueryComponent = ({
  fetchColumnsFromQuery,
  title,
  columnsToValidate,
  selectedColumns,
  defaultSqlInput,
  resetQueryResult,
  queryResult,
  columnsLoading,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const ruleId = searchParams.get("ruleId");
  const [open, setOpen] = useState(!ruleId);
  const [sqlInput, setSqlInput] = useState(defaultSqlInput);
  const handleClickEdit = () => {
    if (columnsToValidate?.length > 0 || selectedColumns?.length > 0) {
      //   setSnack({ message: "Delete existing validation to Edit the Query", open: true, colour: "warning" });
    } else {
      setOpen((prevState) => !prevState);
    }
  };

  const editQuery = () => (
    <Box className="space-between">
      {t(`SQL Query for ${title}`)}
      <IconButton disabled={sqlInput === "" && open === true} size="small" variant="outlined" onClick={handleClickEdit}>
        <Tooltip title={t("View or Edit")} placement="top">
          {open ? (
            <ExpandLessIcon size="large" sx={{ color: "#808080" }} />
          ) : (
            <DriveFileRenameOutlineIcon size="large" sx={{ color: "#808080" }} />
          )}
        </Tooltip>
      </IconButton>
    </Box>
  );

  const onClickSubmitQuery = () => {
    fetchColumnsFromQuery(sqlInput);
    setOpen(false);
  };

  const handleResetQuery = () => {
    setSqlInput("");
    resetQueryResult();
  };

  const onChangeSqlInput = (e) => {
    setSqlInput(e.target.value);
    resetQueryResult();
  };
  return (
    <>
      <Card
        sx={{
          minWidth: 275,
          border: "1px solid #DDDFE1",
          "& .MuiCardHeader-root": { "& .MuiTypography-root": { fontSize: "14px" } },
          mb: 1.5,
        }}>
        <CardHeader title={editQuery()} sx={{ backgroundColor: "#5F64E526", color: "#464a53", p: 0.5 }} />
        <CardContent
          sx={{
            maxHeight: "260px",
            overflowY: "auto",
            pb: "16px!important",
            "& textarea": {
              width: "100%",
              height: "150px !important",
              maxHeight: "150px !important",
              overflowY: "auto !important",
            },
          }}>
          {open ? (
            <TextareaAutosize minRows={10} onChange={onChangeSqlInput} value={sqlInput} />
          ) : (
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                minHeight: "40px",
              }}>
              <b>{t("SQL Query")} : </b> {sqlInput}
            </Typography>
          )}
        </CardContent>
      </Card>

      {!queryResult?.result && (
        <Grid container mt={1.5}>
          <Grid size={6}>
            <Button
              color="error"
              size="small"
              variant="contained"
              disabled={sqlInput?.trim().length === 0}
              onClick={handleResetQuery}>
              {t("Reset")}
            </Button>
          </Grid>
          <Grid size={6} sx={{ textAlign: "right" }}>
            <CustomLoadingButton
              disabled={sqlInput?.trim().length === 0}
              onClick={onClickSubmitQuery}
              loading={columnsLoading}>
              {t("Submit")}
            </CustomLoadingButton>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const SelectSourceTable = ({
  columnsLoading,
  title = "",
  tablesList,
  connectionDetails,
  onChangeTable,
  selectedTable,
  connectionDataLoading,
  selectedColumns,
  handleGoButton,
  fetchColumnsFromQuery,
  queryResult,
  resetQueryResult,
  defaultSqlInput,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const isCustomQuery = JSON.parse(searchParams.get("isCustomQuery"));

  const columnsToValidate = useSelector((state) => state.rowComparison.columnsToValidate);

  return (
    <Grid size={6}>
      <Box className="comparisonSelectTableContainer">
        <Typography variant="h6">{title} : </Typography>

        <Box className="flex column" sx={{ gap: 1, minHeight: "88px", my: 0.5 }}>
          {!connectionDetails?.fileName ? (
            <>
              <ListItemWithColon title={t("Database Type")} value={connectionDetails?.connectionType} />
              {isAzure(connectionDetails?.connectionType) ? (
                <>
                  {connectionDetails?.connectionType === "AWS S3" && (
                    <>
                      <ListItemWithColon title={t("Bucket Name")} value={connectionDetails?.bucketName} />
                      <ListItemWithColon title={t("Region Name")} value={connectionDetails?.regionName} />
                    </>
                  )}

                  {connectionDetails?.connectionType === "Azure Blob" && (
                    <>
                      <ListItemWithColon title={t("Container Name")} value={connectionDetails?.containerName} />
                      <ListItemWithColon title={t("Account Name")} value={connectionDetails?.accountName} />
                    </>
                  )}
                </>
              ) : (
                <>
                  {connectionDetails?.serverHostname && (
                    <ListItemWithColon title={t("Server Host Name")} value={connectionDetails?.serverHostname} />
                  )}
                  {connectionDetails?.catalogName && (
                    <ListItemWithColon title={t("Catalog Name")} value={connectionDetails?.catalogName} />
                  )}
                  {connectionDetails?.server && (
                    <ListItemWithColon title={t("Server")} value={connectionDetails?.server} />
                  )}
                  {connectionDetails?.dataBase && (
                    <ListItemWithColon title={t("Database")} value={connectionDetails?.dataBase} />
                  )}
                  {(connectionDetails?.schema || connectionDetails?.schemas) && (
                    <ListItemWithColon
                      title={t("Schema")}
                      value={connectionDetails?.schemas || connectionDetails?.schema}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <ListItemWithColon title={t("File Name")} value={connectionDetails?.fileName} />
              <ListItemWithColon title={t("Type")} value={connectionDetails?.ext} />
              <ListItemWithColon title={t("Size")} value={`${(connectionDetails?.size * 0.001).toFixed(1)}KB`} />
              <Box visibility={"hidden"}>
                <ListItemWithColon title="" value="" />
              </Box>
            </>
          )}
        </Box>

        {!connectionDetails?.fileName && !isCustomQuery && (
          <Grid container sx={{ height: "48px" }}>
            <Grid size={10}>
              <CustomAutoComplete
                label={t("Select Table")}
                placeholder={t("Select Table")}
                disabled={Boolean(columnsToValidate?.length || selectedColumns?.length)}
                value={selectedTable}
                loading={connectionDataLoading}
                options={tablesList}
                onChange={onChangeTable}
                getOptionLabel={(opt) =>
                  opt?.table_schema ? `${opt?.table_schema} . ${opt?.table_name}` : opt?.table_name
                }
              />
            </Grid>
            <Grid size={2} sx={{ textAlign: "right", pl: 1 }}>
              {selectedTable && (
                <CustomLoadingButton
                  disabled={Boolean(columnsLoading || columnsToValidate?.length || selectedColumns?.length)}
                  onClick={handleGoButton}
                  loading={columnsLoading}>
                  {t("GO")}
                </CustomLoadingButton>
              )}
            </Grid>
          </Grid>
        )}

        {isCustomQuery && (
          <CustomQueryComponent
            columnsLoading={columnsLoading}
            title={title}
            columnsToValidate={columnsToValidate}
            selectedColumns={selectedColumns}
            defaultSqlInput={defaultSqlInput}
            fetchColumnsFromQuery={fetchColumnsFromQuery}
            queryResult={queryResult}
            resetQueryResult={resetQueryResult}
          />
        )}
      </Box>
    </Grid>
  );
};

export default SelectSourceTable;
