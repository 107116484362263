import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { JSONTree } from "react-json-tree";
import { useTranslation } from "react-i18next";
import SkeletonLoader from "../../../SkeletonLoader";
export const jsonTheme = {
  base00: "#6A707E",
  base01: "#6A707E",
  base02: "#6A707E",
  base03: "#6A707E",
  base04: "#6A707E",
  base05: "#6A707E",
  base06: "#6A707E",
  base07: "#6A707E",
  base08: "#6A707E",
  base09: "#6A707E",
  base0A: "#6A707E",
  base0B: "#6A707E",
  base0C: "#6A707E",
  base0D: "#E89D45",
  base0E: "#6A707E",
  base0F: "#6A707E",
};
export default function CustomJsonTree(props) {
  const { t } = useTranslation();
  const { loader, response, returnVal, showCross } = props;
  return (
    <Box className="container">
      <Box component={Paper} className="drawerShortHead" variant="h6">
        {t("Preview")}
      </Box>

      <Paper sx={{ borderRadius: 0, boxShadow: "0px 3px 6px #00000029  !important" }}>
        <Box className="flex-col bg-white&box-shadow" gap={1} p={1.5}>
          <Box display={"flex"}>
            <Box sx={{ width: "140px" }}>
              {t("Data Source Name")}
              <Box sx={{ float: "right" }} component={"span"}>
                &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
              </Box>
            </Box>

            <Typography variant="bold">{response?.fileDetails?.connectionName} </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "140px" }}>
              {t("Total nodes")}
              <Box sx={{ float: "right" }} component={"span"}>
                &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
              </Box>
            </Box>

            <Typography variant="bold">{response?.result?.totalRows}</Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "140px" }}>
              {response?.fileDetails?.connectionType === "Web App" ? t("HTTP Method") : t("Total Columns")}
              <Box sx={{ float: "right" }} component={"span"}>
                &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
              </Box>
            </Box>

            <Typography variant="bold">
              {response?.fileDetails?.connectionType === "Web App" ? response?.fileDetails?.HTTPMethod : t("pending")}
            </Typography>
          </Box>
        </Box>
      </Paper>

      <Box className="innerSubHead">
        <Grid container alignItems="center" justify="center">
          {showCross && (
            <Grid size={2}>
              <IconButton
                onClick={() => returnVal(false)}
                size="small"
                color="error"
                sx={{ ml: "auto", display: "flex" }}
                aria-label="add to shopping cart">
                <CancelOutlinedIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box
        component={Paper}
        className="bg-white&box-shadow"
        height={"100%"}
        p="16px"
        sx={{ boxShadow: "0px 3px 6px #00000029  !important" }}>
        <Grid container>
          <Grid size={12} sx={{ p: 0, display: "grid" }}>
            {loader ? (
              <SkeletonLoader />
            ) : (
              <>{response && <JSONTree data={response?.result?.rows} theme={jsonTheme} />}</>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
