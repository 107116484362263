import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { Box, IconButton, Tooltip } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const CustomHeaderAgGrid = ({ data, errorColumn }) => {
  const gridRef = useRef(null);
  const [resetStatus, setResetStatus] = useState(true);
  const [columnDefs, setColumnDefs] = useState([]);

  // Process data to remove dots from keys
  const formattedData = useMemo(() => {
    return data.map((item) =>
      Object.fromEntries(Object.entries(item).map(([key, value]) => [key.replace(/\./g, ""), value])),
    );
  }, [data]);

  // Generate column definitions
  useEffect(() => {
    if (formattedData.length) {
      const keys = Object.keys(formattedData[0]);
      const newColumnDefs = keys.map((key) => {
        const isErrorColumn =
          typeof errorColumn?.columns === "string"
            ? errorColumn.columns.replace(/\./g, "") === key
            : errorColumn.columns?.includes(key);

        return isErrorColumn
          ? {
              field: key,
              headerName: key,
              cellStyle: { color: errorColumn?.color },
              headerClass: "errorColumnClass",
              width: 200,
            }
          : { field: key };
      });

      setColumnDefs(newColumnDefs);
    }
  }, [formattedData, errorColumn]);

  const clearFilters = useCallback(() => {
    const api = gridRef.current?.api;
    if (api) {
      api.setFilterModel(null);
      api.deselectAll();
      api.getColumnDefs().forEach((colDef) => api.setColumnsVisible([colDef.field], true));
      setResetStatus(true);
    }
  }, []);

  const handleFilterChange = useCallback(() => {
    const api = gridRef.current?.api;
    if (api) {
      setResetStatus(!Object.keys(api.getFilterModel()).length);

      if (!api.rowModel?.rowsToDisplay?.length) {
        api.showNoRowsOverlay();
      } else {
        api.hideOverlay();
      }
    }
  }, []);

  const handleColumnVisibilityChange = useCallback(() => {
    const api = gridRef.current?.api;
    if (api) {
      const visibleColumns = api.getAllDisplayedColumns();
      const totalColumns = api.getColumnDefs();
      setResetStatus(visibleColumns.length === totalColumns.length);
    }
  }, []);

  const handleGridSizeChange = useCallback((params) => {
    params.api.sizeColumnsToFit();
  }, []);

  return (
    <>
      <IconButton color="error" size="small" onClick={clearFilters} disabled={resetStatus}>
        <Tooltip title="Clear Filters" placement="right">
          {resetStatus ? <FilterAltOffIcon fontSize="small" /> : <FilterAltIcon fontSize="small" />}
        </Tooltip>
      </IconButton>

      <Box className="ag-theme-balham" style={{ height: "500px", width: "100%" }}>
        <AgGridReact
          ref={gridRef}
          rowData={formattedData}
          columnDefs={columnDefs}
          animateRows
          defaultColDef={{ sortable: true, filter: "agTextColumnFilter", resizable: true, minWidth: 60 }}
          onFilterChanged={handleFilterChange}
          onColumnVisible={handleColumnVisibilityChange}
          onGridSizeChanged={handleGridSizeChange}
          pagination
          paginationPageSize={20}
          suppressMenuHide
          rowHeight={38}
          suppressMovableColumns
        />
      </Box>
    </>
  );
};

export default CustomHeaderAgGrid;
