import { useContext, useRef, useState } from "react";
import { Box, Table, TableContainer, TableHead, Typography, TableCell } from "@mui/material";
import Grid from "@mui/material/Grid2";

import ExpandableTableRow from "../../Tables/ExpandableTableRow";
import ApiService from "../../../services/app.service";
import ComparativeValidationResult from "./ComparativeValidationResult";
import { formatDateWithTime, getTimeDuration } from "../../../_helpers/utils";

import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../../App";
import ComparativeResultModel from "./ComparativeResultModel";

const ExecutionDetailItem = ({ title, value }) => {
  const { t } = useTranslation();
  return (
    <Typography variant="detailTitle" sx={{ fontSize: "12px !important" }}>
      {t(title)} :{" "}
      <Typography component="span" variant="detailValue">
        {value}
      </Typography>
    </Typography>
  );
};

export default function ComparativeExecutionResult({ selectedExecution, resultValidation }) {
  const scrollRef = useRef();
  const { setSnack } = useContext(SnackbarContext);
  const { t } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [selectedDataType, setSelectedDataType] = useState({});
  const [validateColumnName, setValidateColumnName] = useState({});
  const [display, setDisplay] = useState(false);
  const [loadingType, setLoadingType] = useState(null);

  const validationDetailsRowComparison = resultValidation?.ValidationResults?.map((each) =>
    each?.validationschecks?.filter((rowCount) => rowCount.ValidationName === "RowComparison"),
  ).flat();

  const getTableData = async (itemVal, type, row = null) => {
    // const modifiedType = ["MatchedSecondDatasourceCount", "MatchedFirstDatasourceCount"]?.includes(type)
    //   ? "Matched"
    //   : type;
    try {
      setLoadingType({ id: itemVal?._id, type });
      const response = await ApiService.getFailedRecords({
        validationId: itemVal?._id,
        type: type,
        validationName: itemVal?.ValidationName,
        createdAt: itemVal?.createdAt,
        requestId: row?.requestId,
        batchNumber: row?.batchNumber,
      });

      setSelectedDataType({ type: type, name: type });

      setValidateColumnName({
        columnOne: itemVal?.FirstDataSourceSelectedColumns,
        columnTwo: itemVal?.SecondDataSourceSelectedColumns,
      });

      setTableData(response?.data?.results);
      setDisplay(true);
    } catch (e) {
      setSnack({ message: e?.response?.data?.message ?? e.message, open: true, colour: "error" });
    }
    setLoadingType(null);
  };

  const autoScroll = () => {
    setTimeout(() => {
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, 600);
  };

  const handleClose = () => {
    setDisplay(false);
  };

  const firstDSTypeFile = resultValidation?.validationDetails?.FirstDatasourceType;
  const secondDSTypeFile = resultValidation?.validationDetails?.SecondDatasourceType;

  return (
    <Box sx={{ width: "100%" }} ref={scrollRef}>
      <Grid sx={{ background: "#DCD4FB" }} container>
        <Grid size={12}>
          <Box className="space-between" sx={{ borderBottom: "1px solid #ccc", p: 1 }}>
            <Typography variant="sectionHeading">{t("Comparative Test Result")}</Typography>
            <Typography variant="detailTitle">
              {t("Test Name")} :{" "}
              <Typography component="span" variant="detailValue">
                {resultValidation?.validationDetails?.TestName}
              </Typography>
            </Typography>
            <Typography variant="detailTitle">
              {t("Test Type")} :{" "}
              <Typography component="span" variant="detailValue">
                {resultValidation?.validationDetails?.TestType}
              </Typography>
            </Typography>
          </Box>
        </Grid>
        <Grid size={6} sx={{ borderRight: "1px solid #ccc", px: 1, py: 0.5 }}>
          <Typography className="dataSouceHead">{t("1st Data Source")}</Typography>
          <Box className="v-center" gap={2}>
            <ExecutionDetailItem title="Type" value={firstDSTypeFile} />
            <ExecutionDetailItem
              title={firstDSTypeFile === "File" ? "File Name" : "Database Name"}
              value={resultValidation?.validationDetails?.FirstDatasourceName}
            />
          </Box>

          <Box className="flex column">
            {selectedExecution?.summary?.FirstDatasourceSql ? (
              <ExecutionDetailItem title={t("Query")} value={selectedExecution?.summary?.FirstDatasourceSql} />
            ) : (
              <>
                <ExecutionDetailItem
                  title={firstDSTypeFile === "File" ? t("File") : t("Table")}
                  value={selectedExecution?.summary?.FirstDataSourceTable}
                />

                <ExecutionDetailItem
                  title={t("Column")}
                  value={selectedExecution?.summary?.FirstDataSourceSelectedColumns}
                />
                {/* <ExecutionDetailItem title={t("Row Count")} value={itemVal?.RowCountResult?.FirstDataSource} /> */}
              </>
            )}
          </Box>
        </Grid>
        <Grid size={6} px={1} py={0.5}>
          <Typography className="dataSouceHead">{t("2nd Data Source")}</Typography>

          <Box className="v-center" gap={2}>
            <ExecutionDetailItem title="Type" value={secondDSTypeFile} />
            <ExecutionDetailItem
              title={secondDSTypeFile === "File" ? "File Name" : "Database Name"}
              value={resultValidation?.validationDetails?.SecondDatasourceName}
            />
          </Box>
          <Box className="flex column">
            {selectedExecution?.summary?.SecondDatasourceSql ? (
              <ExecutionDetailItem title={t("Query")} value={selectedExecution?.summary?.SecondDatasourceSql} />
            ) : (
              <>
                <ExecutionDetailItem
                  title={secondDSTypeFile === "File" ? t("File") : t("Table")}
                  value={selectedExecution?.summary?.SecondDataSourceTable}
                />

                <ExecutionDetailItem
                  title={t("Column")}
                  value={selectedExecution?.summary?.SecondDataSourceSelectedColumns}
                />
                {/*  <ExecutionDetailItem title={t("Row Count")} value={itemVal?.RowCountResult?.SecondDataSource} /> */}
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      {resultValidation?.ValidationResults?.map((item, index) => {
        const RowComparisonResults = item?.validationschecks?.filter(
          (validationData) => validationData.ValidationName === "RowComparison",
        );
        const indexKey = `${index}`;
        return (
          <TableContainer key={indexKey}>
            <Table>
              <TableHead className="comparisionResultsHead">
                <ExpandableTableRow
                  autoScroll={autoScroll}
                  showData={<span>{t("View Results")}</span>}
                  expandComponent={
                    <ComparativeValidationResult
                      resultValidation={resultValidation}
                      RowComparisonResults={RowComparisonResults}
                      validationDetailsRowComparison={validationDetailsRowComparison}
                      item={item}
                      getTableData={getTableData}
                      loadingType={loadingType}
                    />
                  }>
                  <TableCell width={"20%"} align="center">
                    {t("Batch Number")} : {item?.batchNumber} of {item?.totalBatchCount}
                  </TableCell>
                  <TableCell align="center">
                    {t("Start Time")} :{formatDateWithTime(item?.startDateTime)}
                  </TableCell>
                  <TableCell align="center">
                    {t("Duration")} :{getTimeDuration(item?.startDateTime, item?.finishDateTime, t)}
                  </TableCell>
                </ExpandableTableRow>
              </TableHead>
            </Table>
          </TableContainer>
        );
      })}
      <ComparativeResultModel
        validateColumnName={validateColumnName}
        selectedDataType={selectedDataType}
        tableData={tableData}
        open={display && !loadingType?.type}
        handleClose={handleClose}
      />
    </Box>
  );
}
