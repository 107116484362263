import React, { useEffect, useState, useRef, useContext, useCallback } from "react";
import { Box, IconButton, Typography, Tabs, Tab, CircularProgress } from "@mui/material";
import Draggable from "react-draggable";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import RotateRightOutlinedIcon from "@mui/icons-material/RotateRightOutlined";
import ApiService from "../../../../services/app.service";
import AbortModal from "../ResultTile/AbortModal";
import Progress from "./Progress";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../../../App";

const POLL_INTERVAL = 20000;

const ExecutionsView = React.memo(({ fetchDataValidations }) => {
  const { setSnack } = useContext(SnackbarContext);

  const draggableRef = useRef(null);
  const intervalRef = useRef(null);

  const { t } = useTranslation();
  const [isExecutionsVisible, setIsExecutionsVisible] = useState(false);
  const [rows, setRows] = useState([]);
  const [abortModalData, setAbortModalData] = useState({});
  const [selectedTab, setSelectedTab] = useState(1);
  const [executionsLoading, setExecutionsLoading] = useState(false);
  const inProgressCount = rows?.filter((row) => row.status === 1 || row.status === 0)?.length;
  const filteredData = rows?.filter((row) => row.status === selectedTab);

  const fetchExecutions = async () => {
    setExecutionsLoading(true);
    try {
      const response = await ApiService.CurrentRuleExecutions();
      setRows(response.data);
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
    setExecutionsLoading(false);
  };

  const clearPolling = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  const handleToggleExecutions = () => {
    setIsExecutionsVisible((prev) => !prev);
    if (isExecutionsVisible) {
      clearPolling();
    }
  };

  const ruleExecuteRequestAbort = async () => {
    try {
      const reqBody = { executionId: abortModalData._id, isAborted: true };
      await ApiService.RuleExecuteRequestUpdate(reqBody);
      fetchExecutions();
      setAbortModalData({});
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    } finally {
      fetchDataValidations();
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    clearPolling();

    fetchExecutions();

    if (newValue === 0 || newValue === 1) {
      intervalRef.current = setInterval(() => {
        fetchExecutions();
      }, POLL_INTERVAL);
    }
  };

  useEffect(() => {
    if (isExecutionsVisible && inProgressCount) {
      fetchExecutions();
      intervalRef.current = setInterval(() => {
        fetchExecutions();
      }, POLL_INTERVAL);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isExecutionsVisible]);

  return (
    <>
      <Draggable handle="#draggable-dialog-title" nodeRef={draggableRef}>
        <Box
          ref={draggableRef}
          className={`exePop ${isExecutionsVisible ? "bg-color" : ""}`}
          id="draggable-dialog-title">
          <Box
            pl={2}
            className={`${isExecutionsVisible ? "" : "exeHeaderIcon"} ${
              inProgressCount > 0 && !isExecutionsVisible && "exeHeaderIconRotate"
            }`}>
            <Box className="space-between">
              {isExecutionsVisible && <Typography variant="h6">{t("Executions List")} : </Typography>}
              <IconButton onClick={handleToggleExecutions} color="primary">
                {isExecutionsVisible ? (
                  <CancelOutlinedIcon sx={{ width: 32, height: 32, color: "#c62828" }} />
                ) : (
                  <>
                    <RotateRightOutlinedIcon sx={{ width: 32, height: 32 }} />
                    {inProgressCount > 0 && <Box component="span">{inProgressCount}</Box>}
                  </>
                )}
              </IconButton>
            </Box>
            {isExecutionsVisible && (
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs variant="fullWidth" className="exepopTabs" value={selectedTab} onChange={handleTabChange}>
                  <Tab label={t("Todo")} value={0} />
                  <Tab label={t("In Progress")} value={1} />
                  <Tab label={t("Completed")} value={2} />
                  <Tab label={t("Aborted")} value={3} />
                  <Tab label={t("Suspended")} value={4} />
                </Tabs>
              </Box>
            )}
          </Box>
          {isExecutionsVisible &&
            (executionsLoading ? (
              <CircularProgress sx={{ mx: "50%", my: 2 }} size={20} />
            ) : (
              <Box px={2} py={1} className="exeListPop">
                {!filteredData?.length > 0 ? (
                  <Typography textAlign="center" variant="h6">
                    {t("No Data Available")}
                  </Typography>
                ) : (
                  filteredData?.map((row) => (
                    <Progress
                      key={row?._id}
                      totalbatches={row?.totalBatchCount}
                      completed={row?.completedBatches}
                      row={row}
                      setAbortModalData={setAbortModalData}
                      handleToggleExecutions={handleToggleExecutions}
                    />
                  ))
                )}
              </Box>
            ))}
        </Box>
      </Draggable>
      {Object.keys(abortModalData)?.length > 0 && (
        <AbortModal
          ruleExecuteRequestAbort={ruleExecuteRequestAbort}
          showModal={Object.keys(abortModalData)?.length}
          setAbortModalData={setAbortModalData}
        />
      )}
    </>
  );
});

ExecutionsView.displayName = "ExecutionsView";
export default ExecutionsView;
