import React, { useContext, useState } from "react";
import { Box, Divider, Typography, Pagination } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ApiService from "../../../services/app.service";
import { formatDateWithTime, totalExecutionsPerPage } from "../../../_helpers/utils";
import SkeletonLoader from "../../SkeletonLoader";
// import ResultDetailView from "./ResultDetailView";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../../App";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomBackButton from "../../CustomComponents/CustomsButtons/CustomBackButton";
import ResultDetailView from "../../Validations/Executions/ResultDetailView";
import ResultsList from "../../Validations/Executions/ResultsList";
import { RESULTS_PER_PAGE } from "../../../_helpers/Constant";

const ExecutionDetailItem = ({ title, value, divider = "" }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ fontWeight: "bold" }}>
      <Typography variant="detailTitle">
        {t(title)} :{" "}
        <Typography variant="detailValue" component="span">
          {value}
        </Typography>
      </Typography>

      {divider && (
        <Divider
          orientation="vertical"
          flexItem
          variant="middle"
          sx={{ borderRightWidth: 2, ml: 2, display: "inline" }}
        />
      )}
    </Box>
  );
};
const FileWatcherTestResults = ({
  resultsLoading,
  executions,
  setExecutions,
  totalexecutions,
  getResultsData,
  fetchDataValidations,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = JSON.parse(searchParams.get("currentPage"));
  const viewType = searchParams.get("viewType");
  const expanded = JSON.parse(searchParams.get("expanded"));

  const [resultValidation, setResultValidation] = useState([]);

  const [selectedExecution, setSelectedExecution] = useState({});

  const [batchesCurrentPage, setBatchesCurrentPage] = useState(1);

  const [executeResultsLoading, setExecuteResultsLoading] = useState(false);

  const getExecutionResults = async (executionId, currentPageNumber) => {
    try {
      setExecuteResultsLoading(true);
      const response = await ApiService.getTestExecutionsResults({
        executionId: executionId,
        pageSize: RESULTS_PER_PAGE,
        page: currentPageNumber,
      });
      setResultValidation(response?.data);
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    } finally {
      setExecuteResultsLoading(false);
    }
  };

  const onClickExecutionCard = (obj) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("expanded", true);
      return newState;
    });
    setSelectedExecution(obj);
    getExecutionResults(obj?._id, 1);
  };
  const executionResultsPageChange = (event, page) => {
    if (batchesCurrentPage !== page) {
      setBatchesCurrentPage(page);
      const executionId = selectedExecution?._id;
      getExecutionResults(executionId, page);
    }
  };
  const handlePageChange = (event, page) => {
    if (currentPage !== page) {
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("currentPage", page);
        return newState;
      });

      getResultsData(executions[0]?.testValidationId);
    }
  };

  const handleSortBy = (sortData) => {
    sortData &&
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("sortBy", sortData);
        return newState;
      });
    getResultsData(executions?.[0]?.testValidationId);
  };
  const handleOnSubmitDateRange = () => {
    getResultsData(executions?.[0]?.testValidationId);
  };
  const handleBack = () => {
    navigate(-2);
    setBatchesCurrentPage(1);
  };
  const renderDetailedView = () => (
    <React.Fragment>
      <Box className="v-center DQRuleExecutionDetails" gap={2}>
        <CustomBackButton onClick={handleBack}>{t("Back")}</CustomBackButton>
        <ExecutionDetailItem divider title="Executed By" value={selectedExecution?.ExecutedBy} />
        <ExecutionDetailItem divider title="Start Time" value={formatDateWithTime(selectedExecution?.startDateTime)} />
        <ExecutionDetailItem divider title="End Time" value={formatDateWithTime(selectedExecution?.finishDateTime)} />
        <ExecutionDetailItem divider title="Total Records" value={selectedExecution?.totalRecordsCount} />
        <ExecutionDetailItem divider title="Batches" value={selectedExecution?.totalBatchCount} />
        <ExecutionDetailItem
          // divider
          title="Checks"
          value={selectedExecution?.Validations?.length}
        />
      </Box>

      {executeResultsLoading && <SkeletonLoader />}
      {!executeResultsLoading && (
        <ResultDetailView
          selectedExecution={selectedExecution}
          resultValidation={resultValidation}
          batchesCurrentPage={batchesCurrentPage}
          executionResultsPageChange={executionResultsPageChange}
        />
      )}
    </React.Fragment>
  );

  const renderResultList = () => (
    <Box>
      {executions.length > 0 && (
        <Grid container className="DQRuleExecutionDetails">
          <Grid size={4}>
            <Typography variant="detailTitle">
              {t("DQ Rule Name")} :{" "}
              <Typography component="span" variant="detailValue">
                {executions[0]?.validationDetails?.TestName}
              </Typography>
            </Typography>
          </Grid>
          <Grid size={4}>
            <Typography variant="detailTitle">
              {t("Rule Type")} :{" "}
              <Typography component="span" variant="detailValue">
                {executions[0]?.validationDetails?.TestType}
              </Typography>
            </Typography>
          </Grid>
          <Grid size={4}>
            <Typography variant="detailTitle">
              {t("Number of Executions")} :{" "}
              <Typography component="span" variant="detailValue">
                {totalexecutions}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      )}
      <ResultsList
        resultsLoading={resultsLoading}
        executions={executions}
        setExecutions={setExecutions}
        handleSortBy={handleSortBy}
        onClickExecutionCard={onClickExecutionCard}
        handleOnSubmitDateRange={handleOnSubmitDateRange}
        fetchDataValidations={fetchDataValidations}
      />

      {viewType === "grid" && (
        <Pagination
          sx={{ my: 1.5, marginLeft: "auto", float: "right" }}
          count={Math.ceil(totalexecutions / totalExecutionsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
        />
      )}
    </Box>
  );

  return <Box className="DQRulesResultsContainer">{expanded ? renderDetailedView() : renderResultList()}</Box>;
};

export default FileWatcherTestResults;
