import React, { useState, useMemo, useCallback } from "react";
import {
  Box,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Typography,
  Tooltip,
  InputBase,
  ListItem,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setColumnsToValidate } from "../../../../Redux/reducers/rowComparisonSlice";
import { isAzure } from "../../../../_helpers/Constant";

const ColumnPopover = ({ anchorEl, isOpen, onClose, columns, defaultColumns, conType, isFirstSource }) => {
  const dispatch = useDispatch();
  const [, setSearchParams] = useSearchParams();
  const selectedColumnsKey = isFirstSource ? "source1" : "source2";
  const columnsToValidate = useSelector((state) => state.rowComparison.columnsToValidate);

  const [searchInput, setSearchInput] = useState("");
  const [selectedColumns, setSelectedColumns] = useState(defaultColumns || []);

  const handleClose = () => {
    if (selectedColumns.length) {
      const orderByColumn = selectedColumns[0]?.Column;
      dispatch(setColumnsToValidate({ ...columnsToValidate, [selectedColumnsKey]: selectedColumns }));
      setSelectedColumns([]);

      if (!defaultColumns.length && !isAzure(conType)) {
        setSearchParams((prev) => {
          const params = new URLSearchParams(prev);
          params.set(isFirstSource ? "firstSourceOrderBy" : "secondSourceOrderBy", orderByColumn);
          return params;
        });
      }
    }
    onClose();
  };

  const handleColumnToggle = useCallback((column) => {
    const updatedColumn = { Column: column.COLUMN_NAME, IsKey: false };
    setSelectedColumns((prev) => {
      const isSelected = prev.some((col) => col.Column === updatedColumn.Column);
      return isSelected ? prev.filter((col) => col.Column !== updatedColumn.Column) : [...prev, updatedColumn];
    });
  }, []);

  const sortedColumns = useMemo(() => {
    return [...columns].sort((a, b) => a.COLUMN_NAME.localeCompare(b.COLUMN_NAME));
  }, [columns]);

  const filteredColumns = useMemo(() => {
    const searchQuery = searchInput.toLowerCase();
    const defaultColumnNames = selectedColumns.map((col) => col?.Column);
    const defaultColumnsList = sortedColumns.filter((col) => defaultColumnNames.includes(col.COLUMN_NAME));
    const searchableColumns = sortedColumns.filter(
      (col) => col.COLUMN_NAME.toLowerCase().includes(searchQuery) && !defaultColumnNames.includes(col.COLUMN_NAME),
    );
    return [...defaultColumnsList, ...searchableColumns];
  }, [sortedColumns, searchInput]);

  const handleSearchChange = (event) => setSearchInput(event.target.value);

  const isDataTypeVisible = useMemo(() => columns.some((col) => col.DATA_TYPE), [columns]);

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{ vertical: "center", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      disableEnforceFocus
      disableAutoFocus>
      <Box sx={{ bgcolor: "background.paper", position: "relative" }}>
        <IconButton
          size="small"
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: -1,
            right: -1,
            backgroundColor: "#fff",
            padding: 0.5,
            zIndex: 3,
            "&:hover": { backgroundColor: "#fff" },
          }}>
          <CancelOutlinedIcon color="error" fontSize="small" />
        </IconButton>
        <TableContainer component={Paper} sx={{ maxHeight: "50vh", overflowY: "auto" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: 40 }} />
                <TableCell>
                  <Paper sx={{ px: 0.5 }}>
                    <InputBase
                      size="small"
                      placeholder="Search Column Name"
                      value={searchInput}
                      onChange={handleSearchChange}
                    />
                  </Paper>
                </TableCell>
                {isDataTypeVisible && <TableCell sx={{ minWidth: 100 }}>Data Type</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredColumns.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={isDataTypeVisible ? 3 : 2} align="center">
                    <Typography variant="body2" color="textSecondary">
                      No columns available
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                filteredColumns.map((column) => (
                  <TableRow key={column.COLUMN_NAME}>
                    <TableCell>
                      <Checkbox
                        checked={selectedColumns.some((col) => col.Column === column.COLUMN_NAME)}
                        onChange={() => handleColumnToggle(column)}
                        size="small"
                        sx={{ p: 0 }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{ maxWidth: 180, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                      {column.COLUMN_NAME.length > 8 ? (
                        <Tooltip title={column.COLUMN_NAME} arrow>
                          {column.COLUMN_NAME}
                        </Tooltip>
                      ) : (
                        column.COLUMN_NAME
                      )}
                    </TableCell>
                    {column?.DATA_TYPE && isDataTypeVisible && (
                      <TableCell
                        sx={{
                          minWidth: 100,
                          maxWidth: 120,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}>
                        {column.DATA_TYPE}
                      </TableCell>
                    )}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Popover>
  );
};

const SelectColumnsComparison = ({ isFirstSource, columns = [], defaultColumns = [], conType }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  const isPopoverOpen = Boolean(anchorEl);

  return (
    <Box>
      <ListItem
        className="h-center v-center"
        onClick={handlePopoverOpen}
        sx={{
          cursor: "pointer",
          color: "#FFA500",
          fontWeight: 500,
          "&:hover": { fontWeight: 600 },
          ...(defaultColumns?.length % 2 !== 0 && { backgroundColor: "#F4F1FF" }),
          ...(defaultColumns?.length === 0 && { borderBottom: "1px solid #D9D9D9" }),
          height: 42,
        }}
        role="listitem">
        <AddOutlinedIcon fontSize="small" /> Add Column
      </ListItem>
      {isPopoverOpen && (
        <ColumnPopover
          anchorEl={anchorEl}
          isOpen={isPopoverOpen}
          onClose={handlePopoverClose}
          columns={columns}
          defaultColumns={defaultColumns}
          conType={conType}
          isFirstSource={isFirstSource}
        />
      )}
    </Box>
  );
};

export default React.memo(SelectColumnsComparison);
