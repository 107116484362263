import axios from "axios";

// import { decryptData, encryptData } from "../helpers/encryptionUtils";
import {
  DQGEngineURLENV,
  ServerBaseURLENV,
  // KeyManagerURLENV,
  NotificationBaseURL,
} from "../_helpers/Constant";

const readBlobImage = async (imgData) => {
  try {
    const imageRes = await axios.get(`${ServerBaseURLENV}/visual-test/readImage/${imgData}`);
    return imageRes?.data;
  } catch (error) {
    console.error(new Error(error));
    return "";
  }
};
class ApiService {
  TestConnection(data) {
    return axios.post(`${ServerBaseURLENV}/connections/test-connection`, data);
  }
  ConnectionsList(data) {
    return axios.post(`${ServerBaseURLENV}/connections/`, data);
  }
  CheckConnectionUnique(data) {
    return axios.post(`${ServerBaseURLENV}/connections/check-by-cname`, data);
  }
  ConnectionCreate(data) {
    return axios.post(`${ServerBaseURLENV}/connections/create`, data);
  }
  ConnectionUpdate(data, id) {
    return axios.put(`${ServerBaseURLENV}/connections/${id}`, data);
  }
  GetSchemaList(data) {
    return axios.post(`${ServerBaseURLENV}/data-source/schema-list/`, data);
  }
  GetSchema_WarehouseList(data) {
    return axios.post(`${ServerBaseURLENV}/data-source/schema-whrehouseIds/`, data);
  }
  ConnectionDetails(id, data = "") {
    return axios.get(`${ServerBaseURLENV}/connections/tables-by-connection/${id}?data=${data}`, {});
  }
  ConnectionTablesColumns(id) {
    return axios.get(`${ServerBaseURLENV}/data-profiling/tables-columns/${id}`);
  }
  GetTablesColumns({ id, Table, tableNames }) {
    return axios.post(`${ServerBaseURLENV}/data-profiling/tables-columns/${id}`, { Table, tableNames });
  }
  GetDataProfilerTablesColumns(data) {
    return axios.post(`${ServerBaseURLENV}/data-profiling/columns`, data);
  }
  ConnectionDelete(data) {
    return axios.post(`${ServerBaseURLENV}/connections/delete`, data);
  }
  ConnectionDatabaseTables(data) {
    return axios.post(`${ServerBaseURLENV}/connections/get-data-by-table`, data);
  }
  getFilesList(data) {
    return axios.post(`${ServerBaseURLENV}/files`, data);
  }
  uploadFiles(data) {
    const formData = new FormData();
    Object.keys(data).forEach((key2) => {
      formData.append(key2, data[key2]);
    });
    return axios.post(`${ServerBaseURLENV}/files/create`, formData);
  }

  deleteFiles(data) {
    return axios.post(`${ServerBaseURLENV}/files/delete`, data);
  }
  GetFilesData(data) {
    return axios.post(`${ServerBaseURLENV}/files/get-file-data`, data);
  }
  ConnectionDetailsDataValidation(data) {
    return axios.post(`${ServerBaseURLENV}/connections/get-connection-details-datavalidation`, data);
  }

  getcomparisionViewTableData(data) {
    return axios.post(`${ServerBaseURLENV}/connections/table-preview`, data);
  }
  createValidation(data) {
    return axios.post(`${ServerBaseURLENV}/validations/create`, data);
  }
  checkValidation(data) {
    return axios.post(`${ServerBaseURLENV}/validations/validate`, data);
  }
  GetDataValidations(data) {
    return axios.post(`${ServerBaseURLENV}/validations/`, data);
  }
  GettestHubDataValidations(data) {
    return axios.post(`${ServerBaseURLENV}/validations/`, data);
  }
  GetDataValidationByID(id) {
    return axios.get(`${ServerBaseURLENV}/validations/${id}`);
  }
  ValidationTestDelete(data) {
    return axios.post(`${ServerBaseURLENV}/validations/delete`, data);
  }
  sqlPreviewData(data) {
    return axios.post(`${ServerBaseURLENV}/connections/raw-query-excution`, data);
  }
  ValidationResult(id) {
    return axios.get(`${ServerBaseURLENV}/validations/validation-result/${id}`);
  }
  ExcuteTestValidation(id) {
    return axios.post(`${ServerBaseURLENV}/validations/excute-test-validation/${id}`);
  }
  editValidation(id, { signal } = {}) {
    return axios.get(`${ServerBaseURLENV}/validations/${id}`, { signal });
  }
  exportRule(id) {
    return axios.get(`${ServerBaseURLENV}/validations/export/${id}`);
  }
  updateValidation(data, id) {
    return axios.post(`${ServerBaseURLENV}/validations/update/${id}`, data);
  }

  // scheduled test
  createScheduleRun(data) {
    return axios.post(`${ServerBaseURLENV}/schedulers/create`, data);
  }
  getScheduledList(data) {
    return axios.post(`${ServerBaseURLENV}/schedulers/`, data);
  }
  getValidationList() {
    return axios.post(`${ServerBaseURLENV}/validations/list/`);
  }
  deleteScheduledTest(data) {
    return axios.post(`${ServerBaseURLENV}/schedulers/delete`, data);
  }
  updateScheduledTestData(id, data) {
    return axios.put(`${ServerBaseURLENV}/schedulers/update/${id}`, data);
  }
  getDashboardData() {
    return axios.post(`${ServerBaseURLENV}/dashboard`, {});
  }
  getDashboardGraphData(data) {
    return axios.post(`${ServerBaseURLENV}/dashboard/graphData`, data);
  }
  checkScheduledTitle(data) {
    return axios.post(`${ServerBaseURLENV}/schedulers/check-unique-title`, data);
  }
  getUsersList(data) {
    return axios.post(`${ServerBaseURLENV}/users`, data);
  }
  addUser(data) {
    return axios.post(`${ServerBaseURLENV}/users/register`, data);
  }
  editUser(data, id) {
    return axios.post(`${ServerBaseURLENV}/users/update/${id}`, data);
  }
  createSmtp(data) {
    return axios.post(`${ServerBaseURLENV}/smtp/get-data/`, data);
  }
  getSmtp() {
    return axios.get(`${ServerBaseURLENV}/smtp/get-data/`);
  }
  deleteUser(data) {
    return axios.post(`${ServerBaseURLENV}/users/delete`, data);
  }
  createPileLine(data) {
    return axios.post(`${ServerBaseURLENV}/pipelines/create`, data);
  }
  checkPipeLineTitle(data) {
    return axios.post(`${ServerBaseURLENV}/pipelines/check-unique-title`, data);
  }
  getPipeLinesList(data) {
    return axios.post(`${ServerBaseURLENV}/pipelines/`, data);
  }
  deletePipeLine(data) {
    return axios.post(`${ServerBaseURLENV}/pipelines/delete`, data);
  }
  updatePipeLineData(id, data) {
    return axios.put(`/pipelines/update/${id}`, data);
  }
  getTestResultsWithIds(data) {
    return axios.post(`${ServerBaseURLENV}/validations/results-by-id`, data);
  }

  getTestExecutions(data) {
    return axios.post(`${ServerBaseURLENV}/validations/executions`, data);
  }

  getTestExecutionsResults(data) {
    return axios.post(`${ServerBaseURLENV}/validations/execution-results`, data);
  }

  getFailedRecords(data) {
    return axios.post(`${ServerBaseURLENV}/validations/failed-records`, data);
  }

  dataProfiling(data) {
    return axios.post(`${ServerBaseURLENV}/data-profiling`, data);
  }
  dataProfilingFile(data) {
    return axios.post(`${ServerBaseURLENV}/data-profiling/python`, data);
  }
  dataSources(data) {
    return axios.post(`${ServerBaseURLENV}/data-source/list`, data);
  }
  getDataSourceByType(data) {
    return axios.post(`${ServerBaseURLENV}/data-source/connections`, data);
  }
  imageComparision(data) {
    return axios.post(`${ServerBaseURLENV}/reports/image-comaprision`, data);
  }
  ChartDataAnalyzor(data) {
    const formData = new FormData();
    Object.keys(data).forEach((key2) => {
      formData.append(key2, data[key2]);
    });
    return axios.post(`${ServerBaseURLENV}/reports/chart-data-analyzor`, formData);
  }
  testHubDataSources() {
    return axios.post(`${ServerBaseURLENV}/connections/source`);
  }
  // testHubDataSources() {
  // return axios.post("/connections/source");
  // }

  async getVisualTestList(data) {
    const res = await axios.post(`${ServerBaseURLENV}/visual-test`, data);

    return { data: { ...res?.data } };
  }

  createVisualTest(data) {
    if (data) {
      data.IgnoredAreas = JSON.stringify(data.IgnoredAreas);
    }
    const formData = new FormData();
    Object.keys(data).forEach((key2) => {
      formData.append(key2, data[key2]);
    });
    return axios.post(`${ServerBaseURLENV}/visual-test/create`, formData);
  }
  editVisualTest(id, data) {
    if (data) {
      data.IgnoredAreas = JSON.stringify(data.IgnoredAreas);
    }
    const formData = new FormData();
    Object.keys(data).forEach((key2) => {
      formData.append(key2, data[key2]);
    });
    return axios.post(`${ServerBaseURLENV}/visual-test/update/${id}`, formData);
  }
  deleteVisualTest(data) {
    return axios.post(`${ServerBaseURLENV}/visual-test/delete`, data);
  }
  async executeTest(id) {
    const res = await axios.post(`${ServerBaseURLENV}/visual-test/execute/${id}`);

    const baseImageLocation = res?.data?.BaseImage?.split("/").pop();
    const screenshotImageLocation = res?.data?.ScreenshotImage?.split("/").pop();

    const [baseImage, screenshotImage] = await Promise.all([
      readBlobImage(baseImageLocation),
      readBlobImage(screenshotImageLocation),
    ]);

    return {
      ...res?.data,
      BaseImage: baseImage,
      ScreenshotImage: screenshotImage,
    };
  }

  executeMultipleTest(data) {
    return axios.post(`${ServerBaseURLENV}/rule-collection/executeMultiRuleByRuleIds`, data);
  }
  async viewVisualTestResults(id) {
    const res = await axios.get(`${ServerBaseURLENV}/visual-test/execution-results/${id}`);

    return res?.data;
  }
  getConnectionsByType(data) {
    return axios.post(`${ServerBaseURLENV}/connections/apis`, data);
  }
  dataCleaning(data) {
    return axios.post(`${ServerBaseURLENV}/data-cleaning`, data);
  }
  dataCleaningdtype(data) {
    return axios.post(`${ServerBaseURLENV}/data-cleaning/data-type`, data);
  }
  dataCleanindSavedata(data) {
    return axios.post(`${ServerBaseURLENV}/data-cleaning/save-to-target`, data);
  }
  MasterdataUpload(data) {
    return axios.post(`${ServerBaseURLENV}/master-data/create`, data);
  }
  Masterdatalist(data) {
    return axios.post(`${ServerBaseURLENV}/master-data`, data);
  }
  deleteMasterdata(data) {
    return axios.post(`${ServerBaseURLENV}/master-data/delete`, data);
  }
  getmasterdatabyId(data) {
    return axios.post(`${ServerBaseURLENV}/master-data/get-file-data`, data);
  }
  getApiAutomation() {
    return axios.get(`${ServerBaseURLENV}/api-automations/`);
  }
  getApiAutomationByid(id) {
    return axios.get(`${ServerBaseURLENV}/api-automations/${id}`);
  }
  postApiAutomation(data) {
    return axios.post(`${ServerBaseURLENV}/api-automations/`, data);
  }
  testApiAutomation(id) {
    return axios.get(`${ServerBaseURLENV}/api-automations/execute/${id}`);
  }
  DeleteApiAutomation(data) {
    return axios.post(`${ServerBaseURLENV}/api-automations/delete/`, data);
  }
  getApiCollection(id) {
    return axios.get(`${ServerBaseURLENV}/api-automations/${id}`);
  }
  SingleAutomationExection(data) {
    return axios.post(`${ServerBaseURLENV}/api-automations/single-api-execution`, data);
  }
  ApiExecutionResultsByid(id) {
    return axios.get(`${ServerBaseURLENV}/api-automations/collection-results/${id}`);
  }
  RuleExecuteRequestUpdate(data) {
    return axios.post(`${ServerBaseURLENV}/validations/ruleExecuteRequestUpdate`, data);
  }
  ValidationChecksByResultId(data) {
    return axios.post(`${ServerBaseURLENV}/validations/validationChecksByResultId`, data);
  }
  DownoladPDF(data) {
    return axios.post(`${ServerBaseURLENV}/rule/v1/generate-pdf`, data);
  }

  RetryExecution(data) {
    return axios.post(`${ServerBaseURLENV}/validations/retry-execution`, data);
  }

  CurrentRuleExecutions(data) {
    return axios.post(`${ServerBaseURLENV}/rule/v1/currentRuleExecutions`, data);
  }

  DQGServerHealthcheck() {
    return axios.get(`${ServerBaseURLENV}/healthcheck`);
  }
  DQGEngineHealthcheck() {
    // axios.defaults.headers = {};
    return axios.get(`${DQGEngineURLENV}/api/HealthCheck`);
  }
  RuleCollectionsList(data) {
    return axios.post(`${ServerBaseURLENV}/rule-collection/`, data);
  }
  CreateCollection(data) {
    return axios.post(`${ServerBaseURLENV}/rule-collection/create`, data);
  }
  UpdateCollection(CollectionId, data) {
    return axios.put(`${ServerBaseURLENV}/rule-collection/${CollectionId}`, data);
  }
  DeleteCollection(data) {
    return axios.post(`${ServerBaseURLENV}/rule-collection/delete`, data);
  }
  executeCollection(data) {
    return axios.post(`${ServerBaseURLENV}/rule-collection/executeCollectionById`, data);
  }
  getRulesList(data) {
    return axios.post(`${ServerBaseURLENV}/validations/rules-list/`, data);
  }
  DownloadCSV(data) {
    return axios.post(`${ServerBaseURLENV}/rule/v1/downloadResults/`, data, {
      responseType: "blob",
    });
  }

  downloadSampleRecords(data) {
    return axios.post(`${DQGEngineURLENV}/api/StorageService/DownloadReport`, data, {
      responseType: "blob",
    });
  }
  getDashboardMetrics(organisationId) {
    return axios.post(`${DQGEngineURLENV}/api/Metrics/ConsolidatedMetrics/`, {
      organisationId: organisationId,
    });
  }

  // data profile

  getDataProfileCon() {
    return axios.get(`${ServerBaseURLENV}/data-profiling/connections`);
  }

  getDataProfConInfo(conId) {
    return axios.get(`${ServerBaseURLENV}/data-profiling/connection/tables/${conId}`);
  }

  getDataProfilePrevTableData(data) {
    return axios.post(`${ServerBaseURLENV}/data-profiling/connection/table-columns/${data?.connectionId}`, {
      tableName: data.tableName,
    });
  }
  getTablesGraphData(data) {
    return axios.post(`${ServerBaseURLENV}/data-profiling/connection/tables-graph-data/${data?.connectionId}`, {
      endDate: data?.endDate,
      startDate: data?.startDate,
    });
  }

  getTableColumnsGraphData(data) {
    return axios.post(`${ServerBaseURLENV}/data-profiling/connection/table-columns-graph-data/${data?.connectionId}`, {
      tableName: data?.tableName,
      endDate: data?.endDate,
      startDate: data?.startDate,
    });
  }

  getInsightStatusByConnection() {
    return axios.post(`${ServerBaseURLENV}/data-profiling/insightStatusByConnection`);
  }
  insightUpdateByConnection(id) {
    return axios.get(`${ServerBaseURLENV}/data-profiling/insightUpdateByConnection/${id}`);
  }
  ChatBotData(data) {
    return axios.post(`${ServerBaseURLENV}/notifications/v1/copilot/query`, data);
  }
  getFoldersList(data) {
    return axios.post(`${ServerBaseURLENV}/file-watcher/folders-list`, data);
  }
  async getFileWatcherTestList(payload) {
    try {
      const response = await axios.post(`${ServerBaseURLENV}/file-watcher/list`, payload);
      const modifiedData = response.data?.data?.map((each) => ({
        ...each,
        ConnectionId: each?.ConnectionId || "localdrive",
        sourceConnectionId: each?.sourceConnectionId || "localdrive",
        ConnectionName: each?.ConnectionName || "NA",
        ConnectionType: each?.ConnectionName || "localdrive",
      }));
      response.data.data = modifiedData;
      return response;
    } catch (error) {
      return error;
    }
  }

  NotificationMarkAsRead(data) {
    return axios.put(`${NotificationBaseURL}/Notification`, data);
  }
  NotificationSubscriptionJson() {
    return axios.get(`${NotificationBaseURL}/Notification/SubscriptionStatus`);
  }
  NotificationSubscriptionUpdate(data) {
    return axios.post(`${NotificationBaseURL}/Notification/Toggle`, data);
  }
  async getNotifications(data) {
    const resust = await axios.post(`${NotificationBaseURL}/Notification/fetch`, {
      pageNumber: data?.pageNumber || 1,
      pageSize: data?.pageSize || 10,
    });
    return resust.data;
  }

  getDataProfileList(data) {
    return axios.post(`${ServerBaseURLENV}/data-profiler/list`, data);
  }
}

const APIServices = new ApiService();

export default APIServices;
