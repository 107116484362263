import { Box, Skeleton } from "@mui/material";

const CustomLoader = () => {
  return (
    <Box width={"100%"} p={2}>
      <Skeleton style={{ background: "#abcee6" }} />
      <Skeleton style={{ background: "#b9d6ea" }} />
      <Skeleton style={{ background: "#c7deee" }} />
    </Box>
  );
};

export default CustomLoader;
