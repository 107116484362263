import { useState } from "react";
import {
  IconButton,
  Tooltip,
  Box,
  CircularProgress,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import APIServices from "../../../../services/app.service";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const ViewTableDatabaseToFile = ({ columns, rows, viewTableDataLoading }) => {
  return (
    <Box sx={{ p: 1 }}>
      <TableContainer sx={{ maxWidth: 650, maxHeight: 300 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns?.map((column, index) => (
                <TableCell key={index}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row?.map((each, columnIndex) => (
                  <TableCell key={columnIndex}>
                    {typeof each === "object" ? <code>{JSON.stringify(each)}</code> : each}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {rows?.length === 0 && (
              <TableRow>
                <TableCell sx={{ textAlign: "center" }} colSpan={columns?.length}>
                  {viewTableDataLoading ? <CircularProgress color="primary" size={20} /> : "No records to display"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const PreviewTable = ({ connectionId, isFirstSource }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const tableKey = isFirstSource ? "selectedSource1Table" : "selectedSource2Table";
  const orderByKey = isFirstSource ? "firstSourceOrderBy" : "secondSourceOrderBy";
  const queryResultKey = isFirstSource ? "source1QueryResult" : "source2QueryResult";
  const sourceColumnsKey = isFirstSource ? "source1Columns" : "source2Columns";

  const orderBy = searchParams.get(orderByKey);
  const isCustomQuery = JSON.parse(searchParams.get("isCustomQuery"));
  const selectedTable = useSelector((state) => state.rowComparison[tableKey]);
  const queryResult = useSelector((state) => state.rowComparison[queryResultKey]);
  const sourceColumns = useSelector((state) => state.rowComparison[sourceColumnsKey]);

  // Component states
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [menuAnchor, setMenuAnchor] = useState(null);

  const isViewTableOpen = Boolean(menuAnchor);
  const isFile = sourceColumns?.result;
  const fetchTableData = async () => {
    if (!selectedTable) {
      return;
    }

    const { table_name: tableName, table_schema: schema } = selectedTable;

    try {
      setTableData([]);
      setIsTableDataLoading(true);
      const response = await APIServices.getcomparisionViewTableData({ connectionId, tableName, schema, orderBy });

      setTableData(response?.data?.rows || []);
    } catch (error) {
      console.error("Error fetching table data:", error);
      // TODO: Add snackbar or notification for error handling
    } finally {
      setIsTableDataLoading(false);
    }
  };

  const handleViewTableClick = async (event) => {
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);

    if (sourceColumns?.result) {
      setTableData(sourceColumns?.result?.rows);
      return;
    }
    if (isCustomQuery) {
      setTableData(queryResult?.result || []);
    } else {
      await fetchTableData();
    }
  };

  const onMouseLeave = (e) => {
    e.stopPropagation();
    setMenuAnchor(null);
  };
  let columns = [];
  if (isFile) {
    columns = tableData?.[0] || [];
  } else if (tableData?.length > 0) {
    columns = Object.keys(tableData[0]);
  }
  let rows = [];

  if (isFile) {
    rows = tableData.slice(1); // Remove the first item
  } else if (tableData?.length > 0 && columns) {
    rows = tableData.map((row) => columns.map((column) => row[column]));
  }

  return (
    <>
      <IconButton
        sx={{ padding: 0 }}
        color="primary"
        aria-describedby="viewTableMenu"
        disabled={!orderBy && !isCustomQuery && !sourceColumns?.result}
        onClick={handleViewTableClick}>
        <Tooltip title={t("View Table Data")} placement="top">
          <RemoveRedEyeOutlinedIcon size="small" />
        </Tooltip>
      </IconButton>

      {isViewTableOpen && (
        <Popover
          sx={{ "& .MuiBackdrop-root": { display: "none !important" }, maxWidth: "520px !important" }}
          classes={{ paper: "no-backdrop" }}
          id={"viewTableMenu"}
          anchorEl={menuAnchor}
          open={true}
          anchorOrigin={{ vertical: "center", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}>
          <IconButton
            onClick={onMouseLeave}
            color="error"
            aria-label="close popover"
            sx={{
              position: "absolute",
              right: -1,
              top: -1,
              background: "#fff",
              p: "2px",
              ":hover": { background: "#fff" },
            }}>
            <CancelOutlinedIcon />
          </IconButton>

          <ViewTableDatabaseToFile columns={columns} rows={rows} viewTableDataLoading={isTableDataLoading} />
        </Popover>
      )}
    </>
  );
};

export default PreviewTable;
