import { createSlice } from "@reduxjs/toolkit";
import { authServices } from "../services/auth.services";
import { userLocalSession } from "../../_helpers/Constant";
const userData = JSON.parse(localStorage.getItem(userLocalSession));
const initialState = {
  token: userData?.Token || null,
  user_details: userData || {},
  sessionExistsDetails: {},
  ADLoginLoading: false,
  loginLoading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserDetails(state, action) {
      state.token = action.payload?.Token || null;
      state.user_details = action.payload;
    },
    setSessionExistsDetails(state, action) {
      state.sessionExistsDetails = action.payload;
      state.ADLoginLoading = false;
    },
    setLoginLoading(state, action) {
      state.loginLoading = action.payload;
    },
    setADLoginLoading(state, action) {
      state.ADLoginLoading = action.payload;
    },
    setIsSessionExists(state, action) {
      state.ADLoginLoading = { ...state.sessionExistsDetails, isSessionExists: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authServices.authenticateUser.pending, (state) => {
        state.loginLoading = true;
      })
      .addCase(authServices.authenticateUser.fulfilled, (state, action) => {
        const userPayload = action.payload?.userDetails;
        const loginExists = action.payload?.response?.ResponseObject?.IsSessionExists;
        const loginRes = action.payload?.response?.ResponseObject;
        if (loginExists) {
          state.sessionExistsDetails = { isSessionExists: true, userPayload, loginRes };
          state.ADLoginLoading = false;
          state.loginLoading = false;
        } else if (loginRes?.Token) {
          localStorage.setItem(userLocalSession, JSON.stringify(loginRes));
          state.user_details = loginRes;
          state.token = loginRes?.Token;
          state.ADLoginLoading = false;
          state.loginLoading = false;
          state.sessionExistsDetails = {};
        }
      })
      .addCase(authServices.authenticateUser.rejected, (state) => {
        state.ADLoginLoading = false;
        state.loginLoading = false;
      });
  },
});

export const { setUserDetails, setSessionExistsDetails, setLoginLoading, setADLoginLoading, setIsSessionExists } =
  authSlice.actions;
export default authSlice.reducer;
