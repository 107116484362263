import { IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  AccessTime as AccessTimeIcon,
  Delete as DeleteIcon,
  NotStartedSharp as NotStartedSharpIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreateScheduledTest from "../../TestScheduler/CreateScheduledTest/CreateScheduledTest";
import { useCheckAccess } from "../../../CustomHooks/useCheckAccess";
import { SnackbarContext } from "../../../App";
import { profilingServices } from "../../../Redux/services/profiling.services";

const DataProfilingTableToolbar = ({
  selectedRowsIds,
  handleClickOpenDialog,
  fetchDataValidations,
  clearPreview,
  gridRef,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);
  const { checkAccess } = useCheckAccess();

  const { user_details } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [scheduleTestOpen, setScheduleTestOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const isAdmin = user_details?.RoleName === "Admin";

  const displayedRowsData = () => {
    if (gridRef?.current) {
      const displayedRowsData = [];

      gridRef.current.api.forEachNode((node) => {
        displayedRowsData.push(node.data);
      });
      setRows(displayedRowsData);
    }
  };

  useEffect(() => {
    displayedRowsData();
  }, [gridRef, selectedRowsIds]);

  const userCreatedRules = rows.filter((each) => each?.UserId !== user_details?.UserId);
  const scheduledOrCollectionRules = rows?.filter((each) => each?.isScheduled || each?.isInCollection);
  const isSelectedScheduled = scheduledOrCollectionRules.some((each) => selectedRowsIds.includes(each?._id));

  const isDisableDelete = userCreatedRules.some((each) => selectedRowsIds.includes(each?._id)) && !isAdmin;

  const updateRowSelectability = () => {
    if (!gridRef?.current) {
      return;
    }
    const selectedIdsSet = new Set(selectedRowsIds);
    gridRef?.current?.api?.forEachNode((node) => {
      const nodeId = node?.data?._id;
      if (selectedIdsSet.has(nodeId)) {
        node.setRowSelectable(false);
        node.data.isExecutionRunning = 1;
      }
    });
  };

  const handleMultipleExecution = async () => {
    try {
      updateRowSelectability();
      clearPreview();
      setLoading(true);
      gridRef?.current?.api?.deselectAll();
      setSnack({ message: "DQ Rule Execution Started", open: true, colour: "success" });
      const payload = { ruleIds: selectedRowsIds };
      await profilingServices.multiExecuteDataProfile(payload);
      setSnack({ message: "DQ Rule Execution Completed", open: true, colour: "success" });
      fetchDataValidations();
    } catch (error) {
      setSnack({ message: error?.response?.data?.message ?? error.message, open: true, colour: "error" });
    } finally {
      setLoading(false);
    }
  };

  const onSuccessCreateScheduled = () => {
    setScheduleTestOpen(false);
    navigate("/ScheduledTest/list");
  };

  let deleteTitle;

  if (isDisableDelete) {
    deleteTitle = "Can't delete this rule. Not created by you.";
  } else if (isSelectedScheduled) {
    deleteTitle = "Please deselect rules that are in collection or scheduled.";
  } else {
    deleteTitle = "Delete";
  }

  return (
    <Toolbar className="headerToolbar">
      <Typography sx={{ mr: 1 }}>
        {selectedRowsIds?.length} {t("Selected")}
      </Typography>
      {checkAccess("DataQualityRule", "Delete") && (
        <Tooltip arrow placement="top-end" title={deleteTitle}>
          <IconButton disabled={isDisableDelete || isSelectedScheduled} size="small" onClick={handleClickOpenDialog}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
      {checkAccess("ScheduledTest", "Create") && (
        <Tooltip
          sx={{ display: "none" }}
          placement="top-end"
          arrow
          title={t(isDisableDelete ? "Kindly deselect rules that you did not create." : "Schedule")}>
          <IconButton
            disabled={isDisableDelete}
            onClick={() => setScheduleTestOpen(true)}
            size="small"
            variant="outlined">
            <AccessTimeIcon />
          </IconButton>
        </Tooltip>
      )}
      {selectedRowsIds?.length <= 5 && (
        <Tooltip arrow placement="top-end" title={t("Execute Selected")} disabled={loading}>
          <IconButton size="small" onClick={handleMultipleExecution}>
            <NotStartedSharpIcon />
          </IconButton>
        </Tooltip>
      )}

      {scheduleTestOpen && (
        <CreateScheduledTest
          selectedRules={selectedRowsIds}
          setSchduleTestOpen={setScheduleTestOpen}
          onSuccessCreateScheduled={onSuccessCreateScheduled}
        />
      )}
    </Toolbar>
  );
};

export default DataProfilingTableToolbar;
