import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useContext, useState } from "react";
import ApiService from "../../services/app.service";
import CustomJsonTree from "../DataSources/FilesDataList/JsonFile/CustomJsonTree";
import { ModalPreview } from "./ModalPreview";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../App";

export function ModalFilePreview({ selectedOption }) {
  const { setSnack } = useContext(SnackbarContext);

  const { t } = useTranslation();
  const [filePreviewData, setfilePreviewData] = useState({});
  const [loader, Setloader] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = async () => {
    Setloader(true);
    try {
      const response = await ApiService.GetFilesData({
        id: selectedOption?.id,
        numberOfRows: 10,
      });
      if (response?.data?.result?.ext === "txt") {
        if (response?.data?.result?.rows?.[0]?.length > 20) {
          const abc = response.data.result.rows[0].slice(0, 20);

          response.data.result.rows[0] = abc;
        }
      }
      setfilePreviewData(response.data);
      setOpen(true);
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    } finally {
      Setloader(false);
    }
  };

  const List = () => (
    <Box
      component={Paper}
      sx={{
        maxWidth: "100%",
        maxHeight: "90vh",
        overflow: "auto",
      }}>
      <Box sx={{ p: "2px 16px", backgroundColor: "#f5f5f5" }} component={Paper}>
        <Grid container>
          <Grid size={12}>
            <Typography textAlign="center" variant="h6">
              {t("Preview")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {filePreviewData?.result?.ext === "json" || filePreviewData?.result?.ext === "xml" ? (
        <CustomJsonTree response={filePreviewData} />
      ) : (
        <React.Fragment>
          <Box
            className="bg-white&box-shadow"
            sx={{
              p: 1.5,
              mb: 2,
              boxShadow: "0px 3px 6px #00000029  !important",
              borderTopLeftRadius: "0px",
            }}>
            <Box
              sx={{
                gap: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}>
              <Box display={"flex"}>
                {t("Data Source Name")} :
                <Typography color={"primary"} variant="bold">
                  &nbsp;{filePreviewData?.fileDetails?.connectionName}
                </Typography>
              </Box>

              <Box display={"flex"}>
                {t("Name")} :
                <Typography color={"primary"} variant="bold">
                  &nbsp;{filePreviewData?.fileDetails?.fileName}
                </Typography>
              </Box>

              <Box display={"flex"}>
                {t("Size")} :
                <Typography color={"primary"} variant="bold">
                  &nbsp;
                  {(filePreviewData?.fileDetails?.size * 0.001).toFixed(1)}KB
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            component={Paper}
            className="bg-white&box-shadow"
            sx={{
              px: 0.5,
              my: 3,
              boxShadow: "0px 3px 6px #00000029  !important",
            }}>
            <TableContainer>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {filePreviewData?.fileDetails.firstRowisHeader &&
                      filePreviewData?.result?.rows[0]?.length > 0 &&
                      filePreviewData?.result?.rows[0].map((obj, i) => <TableCell key={i}>{obj}</TableCell>)}

                    {!filePreviewData?.fileDetails.firstRowisHeader &&
                      filePreviewData?.result?.rows[0].map((obj, index) => (
                        <TableCell key={index}>Column{index + 1}</TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody sx={{ "& tr": { verticalAlign: "top" } }}>
                  {filePreviewData?.fileDetails?.firstRowisHeader &&
                    filePreviewData?.result?.rows?.map((obj, index) => {
                      return (
                        <TableRow key={index}>
                          {index > 0 &&
                            obj.map((data, i) => {
                              return (
                                <TableCell key={i} scope="row">
                                  {data}
                                </TableCell>
                              );
                            })}
                        </TableRow>
                      );
                    })}

                  {!filePreviewData?.fileDetails.firstRowisHeader &&
                    filePreviewData?.result?.rows.map((obj, index) => {
                      return (
                        <TableRow key={index}>
                          {obj.map((data, ind) => (
                            <TableCell scope="row" key={ind}>
                              {data}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ mt: 2 }}>
              <Grid container>
                <Grid size={4} textAlign="center">
                  <Typography sx={{ flex: "1 1 100%", mb: 1 }} id="tableTitle" component="div">
                    <Typography component={"span"} color="primary">
                      {t("Displayed Rows")} :
                    </Typography>
                    &nbsp;{filePreviewData?.result?.rows?.length - 1}
                  </Typography>
                </Grid>
                <Grid size={4} textAlign="center">
                  <Typography sx={{ flex: "1 1 100%", mb: 1 }} id="tableTitle" component="div">
                    <Typography component={"span"} color="primary">
                      {t("Total Rows")} :
                    </Typography>
                    &nbsp;{filePreviewData?.result?.totalRows}
                  </Typography>
                </Grid>
                <Grid size={4} textAlign="center">
                  <Typography sx={{ flex: "1 1 100%", mb: 1 }} id="tableTitle" component="div">
                    <Typography component={"span"} color="primary">
                      {t("Total Columns")} :
                    </Typography>
                    &nbsp;{filePreviewData?.result?.totalColumns}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );

  return (
    <ModalPreview
      label={t("Preview")}
      loader={loader}
      disable={!selectedOption || !Object.keys(selectedOption)?.length}
      Component={<List />}
      open={open}
      handleClose={handleClose}
      handleOpen={handleOpen}
    />
  );
}
