import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Modal,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import * as XLSX from "xlsx";
import { StyledTableCell, StyledTableRow } from "../../Validations/Validationstyle";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  borderTop: "10px solid #2b81d6",
  boxShadow: 24,
  px: 2,
  pb: 2,
  borderRadius: "4px",
  maxHeight: "97vh",
};

const FilePreview = ({ file, model, returnValue }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(model);
  const [rows, setRows] = useState([]);

  const handleClose = () => {
    setOpen(false);
    returnValue(false);
  };

  useEffect(() => {
    setOpen(model);
  }, [model]);

  useEffect(() => {
    const handleFileRead = (e) => {
      const data = e.target.result;
      const readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        defval: null,
      });
      setRows(dataParse);
    };

    const reader = new FileReader();
    reader.onload = handleFileRead;
    reader.readAsBinaryString(file);

    return () => {
      reader.removeEventListener("load", handleFileRead);
    };
  }, [file]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Card>
        <Box sx={style}>
          <Box sx={{ p: "2px 16px", backgroundColor: "#e5f6fd" }}>
            <Grid container>
              <Grid size={12}>
                <Typography textAlign="center" variant="h6">
                  {t("Preview")}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              maxHeight: "70vh",
              overflow: "auto",
              pb: 1,
              width: "100%",
              my: "10px",
            }}>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {rows[0]?.length > 0 && rows[0]?.map((obj, i) => <StyledTableCell key={i}>{obj}</StyledTableCell>)}
                  </TableRow>
                </TableHead>
                <TableBody sx={{ "& tr": { verticalAlign: "top" } }}>
                  {rows.map((obj, index) => {
                    return (
                      <StyledTableRow key={index}>
                        {index > 0 &&
                          obj.map((data, i) => (
                            <StyledTableCell key={i} component="th" scope="row">
                              {data}
                            </StyledTableCell>
                          ))}
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ my: "10px" }}>
              <Grid container>
                <Grid size={6} textAlign="right">
                  <Typography sx={{ flex: "1 1 100%", mb: 1 }} id="tableTitle" component="div">
                    <b>{t("Total Rows")}: </b> {rows.length - 1}
                  </Typography>
                </Grid>
                <Grid size={6} textAlign="right">
                  <Typography sx={{ flex: "1 1 100%", mb: 1 }} id="tableTitle" component="div">
                    <b> {t("Total Columns")} : </b> {rows[0]?.length - 1}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box textAlign="center" mt="16px">
            <Button onClick={handleClose} variant="outlined" size="small">
              {t("OK")}
            </Button>
          </Box>
        </Box>
      </Card>
    </Modal>
  );
};

export default FilePreview;
