import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";

export default function MongoDB({ register, errors }) {
  return (
    <>
      <Grid size={12}>
        <TextField
          {...register("connectionstring", { required: true })}
          label="Connection String"
          fullWidth
          error={Boolean(errors.connectionstring)}
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid size={12}>
        <TextField
          {...register("database", { required: true })}
          label="Database"
          fullWidth
          error={Boolean(errors.database)}
          variant="outlined"
          size="small"
        />
      </Grid>
    </>
  );
}
