export const Messages = [
  {
    ExpiryType: "NoLicense",
    Header: "No License",
    Message: "You don't have a License, Please contact Kairos technologies for License",
  },
  {
    ExpiryType: "LicenseExpired",
    Header: "License Expired",
    Message: "Your License has expired, Please contact Kairos technologies for License subscription / Renewal",
  },
  {
    ExpiryType: "VolumeLimitExceeded",
    Header: "Volume Limit Exceeded",
    Message: "Your License cannot support this volume of data, Please contact Kairos technologies for License upgrade",
  },
];

export const interpolationTechniques = [
  {
    label: "Linear Interpolation",
    value: "Linear",
  },
  {
    label: "Polynomial Interpolation",
    value: "Polynomial",
  },
  { label: "Radial Basis", value: "Radial Basis" },
  { label: "Cubic Spline", value: "Cubic Spline" },
  { label: "Default", value: "Default" },
];

export const connectionTypes = [
  "SQL",
  "Oracle",
  "My SQL",
  "PostgreSQL",
  "Snowflake",
  "Azure SQL",
  "Databricks",
  "Azure Blob",
];

export const AuthenticationTypes = [
  {
    value: "SQL Server Authentication",
    label: "Database Native",
    types: ["My SQL", "SQL", "PostgreSQL", "Snowflake", "SAP HANA"],
  },

  {
    value: "Active Directory - Password",
    label: "Active Directory - Password",
    types: ["Azure SQL"],
  },
  // {
  //   value: "Active Directory - Integrated",
  //   label: "Active Directory - Integrated",
  // },
];

export const ScheduleFrequency = {
  1: "Minute(s)",
  2: "Day(s)",
  3: "Week(s)",
  4: "Month(s)",
};

export const timeAgo = (createdAt) => {
  const now = new Date();
  const createdDate = new Date(`${createdAt}Z`);

  const diffInSeconds = Math.floor((now - createdDate) / 1000);

  const secondsInMinute = 60;
  const secondsInHour = 3600;
  const secondsInDay = 86400;
  const secondsInWeek = 604800;
  const secondsInMonth = 2592000;
  const secondsInYear = 31536000;

  if (diffInSeconds < secondsInMinute) {
    return `${diffInSeconds} sec${diffInSeconds !== 1 ? "s" : ""} ago`;
  }
  if (diffInSeconds < secondsInHour) {
    const minutes = Math.floor(diffInSeconds / secondsInMinute);
    return `${minutes} min${minutes !== 1 ? "s" : ""} ago`;
  }
  if (diffInSeconds < secondsInDay) {
    const hours = Math.floor(diffInSeconds / secondsInHour);
    return `${hours} hr${hours !== 1 ? "s" : ""} ago`;
  }
  if (diffInSeconds < secondsInWeek) {
    const days = Math.floor(diffInSeconds / secondsInDay);
    return `${days} day${days !== 1 ? "s" : ""} ago`;
  }

  if (diffInSeconds < secondsInMonth) {
    const weeks = Math.floor(diffInSeconds / secondsInWeek);
    return `${weeks} week${weeks !== 1 ? "s" : ""} ago`;
  }
  if (diffInSeconds < secondsInYear) {
    const months = Math.floor(diffInSeconds / secondsInMonth);
    return `${months} month${months !== 1 ? "s" : ""} ago`;
  }

  const years = Math.floor(diffInSeconds / secondsInYear);
  return `${years} year${years !== 1 ? "s" : ""} ago`;
};

export const LoginRedirectUrl = window._env_?.REACT_APP_LOGIN_REDIRECT_URL;

export const ServerBaseURLENV = window._env_?.REACT_APP_SERVER_BASE_URL;

export const LicenseManagementBaseURLENV = window._env_?.REACT_APP_LICENSE_MANAGEMENT_BASE_URL_ENV;

export const AuthBaseURLENV = window._env_?.REACT_APP_AUTH_BASE_URL_ENV;

export const DQGEngineURLENV = window._env_?.REACT_APP_DQG_ENGINE_URL_ENV;

export const KeyManagerURLENV = window._env_?.REACT_APP_KEY_MANAGER_URL_ENV;
export const NotificationBaseURL = window._env_?.REACT_APP_NOTIFICATION_URL_ENV;

export const ChatBotAPI = `${DQGEngineURLENV}/api/Copilot/AskQuery`;
export const ProductCode = window._env_?.PRODUCT_CODE_ENV || "DQG";
export const sessionTimeout = window._env_?.REACT_APP_SESSION_TIMEOUT;

export const azureLogin = window._env_?.REACT_APP_AZURE_LOGIN;
export const azureAppInsights = false;
export const schemaLimit = window._env_?.REACT_APP_SCHEMA_LIMIT;
export const isDuplicates = window._env_?.REACT_APP_IS_DUPLICATE;
export const activeClient = window._env_?.REACT_APP_CLIENT;

export const lastActivity = `${ProductCode}LastActivity`;
export const userLocalSession = `${ProductCode}UserData`;

export const isLocalDrive = false;

export const allowDownloadPdf = false;

export const maxSizeInMB = Math.min(Number(window._env_?.REACT_APP_MAX_FILE_FILE_UPLOAD) || 200, 200);

export const getComponentName = (url) => {
  const message = "";
  if (url.includes("DQG-Server")) {
    return `DQG-Server ${message}`;
  } else if (url.includes("data-profiler")) {
    return `Data-Profile ${message}`;
  } else if (url.includes("DQG-Engine")) {
    return `DQG-Engine ${message}`;
  }
  return `Server ${message}`;
};
export const isAzure = (conType) => ["AWS S3", "Azure Blob"].includes(conType || "");

export const RESULTS_PER_PAGE = 10;

export const DEFAULT_TOOLTIPS = {
  Email: ["^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"],
  Date: ["dd/MM/yyyy", "dd-MM-yyyy", "yyyy-MM-dd", "yyyy/MM/dd", "dd-MMM-yyyy", "MM-dd-yyyy", "ddMMyyyy", "yyyyMMdd"],
  DateTime: [
    "yyyy-MM-dd HH:mm:ss.fff",
    "yyyy-MM-dd HH:mm:ss",
    "yyyy-MM-dd HH:mm:ss zzz",
    "ddd, dd MMM yyyy HH:mm:ss",
    "dddd, dd MMMM yyyy HH:mm:ss",
    "MMMM dd, yyyy hh:mm tt",
    "yyyyMMddHHmmss",
    "yyyy-MM-ddTHH:mm:ssK",
  ],
};
