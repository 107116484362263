import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";

const AwsS3 = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid size={12}>
        <TextField
          fullWidth
          size="small"
          name="Description"
          label={t("Description")}
          minRows={2}
          maxRows={4}
          multiline
          {...register("Description")}
        />
      </Grid>
      <Grid size={12}>
        <TextField
          fullWidth
          {...register("accessKeyId", { required: true })}
          label={t("Access Key ID")}
          error={Boolean(errors.accessKeyId)}
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid size={12}>
        <TextField
          fullWidth
          {...register("secretAccessKey", { required: true })}
          label={t("Secret Access Key")}
          error={Boolean(errors.secretAccessKey)}
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid size={12}>
        <TextField
          fullWidth
          {...register("regionName", {
            required: true,
            minLength: {
              value: 4,
              message: t("Minimum length 4 char"),
            },
          })}
          error={Boolean(errors.regionName)}
          helperText={errors?.regionName?.message || null}
          label={t("Region Name")}
          variant="outlined"
          required
          size="small"
          onChange={(e) => (e.target.value = e.target.value.replace(/\s{2,}/g, " "))}
        />
      </Grid>

      <Grid size={12}>
        <TextField
          fullWidth
          {...register("bucketName", {
            required: true,
            minLength: {
              value: 4,
              message: t("Minimum length 4 char"),
            },
          })}
          error={Boolean(errors.bucketName)}
          helperText={errors?.bucketName?.message || null}
          label={t("Bucket Name")}
          variant="outlined"
          required
          size="small"
          onChange={(e) => (e.target.value = e.target.value.replace(/\s{2,}/g, " "))}
        />
      </Grid>
    </>
  );
};

export default AwsS3;
