import { useSearchParams } from "react-router-dom";
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useTranslation } from "react-i18next";
import { isAzure } from "../../../../_helpers/Constant";
import PreviewTable from "./PreviewTable";
import OrderbyDropdown from "../OrderbyDropdown";
import SelectColumnsComparison from "./SelectColumnsComparison";
import { useDispatch, useSelector } from "react-redux";
import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { DndContext } from "@dnd-kit/core";
import { setColumnsToValidate } from "../../../../Redux/reducers/rowComparisonSlice";
import { CustomDeleteIcon } from "../../../CustomComponents/IconButtons";

function arrayMove(arr, fromIndex, toIndex) {
  const result = [...arr];
  const [movedItem] = result.splice(fromIndex, 1);
  result.splice(toIndex, 0, movedItem);
  return result;
}

const SortableItem = ({ col, handleDeleteColumn }) => {
  const id = col.Column;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const handlePointerDown = (e) => {
    if (e.target.closest(".delete-icon")) {
      e.stopPropagation();
    } else {
      if (listeners.onPointerDown) {
        listeners.onPointerDown(e);
      }
    }
  };

  return (
    <ListItem
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      role="listitem"
      sx={{
        height: 42,
        "&:nth-of-type(even)": { backgroundColor: "#F4F1FF" },
        "&:last-child": { borderBottom: "none" },
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : "none",
        transition: transition || "none",
        cursor: "pointer",
        px: 0.5,
        "&:hover .delete-icon": { opacity: 1 },
      }}
      onPointerDown={handlePointerDown}
      secondaryAction={
        <CustomDeleteIcon
          onClick={() => handleDeleteColumn(col.Column)}
          className="delete-icon"
          sx={{ opacity: 0, transition: "opacity 0.2s" }}
        />
      }>
      {/* {isFirstSource && <Checkbox sx={{ p: 0 }} size="small" />} */}
      <ListItemIcon sx={{ minWidth: "auto", mx: 0.5 }}>
        <DragIndicatorIcon color="primary" fontSize="small" />
      </ListItemIcon>
      <ListItemText primary={col.Column} />
    </ListItem>
  );
};

const ComparisonSource = ({ connectionDetails, isFirstSource, columnsData, queryResult }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const isCustomQuery = JSON.parse(searchParams.get("isCustomQuery") || "false");
  const dataSourceType = searchParams.get(isFirstSource ? "firstDataSourceType" : "secondDataSourceType");
  const selectedColumnsKey = isFirstSource ? "source1" : "source2";
  const selectedTableKey = isFirstSource ? "selectedSource1Table" : "selectedSource2Table";
  const columnsToValidate = useSelector((state) => state.rowComparison.columnsToValidate);
  const selectedTable = useSelector((state) => state.rowComparison[selectedTableKey]);

  const defaultColumns = columnsToValidate?.[selectedColumnsKey] || [];
  const columnDetails = columnsData && Object.values(columnsData?.[0] || {})?.[0];
  const conType = connectionDetails?.connectionType;
  const type =
    connectionDetails?.connectionName && dataSourceType === "database" && !isAzure(conType) ? " [Database]" : " [File]";
  const cardHeader = isFirstSource ? t("1st Data Source") : t("2nd Data Source");
  let columns = [];
  const mapRowKeysToColumns = (result, useHeaderAsNames) => {
    if (!result) {
      return [];
    }
    return useHeaderAsNames
      ? result?.columnsDatatypes?.map(({ columnName, datatype }) => ({
          COLUMN_NAME: columnName,
          DATA_TYPE: datatype,
        })) || []
      : Object.keys(result?.rows[0] || {}).map((_, index) => ({ COLUMN_NAME: `Column${index + 1}` }));
  };

  if (columnDetails) {
    columns = columnDetails.Columns || [];
  } else if (isCustomQuery) {
    columns =
      (queryResult?.result?.[0] && Object.keys(queryResult.result[0]).map((key) => ({ COLUMN_NAME: key }))) || [];
  } else if (connectionDetails?.fileName && !isAzure(connectionDetails?.connectionType)) {
    columns = mapRowKeysToColumns(columnsData?.result, columnsData?.fileDetails?.firstRowisHeader);
  }

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      const oldIndex = defaultColumns.findIndex((col) => col.Column === active?.id);
      const newIndex = defaultColumns.findIndex((col) => col.Column === over?.id);

      if (oldIndex !== -1 && newIndex !== -1) {
        const sortedColumns = arrayMove(defaultColumns, oldIndex, newIndex);

        dispatch(setColumnsToValidate({ ...columnsToValidate, [selectedColumnsKey]: sortedColumns }));
      }
    }
  };

  const handleDeleteColumn = (column) => {
    const filteredColumns = columnsToValidate[selectedColumnsKey]?.filter((each) => each?.Column !== column);
    dispatch(setColumnsToValidate({ ...columnsToValidate, [selectedColumnsKey]: filteredColumns }));
  };

  return (
    <Grid size={6} sx={{ borderRight: isFirstSource ? "1px solid #D9D9D9" : "none" }}>
      <Box className="space-between" sx={{ backgroundColor: "#e5f6fd", p: 1, height: 46 }}>
        <Typography variant="h6" sx={{ color: "#1976d2" }}>
          {cardHeader} {type}
        </Typography>
        <Box className="v-center" gap={1} sx={{ color: "#365E7D" }}>
          {(columnDetails?.rowsCount || columnsData?.result?.totalRows) && (
            <Typography sx={{ fontSize: "12px !important" }}>
              Rows : <strong>{columnDetails?.rowsCount || columnsData?.result?.totalRows}</strong>
            </Typography>
          )}
          {(columnDetails?.columnCount || columnsData?.result?.totalColumns) && (
            <Typography sx={{ fontSize: "12px !important" }}>
              Columns : <strong>{columnDetails?.columnCount || columnsData?.result?.totalColumns}</strong>
            </Typography>
          )}
          {dataSourceType === "database" && !isCustomQuery && !isAzure(conType) && (
            <OrderbyDropdown isFirstSource={isFirstSource} />
          )}
          <PreviewTable isFirstSource={isFirstSource} connectionId={connectionDetails?.id} />
        </Box>
      </Box>

      <Box className="v-center" sx={{ background: "#7A86A1", color: "#fff", px: 1.5, height: 40 }}>
        {/* {isFirstSource && <Checkbox sx={{ pl: 0 }} size="small" />} */}
        <Typography variant="bold">
          {!connectionDetails?.fileName && !isAzure(connectionDetails?.connectionType)
            ? t("Columns")
            : t("File Columns")}
        </Typography>

        {(selectedTable?.table_name || connectionDetails?.fileName) && (
          <Typography sx={{ ml: "auto" }}>
            {dataSourceType === "database" ? "Table" : "File"} :{" "}
            <strong>{selectedTable?.table_name || connectionDetails?.fileName || ""}</strong>
          </Typography>
        )}
      </Box>
      <List role="list" sx={{ p: 0, minHeight: 340 }}>
        <DndContext onDragEnd={handleDragEnd}>
          <SortableContext items={defaultColumns.map((col) => col.Column)} strategy={verticalListSortingStrategy}>
            {defaultColumns.map((col) => (
              <SortableItem
                key={col.Column}
                col={col}
                isFirstSource={isFirstSource}
                handleDeleteColumn={handleDeleteColumn}
              />
            ))}
          </SortableContext>
        </DndContext>
        <SelectColumnsComparison
          isFirstSource={isFirstSource}
          columns={columns}
          dataSourceType={dataSourceType}
          conType={conType}
          defaultColumns={defaultColumns}
        />
      </List>
    </Grid>
  );
};

export default ComparisonSource;
