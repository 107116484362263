import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, IconButton, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ClearIcon from "@mui/icons-material/Clear";

const ReplaceColumnValueInputs = ({ inputParams, columns, setInputParams, setInpreplace }) => {
  const { t } = useTranslation();

  const handleFormChange = (index, event) => {
    const data = [...inputParams];
    const str = event.target.value.trimStart();
    data[index][event.target.name] = str;
    setInputParams(data);
  };

  const removeFields = (index) => {
    const data = [...inputParams];
    data.splice(index, 1);
    setInputParams(data.length ? data : []);
  };

  useEffect(() => {
    let count = 0;
    if (inputParams?.length !== 0) {
      inputParams?.forEach((inp) => {
        if (inp.ColumnName.length !== 0 && inp.ExistingValue.length !== 0 && inp.ReplacingValue.length !== 0) {
          count++;
        }
      });
      setInpreplace(count !== inputParams.length);
    } else {
      setInpreplace(false);
    }
  }, [inputParams]);

  return (
    <Grid container>
      <Grid container spacing={2}>
        {inputParams?.map((input, index) => (
          <Grid container columnGap={1} key={index}>
            <Grid size="grow">
              <TextField
                size="small"
                name="ColumnName"
                fullWidth
                select
                label={t("Select Column")}
                required
                value={input?.ColumnName}
                onChange={(event) => handleFormChange(index, event)}>
                {columns?.map((opr, ind) => (
                  <MenuItem value={opr} key={ind}>
                    {opr}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid size="grow">
              <TextField
                fullWidth
                size="small"
                name="ExistingValue"
                label={t("Existing Value")}
                required
                value={input.ExistingValue}
                onChange={(event) => handleFormChange(index, event)}
              />
            </Grid>
            <Grid size="grow">
              <TextField
                fullWidth
                size="small"
                name="ReplacingValue"
                label={t("Replacing Value")}
                required
                value={input.ReplacingValue}
                onChange={(event) => handleFormChange(index, event)}
              />
            </Grid>
            {Object.keys(inputParams).length > 1 && (
              <Grid size={1}>
                <Box sx={{ p: "0 !important" }}>
                  <Typography color="inherit" variant="subtitle1" component="div">
                    <Tooltip title="Delete">
                      <IconButton size="medium" onClick={() => removeFields(index)}>
                        <ClearIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ReplaceColumnValueInputs;
