/* eslint-disable no-nested-ternary */
import { useEffect, useState, useMemo } from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomRadioGroup from "../../CustomComponents/CustomRadioGroup";
// import { DropDownData } from "../DropDown";
import DataProfileSelectConnection from "./DataProfileSelectConnection";
import DataProfileSelectTables from "./DataProfileSelectTables";
import ReportTypeOptions from "../ReportTypeOptions";
import DataProfileSelectColumns from "./DataProfileSelectColumns";
import { profilingServices } from "../../../Redux/services/profiling.services";
import InnerHeader from "../../Layout/InnerHeader";
import CustomLoadingButton from "../../CustomComponents/CustomsButtons/CustomLoadingButton";
import CustomLoader from "../../CustomComponents/CustomLoader";
import { showToast } from "../../../Redux/reducers/toastSlice";
import { useDispatch } from "react-redux";
import APIServices from "../../../services/app.service";

const ROW_OPTIONS = [50000, 100000, 200000];

const CONNECTION_TYPES = [
  { value: "database", label: "Database" },
  { value: "file", label: "File" },
];

const defaultValues = { dataSourceType: "database", reportType: "DQG", numberOfRows: 50000 };

const CreateDataProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams(defaultValues);
  const connectionType = searchParams.get("connectionType");
  const dataSourceType = searchParams.get("dataSourceType");
  const reportType = searchParams.get("reportType") || "DQG";
  const ruleId = searchParams.get("ruleId");
  const orderBy = searchParams.get("orderBy");
  const sortBy = searchParams.get("sortBy");
  const tableName = searchParams.get("tableName");
  const numberOfRows = Number.parseInt(searchParams.get("numberOfRows")) || 50000;
  const connectionId = searchParams.get("connectionId");
  const isFileConnection = dataSourceType === "file";
  const [databaseOptions, setDatabaseOptions] = useState(null);
  const [fileOptions, setFileOptions] = useState(null);
  const [connectionsLoading, setConnectionsLoading] = useState(false);
  const [tableList, setTableList] = useState(null);
  const [tablesLoading, setTablesLoading] = useState(false);
  const [tableDetails, setTableDetails] = useState(null);

  const [columns, setColumns] = useState(null);
  const [columnsLoading, setColumnsLoading] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [min] = useState(0);
  const [max] = useState(0);
  const [columnError, setColumnError] = useState(false);
  const [isSavingProfiling, setIsSavingProfiling] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [testName, setTestName] = useState("");
  const [testNameError, setTestNameError] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  const connections = isFileConnection ? fileOptions : databaseOptions;
  const selectedConnection = connections?.find((each) => each?.id === connectionId || each?._id === connectionId);

  const filteredColumns = useMemo(
    () => columns?.filter(Boolean)?.sort((a, b) => (a?.label ? a?.label?.localeCompare(b?.label) : a.localeCompare(b))),
    [columns],
  );

  const isColumnsRequired = ["Movablebarplot", "Barplot", "scatterplot", "boxplot", "Piechart", "Histogram"].includes(
    reportType,
  );

  const gridSize = isFileConnection && !isColumnsRequired ? 12 : isColumnsRequired && !isFileConnection ? 4 : 6;
  const selectedTable = tableDetails || tableList?.find((each) => each?.table_name === tableName);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const fetchEditData = async () => {
      try {
        setEditLoading(true);
        const response = await APIServices.editValidation(ruleId, { signal });
        isFileConnection
          ? await fetchFileColumns(connectionId)
          : await fetchTables(connectionId, response?.data?.TableName);
        response?.data?.PossibleAttributes && setSelectedColumns(response?.data?.PossibleAttributes);
        setSearchParams((prev) => {
          const newState = new URLSearchParams(prev);
          response?.data?.TableName && newState.set("tableName", response?.data?.TableName);
          response?.data?.numberOfRows && newState.set("numberOfRows", response?.data?.numberOfRows);
          response?.data?.firstSourceOrderBy && newState.set("orderBy", response?.data?.firstSourceOrderBy);
          newState.set("sortBy", response?.data?.isAscending ? "ASC" : "DESC");
          return newState;
        });
        setTestName(response?.data?.TestName || "");
        setIsEditable(!response?.data?.noOfExecutions);
      } catch (error) {
        dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
      }
      setEditLoading(false);
    };

    if (ruleId) {
      fetchEditData();
    }

    const fetchApiData = async () => {
      if (!isFileConnection || !dataSourceType) {
        await fetchConnections("database");
        !ruleId && connectionId && fetchTables(connectionId, tableName);
      } else {
        await fetchConnections("file");
        !ruleId && connectionId && fetchFileColumns(connectionId);
      }
    };

    fetchApiData();

    return () => {
      controller.abort();
    };
  }, []);

  const fetchTables = async (conId, tableNameData) => {
    try {
      setTablesLoading(true);
      const response = await APIServices.ConnectionDetails(conId);
      setTableList(response?.data?.tables || null);
      if (tableNameData) {
        const defaultTableData = response?.data?.tables?.find((each) => each?.table_name === tableNameData);
        await fetchTableColumns(defaultTableData);
      }
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    } finally {
      setTablesLoading(false);
    }
  };

  const fetchFileColumns = async (conId) => {
    try {
      setColumnsLoading(true);
      const response = await APIServices.GetFilesData({ id: conId });
      const fileDetails = response?.data?.fileDetails;
      const resultRows = response?.data?.result?.rows;

      const columns = fileDetails?.firstRowisHeader
        ? resultRows[0]
        : Array.from({ length: resultRows[0]?.length || 0 }, (_, index) => `Column ${index + 1}`);
      setColumns(columns);
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    } finally {
      setColumnsLoading(false);
    }
  };

  const fetchConnections = async (type) => {
    setConnectionsLoading(true);
    try {
      const response = type === "database" ? await APIServices.ConnectionsList() : await APIServices.getFilesList();
      const data = response?.data?.data || [];
      type === "database" ? setDatabaseOptions(data) : setFileOptions(data);
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    } finally {
      setConnectionsLoading(false);
    }
  };

  const handleConnectionTypeChange = (e) => {
    const newType = e.target.value;
    setSearchParams({ ...defaultValues, dataSourceType: newType });
    if (newType === "database" && !databaseOptions?.length) {
      fetchConnections("database");
    }
    if (newType === "file" && !fileOptions?.length) {
      fetchConnections("file");
    }
  };

  const handleConnectionClick = async (connection) => {
    const conId = connection?.id || connection?._id;
    setSearchParams({ ...defaultValues, dataSourceType, connectionId: conId, reportType });
    columns && setSelectedColumns([]);
    columns && setColumns(null);
    tableList && setTableList(null);
    tableDetails && setTableDetails(null);
    await (isFileConnection ? fetchFileColumns(conId) : fetchTables(conId));
  };

  const handleReportTypeChange = (e) => {
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set("reportType", e.target.value);
      return params;
    });
    selectedColumns && setSelectedColumns([]);
  };

  const handleOnChangeColumns = (event, column) => {
    if (event.target.checked) {
      setSelectedColumns((prev) => [...prev, column]);
    } else {
      setSelectedColumns((prev) => prev.filter((item) => item !== column));
    }
  };

  const handleOnChangeNumberOfRows = (event) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("numberOfRows", event.target.value);
      return newState;
    });
  };

  const fetchTableColumns = async (tableData) => {
    setColumnsLoading(true);
    const data = {
      connectionId: selectedConnection?.id || connectionId,
      tableName: [tableData?.table_name],
      tableNames: [{ tableName: tableData?.table_name, schema: tableData?.table_schema }],
    };
    let columns = [];
    try {
      const response = await APIServices.GetDataProfilerTablesColumns(data);
      if (!["Azure Blob"].includes(selectedConnection?.connectionType || connectionType)) {
        columns = response?.data?.Columns?.[tableData?.table_name] || [];
      } else {
        const fileDetails = response?.data?.fileDetails;
        const resultRows = response?.data?.result?.rows;
        if (fileDetails?.firstRowisHeader) {
          columns = resultRows[0];
        } else {
          const numColumns = resultRows[0]?.length || 0;
          columns = Array.from({ length: numColumns }, (_, index) => `Column ${index + 1}`);
        }
      }
      return columns;
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
      setColumns([]);
      return null;
    } finally {
      setColumns(columns);
      setColumnsLoading(false);
    }
  };

  const isSubmitDisabled = () => {
    if (!isFileConnection) {
      if (["Movablebarplot", "Barplot", "scatterplot", "boxplot", "Piechart"].includes(reportType)) {
        return !selectedColumns?.length;
      }
      return !selectedTable?.table_name;
    }
    if (["Movablebarplot", "Barplot", "scatterplot", "boxplot", "Piechart"].includes(reportType)) {
      return !selectedColumns?.length;
    }
    return !selectedConnection;
  };

  const validateForm = () => {
    if (
      (isFileConnection && selectedConnection && !Object?.keys(selectedConnection)?.length > 0) ||
      (!isFileConnection &&
        selectedConnection &&
        (!Object?.keys(selectedConnection)?.length > 0 || !selectedTable?.table_name)) ||
      (["scatterplot", "Piechart", "Barplot"].includes(reportType) && selectedColumns.length < 2) ||
      (reportType === "boxplot" && selectedColumns.length < 1) ||
      (reportType === "Movablebarplot" && selectedColumns.length < 4)
    ) {
      return true;
    }
    return false;
  };

  const handleSave = async () => {
    if (!testName) {
      setTestNameError("Test Name Required");
      return;
    }
    setColumnError(false);
    if (validateForm()) {
      setColumnError(true);
      dispatch(showToast({ message: "Fill in all details", type: "error" }));
      return;
    }

    const payload = {
      CompareColumns: [],
      ConnectionId: selectedConnection?._id || selectedConnection?.id,
      ConnectionType: selectedConnection?.ConnectionType,
      FirstDatasourceType: dataSourceType,
      PossibleAttributes:
        reportType === "Movablebarplot"
          ? [...selectedColumns, min === "" ? 0 : min, max === "" ? 0 : max]
          : selectedColumns,
      ProfilingType: reportType,
      isAscending: sortBy === "ASC",
      firstSourceOrderBy: orderBy,
      Schema: selectedTable?.table_schema,
      SchemaName: selectedTable?.table_schema,
      TableName: isFileConnection ? "" : selectedTable?.table_name,
      TestType: "Data-Profile",
      TestName: testName,
      numberOfRows,
    };

    try {
      setIsSavingProfiling(true);

      await (ruleId && isEditable
        ? APIServices.updateValidation(payload, ruleId)
        : profilingServices.createProfiling(payload));

      dispatch(
        showToast({ message: `Test ${ruleId && isEditable ? "Updated" : "Created"} Successfully`, type: "success" }),
      );
      navigate("/dataprofiler");
    } catch (error) {
      if (error?.status) {
        setTestNameError("Test Name Already Exist");
      } else {
        dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
      }
    } finally {
      setIsSavingProfiling(false);
    }
  };

  const onClickCancel = () => {
    navigate("/dataprofiler");
  };

  const onChangeTestName = (e) => {
    testNameError && e.target.value?.length > 3 && setTestNameError(false);
    setTestName(e.target.value);
  };

  const handleOnClickTable = async (tableData) => {
    selectedColumns?.length && setSelectedColumns([]);
    columns && setColumns(null);
    setTableDetails(tableData);
    const newColumns = await fetchTableColumns(tableData);
    newColumns &&
      setSearchParams((prev) => {
        const params = new URLSearchParams(prev);
        params.set("tableName", tableData?.table_name || "");
        params.set("orderBy", newColumns?.[0] || "");
        params.set("sortBy", "ASC");
        return params;
      });
  };

  if (editLoading) {
    return <CustomLoader />;
  }

  return (
    <>
      <InnerHeader>
        <Typography variant="h6" className="upper-case">{`${t("Data Profiling")}`}</Typography>
      </InnerHeader>
      <Box className="pt74">
        <Box className="createDataProfile ">
          <Box className="containerHead space-between" sx={{ py: "4px !important" }}>
            <Box className="v-center">
              <Typography variant="h6">{t("Data Profiling")}</Typography>
              <CustomRadioGroup
                value={dataSourceType || "database"}
                onChange={handleConnectionTypeChange}
                options={CONNECTION_TYPES}
              />
            </Box>

            <Box className="v-center" sx={{ gap: 2, py: 0.5 }}>
              <FormControl required sx={{ minWidth: 120 }} size="small">
                <InputLabel id="demo-simple-select-label">No. of Rows</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  value={numberOfRows}
                  label="No. of Rows"
                  onChange={handleOnChangeNumberOfRows}>
                  {ROW_OPTIONS?.map((each) => (
                    <MenuItem key={each} value={each}>
                      {each}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Grid container p={2} spacing={2}>
            <Grid size={6}>
              <TextField
                sx={{ "& p": { p: 0, m: 0 } }}
                required
                error={Boolean(testNameError)}
                value={testName}
                onChange={onChangeTestName}
                fullWidth
                size="small"
                helperText={testNameError}
                label={t("Data Profile  Name")}></TextField>
            </Grid>

            <Grid size={6}>
              <TextField
                required
                select
                fullWidth
                size="small"
                value={reportType}
                label={t("Report Type")}
                onChange={handleReportTypeChange}
                sx={{ "& .MuiChip-root": { my: "1px", fontSize: "10px", height: "18px" } }}>
                {ReportTypeOptions?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {t(option.label)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <Box className="selectConnection">
            <Grid container spacing={0.5}>
              <Grid size={gridSize}>
                <DataProfileSelectConnection
                  connections={connections}
                  loading={connectionsLoading}
                  selectedConnection={selectedConnection}
                  handleConnectionClick={handleConnectionClick}
                />
              </Grid>

              {!isFileConnection && (
                <Grid size={gridSize}>
                  <DataProfileSelectTables
                    tableList={tableList}
                    filteredColumns={filteredColumns}
                    loading={tablesLoading}
                    columnsLoading={columnsLoading}
                    handleOnClickTable={handleOnClickTable}
                    selectedTable={selectedTable}
                    selectedConnection={selectedConnection}
                  />
                </Grid>
              )}

              {isColumnsRequired && (
                <Grid size={gridSize}>
                  <DataProfileSelectColumns
                    reportType={reportType}
                    columnError={columnError}
                    loading={columnsLoading}
                    columns={filteredColumns}
                    selectedColumns={selectedColumns}
                    handleOnChangeColumns={handleOnChangeColumns}
                  />
                </Grid>
              )}
            </Grid>
          </Box>

          <Box className="space-between" sx={{ p: 2 }}>
            <Button onClick={onClickCancel} color="error" size="small" variant="contained">
              Cancel
            </Button>
            <CustomLoadingButton
              loading={isSavingProfiling}
              disabled={isSubmitDisabled() || isSavingProfiling || columnsLoading}
              onClick={handleSave}>
              {isEditable ? "Update" : ruleId ? "Create As New" : t("Save")}
            </CustomLoadingButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CreateDataProfile;
