import { useState } from "react";
import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import CustomExpandableTableRow from "../../Tables/CustomExpandableTableRow";
import ExecutionFailedRecords from "./ExecutionFailedRecords";
import { formatDateWithTime, getTimeDuration } from "../../../_helpers/utils";
import ComparativeExecutionResult from "./ComparativeExecutionResult";
import { useTranslation } from "react-i18next";
const GetResults = ({ itemResult }) => {
  const { t } = useTranslation();
  const [showResults, setShowResults] = useState(false);
  const onClickArrow2 = () => {
    setShowResults(!showResults);
  };
  // const totalPassed = itemResult?.validationschecks?.reduce(
  //   (accumulator, each) => accumulator + (each?.Result?.TotalPassed || 0),
  //   0
  // );

  // const totalFailed = itemResult?.validationschecks?.reduce(
  //   (accumulator, each) => accumulator + (each?.Result?.TotalFailed || 0),
  //   0
  // );

  return (
    <>
      <CustomExpandableTableRow
        showAccordian={showResults}
        onClickArrow={onClickArrow2}
        className="batchDetailRows"
        showData={
          <>
            <Typography color="green" component="span">
              {itemResult?.totalRecordsProcessed - itemResult?.totalFailedRecords}
            </Typography>
            &nbsp; | &nbsp;
            <Typography color="error" component="span">
              {itemResult?.totalFailedRecords}
            </Typography>
          </>
        }
        data={itemResult}
        expandComponent={
          <TableCell sx={{ p: 0, width: "10%" }} colSpan="7">
            <ExecutionFailedRecords validations={itemResult} />
          </TableCell>
        }>
        {/* <TableCell sx={{ width: "10%" }}>{itemResult?._id}</TableCell> */}
        <TableCell sx={{ width: "15%" }}>
          {itemResult?.batchNumber} {t("of ")}
          {itemResult?.totalBatchCount}
        </TableCell>
        <TableCell sx={{ width: "25%" }}>
          <Typography>
            {t("Total Batches Records Processed")} :
            {itemResult?.totalBatchesRecordsProcessed
              ? itemResult?.totalBatchesRecordsProcessed
              : itemResult?.totalRecordsCount}
          </Typography>
          <Typography>
            {t("Remaining Records")} :
            {itemResult?.totalBatchesRecordsProcessed
              ? itemResult?.totalRecordsCount - itemResult?.totalBatchesRecordsProcessed
              : itemResult?.totalRecordsCount - itemResult?.totalRecordsProcessed}
          </Typography>

          <Typography>
            {t("Remaining Batches")} :{itemResult?.totalBatchCount - itemResult?.batchNumber}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "20%" }}>{formatDateWithTime(itemResult?.startDateTime)}</TableCell>
        {/* <TableCell sx={{ width: "15%" }}>
              {itemResult &&
                formatDateWithTime(itemResult?.finishDateTime) 
            </TableCell> */}
        <TableCell sx={{ width: "15%" }}>
          {getTimeDuration(itemResult?.startDateTime, itemResult?.finishDateTime, t)}
        </TableCell>
      </CustomExpandableTableRow>
    </>
  );
};

const ResultDetailView = ({ resultValidation, selectedExecution, batchesCurrentPage, executionResultsPageChange }) => {
  const { t } = useTranslation();
  const { validationDetails, ValidationResults, recordsCount } = resultValidation;
  const isComparisonTest = validationDetails?.TestType === "Comparison";
  return (
    <Box className="resultsDetailview">
      {isComparisonTest ? (
        <ComparativeExecutionResult resultValidation={resultValidation} selectedExecution={selectedExecution} />
      ) : (
        <TableContainer>
          <Table>
            <TableHead className="batchDetailsHead">
              <TableRow>
                <TableCell>{t("Batch Number")}</TableCell>
                <TableCell>{t("Processing Status")}</TableCell>
                <TableCell>{t("DQ Rule Start Time")}</TableCell>
                <TableCell>{t("Time Duration")}</TableCell>
                <TableCell align="center">
                  {t("Passed")} | {t("Failed")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ValidationResults?.map((itemResult, i) => (
                <GetResults key={i} itemResult={itemResult} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Pagination
        sx={{ my: 1.5, ml: "auto", float: "right" }}
        count={Math.ceil(recordsCount / 10)}
        page={batchesCurrentPage}
        onChange={executionResultsPageChange}
      />
    </Box>
  );
};

export default ResultDetailView;
