import { useLayoutEffect, useState } from "react";
import { TableContainer, Table, TableBody, Button, Paper, TableCell, TableRow } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { useTranslation } from "react-i18next";
import { isAzure } from "../../../_helpers/Constant";
import { decryptData } from "../../../helpers/encryptionUtils";
import { useSelector } from "react-redux";

const ViewConnectionDetails = ({ connectionData, onClose, setViewType }) => {
  const { t } = useTranslation();
  const { user_details } = useSelector((state) => state.auth);
  const [userName, setUserName] = useState("");
  const showEdit = connectionData?.UserId === user_details?.UserId || user_details?.RoleName === "Admin";

  useLayoutEffect(() => {
    if (connectionData?.user) {
      const getDecryptedValue = async () => {
        try {
          const decryptedUser = await decryptData(connectionData?.user);
          setUserName(decryptedUser || connectionData?.user);
        } catch (error) {
          console.error("Error decrypting data:", error);
        }
      };
      getDecryptedValue();
    }
  }, [connectionData?.user]);

  return (
    <>
      <TableContainer component={Paper} sx={{ p: 1 }}>
        <Table aria-label="customized table">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                {t("Connection Name")}:
              </TableCell>
              <TableCell align="left">{connectionData?.connectionName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                {t("Connection Type")}:{" "}
              </TableCell>
              <TableCell align="left">{connectionData?.connectionType}</TableCell>
            </TableRow>
            {!isAzure(connectionData?.connectionType) && connectionData?.connectionType !== "Databricks" && (
              <>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {t("Server")}:
                  </TableCell>
                  <TableCell align="left">{connectionData?.server}</TableCell>
                </TableRow>
                {connectionData?.connectionType !== "Oracle" && (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("Database")}:
                    </TableCell>
                    <TableCell align="left">{connectionData?.dataBase}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell component="th" scope="row">
                    {t("User")}:
                  </TableCell>
                  <TableCell align="left">{userName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {t("Password")}:
                  </TableCell>
                  <TableCell align="left">*******</TableCell>
                </TableRow>
              </>
            )}
            {connectionData?.connectionType === "Azure Blob" && (
              <>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {t("Account Name")}:
                  </TableCell>
                  <TableCell align="left">{connectionData?.accountName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {t("Container Name")}:
                  </TableCell>
                  <TableCell align="left">{connectionData?.containerName}</TableCell>
                </TableRow>
              </>
            )}
            {connectionData?.connectionType === "AWS S3" && (
              <>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {t("Bucket Name")}:
                  </TableCell>
                  <TableCell align="left">{connectionData?.bucketName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {t("Region Name")}:
                  </TableCell>
                  <TableCell align="left">{connectionData?.regionName}</TableCell>
                </TableRow>
              </>
            )}

            {connectionData?.serverHostname && (
              <TableRow>
                <TableCell component="th" scope="row">
                  {t("Server Host Name")}:
                </TableCell>
                <TableCell align="left">{connectionData?.serverHostname}</TableCell>
              </TableRow>
            )}

            {connectionData?.catalogName && (
              <TableRow>
                <TableCell component="th" scope="row">
                  {t("Catalog Name")}:
                </TableCell>
                <TableCell align="left">{connectionData?.catalogName}</TableCell>
              </TableRow>
            )}

            {connectionData?.token && (
              <TableRow>
                <TableCell component="th" scope="row">
                  {t("Token")}:
                </TableCell>
                <TableCell align="left">{connectionData?.token}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container sx={{ p: 1 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid size={4} textAlign="left">
          <Button onClick={onClose} variant="outlined" color="error">
            {t("Cancel")}
          </Button>
        </Grid>
        <Grid size={4} textAlign="center"></Grid>
        <Grid size={4} textAlign="right">
          <Button
            sx={{ visibility: showEdit ? "visible" : "hidden" }}
            onClick={() => setViewType("Edit")}
            variant="outlined"
            color="success">
            {t("Edit")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewConnectionDetails;
