import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import CustomSwitchButton from "../../../CustomComponents/CustomSwitchButton";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateRowComparisonFields } from "../../../../Redux/reducers/rowComparisonSlice";
import CustomWidthTooltip from "../../../CustomComponents/CustomWidthTooltip";

const ComparativeHeader = ({ validationsToDelete, setValidationsToSelected }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const isFullScan = JSON.parse(searchParams.get("isFullScan"));

  const columnsToValidate = useSelector((state) => state.rowComparison.columnsToValidate);

  const deleteSelected = () => {
    const filteredSource1 = columnsToValidate?.source1.filter((_, index) => !validationsToDelete.includes(index));
    const filteredSource2 = columnsToValidate?.source2.filter((_, index) => !validationsToDelete.includes(index));

    dispatch(
      updateRowComparisonFields({ source1SelectedColumns: filteredSource1, source2SelectedColumns: filteredSource2 }),
    );

    setValidationsToSelected([]);
  };

  const handleSwitchChange = (e) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("isFullScan", e.target.checked);
      return newState;
    });
  };
  return (
    <Box className="space-between" width="100%" height={40}>
      <Typography variant="sectionHeading">{t("Column Mapping")}</Typography>
      <Box className="v-center" sx={{ py: 0.5, mt: 0.5, display: "none !important" }}>
        <CustomSwitchButton
          labels={[t("Position-Based Row Comparison"), t("Intersection Row Comparison")]}
          checked={isFullScan}
          onChange={handleSwitchChange}
        />

        <CustomWidthTooltip
          arrow
          placement="top-end"
          title={
            <>
              <Typography>
                <b>Position-Based Row Comparison</b> : Conducts a row-by-row comparison of data in datasets or tables by
                comparing based on the sequential order or positions.
              </Typography>
              <Typography>
                <b>Intersection Row Comparison</b> : Conducts a row-by-row comparison of data in datasets or tables by
                comparing irrespective of their sequential order or positions.
              </Typography>
            </>
          }>
          <IconButton size="small">
            <InfoOutlinedIcon />
          </IconButton>
        </CustomWidthTooltip>
        {validationsToDelete.length > 0 && (
          <Box className="v-center">
            {validationsToDelete.length} selected
            <Tooltip title={t("Delete")}>
              <IconButton color="error" size="small" onClick={deleteSelected}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ComparativeHeader;
