import axios from "axios";
import { decryptData, encryptData } from "../../helpers/encryptionUtils";
import { AuthBaseURLENV, azureLogin, KeyManagerURLENV, ProductCode, userLocalSession } from "../../_helpers/Constant";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showToast } from "../reducers/toastSlice";
import { CLEAR_STATE } from "../types";

const authenticateUser = createAsyncThunk(
  "auth/authenticateUser",
  async (userDetails, { rejectWithValue, dispatch }) => {
    try {
      const data = {
        Email: userDetails?.email,
        Password: userDetails?.password,
        ProductCode,
        IsIdServerExists: azureLogin,
      };
      const payload = encryptData(JSON.stringify(data));
      const res = await axios.post(`${AuthBaseURLENV}/api/Login/V3/Userlogin`, payload);
      const response = decryptData(res?.data);
      return { userDetails, response };
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      dispatch(showToast({ message: errorMessage, type: "error" }));
      return rejectWithValue(errorMessage);
    }
  },
);

const logoutUser = async (userDetails, dispatch) => {
  try {
    const reqData = encryptData(
      JSON.stringify({ UserId: userDetails?.UserId, SessionId: userDetails?.SessionId, ProductCode }),
    );
    await axios.post(`${AuthBaseURLENV}/api/Login/V1/UserLogout`, reqData);
    localStorage.removeItem(userLocalSession);
    dispatch({ type: CLEAR_STATE });
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(showToast({ message: errorMessage, type: "error" }));
  }
};

const acceptLicense = async (data) => {
  const reqData = encryptData(JSON.stringify({ UserId: data?.UserId }));
  const response = await axios.post(`${AuthBaseURLENV}/api/Login/ULAAgreement`, reqData);
  return decryptData(response?.data);
};

const changePassword = async (passwordObj) => {
  const data = encryptData(JSON.stringify(passwordObj));
  const response = await axios.post(`${AuthBaseURLENV}/api/Login/ChangePassword`, data);
  return decryptData(response?.data);
};

const fetchDecryptedSecrets = async (encryptArray) => {
  try {
    const encryptKeyObj = { secrets: encryptArray?.map((each) => ({ secretKey: each })) };

    const enData = await encryptData(JSON.stringify(encryptKeyObj));
    const res = await axios.post(KeyManagerURLENV, enData);
    const response = decryptData(res.data);

    return response?.ResponseObject?.Secrets;
  } catch (error) {
    return { error: true, message: error };
  }
};

const getRefreshToken = (data) => {
  return axios.post(`${AuthBaseURLENV}/api/Login/V1/RefreshToken`, data);
};

export const authServices = {
  logoutUser,
  acceptLicense,
  changePassword,
  fetchDecryptedSecrets,
  getRefreshToken,
  authenticateUser,
};
