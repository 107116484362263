import { Paper, Box, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import APIServices from "../../../services/app.service";
import { useSearchParams } from "react-router-dom";
import CustomLoader from "../../CustomComponents/CustomLoader";
import { showToast } from "../../../Redux/reducers/toastSlice";
import { useDispatch } from "react-redux";

const renderDetailRow = (label, value) => (
  <Typography>
    <span className="upper-case bold-text" style={{ color: "#495057" }}>
      {label} :{" "}
    </span>
    {value}
  </Typography>
);

const DataProfilingPreview = ({ scrollRef }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const previewId = searchParams.get("previewId");
  const [testDetails, setTestDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const handleClosePreview = () => {
    setSearchParams({});
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data } = await APIServices.GetDataValidationByID(previewId);
      setTestDetails(data);
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [previewId]);

  if (loading) {
    return <CustomLoader />;
  }
  return (
    <Paper ref={scrollRef} sx={{ mt: 2 }} className="DQRulePreviewContainer">
      <Box mb="3px">
        <Grid container alignItems="center">
          <Grid size={10} pl="15px">
            <Typography className="upper-case" style={{ color: "#403C6C" }} variant="h6">
              {t("Preview")}
            </Typography>
          </Grid>
          <Grid size={2} textAlign="right" pr="8px">
            <IconButton onClick={handleClosePreview} size="small" color="error">
              <CancelOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid size={12} className="PreviewBar" py="2px">
            <Box className="space-between">
              {renderDetailRow("Test Name", testDetails?.TestName)}
              {renderDetailRow(
                testDetails?.ConnectionDetails?.connectionType === "Azure Blob" ? t("FILE NAME") : t("TABLE NAME"),
                testDetails?.TableName,
              )}

              {renderDetailRow(t("Data Source Name"), testDetails?.ConnectionName)}
              {renderDetailRow(t("Report type"), testDetails.ProfilingType)}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default DataProfilingPreview;
