import { Box, Button, Paper } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect, useContext, useState } from "react";
import ApiService from "../../../../services/app.service";
import SelectDatasource from "./SelectDatasource";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../../../App";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFirstConnectionDetails, setSecondConnectionDetails } from "../../../../Redux/reducers/rowComparisonSlice"; // Import actions
import { addConnectionsList } from "../../../../Redux/reducers/connectionSlice";

const SelectComparisonSources = () => {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const connectionsInfo = useSelector((state) => state.connectionData);

  const firstDataSourceType = searchParams.get("firstDataSourceType");
  const secondDataSourceType = searchParams.get("secondDataSourceType");

  const [filesOptions, setFilesOptions] = useState([]);
  const [databaseOptions, setDatabaseOptions] = useState([]);
  const [firstDatasourceLoading, setFirstDatasourceLoading] = useState(false);
  const [secondDatasourceLoading, setSecondDatasourceLoading] = useState(false);

  const [localFirstConn, setLocalFirstConn] = useState(null);
  const [localSecondConn, setLocalSecondConn] = useState(null);

  const fetchConnectionByType = async (type) => {
    try {
      const connectionList = connectionsInfo?.connections?.[type?.toLowerCase()];
      if (connectionList) {
        type === "Database" ? setDatabaseOptions(connectionList?.data) : setFilesOptions(connectionList?.data);
      } else {
        const response = await ApiService.getConnectionsByType({ type });
        dispatch(addConnectionsList({ type: type?.toLowerCase(), connectionsList: response?.data }));
        type === "Database" ? setDatabaseOptions(response?.data?.data) : setFilesOptions(response?.data?.data);
      }
    } catch (error) {
      setSnack({ message: error?.response?.data?.message ?? error.message, open: true, colour: "error" });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setFirstDatasourceLoading(true);
      setSecondDatasourceLoading(true);
      await fetchConnectionByType(firstDataSourceType === "database" ? "Database" : "Files");
      if (firstDataSourceType !== secondDataSourceType) {
        await fetchConnectionByType(secondDataSourceType === "database" ? "Database" : "Files");
      }
      setFirstDatasourceLoading(false);
      setSecondDatasourceLoading(false);
    };
    fetchData();
  }, []);

  const updateDataSourceType = (newSourceType, sourceTypeName) => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set(sourceTypeName, newSourceType);
      return updatedParams;
    });
  };

  const handleNext = () => {
    dispatch(setFirstConnectionDetails(localFirstConn));
    dispatch(setSecondConnectionDetails(localSecondConn));
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("isCustomQuery", false);
      newState.set("isPrivate", true);
      newState.set("isUnMatched", true);
      newState.set("firstSourceOrderBy", "");
      newState.set("secondSourceOrderBy", "");
      newState.set("isFullScan", false);
      newState.set("firstSourceConId", localFirstConn?.id);
      newState.set("secondSourceConId", localSecondConn?.id);
      return newState;
    });
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <SelectDatasource
          heading={t("1st Data Source")}
          filesOptions={filesOptions}
          databaseOptions={databaseOptions}
          selectedConnection={localFirstConn}
          setSelectedConnection={(conn) => setLocalFirstConn(conn)}
          loading={firstDatasourceLoading}
          dataSourceType={firstDataSourceType}
          onChangeDataSourceType={(newType) => updateDataSourceType(newType, "firstDataSourceType")}
          setLoading={setFirstDatasourceLoading}
          fetchConnectionByType={fetchConnectionByType}
        />
        <SelectDatasource
          heading={t("2nd Data Source")}
          filesOptions={filesOptions}
          databaseOptions={databaseOptions}
          selectedConnection={localSecondConn}
          setSelectedConnection={(conn) => setLocalSecondConn(conn)}
          loading={secondDatasourceLoading}
          dataSourceType={secondDataSourceType}
          onChangeDataSourceType={(newType) => updateDataSourceType(newType, "secondDataSourceType")}
          setLoading={setSecondDatasourceLoading}
          fetchConnectionByType={fetchConnectionByType}
        />
      </Grid>
      <Box className="v-center" mt={3}>
        <Button
          sx={{ ml: "auto" }}
          disabled={!(localFirstConn && localSecondConn)}
          variant="contained"
          onClick={handleNext}>
          {t("Next")}
        </Button>
      </Box>
    </Paper>
  );
};

export default SelectComparisonSources;
