import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import {
  DriveFileRenameOutline as DriveFileRenameOutlineIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { SnackbarContext } from "../../App";
import CustomLoadingButton from "../CustomComponents/CustomsButtons/CustomLoadingButton";

const SingleDatabaseQuery = ({
  queryInputData,
  getTableData,
  loadingTableDetails,
  finalValidations,
  setQueryInputData,
  isQueryOpened,
  setIsQueryOpened,
}) => {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const handleClickEdit = () => {
    // if (finalValidation?.length > 0 || source1Dragitems?.length > 0) {
    if (finalValidations?.length > 0) {
      setSnack({
        message: "Delete existing validation to Edit the Query",
        open: true,
        colour: "warning",
      });
    } else {
      setIsQueryOpened((prevState) => !prevState);
    }
  };
  const editQuery = () => (
    <Box className="space-between">
      {t("SQL Query")}
      <Tooltip title="View or Edit" placement="top">
        <IconButton
          p={0}
          disabled={queryInputData === ""}
          size="small"
          variant="oulined"
          onClick={handleClickEdit}
          sx={{ color: "#808080" }}>
          {isQueryOpened ? <ExpandLessIcon /> : <DriveFileRenameOutlineIcon />}
        </IconButton>
      </Tooltip>
    </Box>
  );

  const getsourceSql1Preview = async () => {
    await getTableData(queryInputData);
    setIsQueryOpened(false);
  };

  return (
    <Grid size={12}>
      <Box>
        <Card
          sx={{
            minWidth: 275,
            border: "1px solid #DDDFE1",
            "& .MuiCardHeader-root": { "& .MuiTypography-root": { fontSize: "14px" } },
            mb: 1.5,
          }}>
          <CardHeader
            title={editQuery()}
            sx={{
              backgroundColor: "#5F64E526",
              color: "#464a53",
              p: 0.5,
            }}
          />
          <CardContent
            sx={{
              maxHeight: "260px",
              overflowY: "auto",
              pb: "16px!important",
              "& textarea": {
                width: "100%",
                height: "150px !important",
                maxHeight: "150px !important",
                overflowY: "auto !important",
              },
            }}>
            {isQueryOpened ? (
              <TextareaAutosize
                minRows={10}
                onChange={(e) => setQueryInputData(e.target.value)}
                value={queryInputData}
              />
            ) : (
              <Typography>
                <b>{t("SQL Query")} : </b> {queryInputData}
              </Typography>
            )}
            {isQueryOpened && (
              <Grid container mt={1}>
                <Grid size={6}>
                  <Button
                    color="error"
                    size="small"
                    variant="contained"
                    disabled={queryInputData?.trim().length === 0}
                    onClick={() => setQueryInputData("")}>
                    {t("Reset")}
                  </Button>
                </Grid>
                <Grid size={6} sx={{ textAlign: "right" }}>
                  <CustomLoadingButton
                    disabled={queryInputData?.trim().length === 0 || loadingTableDetails}
                    onClick={() => getsourceSql1Preview()}
                    loading={loadingTableDetails}>
                    {t("Submit")}
                  </CustomLoadingButton>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Box>
    </Grid>
  );
};

export default SingleDatabaseQuery;
