import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import JoinInnerOutlinedIcon from "@mui/icons-material/JoinInnerOutlined";

import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import Box from "@mui/material/Box";
import { IconButton, Paper } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { isDuplicates } from "../../_helpers/Constant";
import CustomDialog from "../CustomComponents/CustomDialog";
import { useSelector } from "react-redux";

const ExecutionDetailItem = ({ title, value }) => {
  const { t } = useTranslation();
  return (
    <Typography variant="detailTitle">
      {t(title)} :{" "}
      <Typography component="span" variant="detailValue">
        {value}
      </Typography>
    </Typography>
  );
};

const ComparisonResultTypeCard = ({ title, value, icon }) => (
  <Grid
    size="grow"
    className="comparisonResultType"
    sx={{
      "& .tile.MuiPaper-root": { cursor: "not-allowed !important", "&:hover": { cursor: "not-allowed !important" } },
    }}

    // onClick={onClick}
  >
    <Paper className="tile">
      {icon}
      <Typography>
        {title} <br />
        <span>{value}</span>
      </Typography>
      {/* {loading && (
        <IconButton aria-label="loading" size="small">
          <CircularProgress size={20} color="primary" />
        </IconButton>
      )} */}
      <IconButton disabled={true} size="small">
        <ChevronRightOutlinedIcon />
      </IconButton>
    </Paper>
  </Grid>
);

const ComparativeValidatePreview = ({ validationsResult, handleClose }) => {
  const { t } = useTranslation();

  const firstConn = useSelector((state) => state.rowComparison.firstConnectionDetails);
  const secondConn = useSelector((state) => state.rowComparison.secondConnectionDetails);
  const selectedSource1Table = useSelector((state) => state.rowComparison.selectedSource1Table);
  const selectedSource2Table = useSelector((state) => state.rowComparison.selectedSource2Table);
  const source1Columns = useSelector((state) => state.rowComparison.source1Columns);
  const source2Columns = useSelector((state) => state.rowComparison.source2Columns);
  const columnsToValidate = useSelector((state) => state.rowComparison.columnsToValidate);
  const source1columnDetails = source1Columns && Object.values(source1Columns?.[0] || {})?.[0];
  const source2columnDetails = source2Columns && Object.values(source2Columns?.[0] || {})?.[0];

  return (
    <CustomDialog open={true} title="Data Quality Rule Execution Result" onClose={handleClose} fullWidth={true}>
      <Box sx={{ bgcolor: "#eee" }} className="comparativeValRes">
        <Grid container className="dataSourceDetails">
          <Grid size={6} sx={{ borderRight: "1px solid #fff", px: 1, py: 0.5 }}>
            <Typography className="dataSouceHead">{t("1st Data Source")}</Typography>
            <Box className="v-center" gap={2}>
              <ExecutionDetailItem title="Type" value={firstConn?.connectionType} />
              <ExecutionDetailItem title="Name" value={firstConn?.connectionName} />
            </Box>

            <Typography>
              <b>{t("Table")} : </b>
              {selectedSource1Table?.table_name} | <b>{t("Column")} : </b>
              {columnsToValidate?.source1?.map((each) => each?.Column)?.join(", ")} | <b>{t("Row Count")} : </b>
              {source1columnDetails?.rowsCount || source1columnDetails?.result?.totalRows}
            </Typography>
          </Grid>

          <Grid size={6} px={1} py={0.5}>
            <Typography className="dataSouceHead">{t("2nd Data Source")}</Typography>

            <Box className="v-center" gap={2}>
              <ExecutionDetailItem title="Type" value={secondConn?.connectionType} />
              <ExecutionDetailItem title="Name" value={secondConn?.connectionName} />
            </Box>
            <Typography>
              <b>{t("Table")} : </b>
              {selectedSource2Table?.table_name} | <b>{t("Column")} : </b>
              {columnsToValidate?.source2?.map((each) => each?.Column)?.join(", ")} | <b>{t("Row Count")} : </b>
              {source2columnDetails?.rowsCount || source2columnDetails?.result?.totalRows}
            </Typography>
          </Grid>
        </Grid>

        {validationsResult?.map((itemVal, index) => {
          const indexKey = index;
          return (
            <Box key={indexKey}>
              <Box sx={{ p: 1 }}>
                <Grid container>
                  <ComparisonResultTypeCard
                    icon={<JoinInnerOutlinedIcon />}
                    title={t("Matched First Datasource Count")}
                    value={itemVal?.RowComparisonResult?.MatchedFirstDatasourceCount}
                  />

                  <ComparisonResultTypeCard
                    icon={<JoinInnerOutlinedIcon />}
                    title={t("Matched Second Datasource Count")}
                    value={itemVal?.RowComparisonResult?.MatchedSecondDatasourceCount}
                  />

                  <ComparisonResultTypeCard
                    icon={<ArticleOutlinedIcon />}
                    title={t("Only in 1st Source")}
                    value={itemVal?.RowComparisonResult?.OnlyInFirstDatasourceCount}
                  />

                  <ComparisonResultTypeCard
                    icon={<ArticleOutlinedIcon />}
                    title={t("Only in 2nd Source")}
                    value={itemVal?.RowComparisonResult?.OnlyInSecondDatasourceCount}
                  />

                  {isDuplicates && (
                    <>
                      <ComparisonResultTypeCard
                        icon={<ContentCopyOutlinedIcon />}
                        title={t("Duplicate in 1st Source")}
                        value={itemVal?.RowComparisonResult?.DuplicatesOfFirstDatasourceCount}
                      />
                      <ComparisonResultTypeCard
                        icon={<ContentCopyOutlinedIcon />}
                        title={t("Duplicate in 2nd Source")}
                        value={itemVal?.RowComparisonResult?.DuplicatesOfSecondDatasourceCount}
                      />
                    </>
                  )}
                </Grid>
              </Box>
            </Box>
          );
        })}
      </Box>
    </CustomDialog>
  );
};

export default ComparativeValidatePreview;
