import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Checkbox, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function ImageCordinates({ Imgarea1, setImgarea1, file1, file2, setLoading }) {
  const { t } = useTranslation();
  const [checkBoxes, setCheckBoxes] = useState([]);

  const checkCoordinates = (e) => {
    const arr = [...checkBoxes];
    if (e.target.checked) {
      arr.push(Number.parseInt(e.target.value));
    } else {
      const index = arr.indexOf(Number.parseInt(e.target.value));
      if (index > -1) {
        arr.splice(index, 1);
      }
    }
    setCheckBoxes(arr);
  };

  const resetData = () => {
    const filteredImgarea1 = Imgarea1.filter((element, index) => !checkBoxes.includes(index));
    setImgarea1(filteredImgarea1);
    setCheckBoxes([]);
    setLoading(false);
  };

  const isSelected = (index) => checkBoxes.indexOf(index) !== -1;

  return (
    <Box className="ignoreCordinates">
      <Box className="innerSubHead">
        <Grid container alignItems="center">
          <Grid size={6}>
            <Typography variant="h6">
              {t("Area to ignore")} <b>[{Imgarea1.length}]</b>
            </Typography>
          </Grid>
          <Grid size={6} textAlign="right">
            {checkBoxes.length > 0 && (
              <IconButton size="small" onClick={resetData}>
                <DeleteIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box className="cordinatesBody">
        <Box className="cordinatesSec">
          {Imgarea1.map((obj, index) => {
            const isItemSelected = isSelected(index);
            return (
              <Box key={index}>
                <b>{index + 1}.</b>{" "}
                <Checkbox size="small" onChange={checkCoordinates} value={index} checked={isItemSelected} />
                <Typography>
                  <span>
                    {t("X")} : {obj.x.toFixed(2)}
                  </span>
                  <span>
                    {t("Y")} : {obj.y.toFixed(2)}
                  </span>
                  <span>
                    {t("Width")} : {obj.width.toFixed(2)}
                  </span>
                  <span>
                    {t("Height")} : {obj.height.toFixed(2)}
                  </span>
                  <span>
                    {t("Unit")} : {obj.unit}
                  </span>
                </Typography>
              </Box>
            );
          })}
          {Imgarea1.length === 0 && <Typography>{t("Please select co-ordinates")}</Typography>}
        </Box>
      </Box>
      <Box className="innerSubHead">
        <Grid container alignItems="center">
          <Grid size={6}>
            <Typography variant="h6">{t("Image 1")}</Typography>
          </Grid>
          <Grid size={6}>
            <Typography variant="h6">{t("Image 2")}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className="imageCordinates">
        <Grid container>
          <Grid size={6}>
            {file1 && (
              <Typography>
                {t("Size")} : {(file1[0].size * 0.001).toFixed(1)}KB
              </Typography>
            )}
          </Grid>
          <Grid size={6}>
            {file2 && (
              <Typography>
                {t("Size")} : {(file2[0].size * 0.001).toFixed(1)}KB
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
