import { formatDateWithTime } from "../../../_helpers/utils";
import { customComparator } from "../../AgGrid/CustomSort";
import ReportTypeOptions from "../ReportTypeOptions";

const ExecutionDate = (props) => <>{formatDateWithTime(props.data.latestRuleExecution)}</>;

const ReportType = (props) => (
  <>{ReportTypeOptions?.find((each) => each?.value === props.data.ProfilingType)?.label || ""}</>
);

export const DataProfilingListHeadCells = [
  {
    field: "ConnectionName",
    headerName: "Data Source Name",
    sortable: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
  },
  {
    field: "TestName",
    headerName: "DQ Rule Name",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
    headerTooltip: "DQ Rule Name",
  },
  {
    field: "ConnectionType",
    headerName: "Data Source Type",
    sortable: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
    headerTooltip: "Rule Type",
  },
  {
    field: "ProfilingType",
    headerName: "Report Type",
    sortable: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
    headerTooltip: "Report Type",
    cellRenderer: ReportType,
  },
  {
    field: "latestRuleExecution",
    headerName: "Last Executed On",
    filter: false,
    suppressMovable: true,
    headerTooltip: "Last Executed On",
    cellRenderer: ExecutionDate,
  },
  {},
];
