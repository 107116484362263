import { useContext, useState, useRef } from "react";
import {
  Box,
  Button,
  // Checkbox, FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import FileDownloadDoneOutlinedIcon from "@mui/icons-material/FileDownloadDoneOutlined";
import { SnackbarContext } from "../../../App";
import dragImg from "../../../images/Drag_Drop.png";
import APIServices from "../../../services/app.service";
import FilePreview from "./FilePreview";
import { useTranslation } from "react-i18next";
import CustomLoadingButton from "../../CustomComponents/CustomsButtons/CustomLoadingButton";
import { maxSizeInMB } from "../../../_helpers/Constant";
import { useDispatch, useSelector } from "react-redux";
import { addConnection, addConnectionsList } from "../../../Redux/reducers/connectionSlice";

const defaultFileTypes = {
  XLSX: ".xlsx, .xls",
  CSV: ".csv",
  PARQUET: ".parquet",
};

const FileUpload = ({ fileType = null, setFile = null, validationPage = false, onSuccessCreate, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const fileInput = useRef();

  const [uploadButton, setUploadButton] = useState(true);
  const [loader, setLoading] = useState(false);
  const [showDelimiter, setShowDelimiter] = useState(false);
  const [
    firstRowisHeader,
    // setFirstRowisHeader
  ] = useState(true);
  const connectionsInfo = useSelector((state) => state.connectionData);

  const [delimiter, setDelimiter] = useState(",");
  const [selectedFile, setSelectedFile] = useState([]);
  const [connectionName, setConnectionName] = useState("");
  const [formError, setFormError] = useState("");
  const [fileError, setFileError] = useState(false);
  const [fileSuccess, setFileSuccess] = useState(false);
  const [wrapCss, setBorder] = useState({ border: "1px dashed #096eb6" });
  const [previewFile, setPreviewFile] = useState(false);

  const fileAccept = fileType ? defaultFileTypes[fileType.toUpperCase()] : ".xlsx, .xls, .csv, .parquet,";

  const isValidFileType = (file) => {
    const ext = file.name.split(".").pop().toLowerCase();
    return fileAccept.includes(ext);
  };

  const CheckFileSize = (fileSize) => {
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    if (fileSize > maxSizeInBytes) {
      setSnack({
        message: `File size exceeds the maximum limit of ${maxSizeInMB}`,
        open: true,
        colour: "error",
      });
      setFileSuccess(false);
      setFileError(false);
      setSelectedFile([]);
      return true;
    }
    return false;
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    if (loader) {
      return;
    }
    setBorder({ border: "1px dashed #096eb6" });
    setFileSuccess(false);
    setFileError(false);
    setSelectedFile([]);

    const files = e.dataTransfer.files;
    if (files?.[0]?.size && CheckFileSize(files[0].size)) {
      return;
    }

    if (files.length === 0) {
      return;
    }

    const fileData = files[0];
    const validTypes = [
      "application/vnd.ms-excel",
      ".csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
      ".parquet",
    ];

    // Check if the file type is valid
    if (fileType && !isValidFileType(fileData)) {
      setFileError(true);
      setUploadButton(true);
      return;
    }

    // Check if the file extension is in the valid types list
    const fileExtension = fileData.name.split(".").pop();
    const fileMimeType = fileData.type || `.${fileExtension}`;

    if (!validTypes.includes(fileMimeType)) {
      setFileError(true);
      setUploadButton(true);
      return;
    }

    setShowDelimiter(fileExtension === "csv" || fileExtension === "txt");

    // File successfully selected
    setFileSuccess(true);
    setSelectedFile(files);
    setUploadButton(false);
    if (setFile) {
      setFile(fileData);
    }
  };

  const handleFileDragOver = (e) => {
    e.preventDefault();
    setBorder({ border: "1px solid #1976d2" });
  };

  const handleFileDragEnter = (e) => {
    e.preventDefault();
    setBorder({ border: "1px solid #1976d2" });
  };

  const handleFileDragLeave = (e) => {
    e.preventDefault();
    setBorder({ border: "1px dashed #096eb6" });
  };

  const handleFileChange = (e) => {
    const files = e.target.files;

    if (CheckFileSize(e.target?.files?.[0]?.size)) {
      return;
    }
    const validTypes = [
      "application/vnd.ms-excel",
      ".csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // "text/plain",
      "text/csv",
      // "text/xml",
      // "application/json",
      ".parquet",
    ];
    const allowedExtensions = fileAccept.split(",").map((ext) => ext.trim());
    if (!files || files.length === 0) {
      // No file selected
      return;
    }

    const fileExtension = files?.[0]?.name?.split(".")?.pop();
    const isFileValid =
      validTypes.includes(files[0].type || `.${fileExtension}`) &&
      fileExtension &&
      allowedExtensions.includes(`.${fileExtension}`);

    setFileError(!isFileValid);
    setUploadButton(!isFileValid);
    setFileSuccess(isFileValid);
    setSelectedFile(isFileValid ? files : null);

    if (isFileValid) {
      if (setFile) {
        setFile(files[0]);
      }
      const ext = fileExtension.toLowerCase();
      setShowDelimiter(ext === "csv" || ext === "txt");
      setUploadButton(false);
    }
  };

  const uploadFiles = async () => {
    setLoading(true);
    setUploadButton(true);
    const check = await APIServices.CheckConnectionUnique({
      key: connectionName,
      id: "",
    });
    if (connectionName.trim().length === 0) {
      setFormError(t("This field is required"));
      setLoading(false);
      setUploadButton(false);
      return;
    } else if (connectionName.trim().length < 4) {
      setFormError(t("Minimum length 4 char"));
      setLoading(false);
      setUploadButton(false);
      return;
    }
    if (check.data.data > 0) {
      setFormError("Data Source Name already existed");
      setLoading(false);
      setUploadButton(false);
      return;
    }
    setFormError("");

    const validTypes = [
      "application/vnd.ms-excel",
      // "application/json",
      ".csv",
      // ".json",
      // "text/xml",
      // "text/plain",
      "text/csv",
    ];
    let data = {
      file: selectedFile[0],
      delimiter: delimiter,
      connectionName: connectionName,
      firstRowisHeader: firstRowisHeader,
    };
    if (validTypes.indexOf(selectedFile[0]?.type) === -1) {
      data = {
        file: selectedFile[0],
        connectionName: connectionName,
        firstRowisHeader: firstRowisHeader,
      };
      setDelimiter(",");
    }
    try {
      const response = await APIServices.uploadFiles(data);

      const connectionList = connectionsInfo?.connections?.files;
      if (connectionList) {
        dispatch(addConnection({ type: "files", connection: response?.data?.row }));
      } else {
        const response = await APIServices.getConnectionsByType({ type: "files" });
        dispatch(addConnectionsList({ type: "files", connectionsList: response?.data }));
      }
      onSuccessCreate({ ...response?.data?.row });
      onClose();
      setSnack({
        message: "File uploaded successfully",
        open: true,
        colour: "success",
      });
    } catch (e) {
      setUploadButton(false);
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
    setLoading(false);
  };

  const changeDelimiter = (e) => {
    setDelimiter(e.target.value);
  };

  // const handleHeader = (e) => {
  //   setFirstRowisHeader(e.target.checked);
  // };

  const handleConnectionName = (e) => {
    const cleanedValue = e.target.value.replace(/\s{2,}/g, " ");
    setConnectionName(cleanedValue);

    if (cleanedValue.trim().length < 4) {
      setFormError(t("Minimum length 4 char"));
    } else {
      setFormError("");
    }
  };

  return (
    <Box
      sx={{ mb: 1 }}
      onDrop={(e) => handleFileDrop(e)}
      onDragOver={(e) => handleFileDragOver(e)}
      onDragEnter={(e) => handleFileDragEnter(e)}
      onDragLeave={(e) => handleFileDragLeave(e)}>
      <Grid container spacing={2} alignItems="center">
        <Grid size={7} sx={{ m: "auto" }} className="fileUpGrid1">
          <Grid container>
            <Grid size={12} mb="16px" mt="4px">
              <TextField
                value={connectionName}
                fullWidth
                size="small"
                label={t("Data Source Name")}
                onChange={handleConnectionName}
              />
              <Typography className="errorText">{formError}</Typography>
            </Grid>
            <Grid size={8}>
              <Box
                className="fileTextbox"
                onClick={() => (loader ? "" : fileInput.current.click())}
                title={selectedFile?.length ? selectedFile?.[0]?.name : "Choose a file"}>
                {selectedFile?.length ? selectedFile?.[0]?.name : t("choose_a_file")}
                {fileSuccess && <FileDownloadDoneOutlinedIcon className="sucIcon" />}
              </Box>
              {fileError && (
                <Typography className="errorText">
                  <ErrorOutlineOutlinedIcon />
                  {fileAccept
                    ? `${t("Allowed file types are")} ${fileAccept}`
                    : `${t("Allowed file types are")} ${"excel, csv, and delimiter text"}`}
                </Typography>
              )}
            </Grid>
            <Grid size={2}>
              <Button
                fullWidth
                size="small"
                sx={{ borderRadius: 0 }}
                onClick={() => fileInput.current.click()}
                variant="outlined"
                disabled={loader}>
                {t("Browse")}
              </Button>
              <input
                ref={fileInput}
                onChange={handleFileChange}
                type="file"
                onClick={(e) => (e.target.value = null)}
                style={{ display: "none" }}
                accept={fileAccept}
              />
            </Grid>
            {validationPage && (
              <Grid size={2} sx={{ pl: 1 }}>
                {previewFile ? (
                  <FilePreview file={selectedFile[0]} model={true} returnValue={(value) => setPreviewFile(value)} />
                ) : (
                  <></>
                )}
                <Button
                  onClick={() => setPreviewFile(true)}
                  disabled={!selectedFile}
                  sx={{ mr: 1, borderRadius: 0 }}
                  fullWidth
                  size="small"
                  variant="contained">
                  {t("Preview")}
                </Button>
              </Grid>
            )}
            <Grid size={2} sx={{ pl: 1 }}>
              <CustomLoadingButton disabled={uploadButton} loading={loader} fullWidth onClick={uploadFiles}>
                {t("upload")}
              </CustomLoadingButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={2} className="gridCusmd">
          {showDelimiter && (
            <TextField
              fullWidth
              select
              label={t("delimiter")}
              size="small"
              defaultValue=","
              onChange={(e) => changeDelimiter(e)}>
              <MenuItem key="comma" value=",">
                {t("Comma")}
              </MenuItem>
              <MenuItem key="colon" value=":">
                {t("colon")}
              </MenuItem>
              <MenuItem key="Equal Sign" value="=">
                {t("Equal Sign")}
              </MenuItem>
              <MenuItem key="semicolon" value=";">
                {t("Semicolon")}
              </MenuItem>
              <MenuItem key="Tab" value="\t">
                {t("Tab")}
              </MenuItem>
              <MenuItem key="Space" value=" ">
                {t("Space")}
              </MenuItem>
            </TextField>
          )}
        </Grid>
        {/* <Grid
         
          size={3}
          className="gridCusmd"
          sx={{ textAlign: "right" }}
        >
          <FormControlLabel
            control={
              <Checkbox checked={firstRowisHeader} onChange={handleHeader} />
            }
            label={t("First Row is Headers")}
            labelPlacement="start"
            size="small"
          />
        </Grid> */}
      </Grid>
      <Box style={wrapCss} sx={{ textAlign: "center" }}>
        <Typography sx={{ m: "auto" }}>
          <img alt="loading.." src={dragImg} width="200px" />
        </Typography>
      </Box>
    </Box>
  );
};

export default FileUpload;
