/* eslint-disable no-mixed-operators */
import { select, scaleBand, extent, scaleLinear, axisBottom, axisRight, axisLeft } from "d3";
import { useEffect, useRef } from "react";

const Heatmap = ({ data }) => {
  const svgRef = useRef(null);

  // Define dimensions and margins
  const margin = { top: 50, right: 20, bottom: 200, left: 200 };
  const width = 600 - margin.left - margin.right;
  const height = 600 - margin.top - margin.bottom;

  const draw = () => {
    const svg = select(svgRef.current);

    svg.selectAll("*").remove(); // Clear the SVG before drawing

    // Define x-axis and y-axis scales
    const xScale = scaleBand()
      .domain(data.map((d) => d.x))
      .range([0, width]);

    const yScale = scaleBand()
      .domain(data.map((d) => d.y))
      .range([0, height]);

    // Get min and max values of the data
    const [min, max] = extent(data.map((d) => d.value));

    if (min === undefined || max === undefined || min === null || max === null) {
      return null;
    }

    const colorScale = scaleLinear().domain([-1, 0, 1]).range(["#e6573e", "#fafafa", "#08528a"]);

    // Append x-axis
    svg
      .append("g")
      .attr("transform", `translate(${margin.left - 5}, ${height + margin.top + 5})`)
      .style("font-size", 13)
      .call(axisBottom(xScale).tickSize(0))
      .call((g) => g.select(".domain").remove())
      .selectAll("text")
      .attr("text-anchor", "end")
      .attr("transform", "rotate(-90)");

    // Append y-axis
    svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`)
      .style("font-size", 13)
      .call(axisLeft(yScale).tickSize(0))
      .select(".domain")
      .remove();

    // Append heatmap cells
    svg
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`)
      .selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .attr("x", (d) => xScale(d.x))
      .attr("y", (d) => yScale(d.y))
      .attr("height", yScale.bandwidth())
      .transition()
      .attr("width", xScale.bandwidth())
      .style("fill", (d) => colorScale(d.value));

    // Create the legend
    const legendWidth = 25;
    const legendHeight = height - 20;

    const legendScale = scaleLinear().domain([min, max]).range([legendHeight, 0]);

    const legendAxis = axisRight(legendScale);

    svg
      .append("g")
      .attr("class", "legend-axis")
      .attr("transform", `translate(${width + margin.left + 65}, ${margin.top + 10})`)
      .style("font-size", 12)
      .call(legendAxis)
      .call((g) => g.select(".domain").remove());

    // Append a colored rectangle for each value in the legend
    const legendColors = scaleLinear().domain([-1, 0, 1]).range(["#e6573e", "#fafafa", "#08528a"]);

    svg
      .selectAll(".legend-rect")
      .data(Array.from(Array(31).keys()))
      .enter()
      .append("rect")
      .attr("class", "legend-rect")
      .attr("x", width + margin.left + 40)
      .attr("y", (d) => margin.top + (d * legendHeight) / 30)
      .attr("height", legendHeight / 30)
      .attr("width", legendWidth)
      .style("fill", (d) => legendColors(max - (d / 30) * (max - min)));
    return null;
  };

  useEffect(() => {
    draw();
  }, [data]);

  return <svg ref={svgRef} width={600 + margin.left + margin.right} height={600} />;
};

export default Heatmap;
