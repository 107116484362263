import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, CircularProgress, FormControl, FormLabel, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultipleDropDownData({
  loader = false,
  disabled,
  heading,
  placeholder,
  optionsList,
  Cols,
  setCols,
  selectionsCount = null,
  error = false,
}) {
  return (
    <Grid size={12}>
      <Grid size={12}>
        <FormControl>
          <FormLabel>{heading}</FormLabel>
        </FormControl>
      </Grid>
      <Grid size={12}>
        <Autocomplete
          loading={loader}
          disabled={disabled}
          multiple
          disablePortal
          id="combo-box-demo"
          options={optionsList}
          disableClearable={!Cols}
          disableCloseOnSelect
          value={Cols}
          getOptionLabel={(option) => {
            if (option?.label) {
              return option?.label;
            }
            return option;
          }}
          onChange={(event, newValue, reason) => {
            if (reason === "selectOption") {
              setCols(newValue);
            } else if (reason === "removeOption") {
              setCols(newValue);
            } else if (reason === "clear") {
              setCols([]);
            }
          }}
          groupBy={(option) => option.table_type}
          getOptionDisabled={(option) =>
            selectionsCount ? Cols.length >= selectionsCount && !Cols.includes(option) : false
          }
          renderOption={(props, option, { selected }) => (
            <li {...props} style={{ padding: "0px" }}>
              <Checkbox
                size="small"
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option?.label ? (
                <span>
                  <b style={{ fontSize: "12px" }}>{option.label}:</b> {option.Note}
                </span>
              ) : (
                option
              )}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              size="small"
              placeholder={placeholder}
              error={error}
              sx={{
                "& .MuiChip-root": {
                  my: "1px",
                  fontSize: "12px",
                  height: "18px",
                  "& .MuiChip-deleteIcon": {
                    fontSize: "10px",
                  },
                },
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loader && <CircularProgress color="inherit" size={20} sx={{ mr: "25px" }} />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
