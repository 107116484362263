/* eslint-disable no-nested-ternary */
import RectangleRoundedIcon from "@mui/icons-material/RectangleRounded";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import moment from "moment";
import { formatDate, formatNumber, getDaysDiff } from "../../_helpers/utils";
import PieChart from "./PieChart";

const MetricBox = ({ title, total, used, loading, value, expiry }) => {
  const renderExpiry = expiry && <Typography color="success.main">{`${expiry} Left.`}</Typography>;

  const renderUsedTotal = (
    <Box sx={{ mt: 1 }}>
      <Typography color="#e68200" component="span">
        {formatNumber(used)}
      </Typography>
      {" / "}
      <Typography color="#009b00" component="span">
        {formatNumber(total)}
      </Typography>
    </Box>
  );
  const checkpercentage = () => {
    const percentage = (used / total) * 100;
    if (percentage >= 1) {
      return used;
    }
    return (1 / 100) * total;
  };

  const parsedDate = moment(value, "Do MMM YYYY");
  const daysDifference = parsedDate.diff(moment(), "days");
  return (
    <Box className="DQ v-center">
      <Box>
        <Typography lineHeight={1} variant="h6">
          {title}
        </Typography>
        {!expiry && <Typography variant="body">({value})</Typography>}
        <Typography>{loading ? <CircularProgress size={16} /> : expiry ? renderExpiry : renderUsedTotal}</Typography>
        {expiry && !loading && (
          <Typography variant="bold" color="primary">
            {value}
          </Typography>
        )}
      </Box>

      {!loading && expiry ? (
        <PieChart usedData={365 - Number(daysDifference)} totalData={365} />
      ) : (
        <PieChart usedData={checkpercentage()} totalData={total} />
      )}
    </Box>
  );
};

const DashboardMetrics = ({ metricsData, metricsDataLoading }) => {
  const calculatePercentage = (total, used) => {
    const percentage = (used / total) * 100;
    return percentage.toFixed(4);
  };
  return (
    <Grid container className="overallDQ container">
      <Grid size={12}>
        <Box className="DQhead v-center">
          <Typography variant="h6">Metrics</Typography>
          <Box className="v-center" gap={1} ml="auto">
            <Box className="v-center">
              <IconButton size="small" variant="contained" sx={{ color: "#e68200", boxShadow: "none" }}>
                <RectangleRoundedIcon />
              </IconButton>
              Used
            </Box>
            <Box className="v-center">
              <IconButton size="small" variant="contained" sx={{ color: "#009b00", boxShadow: "none" }}>
                <RectangleRoundedIcon />
              </IconButton>
              Available
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid size={3}>
        <MetricBox
          title="CUP"
          value="Cleansing Units Processed"
          total={metricsData?.Quota_CUP}
          used={metricsData?.CUP}
          loading={metricsDataLoading}
          percentage={calculatePercentage(metricsData?.Quota_CUP, metricsData?.CUP)}
        />
      </Grid>

      <Grid size={3}>
        <MetricBox
          title="QCT Comparison"
          value="Quality Check Transaction"
          total={metricsData?.Quota_QCT_Comparison}
          used={metricsData?.QCT_Comparison}
          loading={metricsDataLoading}
          percentage={calculatePercentage(metricsData?.Quota_QCT_Comparison, metricsData?.QCT_Comparison)}
        />
      </Grid>
      <Grid size={3}>
        <MetricBox
          title="QCT Validation"
          value="Quality Check Transaction"
          total={metricsData?.Quota_QCT_Validation}
          used={metricsData?.QCT_Validation}
          loading={metricsDataLoading}
          percentage={calculatePercentage(metricsData?.Quota_QCT_Validation, metricsData?.QCT_Validation)}
        />
      </Grid>
      <Grid size={3}>
        <MetricBox
          title="Expiry Date"
          value={formatDate(metricsData?.ExpiryDate)}
          loading={metricsDataLoading}
          expiry={getDaysDiff(metricsData?.ExpiryDate)}
        />
      </Grid>
    </Grid>
  );
};

export default DashboardMetrics;
