import { combineReducers } from "redux";
import toastSlice from "./toastSlice";
import authSlice from "./authSlice";
import usersSlice from "./usersSlice";
import connectionsSlice from "./connectionSlice";
import rowComparisonSlice from "./rowComparisonSlice";
import { CLEAR_STATE } from "../types";

const appReducer = combineReducers({
  toast: toastSlice,
  auth: authSlice,
  connectionData: connectionsSlice,
  users: usersSlice,
  rowComparison: rowComparisonSlice,
});

const rootReducer = (state, action) => {
  if (action.type === CLEAR_STATE) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
