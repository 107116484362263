import { useEffect, useRef, useState } from "react";

const SVGExtractor = ({ xmlString }) => {
  const svgRef = useRef();
  const [svgWidth, setSvgWidth] = useState({});

  useEffect(() => {
    if (!xmlString) {
      return;
    }

    // Parse the XML string to a DOM document
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");

    // Find the SVG element in the document
    const svgElement = xmlDoc.getElementsByTagName("svg")[0];

    // Append the SVG element to the ref element
    svgRef.current.appendChild(svgElement.cloneNode(true));
    setSvgWidth(svgElement.width);
  }, [xmlString]);

  return (
    <div className={svgWidth?.baseVal?.value > 1200 ? "SvgContainer" : "CommonSvgContainer"}>
      <div ref={svgRef} />
    </div>
  );
};

export default SVGExtractor;
