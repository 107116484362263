import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import JoinInnerOutlinedIcon from "@mui/icons-material/JoinInnerOutlined";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import ApiService from "../../../services/app.service";
import SkeletonLoader from "../../SkeletonLoader";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../../App";
import { isDuplicates } from "../../../_helpers/Constant";

const ComparisionResultTypeCard = ({ title, value, loading, icon, onClick }) => (
  <Grid size="grow" className="comparisonResultType" onClick={onClick}>
    <Paper sx={{ height: "100%" }} className="tile">
      {icon}
      <Typography>
        {title} <br />
        <span>{value}</span>
      </Typography>
      {loading && (
        <IconButton aria-label="loading" size="small">
          <CircularProgress size={20} color="primary" />
        </IconButton>
      )}
      <IconButton size="small">
        <ChevronRightOutlinedIcon />
      </IconButton>
    </Paper>
  </Grid>
);
export default function ComparativeValidationResult({ item, loadingType = {}, getTableData }) {
  const { setSnack } = useContext(SnackbarContext);

  const { t } = useTranslation();
  const [comparisonResults, setComparisonResults] = useState([]);
  const [loadRecord, setLoadRecord] = useState(false);

  const getChecks = async () => {
    setLoadRecord(true);
    try {
      const response = await ApiService.ValidationChecksByResultId({ validationResultId: item?._id });
      setComparisonResults(response.data.checks);
      setLoadRecord(false);
    } catch (e) {
      setLoadRecord(false);
      setSnack({ message: e?.response?.data?.message ?? e.message, open: true, colour: "error" });
    }
  };

  useEffect(() => {
    getChecks();
  }, [item?._id]);

  return (
    <TableCell sx={{ bgcolor: "#eee" }} className="comparativeValRes" colSpan="6">
      {loadRecord && <SkeletonLoader />}
      {comparisonResults?.map((itemVal, index) => {
        const indexKey = index;
        return (
          <Box key={indexKey}>
            <Box sx={{ p: 1 }}>
              <Grid container spacing={1}>
                <ComparisionResultTypeCard
                  icon={<JoinInnerOutlinedIcon />}
                  title={t("Matched In First Source Count")}
                  value={itemVal?.RowComparisonResult?.MatchedFirstDatasourceCount}
                  loading={loadingType?.type === "MatchedInFirstSource" && loadingType?.id === itemVal?._id}
                  onClick={() => getTableData(itemVal, "MatchedInFirstSource", item)}
                />

                <ComparisionResultTypeCard
                  icon={<JoinInnerOutlinedIcon />}
                  title={t("Matched In Second Source Count")}
                  value={itemVal?.RowComparisonResult?.MatchedSecondDatasourceCount}
                  loading={loadingType?.type === "MatchedInSecondSource" && loadingType?.id === itemVal?._id}
                  onClick={() => getTableData(itemVal, "MatchedInSecondSource", item)}
                />

                <ComparisionResultTypeCard
                  icon={<ArticleOutlinedIcon />}
                  title={t("Only in 1st Source")}
                  value={itemVal?.RowComparisonResult?.OnlyInFirstDatasourceCount}
                  loading={loadingType?.type === "OnlyInFirstDatasource" && loadingType?.id === itemVal?._id}
                  onClick={() => getTableData(itemVal, "OnlyInFirstDatasource", item)}
                />

                <ComparisionResultTypeCard
                  icon={<ArticleOutlinedIcon />}
                  title={t("Only in 2nd Source")}
                  value={itemVal?.RowComparisonResult?.OnlyInSecondDatasourceCount}
                  loading={loadingType?.type === "OnlyInSecondDatasource" && loadingType?.id === itemVal?._id}
                  onClick={() => getTableData(itemVal, "OnlyInSecondDatasource", item)}
                />

                {isDuplicates && (
                  <>
                    <ComparisionResultTypeCard
                      icon={<ContentCopyOutlinedIcon />}
                      title={t("Duplicate in 1st Source")}
                      value={itemVal?.RowComparisonResult?.DuplicatesOfFirstDatasourceCount}
                      loading={loadingType?.type === "DuplicatesOfFirstDatasource" && loadingType?.id === itemVal?._id}
                      onClick={() => getTableData(itemVal, "DuplicatesOfFirstDatasource", item)}
                    />
                    <ComparisionResultTypeCard
                      icon={<ContentCopyOutlinedIcon />}
                      title={t("Duplicate in 2nd Source")}
                      value={itemVal?.RowComparisonResult?.DuplicatesOfSecondDatasourceCount}
                      loading={loadingType?.type === "DuplicatesOfSecondDatasource" && loadingType?.id === itemVal?._id}
                      onClick={() => getTableData(itemVal, "DuplicatesOfSecondDatasource", item)}
                    />
                  </>
                )}
              </Grid>
            </Box>
          </Box>
        );
      })}
    </TableCell>
  );
}
