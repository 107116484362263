import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthBaseURLENV } from "../../_helpers/Constant";
import { showToast } from "../reducers/toastSlice";
import { decryptData, encryptData } from "../../helpers/encryptionUtils";

const fetchExistingUsers = createAsyncThunk(
  "users/fetchExistingUsers",
  async (userDetails, { rejectWithValue, dispatch }) => {
    try {
      const encryptedPayload = await encryptData(JSON.stringify(userDetails));

      const response = await axios.post(`${AuthBaseURLENV}/api/User/v1/AllUsers`, encryptedPayload);

      const decryptedResponse = await decryptData(response?.data);

      return decryptedResponse?.ResponseObject;
    } catch (error) {
      // Handle and display error using toast
      const errorMessage = error.response?.data?.message || error.message;
      dispatch(showToast({ message: errorMessage, type: "error" }));
      return rejectWithValue(errorMessage);
    }
  },
);

const createDQGUser = async (data) => {
  try {
    const enData = await encryptData(JSON.stringify(data));
    const res = await axios.post(`${AuthBaseURLENV}/api/User/Create`, enData);
    return await decryptData(res?.data);
  } catch (e) {
    const errorData = await decryptData(e.response?.data);
    throw new Error(errorData?.Message);
  }
};

const getUserRoles = async () => {
  const rolesRes = await axios.get(`${AuthBaseURLENV}/api/Role`);
  return await decryptData(rolesRes?.data);
};

const getDQGUserDetails = (id) => {
  return axios.get(`${AuthBaseURLENV}/api/User/v1/${id}`);
};

const getFilteredUsers = async (searchString) => {
  const enData = await encryptData(JSON.stringify(searchString));
  const usersRes = await axios.post(`${AuthBaseURLENV}/api/ADFS/SearchADUsers`, enData);
  const deData = await decryptData(usersRes?.data);
  return deData?.users;
};

const deleteDQGUser = async (data) => {
  const res = await axios.post(`${AuthBaseURLENV}/api/User/v1/DeleteUser`, data);
  return await decryptData(res?.data);
};

const updateDQGUser = async (data) => {
  const enData = await encryptData(JSON.stringify(data));
  const res = await axios.put(`${AuthBaseURLENV}/api/User/Update`, enData);
  return await decryptData(res?.data);
};

const getDQGUserLogs = async (data) => {
  const enData = await encryptData(JSON.stringify(data));
  const userLogsRes = await axios.post(`${AuthBaseURLENV}/api/UserActivity`, enData);
  return decryptData(userLogsRes?.data);
};

export const usersService = {
  fetchExistingUsers,
  createDQGUser,
  getUserRoles,
  getDQGUserDetails,
  getFilteredUsers,
  deleteDQGUser,
  updateDQGUser,
  getDQGUserLogs,
};
