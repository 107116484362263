import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import CustomSwitchButton from "../../CustomComponents/CustomSwitchButton";
import { useSelector } from "react-redux";
import { SnackbarContext } from "../../../App";
import CustomLoadingButton from "../../CustomComponents/CustomsButtons/CustomLoadingButton";

const CreateModel = ({ defaultRuleName, createNew, createLoading, onClickCancel, createValidation }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [ruleNameInput, setRuleNameInput] = useState(defaultRuleName);
  const [searchParams, setSearchParams] = useSearchParams();

  const isPrivate = JSON.parse(searchParams.get("isPrivate"));
  const isUnMatched = JSON.parse(searchParams.get("isUnMatched")) || false;

  const handleSwitchChange = (e) => {
    setSearchParams((params) => {
      params.set("isPrivate", e.target.checked);
      return params;
    });
  };

  const handleChangeMatchSwitch = (e) => {
    setSearchParams((params) => {
      params.set("isUnMatched", e.target.checked);
      return params;
    });
  };

  const isDisableRuleName = !(ruleNameInput?.length >= 4) || createLoading;
  const isComparison = location?.pathname?.includes("DataQualityRule/row-comparison");
  const showIsPrivate = !defaultRuleName || createNew;
  return (
    <Dialog fullWidth={true} maxWidth={"xs"} open={true} onClose={() => onClickCancel()}>
      <DialogTitle sx={{ p: 1.5, py: showIsPrivate ? 0.5 : 1, borderBottom: "1px solid #ccc" }}>
        <Box className="space-between">
          {`${t("Provide")} ${t("Data Quality Rule Name")}`}
          {showIsPrivate && (
            <Box sx={{ pl: 2, float: "left" }}>
              <CustomSwitchButton
                labels={[t("public"), t("private")]}
                checked={isPrivate}
                onChange={handleSwitchChange}
              />
            </Box>
          )}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ p: 1.5, pt: "12px !important" }}>
        <TextField
          fullWidth
          autoFocus
          margin="dense"
          required
          label={t("DQ Rule Name")}
          placeholder={t("Data Quality Rule Name")}
          value={ruleNameInput}
          onChange={(e) => setRuleNameInput(e.target.value)}
        />

        {isComparison && (
          <Box sx={{ float: "right", display: "none" }}>
            <CustomSwitchButton
              labels={[t("Matched In Target"), t("UnMatched In Target")]}
              checked={isUnMatched}
              onChange={handleChangeMatchSwitch}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ px: 2, justifyContent: !defaultRuleName || createNew ? "space-between" : "flex-end" }}>
        <Button color="error" size="small" variant="outlined" onClick={() => onClickCancel()}>
          {t("Cancel")}
        </Button>
        {(!defaultRuleName || createNew) && (
          <CustomLoadingButton
            disabled={isDisableRuleName}
            onClick={() => createValidation(ruleNameInput, createNew)}
            loading={createLoading}>
            {t("Create")}
          </CustomLoadingButton>
        )}
        {defaultRuleName && !createNew && (
          <CustomLoadingButton
            disabled={ruleNameInput?.length < 5 || createLoading}
            onClick={() => createValidation(ruleNameInput)}
            loading={createLoading}>
            {t("Update")}
          </CustomLoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

const CreateOrUpdateDQRule = ({ createLoading, validateLoading, createValidation }) => {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);

  const [openModal, setOpenModal] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [searchParams] = useSearchParams();

  const columnsToValidate = useSelector((state) => state.rowComparison.columnsToValidate);

  const defaultRuleName = searchParams.get("ruleName");
  const isExistsExecutions = JSON.parse(searchParams.get("isExistsExecutions"));

  const handleCreateNew = () => {
    if (columnsToValidate?.source1?.length !== columnsToValidate?.source2?.length) {
      const msg = "Column mapping has unmapped columns. Please fix to create validation.";
      setSnack({ message: msg, open: true, colour: "warning" });
      return;
    }

    setCreateNew(true);
    setOpenModal(true);
  };

  const handleUpdate = () => {
    if (columnsToValidate?.source1?.length !== columnsToValidate?.source2?.length) {
      const msg = "Column mapping has unmapped columns. Please fix to create validation.";
      setSnack({ message: msg, open: true, colour: "warning" });
      return;
    }

    setOpenModal(true);
  };

  const onClickCancel = () => {
    setCreateNew(false);
    setOpenModal(false);
  };

  return (
    <React.Fragment>
      {!isExistsExecutions && (
        <Button
          onClick={handleUpdate}
          size="small"
          sx={{ mr: 1 }}
          variant="contained"
          disabled={validateLoading || !columnsToValidate?.source1?.length}
          className="loader-button">
          {t(defaultRuleName ? "Update" : "Create DQ Rule")}
        </Button>
      )}
      {defaultRuleName && (
        <Button
          className="loader-button"
          onClick={handleCreateNew}
          size="small"
          variant="contained"
          disabled={validateLoading || !columnsToValidate?.source1?.length}>
          {t("Create As New DQ Rule")}
        </Button>
      )}

      {openModal && (
        <CreateModel
          defaultRuleName={defaultRuleName}
          createNew={createNew}
          createLoading={createLoading}
          onClickCancel={onClickCancel}
          createValidation={createValidation}
        />
      )}
    </React.Fragment>
  );
};

export default CreateOrUpdateDQRule;
