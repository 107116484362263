import {
  Box,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useTranslation } from "react-i18next";
const TotalChecksPopup = ({ anchorE1, setAnchorE1, popupData }) => {
  const { t } = useTranslation();
  const id = anchorE1 ? "simple-popover1" : undefined;
  const open = Boolean(anchorE1);
  const onMouseLeave = (e) => {
    e.stopPropagation();
    setAnchorE1(null);
  };
  return (
    <Popover
      id={id}
      anchorEl={anchorE1}
      onClose={(e) => {
        onMouseLeave(e);
      }}
      open={open}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}>
      <Box
        sx={{ p: 1 }}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <IconButton
          onClick={(e) => {
            onMouseLeave(e);
          }}
          color="error"
          aria-label="close popover"
          sx={{
            position: "absolute",
            right: -1,
            top: -1,
            background: "#fff",
            p: "2px",
            ":hover": { background: "#fff" },
          }}>
          <CancelOutlinedIcon />
        </IconButton>
        <TableContainer
          onClick={(e) => {
            e.stopPropagation();
          }}
          sx={{ maxWidth: 650, maxHeight: 300 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t("Column Name")}</TableCell>
                <TableCell>{t("DQ Checks")}</TableCell>
                <TableCell>{t("Validate Checks")}</TableCell>
                <TableCell>{t("Passed")} </TableCell>
                <TableCell>{t("Failed")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {popupData?.Validations?.map((itemRow, ki) => {
                return (
                  <TableRow key={ki}>
                    <TableCell>{itemRow?.ColumnName}</TableCell>
                    <TableCell>{itemRow?.CombinedDQCheckName || itemRow?.ValidationDisplayName}</TableCell>
                    <TableCell>{itemRow?.CombinedDQChecks?.length || popupData?.Validations?.length}</TableCell>
                    <TableCell>{itemRow?.Result?.TotalPassed}</TableCell>
                    <TableCell>{itemRow?.Result?.TotalFailed}</TableCell>
                  </TableRow>
                );
              })}

              {popupData?.Validations?.length === 0 && (
                <TableRow>
                  <TableCell sx={{ textAlign: "center" }}>{t("No records to display")}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Popover>
  );
};

export default TotalChecksPopup;
