import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useState, useEffect, useRef } from "react";
import Plot from "react-plotly.js";
import useResizeObserver from "./Barchart/useResizeObserver";

const config = {
  displaylogo: false,
};

const layout = {
  autosize: true,
  margin: {
    l: 30,
    r: 30,
    b: 30,
    t: 30,
    pad: 4,
  },
};

const PlotlyReport = ({ data }) => {
  const wrapperRef = useRef();

  const dimensions = useResizeObserver(wrapperRef);
  const [{ width, height }, setDimensions] = useState({});
  const [, setHoverData] = useState(null);

  useEffect(() => {
    const { width1, height1 } = dimensions || wrapperRef.current.getBoundingClientRect();
    setDimensions({ width1, height1 });
  }, [dimensions]);

  const handleHover = (eventData) => {
    if (eventData.points.length > 0) {
      // Extract hover data from eventData
      const hoverInfo = eventData.points[0];
      const updatedHoverTemplate = hoverInfo.data.hovertemplate.replaceAll("<br>", "\n");
      // Update hovertemplate in the hoverInfo object
      hoverInfo.data.hovertemplate = updatedHoverTemplate;
      setHoverData(hoverInfo);
    } else {
      setHoverData(null);
    }
  };

  return (
    <Grid container>
      <Grid size={12} sx={{ p: 0, display: "grid" }}>
        <Box className="dataProfileHeader">
          <Typography variant="h6" marginBottom="0" gutterBottom component="div" padding={1}>
            {data?.data[0]?.type.includes("scatter") && "SCATTER PLOT"}
            {data?.data[0]?.type.includes("pie") && "PIE CHART"}
            {data?.data[0]?.type.includes("box") && "BOX PLOT"}
            {data?.data[0]?.type.includes("histogram") && "HISTOGRAM"}
            {data?.data[0]?.type.includes("bar") && !data?.frames && "BAR CHART"}
            {data?.data[0]?.type.includes("bar") && data?.frames && "MOVABLE BAR GRAPH"}
          </Typography>
        </Box>
      </Grid>

      <Grid
        size={12}
        sx={{
          p: 0,
          display: "grid",
          ...(data?.data[0]?.type.includes("bar") &&
            data?.frames && {
              "& .menulayer": { display: "none" },
              ".modebar-btn[data-val='select']": { display: "none" },
              ".modebar-btn[data-val='lasso']": { display: "none" },
            }),
        }}
        ref={wrapperRef}>
        <Plot
          config={config}
          data={data?.data}
          frames={data?.frames}
          layout={{
            ...data?.layout,
            ...layout,
            width: width,
            height: height,
          }}
          style={{ width: "100%", height: "100vh" }}
          onHover={handleHover}
        />
      </Grid>
    </Grid>
  );
};

export default PlotlyReport;
