import { Box, Drawer, Button, Tooltip, Typography } from "@mui/material";
import { useRef } from "react";
import { CustomAgGrid } from "../AgGrid";

export default function CustomDrawer({ state, setState, drawerData, sx = {}, headCells }) {
  const gridRef = useRef();

  return (
    <Drawer sx={sx} key={1} anchor={"right"} open={state}>
      <Box sx={{ width: 500 }}>
        <Box className="containerHead">
          <Typography variant="h6">
            {drawerData.key}: {drawerData.value}
          </Typography>
        </Box>
        <Box p={2}>
          <CustomAgGrid gridRef={gridRef} headCells={headCells} rows={drawerData.details} />
        </Box>

        <Box px={2}>
          <Tooltip title="Cancel" placement="top-end" arrow>
            <Button variant="outlined" color="error" onClick={() => setState(false)} size="small">
              Cancel
            </Button>
          </Tooltip>
        </Box>
      </Box>
    </Drawer>
  );
}
