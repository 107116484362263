import { Box, Button, Typography, LinearProgress, Paper } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { dateRangeObj, getTimeDuration } from "../../../../_helpers/utils";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const LinearProgressWithLabel = ({ value }) => (
  <Box sx={{ width: "100%", position: "relative" }}>
    <LinearProgress variant="determinate" value={value || 0} />
    <Typography className="percentage" variant="body2" color="text.secondary">
      {`${Math.round(value || 0)}%`}
    </Typography>
  </Box>
);

const ExecutionStatus = ({ row, setAbortModalData, handleToggleExecutions }) => {
  const { t } = useTranslation();

  const [, setSearchParams] = useSearchParams();

  const onClickViewResult = (rowId) => {
    setSearchParams({
      ruleId: rowId,
      sortBy: "DESC",
      currentPage: 1,
      viewType: "grid",
      startDate: dateRangeObj.startDate,
      endDate: dateRangeObj.endDate,
    });
    handleToggleExecutions();
  };

  const getStatusButton = {
    1: (
      <Button onClick={() => setAbortModalData(row)} variant="outlined" size="small" color="warning">
        {t("Abort")}
      </Button>
    ),
    2: (
      <Button onClick={() => onClickViewResult(row?.testValidationId)} variant="outlined" size="small" color="success">
        {t("View Result")}.
      </Button>
    ),
    3: <Typography>{t("Aborted")}</Typography>,
    4: <Typography>{t("Suspended")}</Typography>,
  };

  return (
    <Grid size="grow" ml="auto" textAlign="right">
      {row?.status !== 1 && getStatusButton[row?.status]}
    </Grid>
  );
};
const statusClassMap = { 2: "completed", 3: "aborted" };

const Progress = ({ totalbatches, completed = 0, row, setAbortModalData, handleToggleExecutions }) => {
  const { t } = useTranslation();
  const { validationDetails, ExecutedBy, finishDateTime } = row || {};
  const rowStatus = row?.status;
  const statusClassName = statusClassMap[rowStatus] || "";
  const percentage = (row?.completedBatches / row?.totalBatchCount) * 100 || 0;

  return (
    <Paper className={`exList ${statusClassName}`}>
      <Box sx={{ width: "100%" }}>
        <Grid container alignItems="center" mb="8px">
          <Grid size="grow">
            <Typography>
              <b>{validationDetails?.TestName}</b> | {t("User")} : <b>{ExecutedBy}</b>
            </Typography>
          </Grid>
          <ExecutionStatus
            row={row}
            setAbortModalData={setAbortModalData}
            handleToggleExecutions={handleToggleExecutions}
          />
        </Grid>
        <LinearProgressWithLabel value={percentage} />
        <Grid container alignItems="center" mt="8px">
          <Grid size="grow">
            <Typography>
              {t("Batches Completed")} :{" "}
              <Box component="span" color="#1565c0" fontWeight="600">
                {completed} {t("of")}{" "}
                <Box component="span" color="#ed6c02" fontWeight="600">
                  {totalbatches}
                </Box>
              </Box>
            </Typography>
          </Grid>
          <Grid size="grow" ml="auto" textAlign="right">
            <Typography color="#ccc">
              {getTimeDuration(finishDateTime, new Date(), t)} {t("ago")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default Progress;
