import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

export default function ExpandableTableRow({
  disable,
  autoScroll = null,
  children,
  showData,
  type,
  expandComponent,
  ...otherProps
}) {
  const [isExpanded, setIsExpanded] = useState(type);

  const onClickFailedRecords = () => {
    setIsExpanded(!isExpanded);
    autoScroll?.();
  };

  return (
    <React.Fragment>
      <TableRow {...otherProps}>
        {children}
        <TableCell align="center">
          <Box sx={{ gap: 2, display: "block" }}>
            {showData}
            <IconButton
              variant="contained"
              color="primary"
              onClick={() => onClickFailedRecords()}
              size="small"
              className="accordionArrow"
              disabled={disable}>
              {isExpanded ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      {isExpanded && <TableRow>{expandComponent}</TableRow>}
    </React.Fragment>
  );
}
