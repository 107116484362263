/* eslint-disable no-nested-ternary */
import { useState } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CreateConnection from "../CreateDS/CreateConnection";
import { customComparator } from "../../AgGrid/CustomSort";
import { isAzure } from "../../../_helpers/Constant";

// Component for rendering connection title with click handler
function AgGridTitle({ data, context }) {
  const [state, setState] = useState(false);
  const connectionData = data;

  const openDrawer = () => setState(true);
  const closeDrawer = () => setState(false);

  const onSuccessCreate = (newData) => {
    const { setRows, setType, setSelected, refreshData } = context;
    setRows?.((prevState) => [newData, ...prevState]);
    setType?.("All");
    setSelected([]);
    refreshData();
  };

  return (
    <>
      <Box className="v-center">
        <Typography onClick={openDrawer} sx={{ color: "#0e71b7", cursor: "pointer" }}>
          {data.connectionName}
        </Typography>

        {data?.UsedInTests?.length > 0 && (
          <Tooltip
            arrow
            title={
              <Box sx={{ overflowY: "auto", maxHeight: 200 }}>
                <Typography sx={{ fontSize: "10px" }}>Used In These Rules : </Typography>
                <Box className="flex column" gap={1}>
                  {data?.UsedInTests?.map((each, i) => (
                    <Box key={i} className="flex" sx={{ gap: 0.5 }}>
                      {i + 1}
                      <Box>
                        <Typography sx={{ fontSize: "10px" }}>Rule Name : {each.TestName}</Typography>
                        <Typography sx={{ fontSize: "10px" }}>User Email : {each.UserEmail}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            }>
            <IconButton size="small">
              <InfoOutlinedIcon sx={{ fontSize: "16px !important" }} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {state && (
        <CreateConnection
          type={"View"}
          defaultConType={connectionData?.connectionType}
          connectionData={connectionData}
          handleSuccessCreate={onSuccessCreate}
          onClose={closeDrawer}
        />
      )}
    </>
  );
}

// Helper function to determine if user is authorized to select a row
// const canSelectRow = (params) => {
//   const userDetails = JSON.parse(localStorage.getItem(userLocalSession));
//   return (
//     params.data?.UsedInTests?.length === 0 &&
//     (params?.data?.UserId === userDetails?.UserId || userDetails?.RoleName === "Admin")
//   );
// };

// Helper function to get appropriate server field name based on the type
const getServerField = (type) => (type === "Databricks" ? "serverHostname" : "server");

// Helper function to get appropriate server header based on the type
const getServerHeader = (type) => (type === "Databricks" ? "Server Host Name" : "Server");

// Function to generate the columns based on the data source type
const getDSHeadCells = (type) => {
  const serverField = getServerField(type);
  const serverHeader = getServerHeader(type);

  const headCellsDSDetails = [
    {
      field: "connectionName",
      headerName: "Data Source Name",
      cellRenderer: AgGridTitle,
      sortable: true,
      suppressColumnsToolPanel: true,
      filter: "agTextColumnFilter",
      comparator: customComparator,
      flex: 1,
    },
    {
      field: "connectionType",
      headerName: "Database Type",
      sortable: true,
      filter: "agTextColumnFilter",
      comparator: customComparator,
      flex: 1,
    },
    {
      field: "UserEmail",
      headerName: "Created By",
      sortable: true,
      filter: "agTextColumnFilter",
      comparator: customComparator,
      flex: 1,
    },

    ...(type === "Databricks"
      ? [
          {
            field: "catalogName",
            headerName: "Catalog Name",
            sortable: true,
            filter: "agTextColumnFilter",
            comparator: customComparator,
            flex: 1,
          },
        ]
      : []),

    ...(!isAzure(type)
      ? [
          {
            field: serverField,
            headerName: serverHeader,
            sortable: true,
            filter: "agTextColumnFilter",
            comparator: customComparator,
            flex: 1,
          },
        ]
      : type === "AWS S3"
        ? [
            {
              field: "bucketName",
              headerName: "Bucket Name",
              sortable: true,
              filter: "agTextColumnFilter",
              flex: 1,
            },
          ]
        : type === "Azure Blob"
          ? [
              {
                field: "containerName",
                headerName: "Container Name",
                sortable: true,
                filter: "agTextColumnFilter",
                flex: 1,
              },
            ]
          : []),

    ...(type !== "Oracle"
      ? [
          {
            field: "dataBase",
            headerName: "Database",
            sortable: true,
            filter: "agTextColumnFilter",
            comparator: customComparator,
            flex: 1,
          },
        ]
      : []),
  ];

  return headCellsDSDetails;
};

export { getDSHeadCells };
