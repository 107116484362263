import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

export default function Output({ outPut }) {
  return (
    <Box>
      <Box className="innerSubHead">
        <Grid container alignItems="center">
          <Grid size={12} textAlign="center">
            <Typography variant="h6">Output</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Box className="chartOutput">
          <Grid container spacing={0.5}>
            {outPut?.Data?.Segments?.map((obj, index) => {
              return (
                <Grid size={12} key={index}>
                  <Box sx={{ borderLeftColor: `${obj.ColorCode} !important` }} className="CDAOutputBox">
                    <Grid container alignItems="center">
                      <Grid size={12}>
                        <Grid container>
                          <Grid size={6}>
                            <Typography>
                              Color Code: <b>{obj.ColorCode}</b>
                            </Typography>
                          </Grid>
                          <Grid size={6}>
                            <Box className="colCode" backgroundColor={obj.ColorCode}>
                              <Typography>{index + 1}</Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid size={12}>
                        <Box className="colCodeR">
                          <Typography>
                            Name of color: <b>{obj.ColorName}</b>
                          </Typography>

                          <Typography>
                            Relative Size:{" "}
                            <b>
                              {`${obj.RelativeSizeInPercent[0]}%`}
                              {" - "}
                              {`${obj.RelativeSizeInPercent[1]}%`}
                            </b>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
