import CryptoJS from "crypto-js";
import shajs from "sha.js";
import { authServices } from "../Redux/services/auth.services";

const DECRYPTION_KEY = "AecECroRUgnGTa";
const IV = CryptoJS.enc.Utf8.parse("globalaesvectors");

const generateKey = () => {
  const hashString = shajs("sha256").update(DECRYPTION_KEY).digest("hex");
  return CryptoJS.enc.Utf8.parse(hashString.substring(0, 32));
};

export const decryptData = (encryptedString) => {
  if (!encryptedString) {
    console.error(new Error("Encrypted string is undefined or null"));
    return null;
  }

  try {
    const key = generateKey();
    const formattedString = encryptedString.replace(/-/g, "+").replace(/_/g, "/");
    const decrypted = CryptoJS.AES.decrypt(formattedString, key, {
      keySize: 128 / 8,
      iv: IV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    const decryptedUtf8 = decrypted.toString(CryptoJS.enc.Utf8);

    // Check if the decrypted output is valid JSON
    try {
      return JSON.parse(decryptedUtf8);
    } catch (jsonError) {
      console.error(new Error("Decryption succeeded but JSON parsing failed:", jsonError));
      return null; // Return null if JSON parsing fails
    }
  } catch (error) {
    console.error(new Error("Error during decryption:", error));
    return null; // Return null if an error occurs during decryption
  }
};

export const encryptData = (inputString) => {
  if (!inputString) {
    console.error(new Error("Input string is undefined or null"));
    throw new Error("Input string cannot be null or undefined");
  }

  try {
    const key = generateKey();

    // Encrypt the string using AES
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(inputString), key, {
      keySize: 128 / 8,
      iv: IV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    // Convert the encrypted data to Base64 format
    let encryptedBase64 = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    encryptedBase64 = encryptedBase64.replace(/\+/g, "-").replace(/\//g, "_");

    return { encryptedData: encryptedBase64 };
  } catch (error) {
    console.error(new Error("Error during encryption:", error));
    throw error; // Rethrow the error after logging it
  }
};

const getSecretKey = async () => {
  return "AecECroRUgnGTa";
};

export const decryptMultipleData = async (enArray) => {
  const multiDeKey = await getSecretKey();
  try {
    const muitiResponse = await Promise.all(await enArray.map(async (each) => await decryptData(each, multiDeKey)));
    return muitiResponse;
  } catch {
    return [];
  }
};

export const fetchKeys = async () => {
  const data = await authServices.fetchDecryptedSecrets(["DQG-AppClientId", "DQG-AppTenantId"]);
  if (data.error) {
    return data.message;
  }
  const azureClientId = data?.find((item) => item.SecretKey === "DQG-AppClientId")?.SecretValue;
  const azureTenentId = data?.find((item) => item.SecretKey === "DQG-AppTenantId")?.SecretValue;
  return { azureClientId, azureTenentId };
};
