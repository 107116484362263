import { useState, useContext, useRef } from "react";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import FileDownloadDoneOutlinedIcon from "@mui/icons-material/FileDownloadDoneOutlined";
import { Box, Button, Divider, FormHelperText, MenuItem, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import ApiService from "../../services/app.service";
import dragImg from "../../images/Drag_Drop.png";
import { SnackbarContext } from "../../App";
import CustomLoadingButton from "../CustomComponents/CustomsButtons/CustomLoadingButton";

const MasterdataUploadForm = ({ fetchList, toggleDrawer }) => {
  const validTypes = [
    "application/vnd.ms-excel",
    ".csv",
    // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
  ];
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const fileInput = useRef();

  const [uploadButton, setUploadButton] = useState(true);
  const [loader, setLoading] = useState(false);
  const [masterdataName, setMasterdataName] = useState("");
  const [description, setDescription] = useState("");
  const [showDelimiter, setShowDelimiter] = useState(false);
  const [delimiter, setDelimiter] = useState(",");
  const [file, setFile] = useState([]);
  const [formError, setFormError] = useState("");
  const [fileError, setFileError] = useState(false);
  const [fileSuccess, setFileSuccess] = useState(false);

  const resetFileState = () => {
    setFileSuccess(false);
    setFileError(false);
    setFile([]);
    setFormError("");
  };

  const handleFile = (files) => {
    resetFileState();
    const originalName = files[0].name;
    const ext = originalName.split(".").pop().toLowerCase();
    if (ext === "csv") {
      setShowDelimiter(true);
    } else {
      setShowDelimiter(false);
    }
    setFileSuccess(true);
    setFile(files);
    setUploadButton(false);
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    if (loader) {
      return;
    }
    resetFileState();
    const files = e.dataTransfer.files;
    if (files.length === 0 || !isValidFileType(files[0].type)) {
      setFileError(true);
      setUploadButton(true);
      return;
    }
    handleFile(files);
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files.length === 0 || !isValidFileType(files[0].type)) {
      setFileError(true);
      setUploadButton(true);
      return;
    }
    handleFile(files);
  };

  const isValidFileType = (fileType) => {
    return validTypes.indexOf(fileType) !== -1;
  };

  const handleMasterdataName = (e) => {
    const value = e.target.value;
    setMasterdataName(value);
    if (value.trim().length < 4) {
      setFormError(t("Minimum length 4 char"));
    } else {
      setFormError("");
    }
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const changeDelimiter = (e) => {
    setDelimiter(e.target.value);
  };

  const Formdata = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return formData;
  };

  const uploadFiles = async () => {
    if (masterdataName.trim().length === 0) {
      setFormError("This field is required");
      return;
    } else if (masterdataName.trim().length < 4) {
      setFormError(t("Minimum length 4 char"));
      return;
    }
    setLoading(true);
    setUploadButton(true);
    const data = {
      file: file[0],
      delimiter: delimiter,
      name: masterdataName,
      description: description,
    };
    const formData = Formdata(data);
    try {
      await ApiService.MasterdataUpload(formData);
      resetFileState();
      setDelimiter(",");
      setShowDelimiter(false);
      setUploadButton(true);
      fetchList();
      setSnack({
        message: "File uploaded successfully",
        open: true,
        colour: "success",
      });
      toggleDrawer();
    } catch (error) {
      setUploadButton(false);
      setSnack({
        message: error?.response?.data?.name?.message
          ? "Master datasource Name already Exists"
          : error?.response?.data?.message,
        open: true,
        colour: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{ border: "1px dashed #096eb6", p: 1.5, mx: 2 }}
      onDrop={(e) => handleFileDrop(e)}
      onDragOver={(e) => e.preventDefault()}
      onDragEnter={(e) => e.preventDefault()}
      onDragLeave={(e) => e.preventDefault()}>
      <Grid container spacing={2} alignItems="center">
        <Grid size={7} sx={{ m: "auto" }} className="fileUpGrid1">
          <Grid container>
            <Grid size={12} mb="16px" mt="4px">
              <TextField
                fullWidth
                size="small"
                label={t("Masterdata Source Name")}
                required
                value={masterdataName}
                onChange={handleMasterdataName}
              />
              <Typography className="errorText">{formError}</Typography>
            </Grid>
            <Grid size={12} mb="16px" mt="4px">
              <TextField
                fullWidth
                size="small"
                label={t("Description")}
                minRows={2}
                maxRows={4}
                multiline
                value={description}
                onChange={handleDescription}
              />
            </Grid>
            <Grid size={8}>
              <Box
                className="fileTextbox"
                onClick={() => (loader ? "" : fileInput.current.click())}
                title={file?.length ? file?.[0]?.name : t("Choose a file")}>
                {file?.length ? file?.[0]?.name : t("Choose a file")}
                {fileSuccess && <FileDownloadDoneOutlinedIcon className="sucIcon" />}
              </Box>
              {fileError && (
                <Typography className="errorText">
                  <ErrorOutlineOutlinedIcon /> {t("Allowed file types are ")}
                  csv {t("only")}
                </Typography>
              )}
            </Grid>
            <Grid size={2}>
              <Button
                fullWidth
                size="small"
                sx={{ borderRadius: 0 }}
                onClick={() => fileInput.current.click()}
                variant="outlined"
                disabled={loader}>
                {t("Browse")}
              </Button>
              <input
                ref={fileInput}
                onChange={handleFileChange}
                type="file"
                onClick={(e) => (e.target.value = null)}
                style={{ display: "none" }}
                accept=".csv"
              />
            </Grid>
            <Grid size={2} sx={{ pl: 1, "& .loader-button": { py: 0.55 } }}>
              <CustomLoadingButton disabled={uploadButton} fullWidth onClick={uploadFiles} loading={loader}>
                {t("Upload")}
              </CustomLoadingButton>
            </Grid>
            <Grid size={12} mb="16px" mt="4px">
              <FormHelperText>
                {t("Note")} : {t("note8")}
              </FormHelperText>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={2} className="gridCusmd">
          {showDelimiter && (
            <TextField fullWidth select label={t("Delimiter")} size="small" defaultValue="," onChange={changeDelimiter}>
              <MenuItem key="comma" value=",">
                {t("Comma")}
              </MenuItem>
              <MenuItem key="colon" value=":">
                {t("Colon")}
              </MenuItem>
              <MenuItem key="Equal Sign" value="=">
                {t("Equal Sign")}
              </MenuItem>
              <MenuItem key="semicolon" value=";">
                {t("Semicolon")}
              </MenuItem>
              <MenuItem key="Tab" value="\t">
                {t("Tab")}
              </MenuItem>
              <MenuItem key="Space" value=" ">
                {t("Space")}
              </MenuItem>
            </TextField>
          )}
        </Grid>
      </Grid>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ textAlign: "center" }}>
        <Typography sx={{ m: "auto" }}>
          <img alt="loading.." src={dragImg} width="200px" />
        </Typography>
      </Box>
    </Box>
  );
};

export default MasterdataUploadForm;
