const CustomXLSIcon = ({ height = 38, width = 38 }) => {
  return (
    <svg
      id="fi_9034471"
      enableBackground="new 0 0 512 512"
      height={height}
      viewBox="0 0 512 512"
      width={width}
      xmlns="http://www.w3.org/2000/svg">
      <g id="Layer_2_00000009558824404469681000000000518764579714548658_">
        <g id="XLS">
          <path
            d="m463.6 141.4v308.9c0 34.1-27.6 61.7-61.7 61.7h-244.8c-34.1 0-61.7-27.6-61.7-61.7v-388.6c-.1-34.1 27.6-61.7 61.7-61.7h165.1z"
            fill="#ededed"></path>
          <path d="m463.6 141.4h-115.9c-14.1 0-25.5-11.4-25.5-25.5v-115.9z" fill="#50bee8"></path>
          <path
            d="m86.2 210.7h306.3c20.9 0 37.8 16.9 37.8 37.8v92c0 20.9-16.9 37.8-37.8 37.8h-306.3c-20.9 0-37.8-16.9-37.8-37.8v-92c0-20.9 16.9-37.8 37.8-37.8z"
            fill="#50bee8"></path>
          <g fill="#fff">
            <path d="m195.3 344.8-15.9-37.6-16.1 37.6h-16.3l24.2-52.6-22.8-48.9h16.1l14.8 34.4 14.6-34.4h16.1l-22.8 48.9 24.2 52.6z"></path>
            <path d="m271.6 330.8v13.9h-48.3v-101.4h14.6v87.6h33.7z"></path>
            <path d="m273.7 316.9h14.9c.1 10.4 5.8 15.5 14.8 15.5 9.7 0 14.5-5.8 14.5-13.9 0-20.1-42.5-21.3-42.5-51.5 0-14.6 10.7-25.7 27.8-25.7 17.4 0 27.8 11.2 28.3 28.4h-14.9c-.3-9.7-5.4-14.4-13.2-14.4-8.3 0-13.1 4.6-13.1 11.9 0 19.9 42.6 19.9 42.6 51.3 0 15.1-10.6 28-29.6 28-17.8.1-29.4-11-29.6-29.6z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CustomXLSIcon;
