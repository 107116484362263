import { Box, Typography } from "@mui/material";

export function DataProfilingListItem({ title, value, color, width = "450px" }) {
  return (
    <Box display={"flex"}>
      <Box sx={{ width: width, color: "#7A86A1" }}>
        {title}
        <Box sx={{ float: "right" }} component={"span"}>
          &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
        </Box>
      </Box>

      <Typography width={"100%"} color={color ? color : "primary"} variant="bold">
        {value || value === 0 ? value : ""}
      </Typography>
    </Box>
  );
}

export function roundToX(num, decimals) {
  return Number(`${Math.round(`${num}e${decimals}`)}e-${decimals}`);
}
export function getMax(arr) {
  let len = arr.length;
  let max = -Infinity;

  while (len--) {
    max = arr[len] > max ? arr[len] : max;
  }
  return max;
}
