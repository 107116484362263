import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { useEffect, useState } from "react";

export default function CustomTabs({ tabList, componentList }) {
  const [selectedTab, setSelectedTab] = useState("0");
  const [tabs, setTabs] = useState([]);
  const [components, setComponents] = useState([]);

  useEffect(() => {
    setSelectedTab("0");
    if (tabList.length !== 0) {
      setTabs(tabList);
    }
    if (componentList.length !== 0) {
      setComponents(componentList);
    }
  }, [tabList, componentList]);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <TabContext value={selectedTab}>
      <TabList className="overviewSections" onChange={handleChangeTab} textColor="primary" indicatorColor="primary">
        {tabs.map((tab, indx) => {
          return <Tab key={indx} value={indx.toString()} label={tab} />;
        })}
      </TabList>

      {tabs.map((tab, indx) => {
        return (
          <TabPanel sx={{ py: 1, px: 2 }} key={indx} value={indx.toString()}>
            <Grid container>
              <Grid size={12} sx={{ p: 0, display: "grid" }}>
                {components[tab]}
              </Grid>
            </Grid>
          </TabPanel>
        );
      })}
    </TabContext>
  );
}
