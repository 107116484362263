import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatDate, formatDateWithTime } from "../../_helpers/utils";
import { useSearchParams } from "react-router-dom";

const PreviewCollection = ({ scrollRef, data }) => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const testName = searchParams.get("testName");
  return (
    <Box ref={scrollRef} className="prevCollection container" sx={{ mt: 2 }}>
      <Box className="v-center" sx={{ gap: 5, p: 1.5 }}>
        <Typography variant="sectionHeading">{t("Preview")} : </Typography>
        <Box className="v-center">
          <Typography variant="detailTitle">
            {t("name")} :{" "}
            <Typography component="span" sx={{ variant: "detailValue" }}>
              {testName}
            </Typography>
          </Typography>
        </Box>
        <Box className="v-center">
          <Typography variant="detailTitle">
            {t("total_rules")} :{" "}
            <Typography component="span" sx={{ variant: "detailValue" }}>
              {data?.length}
            </Typography>
          </Typography>
        </Box>
      </Box>

      <TableContainer className="prevCollectionTable" component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>Data Quality Rules</TableCell>
              <TableCell align="center">DQ Source Type</TableCell>
              <TableCell align="center">Data Quality Checks</TableCell>
              <TableCell align="center">Last Executed On</TableCell>
              <TableCell align="center">Created Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.TestName}>
                <TableCell scope="row">{row.TestName}</TableCell>
                <TableCell align="center">{row.TestType}</TableCell>
                <TableCell align="center">{row.NoOfValidations}</TableCell>
                <TableCell align="center">{formatDateWithTime(row.LastExecution)}</TableCell>
                <TableCell align="center">{formatDate(row.createdAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PreviewCollection;
