import { useCallback, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import InnerHeader from "../../Layout/InnerHeader";
import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";

import AgGridSSRM from "../../AgGrid/AgGridSSRM";

import { DataProfilingListHeadCells } from "./DataProfilingListHeadCells";
import DataProfilingTableToolbar from "./DataProfilingTableToolbar";
import DataProfilingListActionCells from "./DataProfilingListActionCells";
import { dateRangeObj } from "../../../_helpers/utils";
import DataProfilingExecutionList from "./DataProfilingExecutionList";
import APIServices from "../../../services/app.service";
import CustomDeleteModal from "../../CustomComponents/CustomDeleteModal";
import { showToast } from "../../../Redux/reducers/toastSlice";
import { useDispatch } from "react-redux";
import DataProfilingPreview from "./DataProfilingPreview";
import CustomLoadingButton from "../../CustomComponents/CustomsButtons/CustomLoadingButton";
// import { profilingServices } from "../../Redux/services/profiling.services";

const DataProfilingList = () => {
  const gridRef = useRef();
  const scrollRef = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const ruleId = searchParams.get("ruleId");
  const previewId = searchParams.get("previewId");

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [previewTestDetails, setPreviewTestDetails] = useState(null);
  const selectedRowsIds = selectedRows?.map((each) => each?._id);

  const onClickCreate = () => {
    navigate("create");
  };

  const autoScroll = () => {
    setTimeout(() => {
      scrollRef?.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, 600);
  };

  const handleOnClickResults = (testRes) => {
    setSearchParams({
      testName: testRes?.testName,
      reportType: testRes?.ProfilingType,
      ruleId: testRes?.ruleId,
      sortBy: "DESC",
      currentPage: 1,
      viewType: "grid",
      startDate: dateRangeObj.startDate,
      endDate: dateRangeObj.endDate,
    });
    autoScroll();
    previewTestDetails && setPreviewTestDetails(null);
  };

  const onClickPreviewDataProfile = (testDetails) => {
    gridRef.current.api.deselectAll();
    setSelectedRows([]);
    setSearchParams({ previewId: testDetails._id });
    autoScroll();
  };

  const handleClickOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const refreshData = useCallback(() => {
    if (gridRef) {
      gridRef?.current?.api?.refreshServerSide({ purge: true });
    }
  }, [gridRef]);

  const deleteSelectedRules = async () => {
    setLoadingDelete(true);
    try {
      await APIServices.ValidationTestDelete({ ids: selectedRowsIds });
      setSelectedRows([]);
      setOpenDialog(false);
      dispatch(showToast({ message: "Successfully deleted", type: "success" }));
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    } finally {
      setLoadingDelete(false);
      refreshData();
    }
  };

  const action = {
    headerName: "Actions",
    sortable: false,
    cellRenderer: DataProfilingListActionCells,
    lockPosition: "right",
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    filter: false,
  };

  DataProfilingListHeadCells[DataProfilingListHeadCells.length - 1] = action;

  return (
    <>
      <InnerHeader>
        <Box width="100%" className="space-between ">
          <Box className="v-center">
            <Typography variant="h6" className="upper-case">{`${t("Data Profiling")}`}</Typography>
          </Box>

          <CustomLoadingButton color="success" onClick={onClickCreate}>
            {t("Create Data Profiling")}
          </CustomLoadingButton>
        </Box>
      </InnerHeader>
      <Box className="pt74">
        {selectedRowsIds?.length > 0 && (
          <DataProfilingTableToolbar
            selectedRowsIds={selectedRowsIds}
            gridRef={gridRef}
            handleClickOpenDialog={handleClickOpenDialog}
            clearPreview={() => setSelectedRows([])}
            fetchDataValidations={refreshData}
          />
        )}
        <AgGridSSRM
          gridRef={gridRef}
          apiMethod={"getDataProfileList"}
          headCells={DataProfilingListHeadCells}
          setSelected={setSelectedRows}
          context={{
            onClickPreviewDataProfile: onClickPreviewDataProfile,
            handleOnClickResults: handleOnClickResults,
          }}
        />
      </Box>
      {ruleId && <DataProfilingExecutionList scrollRef={scrollRef} />}

      {openDialog && (
        <CustomDeleteModal
          loading={loadingDelete}
          disabled={loadingDelete}
          onClickDelete={deleteSelectedRules}
          handleClose={handleCloseDialog}
        />
      )}

      {previewId && <DataProfilingPreview scrollRef={scrollRef} />}
    </>
  );
};

export default DataProfilingList;
