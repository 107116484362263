import { useTranslation } from "react-i18next";
import { Box, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import ResultLabelComponent from "../../Validations/Executions/ResultTile/ResultLabelComponent";
import { formatDateWithTime } from "../../../_helpers/utils";

const STATUS_MESSAGES = { 0: "Todo", 1: "In Progress", 2: "Completed", 3: "Aborted", 4: "Suspended" };

const COLOR_VARIANTS = { 0: "#ff9800", 1: "warning.main", 2: "success.main", 3: "error.main", 4: "error.main" };

const DataProfilingResultTile = ({ executionResult, onClickCard }) => {
  const { t } = useTranslation();

  const renderErrorTile = () => (
    <Box component={Paper} className="DQRuleResultcard disableDQRuleResultcard" gap={1}>
      <Typography
        sx={{
          opacity: 0.6,
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        textAlign="center"
        color="error">
        {executionResult?.message}
      </Typography>

      <Typography sx={{ opacity: 0.6 }} textAlign="center" color="error">
        Failed
      </Typography>
    </Box>
  );

  const renderResultTile = () => (
    <Box
      component={Paper}
      onClick={[2, 3].includes(executionResult?.status) ? () => onClickCard(executionResult) : null}
      style={{ cursor: ![2, 3].includes(executionResult?.status) ? "not-allowed" : "pointer" }}
      className="DQRuleResultcard">
      <ResultLabelComponent title={t("Executed By")} value={executionResult?.UserEmail} />
      <ResultLabelComponent title={t("Executed At")} value={formatDateWithTime(executionResult?.createdAt)} />
      <ResultLabelComponent
        title="Status"
        spanProps={{ color: COLOR_VARIANTS[executionResult?.status] }}
        value={t(STATUS_MESSAGES[executionResult?.status])}
      />
    </Box>
  );

  return <Grid size={4}>{[5].includes(executionResult?.status) ? renderErrorTile() : renderResultTile()}</Grid>;
};

export default DataProfilingResultTile;
