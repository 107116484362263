import moment from "moment";

export const totalExecutionsPerPage = Number.parseInt(window._env_?.REACT_APP_PAGESIZE, 10) || 100;
export const totalBatchesPerPage = Number.parseInt(window._env_?.REACT_APP_PAGESIZE, 10) || 10;

export const formatDateLocale = (dateString) => {
  const date = new Date(dateString);
  const options = {
    weekday: "short",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  };
  return date.toLocaleString("en-US", options);
};

export const formattedTime = (dateString) => {
  return dateString ? moment(dateString).format("HH:mm:ss") : "--";
};

export const isDateBefore = (dateString) => {
  const targetDate = moment("2023-09-25");
  const date = moment(dateString);
  return date.isAfter(targetDate);
};

export const isDateTwelveHoursAgo = (dateString) => {
  const inputDate = moment(dateString);
  const currentDate = moment();
  const differenceInHours = currentDate.diff(inputDate, "hours");
  return differenceInHours >= 12;
};

export const isDateBeforeOneDay = (inputDate) => {
  const now = moment();
  const targetDate = moment(inputDate);
  return targetDate.isBefore(now.subtract(1, "days"));
};
export const formatDate = (date) => {
  return date ? moment(date)?.local()?.format("Do MMM YYYY") : "--";
};

export const formatDateWithTime = (date) => {
  return date ? moment(date)?.local()?.format("Do MMM YYYY hh:mm A") : "--";
};

export const formatDateWithTime2 = (date) => {
  return date ? moment(date)?.local()?.format("D/M/YYYY, h:mm:ss a") : "--";
};

export const formatDateString = (date) => {
  return moment.utc(date).local().format("YYYY-MM-DD");
};

export const getTimeDuration = (startTime, endTime) => {
  if (startTime === undefined || startTime === null || endTime === undefined || endTime === null) {
    return "--";
  }

  const startDate = moment(startTime);
  const endDate = moment(endTime);
  const durationInSeconds = endDate.diff(startDate, "seconds");

  let duration;

  if (durationInSeconds >= 86400) {
    const durationInDays = Math.floor(durationInSeconds / 86400);
    const unit = `day${durationInDays > 1 ? "s" : ""}`;
    duration = `${durationInDays} ${unit}`;
  } else if (durationInSeconds >= 3600) {
    const durationInHours = Math.floor(durationInSeconds / 3600);
    const unit = `hour${durationInHours > 1 ? "s" : ""}`;
    duration = `${durationInHours} ${unit}`;
  } else if (durationInSeconds >= 60) {
    const durationInMinutes = Math.floor(durationInSeconds / 60);
    const unit = `minute${durationInMinutes > 1 ? "s" : ""}`;
    duration = `${durationInMinutes} ${unit}`;
  } else {
    const unit = `second${durationInSeconds > 1 ? "s" : ""}`;
    duration = `${durationInSeconds} ${unit}`;
  }

  return duration;
};

export const getDaysDiff = (expiryDate) => {
  if (expiryDate === undefined || expiryDate === null) {
    return "--";
  }

  const today = moment();
  const expiryMoment = moment(expiryDate);
  const duration = moment.duration(expiryMoment.diff(today));

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();

  let result = "";

  if (years > 0) {
    result += `${years} year${years > 1 ? "s" : ""} `;
  }

  if (months > 0) {
    result += `${months} month${months > 1 ? "s" : ""} `;
  }

  if (days > 0) {
    result += `${days} day${days > 1 ? "s" : ""}`;
  }

  return result.trim();
};

export const formatNumber = (number) => {
  if (number >= 1000000) {
    const millionValue = (number / 1000000).toFixed(2);
    const millionString = millionValue.replace(/\.0+$/, ""); // Remove trailing zeros
    return `${millionString}M`;
  } else if (number >= 1000) {
    const thousandValue = (number / 1000).toFixed(2);
    const thousandString = thousandValue.replace(/\.0+$/, ""); // Remove trailing zeros
    return `${thousandString}k`;
  }
  return number?.toString();
};

export const toasterMessages = {
  "Request failed with status code 401": "Session Expired Please Login Again",
  "Network Error": "Oops! Something went wrong Please contact your IT support team",
};

const currentDate = new Date();

const startDate = new Date();
startDate.setDate(currentDate.getDate() - 30);

export const dateRangeObj = {
  startDate: startDate,
  endDate: currentDate,
  key: "selection",
  isSelected: false,
};

export const dashboardDateRangeObj = {
  startDate: moment().subtract(7, "days").toDate(),
  endDate: currentDate,
  key: "selection",
  isSelected: false,
};
