import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import ApiService from "../../services/app.service";
import SkeletonLoader from "../SkeletonLoader";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../App";

export default function MasterDataPreview({ connection, autoScroll, returnVal }) {
  const { setSnack } = useContext(SnackbarContext);

  const { t } = useTranslation();
  const [tablesData, setTablesData] = useState([]);
  const [file, setFile] = useState({});
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    (async () => {
      setLoader(true);
      setTablesData([]);
      setFile({});
      try {
        const response = await ApiService.getmasterdatabyId({
          id: connection,
          numberOfRows: 1000,
        });
        setTablesData(response?.data?.result);
        setFile(response?.data?.fileDetails);
        autoScroll();
      } catch (e) {
        setSnack({
          message: e?.response?.data?.message ?? e.message,
          open: true,
          colour: "error",
        });
        returnVal(false);
      } finally {
        setLoader(false);
      }
    })();
  }, [connection]);

  const firstcolumn = tablesData?.rows?.map((subArray) => subArray[0]);
  return (
    <Box sx={{ maxWidth: "100%" }}>
      <Box>
        <Box className="innerSubHead">
          <Grid container alignItems="center" justify="center">
            <Grid size={2}>
              <Typography variant="h6">{t("Preview")} : </Typography>
            </Grid>
            <Grid size={8} align="center">
              <Grid container>
                <Grid size="grow">
                  <Box>
                    <Typography variant="bold">{t("Data Source Name")} : </Typography>
                    <Typography>{file?.connectionName ? file?.connectionName : file?.name} </Typography>
                  </Box>
                </Grid>
                <Grid size="grow">
                  <Box>
                    <Typography variant="bold">{t("Total Records")} :</Typography>
                    <Typography>{tablesData?.totalRows}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid size={2}>
              <IconButton
                onClick={() => {
                  returnVal(false);
                }}
                size="small"
                color="error"
                sx={{ ml: "auto", display: "flex" }}
                aria-label="add to shopping cart">
                <CancelOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Box p="16px">
          <Grid container>
            <Grid size={12} sx={{ p: 0, display: "grid" }}>
              {tablesData?.rows?.length > 0 && (
                <Grid container>
                  {firstcolumn.flat(1)?.map((item, key) => {
                    const labelId = `filesrow-${key}`;
                    return (
                      <Grid size={2} key={labelId} sx={{ border: "1px solid #eeeeee", p: 0.5 }}>
                        {item}
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      {loader && <SkeletonLoader />}
    </Box>
  );
}
