import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Link } from "react-router-dom";
import { formatDate } from "../../_helpers/utils";

const DBScheduleSources = ({ tests }) => {
  return (
    <Grid container gap={1}>
      <Grid size={3.9} pb={1} className="noborder-container left-border">
        <Box className="DQhead">
          <Typography variant="h6">Scheduled Rule Sets</Typography>
        </Box>
        <Box className="STresult">
          <Grid container alignItems="center">
            <Grid size={7} sx={{ borderRight: "1px solid #ccc" }}>
              <Box className="rBox">
                {tests?.scheduledTestSets?.map((item, i) => (
                  <Typography key={i}>
                    {item.Title} - {formatDate(item.FromDate)}
                  </Typography>
                ))}
              </Box>
            </Grid>
            <Grid size={5} textAlign="center">
              <Box sx={{ py: 1, px: 2 }}>
                <Box className="Rsec">
                  <Link to="/ScheduledTest/list" style={{ textDecoration: "none" }}>
                    <Typography variant="h5">
                      <AccessTimeOutlinedIcon />
                      <b>{tests?.scheduledTestSets?.length}</b>
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid size={8} className="noborder-container right-border">
        <Box className="DQhead v-center">
          <Typography variant="h6">
            Connections <b>{tests?.connections?.totalConnections}</b>
          </Typography>
        </Box>
        <Grid container>
          <Grid size={7}>
            <Box className="STresult">
              <Grid container alignItems="center">
                <Grid size={12} textAlign="center" sx={{ borderRight: "1px solid #ccc" }}>
                  <Box className="Rsec" mt="16px">
                    <Typography variant="h5">
                      <ShareOutlinedIcon /> Database
                    </Typography>
                  </Box>
                </Grid>
                <Grid className="bBox" size={12}>
                  <Typography>
                    Snowflake <br />
                    <b>{tests?.connections?.snowflake}</b>
                  </Typography>
                  <Typography>
                    SQL <br />
                    <b>{tests?.connections?.sql}</b>
                  </Typography>
                  <Typography>
                    My SQL <br />
                    <b>{tests?.connections?.mysql}</b>
                  </Typography>
                  <Typography>
                    PostgreSQL <br />
                    <b>{tests?.connections?.PostgreSQL}</b>
                  </Typography>
                  <Typography>
                    Databricks <br />
                    <b>{tests?.connections?.Databricks}</b>
                  </Typography>
                  <Typography>
                    Oracle <br />
                    <b>{tests?.connections?.Oracle}</b>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid size={5}>
            <Box className="STresult">
              <Grid container mb="16px" alignItems="center">
                <Grid size={6}></Grid>
                <Grid size={6} textAlign="right"></Grid>
              </Grid>

              <Grid container alignItems="center">
                <Grid size={12} textAlign="center">
                  <Box className="Rsec">
                    <Typography variant="h5">
                      <ArticleOutlinedIcon />
                      File
                    </Typography>
                  </Box>
                </Grid>
                <Grid size={12}>
                  <Box className="bBox">
                    <Typography>
                      CSV <br />
                      <b>{tests?.connections?.csv}</b>
                    </Typography>
                    <Typography>
                      XLSX <br />
                      <b>{tests?.connections?.xlsx}</b>
                    </Typography>
                    <Typography>
                      Parquet <br />
                      <b>{tests?.connections?.parquet || 0}</b>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DBScheduleSources;
