/* eslint-disable no-nested-ternary */
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const CleanDataConnectionDetails = ({ Connectiondetails, datasourcetype, tableName }) => {
  const { t } = useTranslation();

  const renderDetailRow = (label, value) => (
    <Box display={"flex"}>
      <Box sx={{ width: "190px", color: "#7A86A1" }}>
        {label}
        <Box sx={{ float: "right" }} component={"span"}>
          &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
        </Box>
      </Box>

      <Typography width={"100%"} color={"primary"} variant="bold">
        {value}
      </Typography>
    </Box>
  );

  return (
    <Box sx={{ width: "100%" }} className="flex column" gap={1} mb={3}>
      <Typography variant="h6">{t("Preview")}</Typography>

      {renderDetailRow(t("Data Source Name"), Connectiondetails?.connectionName)}

      {renderDetailRow(t("Database Type"), `${datasourcetype} [${Connectiondetails?.connectionType}]`)}

      {Connectiondetails?.connectionType !== "Azure Blob" &&
        renderDetailRow(
          datasourcetype === "Database" && Connectiondetails?.dataBase
            ? t("Database")
            : datasourcetype === "Database" && Connectiondetails?.catalogName
              ? t("CatalogName")
              : t("File"),
          datasourcetype === "Database" && Connectiondetails?.dataBase
            ? Connectiondetails?.dataBase
            : datasourcetype === "Database" && Connectiondetails?.catalogName
              ? Connectiondetails?.catalogName
              : Connectiondetails?.fileName,
        )}

      {datasourcetype === "Database" &&
        renderDetailRow(Connectiondetails?.connectionType === "Azure Blob" ? t("File") : t("Table"), tableName)}
    </Box>
  );
};

export default CleanDataConnectionDetails;
