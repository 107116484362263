import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Box, Divider, IconButton, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import { ImagePreview } from "./ImagePreview";
import Output from "./Output";
import { formatDateWithTime } from "../../_helpers/utils";

export default function VisualTestResults(props) {
  const { result, outPut, setOutPut, CloseResults, ScrollRef } = props;

  const [open, setOpen] = useState(false);

  const showDifference = (obj) => {
    setOutPut(obj);
  };

  return (
    <>
      <Paper sx={{ maxWidth: "100%" }} ref={ScrollRef}>
        <Box>
          <Box className="innerSubHead">
            <Grid container alignItems="center" justify="center">
              <Grid size={2}>
                <Typography variant="h6">View Results: </Typography>
              </Grid>
              <Grid size={8} align="center"></Grid>
              <Grid size={2}>
                <IconButton
                  onClick={() => CloseResults()}
                  size="small"
                  color="error"
                  sx={{ ml: "auto", display: "flex" }}
                  aria-label="add to shopping cart">
                  <CancelOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className="VTPreview">
          <Grid container>
            <Grid size={10}></Grid>
            <Grid size={2}></Grid>
            <Grid size={12}>
              <Box
                p={2}
                className="DBListMain"
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "16px",
                }}>
                <Box className="DSListItem" key="0" p={2} border={"1px solid #ccc"}>
                  <Box className="DBList" onClick={() => setOpen(true)}>
                    <Box textAlign="center">
                      <img width={"100%"} src={result[0].BaseImage} alt="Base Image" />
                    </Box>
                    <Divider />
                    <Grid container alignItems="center">
                      <Grid size={12}>
                        <Box mt={2}>
                          <Typography variant="h6"> Base Image </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                {result?.map((obj, index) => {
                  return (
                    <Box
                      p={2}
                      border={"1px solid #ccc"}
                      className={outPut?._id === obj?._id ? "DSListItem item-selected" : "DSListItem"}
                      key={index + 1}
                      onClick={() => {
                        showDifference(obj);
                      }}
                      sx={{
                        "&:hover": { cursor: "pointer" },
                      }}>
                      <Box className="DBList">
                        <Box textAlign="center">
                          <img width={"100%"} src={obj.ScreenshotImage} alt="Screenshot Image" />
                        </Box>
                        <Divider />
                        <Grid container alignItems="center">
                          <Grid size={12}>
                            <Box mt={2}>
                              <Typography>Executed Date :{formatDateWithTime(obj?.CreatedDate)}</Typography>
                              <Typography>No of differences : {obj.Differences.length}</Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <ImagePreview open={open} setOpen={setOpen} url={result[0].BaseImage} ImagePreview={() => null} />
      </Paper>

      {outPut && <Output outPut={outPut} setOutPut={setOutPut} />}
    </>
  );
}
