import { Box, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

import { useTranslation } from "react-i18next";
import PieChartComponent from "../../Charts/PieChartComponent";

const SimpleProfiling = ({ reportData }) => {
  const { t } = useTranslation();
  const columnData = reportData?.ResponseObject?.Columns;
  const selectedSource = reportData?.ResponseObject?.DataSource;
  const dataSourceType = reportData?.ResponseObject?.DataSource?.FirstDatasourceType;
  return (
    <Paper className="simple-report">
      <Grid container className="DPGraphHead" spacing={1}>
        <Grid size="grow">
          <Box>
            <Typography variant="h6">{dataSourceType === "database" ? t("Connection") : t("File Name")}</Typography>
            <Typography>
              {dataSourceType === "database" ? selectedSource?.connectionName : selectedSource?.Name || "NA"}
            </Typography>
          </Box>
        </Grid>
        {dataSourceType === "database" && (
          <>
            <Grid size="grow">
              <Box>
                <Typography variant="h6">{t("Database")}</Typography>
                <Typography>{selectedSource?.ConnectionType}</Typography>
              </Box>
            </Grid>
            <Grid size="grow">
              <Box>
                <Typography variant="h6">{t("Table Name")}</Typography>
                <Typography>{selectedSource?.TableName || "NA"}</Typography>
              </Box>
            </Grid>
          </>
        )}
        <Grid size="grow">
          <Box>
            <Typography variant="h6">{t("Column Count")}</Typography>
            <Typography>{selectedSource?.ColumnsCount}</Typography>
          </Box>
        </Grid>
        <Grid size="grow">
          <Box>
            <Typography variant="h6">{t("RowsCount")}</Typography>
            <Typography>{selectedSource?.RowsCount}</Typography>
          </Box>
        </Grid>
      </Grid>

      <Box className="DPOuptlabel">
        <Grid container alignItems="center">
          <Grid size={2}>
            <Typography variant="h6">{t("Column Name")}</Typography>
          </Grid>
          <Grid size={10} fontSize={10}>
            <Grid container spacing={0.5} alignItems="center">
              <Grid size={7}>
                <Typography variant="h6">{t("Data Profile")}</Typography>
              </Grid>
              <Grid size={5}>
                <Box className="DPToolTip">
                  <Typography variant="h6">
                    {t("Duplicates")} | {t("Distinct")} | {t("Null")}
                  </Typography>

                  <Tooltip title={t("tooltip1")} placement="top" arrow>
                    <IconButton size="small">
                      <ErrorOutlineOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <PieChartComponent columnData={columnData} />
    </Paper>
  );
};

export default SimpleProfiling;
