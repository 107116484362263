import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Box, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useRef } from "react";
import { CustomAgGrid } from "../AgGrid";

import { headCellss } from "./PreviewModel";
import { useTranslation } from "react-i18next";

export default function PreviewSchedulerData({ scrollRef, closePreview, data, obj }) {
  const { t } = useTranslation();
  const gridRef = useRef();

  return (
    <Box ref={scrollRef} className="pvTestSch">
      <Box className="innerSubHead">
        <Grid container alignItems="center" justify="center">
          <Grid size={1}>
            <Typography variant="h6">{t("Preview")} : </Typography>
          </Grid>
          <Grid size={10} align="center">
            <Grid container>
              <Grid size="grow">
                <Box>
                  <Typography variant="bold"> {t("Name")} : </Typography> <Typography> {` ${obj.Title}`} </Typography>
                </Box>
              </Grid>
              <Grid size="grow">
                <Box>
                  <Typography variant="bold"> {t("Total Tests")} : </Typography>{" "}
                  <Typography> {` ${obj.TestIds.length}`} </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid size={1}>
            <IconButton
              onClick={closePreview}
              size="small"
              color="error"
              sx={{ ml: "auto", display: "flex" }}
              aria-label="add to shopping cart">
              <CancelOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      {/* <Box >
        <FormControl size="small">
          <Button size="small" variant="outlined" onClick={getTestResults}>
            View Results
          </Button>
        </FormControl>
      </Box> */}
      <CustomAgGrid
        previewId={obj?.id}
        gridRef={gridRef}
        closePreview={() => null}
        headCells={headCellss}
        rows={data}
      />
    </Box>
  );
}
