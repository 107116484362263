import { useRef } from "react";
import { Box, Table, TableBody, TableCell, TableContainer, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import LockVisibleCustomAgGrid from "../../AgGrid/LockVisibleCustomAgGrid";
import ExpandableTableRow from "../../Tables/ExpandableTableRow";

const FuzzyReplaceResult = ({ Result }) => {
  const { CleanedResponse } = Result || {};
  const { dataCleaning } = CleanedResponse || {};
  const { columns, connectionDeatails } = dataCleaning || {};
  const gridRef = useRef();

  const renderCleanedData = (item, connectiondetails) => {
    return (
      <ExpandableTableRow
        showData={
          <>
            <span style={{ color: "Green" }}>Cleaned Records: {item?.cleanedResponse?.length}</span>
          </>
        }
        expandComponent={
          <TableCell className="expandableRow" sx={{ width: "20%" }} colSpan="6">
            {item?.cleanedResponse?.length === 0 ? (
              <center>
                <Typography component={"h6"}>No Rows Modified by Cleaning Algorithm</Typography>
              </center>
            ) : (
              <Box>
                <LockVisibleCustomAgGrid
                  gridRef={gridRef}
                  Request={Result}
                  data={item?.cleanedResponse}
                  highLightColumn={{
                    columns: Object?.keys(item?.cleanedResponse[0])?.filter((col) => col?.includes("New Value")),
                    color: "Green",
                  }}
                  errorColumn={{
                    columns: [item?.columnName],
                    color: "#ef6c00",
                  }}
                />
              </Box>
            )}
          </TableCell>
        }>
        <TableCell sx={{ width: item?.masterDatasetName ? "20%" : "35%" }}>
          {connectiondetails?.ext ? <b>File Name:</b> : <b>Table:</b>}{" "}
          {connectiondetails?.ext ? connectiondetails?.fileName : item?.TableName}
        </TableCell>
        <TableCell sx={{ width: item?.masterDatasetName ? "20%" : "35%" }}>
          <b>Column:</b> {item.columnName}
        </TableCell>
        {item?.masterDatasetName && (
          <TableCell sx={{ width: "20%" }}>
            <b>Master Dataset:</b> {item?.masterDatasetName}
          </TableCell>
        )}
        {item?.masterDatasetName && (
          <TableCell sx={{ width: "20%" }}>
            <b>Min Similarity Percent:</b> {item?.minSimilarityPercentage}%
          </TableCell>
        )}
      </ExpandableTableRow>
    );
  };

  return (
    <Box sx={{ maxHeight: "70vh", overflow: "auto", pb: 1, width: "100%" }}>
      <Grid container sx={{ my: 0 }}>
        <Grid size={12}>
          {columns?.map((item, index) => (
            <Box key={index}>
              <Grid container sx={{ mt: 1 }}>
                <Grid size={12}>
                  <Box>
                    <Grid size={12} sx={{ "& .MuiFormControl-root": { width: "300px" } }}>
                      <TableContainer>
                        <Table aria-label="simple table">
                          <TableBody>{renderCleanedData(item, connectionDeatails)}</TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FuzzyReplaceResult;
