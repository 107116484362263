import { useState, useRef, useContext } from "react";
import {
  EditOutlined as EditOutlinedIcon,
  FileDownloadDoneOutlined as FileDownloadDoneOutlinedIcon,
  ErrorOutlineOutlined as ErrorOutlineOutlinedIcon,
} from "@mui/icons-material";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import ApiService from "../../services/app.service";
import { JSONTree } from "react-json-tree";
import { SnackbarContext } from "../../App";
import CustomLoadingButton from "../CustomComponents/CustomsButtons/CustomLoadingButton";
import dropfile from "../../images/File_Drop.svg";

export default function JsonFileReaderForm({ toggleDrawer, refreshData }) {
  const validTypes = ["application/json", "text/json"];
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const fileInput = useRef();
  const [uploadButton, setUploadButton] = useState(true);
  const [loader, setLoading] = useState(false);
  const [ruleName, setRuleName] = useState("");
  const [editruleName, setEditRuleName] = useState(false);
  const [file, setFile] = useState([]);
  const [formError, setFormError] = useState("");
  const [fileError, setFileError] = useState(false);
  const [fileSuccess, setFileSuccess] = useState(false);
  const [jsonPreview, setJsonPreview] = useState(false);
  const inputRef = useRef(null);
  const [errorMsg, setErrorMsg] = useState("");

  const resetFileState = () => {
    setFileSuccess(false);
    setFileError(false);
    setFile();
    setFormError("");
    setJsonPreview(false);
  };

  const handleFile = (files) => {
    resetFileState();
    const originalName = files[0].name;
    const ext = originalName.split(".").pop().toLowerCase();

    if (ext === "json" || files[0].type === "application/json" || files[0].type === "text/json") {
      const reader = new FileReader();

      reader.onload = () => {
        try {
          const jsonData = JSON.parse(reader.result);
          setFileSuccess(true);
          setFile(files);
          setUploadButton(false);
          if (jsonData?.TestName) {
            setRuleName(jsonData?.TestName);
            setJsonPreview(jsonData);
          } else {
            const updatedJsonData = { ...jsonData };
            updatedJsonData.TestName = ruleName.trimEnd().trimStart();
            setJsonPreview(updatedJsonData);
          }
        } catch (error) {
          setSnack({ message: error?.response?.data?.message || error.message, open: true, colour: "error" });
          setFileError(true);
          setUploadButton(true);
        }
      };

      reader.readAsText(files[0]);
    } else {
      // Invalid file type
      setFileError(true);
      setUploadButton(true);
    }
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    if (loader) {
      return;
    }
    resetFileState();
    const files = e.dataTransfer.files;
    if (files.length === 0 || !isValidFileType(files[0].type)) {
      setFileError(true);
      setUploadButton(true);
      return;
    }
    handleFile(files);
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files.length === 0 || !isValidFileType(files[0].type)) {
      setFileError(true);
      setUploadButton(true);
      return;
    }
    handleFile(files);
  };

  const isValidFileType = (fileType) => {
    return validTypes.indexOf(fileType) !== -1;
  };

  const nameValidation = (e) => {
    let value = e.target.value.replace(/\s{2,}/g, " ");

    if (value.trim().length < 4) {
      setFormError(t("Minimum length 4 char"));
    } else {
      setFormError("");
    }

    if (value.length > 32) {
      value = value.substring(0, 32);
    }

    if (jsonPreview && jsonPreview?.TestName !== value) {
      setJsonPreview((prevJsonPreview) => {
        const updatedJsonData = { ...prevJsonPreview };
        updatedJsonData.TestName = value.trimEnd().trimStart();
        return updatedJsonData;
      });
    }

    setRuleName(value);
  };

  const createDQRule = async () => {
    if (ruleName.trim().length === 0) {
      setFormError("This field is required");
      return;
    } else if (ruleName.trim().length < 4) {
      setFormError(t("Minimum length 4 char"));
      return;
    }

    setLoading(true);
    setUploadButton(true);

    try {
      await ApiService.createValidation(jsonPreview);
      setSnack({ message: "DQ Rule Created", open: true, colour: "success" });
      resetFileState();
      setUploadButton(true);
      toggleDrawer();
      refreshData();
    } catch (error) {
      if (error?.response?.data?.TestName?.message) {
        setFormError(error.response.data.TestName.message);
      } else if (error?.response?.status === 422) {
        const keyValues = Object.keys(error?.response?.data);
        const values = Object.values(error?.response?.data);
        const resError = values.map((each, index) => `${keyValues[index]} : ${each?.message}`);
        setErrorMsg(resError);
      }
      setUploadButton(false); // Ensures this runs in the error block
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      onDrop={(e) => handleFileDrop(e)}
      onDragOver={(e) => e.preventDefault()}
      onDragEnter={(e) => e.preventDefault()}
      onDragLeave={(e) => e.preventDefault()}>
      <Grid container alignItems="center" rowGap={3}>
        <Grid size={12}>
          <TextField
            disabled={!editruleName}
            inputRef={inputRef}
            fullWidth
            size="small"
            label={t("Data Quality Rule Name")}
            required
            value={ruleName}
            onChange={nameValidation}
            InputProps={{
              endAdornment: !editruleName && (
                <IconButton
                  size="small"
                  onClick={() => {
                    setEditRuleName(true);
                    if (editruleName && inputRef?.current) {
                      inputRef.current.focus();
                    }
                  }}
                  sx={{ color: "#4840C5" }}>
                  <EditOutlinedIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
          <Typography className="errorText">{formError}</Typography>
        </Grid>
        <Grid size={12}>
          <Grid container>
            <Grid size={9}>
              <Box
                className="fileTextbox"
                onClick={() => (loader ? "" : fileInput.current.click())}
                title={file?.length ? file?.[0]?.name : t("Choose a file")}>
                {file?.length ? file?.[0]?.name : t("Choose a file")}
                {fileSuccess && <FileDownloadDoneOutlinedIcon className="sucIcon" />}
              </Box>
            </Grid>
            <Grid size={3}>
              <Button
                className="UploadButton"
                fullWidth
                size="small"
                onClick={() => fileInput.current.click()}
                variant="contained"
                disabled={loader}>
                {t("Browse")}
              </Button>
              <input
                ref={fileInput}
                onChange={handleFileChange}
                type="file"
                onClick={(e) => (e.target.value = null)}
                style={{ display: "none" }}
                accept=".json, application/json, text/json"
              />
            </Grid>
            <Grid size={12}>
              {fileError && (
                <Typography className="errorText">
                  <ErrorOutlineOutlinedIcon /> {t("Allowed file types are ")}
                  JSON {t("only")}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid size={12}>
          {jsonPreview ? (
            <Box sx={{ maxHeight: "300px", overflow: "auto", mb: "8px" }}>
              <JSONTree
                data={jsonPreview}
                hideRoot
                theme={{
                  tree: {
                    backgroundColor: "#fff",
                  },
                }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                textAlign: "center",
                border: "1px dashed #CED4DA",
                borderRadius: "4px",
                color: "#ADB5BD",
                py: 3.5,
              }}>
              <Typography sx={{ mb: 1.5 }}>Drag and drop file here</Typography>
              <img height="48px" src={dropfile} alt={"Loading..."} />
            </Box>
          )}
        </Grid>
        <Grid size={12} ml={2}>
          {errorMsg?.length > 0 &&
            errorMsg?.map((errmsg, ind) => (
              <Typography display="block" key={ind} variant="bold" color="error">
                {errmsg}
              </Typography>
            ))}
        </Grid>
        <Grid size={12} textAlign={"right"}>
          <Button variant="outlined" size="small" color="error" sx={{ mr: 2 }} onClick={toggleDrawer}>
            {t("Cancel")}
          </Button>

          <CustomLoadingButton disabled={uploadButton} loading={loader} onClick={createDQRule}>
            {t("Create Rule")}
          </CustomLoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
}
