import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Paper, Tab, Typography } from "@mui/material";
import { useState } from "react";
import SVGExtractor from "../SVGExtractor";

const Missing = ({ data }) => {
  const [tabList] = useState(Object.keys(data));

  const [selectedTab, setSelectedTab] = useState(tabList[0]);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Paper sx={{ py: 1, px: 2, mt: 2 }} className="sampleContainer">
      <Typography variant="h5" color="primary">
        Missing Values
      </Typography>
      <TabContext value={selectedTab}>
        <TabList className="overviewSections" onChange={handleChangeTab} textColor="primary" indicatorColor="primary">
          {tabList.map((tab, indx) => {
            return <Tab key={indx} value={tab} label={tab} />;
          })}
        </TabList>
        {tabList.map((tab, indx) => {
          return (
            <TabPanel sx={{ py: 0, px: 2 }} key={indx} value={tab}>
              <SVGExtractor xmlString={data?.[selectedTab]?.matrix} />
              <Typography sx={{ fontSize: "14px", color: "#808080", textAlign: "center" }}>
                {data?.[selectedTab]?.caption}
              </Typography>
            </TabPanel>
          );
        })}
      </TabContext>
    </Paper>
  );
};

export default Missing;
