import { Box, IconButton, Toolbar, Tooltip, Typography } from "@mui/material";

import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
// import io from "socket.io-client";
import {
  AccessTime as AccessTimeIcon,
  Delete as DeleteIcon,
  NotStartedSharp as NotStartedSharpIcon,
  OutputRounded as OutputRoundedIcon,
  Input as InputIcon,
} from "@mui/icons-material";
import { SnackbarContext } from "../../../App";
import ApiService from "../../../services/app.service";
import InnerHeader from "../../Layout/InnerHeader";
// import PipeLinePopUpScheduler from "../PipeLines/PipeLine";
import { dateRangeObj } from "../../../_helpers/utils";
import CreateScheduledTest from "../../TestScheduler/CreateScheduledTest/CreateScheduledTest";
import Executions from "../Executions";
import ExecutionsView from "../Executions/ViewExecutions/ExecutionsView";
import PreviewValidationData from "./PreviewValidationData";
import CreateNewDqRule from "../CreateNewDqRule";

import { useTranslation } from "react-i18next";
import moment from "moment";
import AgGridSSRM from "../../AgGrid/AgGridSSRM";
import {
  CustomEditIcon,
  CustomExecuteIcon,
  CustomPreviewIcon,
  CustomResultIcon,
} from "../../CustomComponents/IconButtons";
import { isAzure } from "../../../_helpers/Constant";
import CustomDeleteModal from "../../CustomComponents/CustomDeleteModal";
import { dataValidationsListHeadCells } from "./dataValidationsListHeadCells";
import { useSelector } from "react-redux";
import { useCheckAccess } from "../../../CustomHooks/useCheckAccess";
import CustomLoadingButton from "../../CustomComponents/CustomsButtons/CustomLoadingButton";

const EnhancedTableToolbar = ({
  numSelected,
  selectedRows,
  handleClickOpenDialog,
  fetchDataValidations,
  clearPreview,
  gridRef,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);
  const { checkAccess } = useCheckAccess();

  const { user_details } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [scheduleTestOpen, setScheduleTestOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const isAdmin = user_details?.RoleName === "Admin";

  const displayedRowsData = () => {
    if (gridRef?.current) {
      const displayedRowsData = [];

      gridRef.current.api.forEachNode((node) => {
        displayedRowsData.push(node.data);
      });
      setRows(displayedRowsData);
    }
  };

  useEffect(() => {
    displayedRowsData();
  }, [gridRef, selectedRows]);

  const userCreatedRules = rows.filter((each) => each?.UserId !== user_details?.UserId);
  const schduledOrCollectionRules = rows?.filter((each) => each?.isScheduled || each?.isInCollection);
  const isSelectedScheduled = schduledOrCollectionRules.some((each) => selectedRows.includes(each?._id));

  const isDisableDelete = userCreatedRules.some((each) => selectedRows.includes(each?._id)) && !isAdmin;

  const updateRowSelectability = () => {
    if (!gridRef?.current) {
      return;
    }
    const selectedIdsSet = new Set(selectedRows);
    gridRef?.current?.api?.forEachNode((node) => {
      const nodeId = node?.data?._id;
      if (selectedIdsSet.has(nodeId)) {
        node.setRowSelectable(false);
        node.data.isExecutionRunning = 1;
      }
    });
  };

  const handleMultipleExecution = async () => {
    try {
      updateRowSelectability();

      clearPreview();
      setLoading(true);
      gridRef?.current?.api?.deselectAll();
      setSnack({
        message: "DQ Rule Execution Started",
        open: true,
        colour: "success",
      });
      const payload = {
        ruleIds: selectedRows,
      };
      await ApiService.executeMultipleTest(payload);
      setSnack({
        message: "DQ Rule Execution Completed",
        open: true,
        colour: "success",
      });
      fetchDataValidations();
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const onSuccessCreateScheduled = () => {
    setScheduleTestOpen(false);
    navigate("/ScheduledTest/list");
  };

  let deleteTitle;

  if (isDisableDelete) {
    deleteTitle = "Can't delete this rule. Not created by you.";
  } else if (isSelectedScheduled) {
    deleteTitle = "Please deselect rules that are in collection or scheduled.";
  } else {
    deleteTitle = "Delete";
  }

  return (
    <Toolbar className="headerToolbar">
      <Typography color="inherit" variant="subtitle1" component="div" sx={{ pr: 1 }}>
        {numSelected} {t("Selected")}
      </Typography>
      {checkAccess("DataQualityRule", "Delete") && (
        <Tooltip arrow placement="top-end" title={deleteTitle}>
          <Box>
            <IconButton disabled={isDisableDelete || isSelectedScheduled} size="small" onClick={handleClickOpenDialog}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Tooltip>
      )}
      {checkAccess("ScheduledTest", "Create") && (
        <Tooltip
          placement="top-end"
          arrow
          title={t(isDisableDelete ? "Kindly deselect rules that you did not create." : "Schedule")}>
          <Box>
            <IconButton
              disabled={isDisableDelete}
              onClick={() => setScheduleTestOpen(true)}
              size="small"
              variant="outlined">
              <AccessTimeIcon />
            </IconButton>
          </Box>
        </Tooltip>
      )}
      {checkAccess("DataQualityRule", "Execute") && numSelected <= 5 && (
        <Tooltip arrow placement="top-end" title={t("Execute Selected")} disabled={loading}>
          <IconButton size="small" onClick={handleMultipleExecution}>
            <NotStartedSharpIcon />
          </IconButton>
        </Tooltip>
      )}

      {scheduleTestOpen && (
        <CreateScheduledTest
          selectedRules={selectedRows}
          setSchduleTestOpen={setScheduleTestOpen}
          onSuccessCreateScheduled={onSuccessCreateScheduled}
        />
      )}
    </Toolbar>
  );
};

function ActionsCell(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user_details } = useSelector((state) => state.auth);
  const { checkAccess } = useCheckAccess();

  const [loading, setLoading] = useState(false);
  const [exportJson, setExportJson] = useState(false);
  const row = props?.data;

  const handleExecuteClick = () => {
    props?.node?.setRowSelectable(false);
    props?.context?.executeTest(row?._id, props?.node, setLoading);
  };

  const handleEditClick = (path) => {
    navigate(path);
  };

  const showEdit =
    props?.data?.UserId === user_details?.UserId || user_details?.RoleName === "Admin" ? "visible" : "hidden";
  const isDQRuleExecutionRunning = props?.data?.isExecutionRunning > 0;
  const reset = true;
  return (
    <Box className="v-center">
      {row?.TestType === "Comparison" && (
        <CustomEditIcon
          sx={{ visibility: showEdit }}
          title={t("Edit")}
          onClick={() =>
            handleEditClick(
              `/DataQualityRule/row-comparison/create?ruleId=${row._id}&firstSourceConId=${row?.FirstDatasourceId}&secondSourceConId=${row.SecondDatasourceId}`,
            )
          }
          disabled={loading || isDQRuleExecutionRunning}
        />
      )}

      {(row?.TestType === "Single File" || row?.TestType === "Single API") && (
        <CustomEditIcon
          sx={{ visibility: showEdit }}
          title={t("Edit")}
          onClick={() =>
            handleEditClick(
              `/DataQualityRule/create?connectionId=${row?.ConnectionId}&ruleId=${row._id}&ruleName=${
                row?.TestName
              }&testType=${row?.TestType === "Single Database" || isAzure(row?.ConnectionType) ? "database" : "Files"}`,
            )
          }
          disabled={loading || isDQRuleExecutionRunning}
        />
      )}
      {row?.TestType === "Single Database" && (
        <CustomEditIcon
          sx={{ visibility: showEdit }}
          title={t("Edit")}
          onClick={() =>
            handleEditClick(
              `/DataQualityRule/create?connectionId=${row?.ConnectionId}&ruleId=${row._id}&ruleName=${
                row?.TestName
              }&testType=${row?.TestType === "Single Database" ? "database" : "Files"}`,
            )
          }
          disabled={loading || isDQRuleExecutionRunning}
        />
      )}
      {checkAccess("DataQualityRule", "Preview") && (
        <CustomPreviewIcon title={t("Preview")} onClick={() => props.context.ClickPreviewConnection(row)} />
      )}
      {checkAccess("DataQualityRule", "Execute") && (
        <CustomExecuteIcon
          title={t("Execute")}
          onClick={handleExecuteClick}
          disabled={loading || isDQRuleExecutionRunning}
        />
      )}
      <CustomResultIcon title={t("Results")} onClick={() => props.context.handleOnClickResults(row?._id, reset)} />
      <IconButton
        // title={t("Export Rule")}
        className="v-center"
        aria-label={t("Export Rule")}
        onClick={() => props.context.handleExportDQRule(row?._id, setExportJson)}
        size="small"
        disabled={exportJson}
        sx={{ color: "#123e8a" }}>
        <Tooltip className="v-center" title={t("Export Rule")} placement="top" arrow>
          <OutputRoundedIcon fontSize="small" />
        </Tooltip>
      </IconButton>
    </Box>
  );
}
export default function DataValidationsList() {
  const { t } = useTranslation();
  const gridRef = useRef(null);
  const scrollRef = useRef();
  const { checkAccess } = useCheckAccess();

  const { setSnack } = useContext(SnackbarContext);
  const [searchParams, setSearchParams] = useSearchParams({
    sortBy: "DESC",
    currentPage: 1,
    startDate: dateRangeObj.startDate,
    endDate: dateRangeObj.endDate,
  });

  const ruleId = searchParams.get("ruleId");
  const previewId = searchParams.get("previewId");
  const createRule = JSON.parse(searchParams.get("createRule"));
  const [selected, setSelected] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState();

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const autoScroll = () => {
    setTimeout(() => {
      scrollRef?.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, 600);
  };

  const refreshData = useCallback(() => {
    if (gridRef) {
      gridRef?.current?.api?.refreshServerSide({ purge: true });
    }
  }, [gridRef]);

  const deleteFiles = async () => {
    setLoadingDelete(true);
    try {
      await ApiService.ValidationTestDelete({ ids: selected });
      setSelected([]);
      setOpenDialog(false);
      setSnack({ message: "Successfully deleted", open: true, colour: "success" });
    } catch (error) {
      setSnack({ message: error?.response?.data?.message ?? error.message, open: true, colour: "error" });
    } finally {
      setLoadingDelete(false);
      refreshData();
    }
  };

  const executeTest = async (row, rowNode, setLoading) => {
    setSearchParams({});
    setLoading(true);
    gridRef?.current?.api?.deselectAll();

    setSnack({ message: "DQ Rule Execution Started", open: true, colour: "success" });
    try {
      const response = await ApiService.ExcuteTestValidation(row);
      const formattedDate = moment.utc(response?.data?.DateTime).local().format("MM/DD/YYYY h:mm:ss A");
      rowNode.setDataValue("LastExecution", formattedDate);
    } catch (e) {
      setSnack({ message: e?.response?.data?.message ?? e.message, open: true, colour: "error" });
    } finally {
      setLoading(false);
      rowNode.setRowSelectable(true);
    }
  };

  const ClickPreviewConnection = (row) => {
    gridRef.current.api.deselectAll();
    setSelected([]);
    setSearchParams({ previewId: row._id });
    autoScroll();
  };

  const handleOnClickResults = (rowId) => {
    setSearchParams({
      ruleId: rowId,
      sortBy: "DESC",
      currentPage: 1,
      viewType: "grid",
      startDate: dateRangeObj.startDate,
      endDate: dateRangeObj.endDate,
    });

    autoScroll();
  };

  const handleExportDQRule = async (rowId, setExportJson) => {
    setExportJson(true);
    gridRef?.current?.api?.deselectAll();
    try {
      const response = await ApiService.exportRule(rowId);
      const Json = response?.data;
      delete Json?._id;
      if (Json) {
        const jsonData = JSON.stringify(Json, null, 2);

        const blob = new Blob([jsonData], { type: "application/json" });

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = `${Json?.TestName}.json`;

        document.body.appendChild(downloadLink);

        downloadLink.click();

        document.body.removeChild(downloadLink);
      }
    } catch (e) {
      setSnack({ message: e?.response?.data?.message ?? e.message, open: true, colour: "error" });
    } finally {
      setExportJson(false);
    }
  };

  const action = {
    headerName: "Actions",
    sortable: false,
    cellRenderer: ActionsCell,
    lockPosition: "right",
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    filter: false,
  };

  dataValidationsListHeadCells[dataValidationsListHeadCells.length - 1] = action;

  return (
    <>
      <InnerHeader>
        <Box width="100%" className="space-between ">
          <Box className="v-center">
            <Typography variant="h6" className="upper-case">{`${t("Data Quality Rules")}`}</Typography>
          </Box>
          <Box>
            {checkAccess("DataQualityRule", "Create") && (
              <CustomLoadingButton
                color="success"
                sx={{ marginRight: "8px" }}
                startIcon={<InputIcon />}
                onClick={() => setSearchParams({ importRule: true })}>
                {t("Import Rule")}
              </CustomLoadingButton>
            )}

            {checkAccess("DataQualityRule", "Create") && (
              <CustomLoadingButton color="success" onClick={() => setSearchParams({ createRule: !createRule })}>
                {t("Create DQ Rule")}
              </CustomLoadingButton>
            )}
          </Box>
        </Box>
      </InnerHeader>
      <Box className="pt74" ref={scrollRef}>
        <Box sx={{ width: "100%", mb: 2 }}>
          <CreateNewDqRule refreshData={refreshData} />

          {selected?.length !== 0 && (
            <EnhancedTableToolbar
              selectedRowId={selectedRowId}
              selectedRows={selected}
              numSelected={selected?.length}
              handleClickOpenDialog={handleClickOpenDialog}
              fetchDataValidations={refreshData}
              clearPreview={() => setSelected([])}
              gridRef={gridRef}
            />
          )}
          <AgGridSSRM
            gridRef={gridRef}
            setSelected={setSelected}
            setSelectedRowId={setSelectedRowId}
            apiMethod={"GettestHubDataValidations"}
            headCells={dataValidationsListHeadCells}
            context={{
              ClickPreviewConnection: ClickPreviewConnection,
              handleOnClickResults: handleOnClickResults,
              handleExportDQRule: handleExportDQRule,
              executeTest: executeTest,
            }}
          />
        </Box>

        {previewId && (
          <PreviewValidationData autoScroll={autoScroll} connection={previewId} returnVal={() => setSearchParams({})} />
        )}

        {ruleId && <Executions fetchDataValidations={refreshData} />}
        <ExecutionsView fetchDataValidations={refreshData} />
      </Box>

      {openDialog && (
        <CustomDeleteModal
          loading={loadingDelete}
          disabled={loadingDelete}
          onClickDelete={deleteFiles}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}
