import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";

const OneDrive = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid size={12}>
        <TextField
          fullWidth
          size="small"
          name="Description"
          label={t("Description")}
          minRows={2}
          maxRows={4}
          multiline
          {...register("Description")}
        />
      </Grid>
      <Grid size={12}>
        <TextField
          {...register("clientId", { required: true })}
          label={t("Client Id")}
          fullWidth
          error={Boolean(errors.clientId)}
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid size={12}>
        <TextField
          {...register("clientSecret", { required: true })}
          label={t("Client Secret")}
          fullWidth
          error={Boolean(errors.clientSecret)}
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid size={12}>
        <TextField
          fullWidth
          {...register("tenentId", {
            required: true,
            minLength: {
              value: 4,
              message: t("Minimum length 4 char"),
            },
          })}
          error={Boolean(errors.tenentId)}
          helperText={errors?.tenentId?.message || null}
          label={t("Tenent Id")}
          variant="outlined"
          required
          size="small"
          onChange={(e) => (e.target.value = e.target.value.replace(/\s{2,}/g, " "))}
        />
      </Grid>

      <Grid size={12}>
        <TextField
          fullWidth
          {...register("userEmail", {
            required: true,
            minLength: {
              value: 4,
              message: t("Minimum length 4 char"),
            },
          })}
          error={Boolean(errors.userEmail)}
          helperText={errors?.userEmail?.message || null}
          label={t("User Email")}
          variant="outlined"
          required
          size="small"
          onChange={(e) => (e.target.value = e.target.value.replace(/\s{2,}/g, " "))}
        />
      </Grid>
    </>
  );
};

export default OneDrive;
