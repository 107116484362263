import { useSearchParams } from "react-router-dom";
import { isAzure } from "../../../../_helpers/Constant";
import CustomRadioGroup from "../../../CustomComponents/CustomRadioGroup";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid2";

import { useDispatch, useSelector } from "react-redux";
import { addTableData, addTables } from "../../../../Redux/reducers/connectionSlice";
import { useEffect, useState } from "react";
import APIServices from "../../../../services/app.service";
import {
  resetComparativeDetails,
  setFirstConnectionDetails,
  setSecondConnectionDetails,
  setSelectedSource1Table,
  setSelectedSource2Table,
  setSource1Columns,
  setSource1OrderByColumns,
  setSource1QueryInput,
  setSource1QueryResult,
  setSource2Columns,
  setSource2OrderByColumns,
  setSource2QueryInput,
  setSource2QueryResult,
} from "../../../../Redux/reducers/rowComparisonSlice";
import SelectSourceTable from "./SelectSourceTable";
import { Box, Paper } from "@mui/material";
import { showToast } from "../../../../Redux/reducers/toastSlice";

const SelectComparisonTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const ruleId = searchParams.get("ruleId");
  const isCustomQuery = JSON.parse(searchParams.get("isCustomQuery"));
  const firstSourceConId = searchParams.get("firstSourceConId");
  const secondSourceConId = searchParams.get("secondSourceConId");
  const firstDataSourceType = searchParams.get("firstDataSourceType");
  const secondDataSourceType = searchParams.get("secondDataSourceType");

  const connectionsInfo = useSelector((state) => state.connectionData);

  const selectedSource1Table = useSelector((state) => state.rowComparison.selectedSource1Table);
  const selectedSource2Table = useSelector((state) => state.rowComparison.selectedSource2Table);
  const source1QueryResult = useSelector((state) => state.rowComparison.source1QueryResult);
  const source2QueryResult = useSelector((state) => state.rowComparison.source2QueryResult);

  const source1SqlInput = useSelector((state) => state.rowComparison.source1SqlInput);
  const source2SqlInput = useSelector((state) => state.rowComparison.source2SqlInput);
  const firstConn = useSelector((state) => state.rowComparison.firstConnectionDetails);
  const secondConn = useSelector((state) => state.rowComparison.secondConnectionDetails);

  const columnsToValidate = useSelector((state) => state.rowComparison.columnsToValidate);

  const firstSourceConType = firstConn?.connectionType;
  const secondSourceConType = secondConn?.connectionType;

  const [loadingSource1Columns, setLoadingSource1Columns] = useState(false);
  const [loadingSource2Columns, setLoadingSource2Columns] = useState(false);

  const [source1TablesList, setSource1TableList] = useState([]);
  const [source2TablesList, setSource2TableList] = useState([]);

  const [loadingSource1ConData, setLoadingSource1ConData] = useState(false);
  const [loadingSource2ConData, setLoadingSource2ConData] = useState(false);

  useEffect(() => {
    const getSource1fetchTableList = () => {
      fetchTableList(
        firstDataSourceType,
        firstSourceConId,
        setFirstConnectionDetails,
        setSource1TableList,
        setSource1Columns,
        setLoadingSource1ConData,
        selectedSource1Table?.table_name,
      );
    };

    const getSource2fetchTableList = () => {
      fetchTableList(
        secondDataSourceType,
        secondSourceConId,
        setSecondConnectionDetails,
        setSource2TableList,
        setSource2Columns,
        setLoadingSource2ConData,
        selectedSource2Table?.table_name,
      );
    };
    firstDataSourceType && getSource1fetchTableList();
    secondDataSourceType && getSource2fetchTableList();
    if (isCustomQuery && source1SqlInput) {
      fetchColumnsFromQuerySource1(source1SqlInput);
      fetchColumnsFromQuerySource2(source2SqlInput);
    } else {
      (isAzure(firstSourceConType) || firstDataSourceType === "database") &&
        selectedSource1Table &&
        getConnections1TableData();
      (isAzure(secondSourceConType) || secondDataSourceType === "database") &&
        selectedSource2Table &&
        getConnections2TableData();
    }
  }, []);

  const resetValidation = () => {
    dispatch(resetComparativeDetails());
  };

  const onChangeIsQuery = (e) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("isCustomQuery", e.target.value);
      return newState;
    });

    resetValidation();
  };

  const getConnections1TableData = async () => {
    setLoadingSource1Columns(true);
    const isDatabase = !isAzure(firstSourceConType);
    try {
      const tableInfo = connectionsInfo?.[firstSourceConId]?.tables?.find(
        (e) =>
          e.table_name === selectedSource1Table?.table_name && e.table_schema === selectedSource1Table?.table_schema,
      );
      if (tableInfo?.table_data?.length && isDatabase) {
        dispatch(setSource1Columns(tableInfo?.table_data));
        const columns = selectedSource1Table?.table_name
          ? tableInfo?.table_data[0][selectedSource1Table?.table_name]?.Columns?.map((e) => {
              return { label: e?.COLUMN_NAME, value: e?.COLUMN_NAME };
            })
          : [];

        dispatch(setSource1OrderByColumns(columns));
      } else {
        const response = isDatabase
          ? await APIServices.ConnectionDetailsDataValidation({
              connectionId: firstSourceConId,
              tableName: [selectedSource1Table?.table_name],
              tableNames: [{ tableName: selectedSource1Table?.table_name, schema: selectedSource1Table?.table_schema }],
            })
          : await APIServices.GetFilesData({ id: firstSourceConId, tableName: selectedSource1Table?.table_name });
        if (isDatabase) {
          dispatch(
            addTableData({
              connectionId: firstSourceConId,
              tableName: selectedSource1Table?.table_name,
              tableSchema: selectedSource1Table?.table_schema,
              tableData: response?.data?.tablesData,
            }),
          );

          dispatch(setSource1Columns(response?.data?.tablesData));
          const columns = selectedSource1Table?.table_name
            ? response?.data?.tablesData[0][selectedSource1Table?.table_name]?.Columns?.map((e) => {
                return { label: e?.COLUMN_NAME, value: e?.COLUMN_NAME };
              })
            : [];

          dispatch(setSource1OrderByColumns(columns));
        } else {
          setSearchParams((prev) => {
            const newState = new URLSearchParams(prev);
            newState.set("firstDSFileName", selectedSource1Table?.table_name);
            return newState;
          });
          if (response?.data?.result?.rows?.length > 0) {
            dispatch(setSource1Columns(response?.data));
          } else {
            dispatch(showToast({ message: "No rows found in source", type: "error" }));
          }
        }
      }
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
    setLoadingSource1Columns(false);
  };

  const getConnections2TableData = async () => {
    setLoadingSource2Columns(true);
    const isDatabase = !isAzure(secondSourceConType);

    try {
      const tableInfo = connectionsInfo[secondSourceConId]?.tables?.find(
        (e) =>
          e.table_name === selectedSource2Table?.table_name && e.table_schema === selectedSource2Table?.table_schema,
      );
      if (tableInfo?.table_data?.length && isDatabase) {
        dispatch(setSource2Columns(tableInfo?.table_data));
        const columns = selectedSource2Table?.table_name
          ? tableInfo?.table_data[0][selectedSource2Table?.table_name]?.Columns?.map((e) => {
              return { label: e?.COLUMN_NAME, value: e?.COLUMN_NAME };
            })
          : [];

        dispatch(setSource2OrderByColumns(columns));
      } else {
        const response = isDatabase
          ? await APIServices.ConnectionDetailsDataValidation({
              connectionId: secondSourceConId,
              tableName: [selectedSource2Table?.table_name],
              tableNames: [{ tableName: selectedSource2Table?.table_name, schema: selectedSource2Table?.table_schema }],
            })
          : await APIServices.GetFilesData({ id: secondSourceConId, tableName: selectedSource2Table?.table_name });

        if (isDatabase) {
          dispatch(
            addTableData({
              connectionId: secondSourceConId,
              tableName: selectedSource2Table?.table_name,
              tableSchema: selectedSource2Table?.table_schema,
              tableData: response?.data?.tablesData,
            }),
          );
          dispatch(setSource2Columns(response?.data?.tablesData));

          const columns = selectedSource2Table?.table_name
            ? response?.data?.tablesData[0][selectedSource2Table?.table_name]?.Columns?.map((e) => {
                return { label: e?.COLUMN_NAME, value: e?.COLUMN_NAME };
              })
            : [];
          dispatch(setSource2OrderByColumns(columns));
        } else {
          setSearchParams((prev) => {
            const newState = new URLSearchParams(prev);
            newState.set("secondDSFileName", selectedSource2Table?.table_schema);
            return newState;
          });

          if (response?.data?.result?.rows.length > 0) {
            dispatch(setSource2Columns(response?.data));
          } else {
            dispatch(showToast({ message: "No rows found in source", type: "error" }));
          }
        }
      }
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
    setLoadingSource2Columns(false);
  };

  const fetchTableList = async (DSType, conId, setConDetails, setOptions, setColumns, setLoader, tableName) => {
    setLoader(true);
    try {
      if (DSType === "database") {
        const tables = connectionsInfo?.[conId]?.tables;
        if (tables?.length > 0) {
          setOptions(tables);
        } else {
          const conRes = await APIServices.ConnectionDetails(conId);
          const resTables = conRes?.data?.tables;
          const resTablesCon = conRes?.data?.ConnectionDetails;
          dispatch(addTables({ connectionId: conId, tables: resTables, connectionDetails: resTablesCon }));
          setOptions(conRes?.data?.tables);
          dispatch(setConDetails(conRes?.data?.ConnectionDetails));
        }
      } else {
        const response = await APIServices.GetFilesData({ id: conId, tableName });
        dispatch(setConDetails(response?.data?.fileDetails));
        if (response?.data?.result?.rows.length > 0) {
          dispatch(setColumns(response?.data));
        } else {
          dispatch(showToast({ message: "No rows found in source", type: "error" }));
        }
      }
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    } finally {
      setLoader(false);
    }
  };

  const onChangeSource1Table = (e, value) => {
    dispatch(setSelectedSource1Table(value));
    dispatch(setSource1Columns([]));
  };

  const onChangeSource2Table = (e, value) => {
    dispatch(setSelectedSource2Table(value));
    dispatch(setSource2Columns([]));
  };

  const fetchColumnsFromQuerySource1 = async (inputQuery) => {
    setLoadingSource1Columns(true);

    try {
      const payload = { ConnectionId: firstSourceConId, sqlQuery: inputQuery };
      const response = await APIServices.sqlPreviewData(payload);
      if (response?.data?.result?.length > 10) {
        response.data.result = response.data.result.splice(0, 10);
      }
      inputQuery && dispatch(setSource1QueryInput(inputQuery));
      dispatch(setSource1QueryResult(response.data));
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    } finally {
      setLoadingSource1Columns(false);
    }
  };

  const fetchColumnsFromQuerySource2 = async (inputQuery) => {
    setLoadingSource2Columns(true);
    try {
      const payload = { ConnectionId: secondSourceConId, sqlQuery: inputQuery };
      const response = await APIServices.sqlPreviewData(payload);
      if (response?.data?.result?.length > 10) {
        response.data.result = response.data.result.splice(0, 10);
      }
      inputQuery && dispatch(setSource2QueryInput(inputQuery));
      dispatch(setSource2QueryResult(response.data));
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    } finally {
      setLoadingSource2Columns(false);
    }
  };

  const resetSource1QueryResult = () => {
    dispatch(setSource1QueryResult(null));
  };

  const resetSource2QueryResult = () => {
    dispatch(setSource2QueryResult(null));
  };

  return (
    <Box component={Paper} className="comparisonDatasourcesContainer">
      {!isAzure(secondSourceConType) &&
        !isAzure(firstSourceConType) &&
        (firstDataSourceType === "database" || secondDataSourceType === "database") && (
          <CustomRadioGroup
            value={isCustomQuery}
            onChange={onChangeIsQuery}
            disabled={Boolean(ruleId)}
            options={[
              { value: true, label: t("Yes") },
              { value: false, label: t("No") },
            ]}
            label={t("Do you wish to enter raw SQL query?")}
          />
        )}
      <Grid container spacing={2}>
        <SelectSourceTable
          title={t("1st Data Source")}
          connectionDetails={firstConn}
          columnsLoading={loadingSource1Columns}
          selectedTable={selectedSource1Table}
          tablesList={source1TablesList}
          defaultSqlInput={source1SqlInput}
          onChangeTable={onChangeSource1Table}
          handleGoButton={getConnections1TableData}
          connectionDataLoading={loadingSource1ConData}
          selectedColumns={columnsToValidate?.source1}
          fetchColumnsFromQuery={fetchColumnsFromQuerySource1}
          queryResult={source1QueryResult}
          resetQueryResult={resetSource1QueryResult}
        />

        <SelectSourceTable
          title={t("2nd Data Source")}
          connectionDetails={secondConn}
          columnsLoading={loadingSource2Columns}
          selectedTable={selectedSource2Table}
          tablesList={source2TablesList}
          defaultSqlInput={source2SqlInput}
          onChangeTable={onChangeSource2Table}
          handleGoButton={getConnections2TableData}
          connectionDataLoading={loadingSource2ConData}
          selectedColumns={columnsToValidate?.source2}
          fetchColumnsFromQuery={fetchColumnsFromQuerySource2}
          queryResult={source2QueryResult}
          resetQueryResult={resetSource2QueryResult}
        />
      </Grid>
    </Box>
  );
};

export default SelectComparisonTable;
