import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { formatNumber } from "../../_helpers/utils";
const STresult = {
  "& .MuiSvgIcon-root": {
    fontSize: "2rem",
  },
  "& p.MuiTypography-root": {
    color: "#333",
  },
  "& p": {
    fontWeight: "500",
  },
  "&  div": {
    color: "#71787e",
    textDecoration: "none",
    "& h5": {
      color: "#006dba",
      fontSize: "1rem",
      "& b": {
        fontSize: "14px",
        marginLeft: "5px",
      },
    },
  },
  "& .Rsec": {
    textAlign: "center",
    display: "inline-block",
    marginBottom: "16px",
    "& h5": {
      display: "flex",
      alignItems: "center",
    },
  },
  "& .bBox": {
    width: "100%",
    display: "flex",
    "& p": {
      display: "inline-block",
      width: "100%",
      border: "1px solid #eee",
      textAlign: "center",
      minHeight: "51px",
      float: "left",
      padding: "5px 0",
      "&:last-child": {
        // lineHeight: "38px",
        "& a": {
          fontWeight: "normal",
        },
      },
      "& a": {
        display: "block",
      },
    },
  },
};

const DQRulesOverview = ({ tests }) => {
  return (
    <Box className="container">
      <Typography className="DQhead" variant="h6">
        Data Quality
      </Typography>

      <Box className="compSec">
        <Grid container my="8px" spacing={2}>
          <Grid size={4}>
            <Box className="DBsec">
              <Grid container spacing={1} alignItems="center">
                <Grid size={8}>
                  <Box sx={STresult}>
                    <StorageOutlinedIcon /> <Typography>DB</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box className="wBox">
                <Typography>
                  Rules <b>{tests?.singleDatabase?.ValidatedRecords?.Tests}</b>
                </Typography>
                <Typography>
                  DQ Checks <b>{tests?.singleDatabase?.ValidatedRecords?.TotalValidations}</b>
                </Typography>
                <Typography>
                  Records Validated <b>{formatNumber(tests?.singleDatabase?.ValidatedRecords.ValidatedRecords)}</b>
                </Typography>
                <Typography>
                  Data Sources <b> {tests.singleDatabase?.dataSource} </b>
                </Typography>
              </Box>

              <Grid container className="PassFail" mt="0!important">
                <Grid size={6}>
                  <Box className="passed">
                    <CheckCircleRoundedIcon sx={{ color: "#50cd89" }} />
                    <Typography>
                      {formatNumber(tests?.singleDatabase?.ValidatedRecords.Passed)}
                      {" Passed"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid size={6}>
                  <Box className="failed">
                    <CancelRoundedIcon color="error" />
                    <Typography>
                      {formatNumber(tests?.singleDatabase?.ValidatedRecords.Failed)}
                      {" Failed"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid size={4}>
            <Box className="DBsec">
              <Grid container spacing={1} alignItems="center">
                <Grid size={8}>
                  <Box sx={STresult}>
                    <ArticleOutlinedIcon /> <Typography>File</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box className="wBox">
                <Typography>
                  Rules <b>{tests?.singleFile?.ValidatedRecords?.Tests}</b>
                </Typography>
                <Typography>
                  DQ Checks <b>{tests?.singleFile?.ValidatedRecords?.TotalValidations}</b>
                </Typography>
                <Typography>
                  Records Validated <b>{formatNumber(tests?.singleFile?.ValidatedRecords?.ValidatedRecords)}</b>
                </Typography>
                <Typography>
                  Connections <b> {tests?.singleFile?.dataSource}</b>
                </Typography>
              </Box>

              <Box>
                <Grid container className="PassFail" mt="0!important">
                  <Grid size={6}>
                    <Box className="passed">
                      <CheckCircleRoundedIcon sx={{ color: "#50cd89" }} />
                      <Typography>
                        {formatNumber(tests?.singleFile?.ValidatedRecords?.Passed)}
                        Passed
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid size={6}>
                    <Box className="failed">
                      <CancelRoundedIcon color="error" />
                      <Typography>{formatNumber(tests?.singleFile?.ValidatedRecords?.Failed)} Failed</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid size={4}>
            <Box height={"90%"} className="DBsec">
              <Grid container spacing={1} alignItems="center">
                <Grid size={8}>
                  <Box sx={STresult}>
                    <CompareArrowsIcon /> <Typography>Comparative</Typography>
                  </Box>
                </Grid>
              </Grid>

              <Box className="wBox">
                <Typography>
                  Total row-comparison rules <b>{tests?.comparisonRules?.totalComparisonRules}</b>
                </Typography>
                <Typography>
                  Positional row-comparison rules <b>{tests?.comparisonRules?.fullScanComparisonRules}</b>
                </Typography>
                <Typography>
                  Full scan row-comparison rules <b>{tests?.comparisonRules?.positionalComparisonRules}</b>
                </Typography>
                <Typography>
                  Total row compared till date <b>{formatNumber(tests?.comparisonRules?.totalRowsCompared)}</b>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DQRulesOverview;
