import { Typography } from "@mui/material";
import InnerHeader from "../Layout/InnerHeader";

const AIAgent = () => {
  return (
    <InnerHeader>
      <Typography variant="h6" className="upper-case">
        AI Agent
      </Typography>
    </InnerHeader>
  );
};

export default AIAgent;
