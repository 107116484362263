import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Box, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import InnerHeader from "../Layout/InnerHeader.js";
import ECRulesList from "./ECRulesList";
import ECAddColumnsContainer from "./ECAddColumnsContainer";
import SelectECConnectionDetails from "./SelectECConnectionDetails";
import SkeletonLoader from "../SkeletonLoader";
import APIServices from "../../services/app.service";
import { SnackbarContext } from "../../App";
import CustomLoadingButton from "../CustomComponents/CustomsButtons/CustomLoadingButton";
import CustomBackButton from "../CustomComponents/CustomsButtons/CustomBackButton";
import CustomSwitchButton from "../CustomComponents/CustomSwitchButton";
import { isAzure } from "../../_helpers/Constant.js";
import { useDispatch, useSelector } from "react-redux";
import {
  addConnectionsList,
  addTableData,
  addTables,
  addValidationsForm,
} from "../../Redux/reducers/connectionSlice.js";
import SingleDatabaseQuery from "./SingleDatabaseQuery.js";
import DatabaseResultsPreview from "../Validations/DatabaseResultsPreview.js";

const ExecutionConfigurations = () => {
  const dispatch = useDispatch();
  const connectionsInfo = useSelector((state) => state.connectionData);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const scrollRef = useRef();
  const { setSnack } = useContext(SnackbarContext);
  const [searchParams, setSearchParams] = useSearchParams({ ruleName: "", testType: "database", IsCustomQuery: false });
  const connectionId = searchParams.get("connectionId");
  const connectionType = searchParams.get("connectionType");
  const testType = searchParams.get("testType");
  const tableName = searchParams.get("tableName");
  const schema = searchParams.get("schema");
  const ruleName = searchParams.get("ruleName");
  const fileName = searchParams.get("fileName");
  const ruleId = searchParams.get("ruleId");
  const IsCustomQuery = JSON.parse(searchParams.get("IsCustomQuery"));
  const isExistsExecutions = JSON.parse(searchParams.get("isExistsExecutions"));

  const [columnValidationData, setColumnValidationData] = useState([{ columns: [], rules: [] }]);
  const [columnsData, setColumnsData] = useState([]);
  const [validationsFormData, setValidationsFormData] = useState([]);
  const [loadingColumns, setLoadingColumns] = useState(false);
  const [connectionsList, setConnectionsList] = useState([]);
  const [loadingConList, setLoadingConList] = useState(false);
  const [tablesList, setTablesList] = useState([]);
  const [tablesLoading, setTablesLoading] = useState(false);
  const [queryInput, setQueryInput] = useState("");
  const [isQueryOpened, setIsQueryOpened] = useState(!queryInput);
  const [previewResult, setPreviewResult] = useState([]);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [createTestLoading, setCreateTestLoading] = useState(false);
  const [updateTestLoading, setUpdateTestLoading] = useState(false);

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getConnectionsList(testType);
    connectionId && (testType === "database" ? getTablesData(connectionId) : getFileColumns(connectionId));
    ruleId && getEditValidationData();
    isAzure(connectionType)
      ? tableName && getFileColumns(connectionId, tableName)
      : tableName && testType === "database" && handleSelectTable(tableName, schema);
  }, []);

  const handleSelectTable = async (tableNameData, tableSchemaData) => {
    resetData();
    const IsCusQry = JSON.parse(searchParams.get("IsCustomQuery"));

    setLoadingColumns(true);
    const payload = {
      connectionId,
      tableName: !IsCusQry ? [tableNameData] : [],
      tableNames: !IsCusQry ? [{ tableName: tableNameData, schema: tableSchemaData }] : [],
      CustomQuery: queryInput || IsCusQry ? tableNameData : "",
      IsCustomQuery: IsCusQry,
    };
    try {
      const tableInfo = connectionsInfo?.[connectionId]?.tables?.find(
        (e) => e.table_name === tableNameData && e.table_schema === tableSchemaData,
      );
      if (
        tableInfo?.table_data &&
        Array.isArray(tableInfo?.table_data) &&
        tableInfo?.table_data.length > 0 &&
        connectionsInfo.validationsForm
      ) {
        const columnsArray = Object.values(tableInfo?.table_data?.[0])?.[0]?.Columns;
        setColumnsData(columnsArray || []);
        setValidationsFormData(
          connectionsInfo.validationsForm?.map((each) => ({
            ...each,
            ControlProperties: { IsChecked: true },
          })),
        );
        setTableData(tableInfo?.table_data);
      } else {
        const response = await APIServices.ConnectionDetailsDataValidation(payload);
        dispatch(
          addTableData({
            connectionId,
            tableName: tableNameData,
            tableSchema: tableSchemaData,
            tableData: response?.data?.tablesData,
          }),
        );
        dispatch(addValidationsForm({ validationsForm: response?.data?.ValidationsForm }));

        const columnsArray = Object.values(response?.data?.tablesData?.[0])?.[0]?.Columns;
        setColumnsData(columnsArray || []);
        setValidationsFormData(
          response?.data?.ValidationsForm?.map((each) => ({
            ...each,
            ControlProperties: { IsChecked: true },
          })),
        );
        setTableData(response?.data?.tablesData);
      }
    } catch (e) {
      handleApiError(e);
    }
    setLoadingColumns(false);
  };

  const getConnectionsList = async (type) => {
    setLoadingConList(true);
    try {
      const connectionList = connectionsInfo?.connections?.[type?.toLowerCase()];

      if (connectionList) {
        setConnectionsList(connectionList?.data);
      } else {
        const response = await APIServices.getConnectionsByType({ type: type });
        dispatch(
          addConnectionsList({
            type: type?.toLowerCase(),
            connectionsList: response?.data,
          }),
        );
        setConnectionsList(response?.data?.data);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoadingConList(false);
    }
  };

  const getTablesData = async (conId) => {
    setTablesList([]);
    setTablesLoading(true);
    try {
      const tables = connectionsInfo?.[conId]?.tables;
      if (Array.isArray(tables) && tables?.length > 0) {
        setTablesList(tables);
      } else {
        const results = await APIServices.ConnectionDetails(conId);
        dispatch(
          addTables({
            connectionId: conId,
            tables: results?.data?.tables,
            connectionDetails: results?.data?.ConnectionDetails,
          }),
        );
        setTablesList(results?.data?.tables);
      }
    } catch (error) {
      handleApiError(error);
    }
    setTablesLoading(false);
  };

  const getFileColumns = async (conId, tableNameData) => {
    setLoadingColumns(true);
    try {
      const response = await APIServices.GetFilesData({ id: conId, tableName: tableNameData });
      const updatedFormData = response?.data?.ValidationsForm?.map((each) => ({
        ...each,
        ControlProperties: { IsChecked: true },
      }));
      const fileDetails = response?.data?.fileDetails;
      const resultRows = response?.data?.result?.rows;
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("fileName", fileDetails?.fileName);
        newState.set(
          tableNameData ? "tableName" : "fileName",
          tableNameData ? tableNameData : response?.data?.fileDetails?.fileName,
        );
        newState.set("connectionType", response?.data?.fileDetails?.connectionType);
        newState.set("connectionId", conId);
        !tableNameData && tableName && newState.delete("tableName");
        return newState;
      });
      setValidationsFormData(updatedFormData);

      let columns = [];
      if (fileDetails?.firstRowisHeader) {
        columns = resultRows[0];
      } else {
        // Generate an array of column headers based on the number of columns
        const numColumns = resultRows[0]?.length || 0;
        columns = Array.from({ length: numColumns }, (_, index) => `Column ${index + 1}`);
      }
      const formatedFileColumns = columns?.map((each) => ({
        COLUMN_NAME: each,
      }));

      setColumnsData(formatedFileColumns);

      setTableData({
        columns,
        rows: fileDetails?.firstRowisHeader ? resultRows?.slice(1) : resultRows,
      });
    } catch (error) {
      handleApiError(error);
    }
    setLoadingColumns(false);
  };

  const handleApiError = (error) => {
    setSnack({ message: error?.response?.data?.message ?? error.message, open: true, colour: "error" });
  };

  const transformData = (originalData) => {
    const formatData = originalData?.map((each) => ({
      ...each,
      columns: each?.columns?.map((each) => each),
    }));
    // const finalValidationData = [];

    // formatData?.forEach((eachrow) => {
    //   eachrow?.columns?.forEach((column) => {
    //     const existingColumnIndex = finalValidationData.findIndex(
    //       (item) => item.ColumnName === column
    //     );

    //     if (existingColumnIndex === -1) {
    //       finalValidationData.push({
    //         ColumnName: column,
    //         validation: eachrow?.rules,
    //       });
    //     } else {
    //       const oldData = finalValidationData[existingColumnIndex].validation;
    //       const newData = [...oldData, ...eachrow.rules];

    //       finalValidationData[existingColumnIndex].validation = newData;
    //     }
    //   });
    // });
    const formatedOriginalData = formatData?.map((each) => ({
      tablename: testType !== "database" ? fileName : tableName || "",
      CustomQuery: queryInput,
      IsCustomQuery: IsCustomQuery,
      columns: each?.columns?.map((column) => ({ ColumnName: column, validation: each?.rules })),
      ValidationProperties: [],
    }));
    return formatedOriginalData;
  };

  const scrollToTop = () => {
    setTimeout(() => {
      scrollRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
        boundary: scrollRef.current,
      });
    }, 400);
  };

  const handleValidation = async () => {
    const isAddedColAndRules = columnValidationData?.some(
      (each) => each?.columns?.length === 0 || each?.rules?.length === 0,
    );
    const isCheckValidate = columnValidationData?.some((each) =>
      each?.rules?.some((eachRule) =>
        eachRule?.NestedControls?.length &&
        eachRule?.NestedControls?.some((eachnes) => ["Text", "Integer"]?.includes(eachnes?.ControlType))
          ? !eachRule?.NestedControls?.[0]?.SelectedValue
          : false,
      ),
    );
    if (isCheckValidate) {
      setSnack({ message: "Enter Value For Checks", open: true, colour: "warning" });
      setSearchParams((params) => {
        params.set("validateCheck", true);
        return params;
      });
      return false;
    }
    if (isAddedColAndRules) {
      setSnack({ message: "Add Missing Columns Or Rule Checks", open: true, colour: "warning" });
      return false;
    }
    return true;
  };

  const onClickCreateEC = async () => {
    if (!(await handleValidation())) {
      return;
    }
    setCreateTestLoading(true);
    const finalValidationsColumns = transformData(columnValidationData);
    const payload = {
      FirstTableName: tableName,
      FirstSourceSchema: schema,
      TestName: ruleName.trim(),
      TestType: testType === "database" && !isAzure(connectionType) ? "Single Database" : "Single File",
      Tables: finalValidationsColumns,
      ConnectionId: connectionId,
      ...(testType !== "database" && { File: fileName }),
      isPrivate: true,
    };
    try {
      await APIServices.createValidation(payload);
      setSnack({ message: "Created Successfully", open: true, colour: "success" });
      navigate("/DataQualityRule");
    } catch (error) {
      setSnack({ message: error?.response?.data?.TestName?.message || error.message, open: true, colour: "error" });
      if (error?.response?.data?.TestName?.message) {
        setSearchParams((params) => {
          params.set("ecNameError", true);
          return params;
        });
        scrollToTop();
      }
    } finally {
      setCreateTestLoading(false);
    }
  };

  const ValidationCheck = async () => {
    if (!(await handleValidation())) {
      return;
    }

    setPreviewLoading(true);
    const finalValidations = await transformData(columnValidationData);
    const data = {
      TestType: testType === "database" && !isAzure(connectionType) ? "Single Database" : "Single File",
      Tables: finalValidations,
      ConnectionId: connectionId,
      FirstTableName: tableName,
      FirstSourceSchema: schema,
    };
    try {
      const response = await APIServices.checkValidation(data);
      setPreviewResult(response?.data?.response?.ResponseObject?.Validations);
    } catch (e) {
      handleApiError(e);
    } finally {
      setPreviewLoading(false);
    }
  };

  const getEditValidationData = async () => {
    try {
      const response = await APIServices.editValidation(ruleId);
      setSearchParams((params) => {
        if (testType !== "database") {
          params.set("fileName", response?.data?.ConnectionDetails?.fileName);
        } else {
          params.set("tableName", response?.data?.Tables?.[0]?.IsCustomQuery ? "" : response?.data?.FirstTableName);
          params.set("schema", response?.data?.FirstSourceSchema);
        }
        params.set("IsCustomQuery", response?.data?.Tables?.[0]?.IsCustomQuery);
        params.set("isFullScan", response?.data?.isFullScan);
        params.set("isPrivate", response?.data?.isPrivate);
        params.set("isExistsExecutions", response?.data?.noOfExecutions > 0 || false);
        return params;
      });

      const formatedEditData = response?.data?.Tables?.map((each) => ({
        columns: each?.columns?.map((column) => column?.ColumnName),
        rules: each?.columns?.[0]?.validation,
      }));

      if (response?.data?.Tables?.[0]?.IsCustomQuery) {
        setQueryInput(response?.data?.Tables?.[0]?.CustomQuery);
        await handleSelectTable(response?.data?.Tables?.[0]?.CustomQuery);
        setIsQueryOpened(false);
      }

      const tableNameData = searchParams.get("tableName");
      const tableSchemaData = searchParams.get("schema");
      isAzure(response?.data?.ConnectionType)
        ? getFileColumns(connectionId, tableNameData)
        : tableNameData && testType === "database" && (await handleSelectTable(tableNameData, tableSchemaData));
      setColumnValidationData(formatedEditData);
    } catch (e) {
      handleApiError(e);
    }
  };

  const handleIsCustomQuery = (e) => {
    setSearchParams((params) => {
      params.set("IsCustomQuery", e.target.checked);
      params.set("testType", "database");
      params.delete("connectionId");
      params.delete("tableName");
      return params;
    });
    testType !== "database" && getConnectionsList("database");
    e.target.checked && !isQueryOpened && setIsQueryOpened(true);
    resetData();
  };

  const resetData = () => {
    setColumnValidationData([{ columns: [], rules: [] }]);
    setColumnsData([]);
    setValidationsFormData([]);
    setTableData([]);
  };

  const handleBack = () => {
    navigate("/DataQualityRule");
  };

  const onClickUpdateEc = async () => {
    if (!(await handleValidation())) {
      return;
    }
    setUpdateTestLoading(true);
    const finalValidationsColumns = transformData(columnValidationData);
    const trimmedRulename = ruleName.trim();
    const payload = {
      FirstTableName: tableName,
      FirstSourceSchema: schema,
      TestName: trimmedRulename,
      TestType: testType === "database" ? "Single Database" : "Single File",
      Tables: finalValidationsColumns,
      ConnectionId: connectionId,
      ...(testType !== "database" && { File: fileName }),
      isPrivate: true,
    };
    try {
      await APIServices.updateValidation(payload, ruleId);

      setSnack({ message: "Updated Successfully", open: true, colour: "success" });
      navigate("/DataQualityRule");
    } catch (error) {
      setSnack({ message: error?.response?.data?.TestName?.message || error.message, open: true, colour: "error" });
      if (error?.response?.data?.TestName?.message) {
        setSearchParams((params) => {
          params.set("ecNameError", true);
          return params;
        });
        scrollToTop();
      }
    } finally {
      setUpdateTestLoading(false);
    }
  };

  return (
    <>
      <InnerHeader>
        <Box width="100%" className="v-center" gap={2}>
          <CustomBackButton onClick={handleBack}>{t("Back")}</CustomBackButton>
          <Box className="v-center">
            <Typography variant="h6" className="upper-case">{`${t("Data Quality Rules")}`}</Typography>
          </Box>
        </Box>
      </InnerHeader>

      <Box ref={scrollRef} className="pt74 ecContainer">
        <Box className="container">
          <Box
            className="ecConfigTitle space-between"
            sx={{ py: testType === "database" && !isAzure(connectionType) ? 0 : 1.25, px: 1.2 }}>
            <Typography>Create DQ Rule</Typography>
            {testType === "database" && !isAzure(connectionType) && (
              <Box sx={{ pl: 1, float: "left" }} className="v-center">
                <CustomSwitchButton
                  labels={[t("Table Selection"), t("Raw Query")]}
                  disabled={ruleId}
                  checked={IsCustomQuery}
                  onChange={handleIsCustomQuery}
                />
              </Box>
            )}
          </Box>
          <Paper className="flex column" sx={{ p: 2, borderRadius: "12px" }}>
            <SelectECConnectionDetails
              tablesList={tablesList}
              tablesLoading={tablesLoading}
              loadingConList={loadingConList}
              connectionsList={connectionsList}
              getConnectionsList={getConnectionsList}
              handleSelectTable={handleSelectTable}
              getTablesData={getTablesData}
              getFileColumns={getFileColumns}
              resetData={resetData}
            />
            {IsCustomQuery && connectionId && (
              <SingleDatabaseQuery
                queryInputData={queryInput}
                getTableData={handleSelectTable}
                finalValidations={[]}
                loadingTableDetails={loadingColumns}
                setQueryInputData={setQueryInput}
                isQueryOpened={isQueryOpened}
                setIsQueryOpened={setIsQueryOpened}
              />
            )}
            {loadingColumns ? (
              <SkeletonLoader />
            ) : (
              validationsFormData?.length > 0 && (
                <>
                  <Grid container className="ecRulesColumns">
                    <ECRulesList validationTemplate={validationsFormData} />
                    <ECAddColumnsContainer
                      tableData={tableData}
                      loadingCreateOrPreview={previewLoading || createTestLoading || updateTestLoading}
                      columnsData={columnsData}
                      columnValidationData={columnValidationData}
                      setColumnValidationData={setColumnValidationData}
                    />
                  </Grid>
                  <Box className="v-center" gap={2}>
                    <CustomLoadingButton
                      onClick={ValidationCheck}
                      sx={{ ml: "auto", mt: 2 }}
                      disabled={previewLoading || createTestLoading || updateTestLoading}
                      loading={previewLoading}>
                      {t("Preview")}
                    </CustomLoadingButton>
                    {ruleId && !isExistsExecutions && (
                      <CustomLoadingButton
                        onClick={onClickUpdateEc}
                        sx={{ mt: 2 }}
                        disabled={
                          previewLoading ||
                          !ruleName ||
                          !columnValidationData?.length ||
                          createTestLoading ||
                          updateTestLoading
                        }
                        loading={updateTestLoading}>
                        Update
                      </CustomLoadingButton>
                    )}
                    <CustomLoadingButton
                      color="success"
                      sx={{ mt: 2 }}
                      onClick={onClickCreateEC}
                      disabled={
                        previewLoading ||
                        !ruleName ||
                        !columnValidationData?.length ||
                        createTestLoading ||
                        updateTestLoading
                      }
                      loading={createTestLoading}>
                      {ruleId ? "Create As New" : "Create Rule"}
                    </CustomLoadingButton>
                  </Box>
                </>
              )
            )}
          </Paper>
        </Box>
        {previewResult?.length > 0 && (
          <DatabaseResultsPreview validations={previewResult} handleClose={() => setPreviewResult([])} />
        )}
      </Box>
    </>
  );
};

export default ExecutionConfigurations;
