import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import CloseIcon from "@mui/icons-material/Close";

import AddIcon from "@mui/icons-material/Add";
import ApiService from "../../../services/app.service";
import MasterdataUpload from "../../MasterData/MasterDataUpload";

const Fuzzyinputs = ({
  inputParams,
  setInputParams,
  inputs,
  columnNames,
  disableDataCleansing,
  setDisableDataCleansing,
}) => {
  const { t } = useTranslation();
  const [masterdatalist, setMasterdatalist] = useState([]);
  const [state, setState] = useState(false);

  const getMasterdatalist = async () => {
    const response = await ApiService.Masterdatalist();
    setMasterdatalist(response.data?.data);
  };

  const validateInputs = (inputData) => {
    let count = 0;
    if (inputData.length !== 0) {
      inputData.forEach((inp) => {
        if (inp.Columnvalue.length !== 0 && inp.masterData.length !== 0 && inp.Similarity >= 0) {
          count++;
        }
      });
      setDisableDataCleansing(count !== inputData.length);
    } else {
      setDisableDataCleansing(false);
    }
  };

  const handleFormChange = (index, event, value) => {
    const data = JSON.parse(JSON.stringify(inputParams));
    data[index][event.target.name] = value;
    validateInputs(data);
    setInputParams(data);
  };

  const addFields = () => {
    const newInputData = [...inputParams, inputs];
    validateInputs(newInputData);
    setInputParams(newInputData);
  };

  const toggleDrawer = () => {
    setState(false);
  };

  const removeFields = (index) => {
    const data = [...inputParams];
    data.splice(index, 1);
    setInputParams(data);
  };

  useEffect(() => {
    validateInputs(inputParams);
    getMasterdatalist();
  }, []);

  return (
    <>
      <Grid container rowSpacing={2}>
        <Grid size={12}>
          <Box className="space-between">
            <Typography variant="detailTitle">
              {t("Replace Columns Inputs")} {" : "}
            </Typography>
            <Button disabled={disableDataCleansing} onClick={addFields}>
              <AddIcon mr={1} fontSize="small" /> {t("Add more")}
            </Button>
          </Box>
        </Grid>
        {inputParams.map((input, index) => (
          <Grid size={12} container spacing={1} key={index}>
            <Grid size="grow">
              <TextField
                select
                fullWidth
                size="small"
                name="Columnvalue"
                label={t("Select Column")}
                value={input.Columnvalue}
                required
                onChange={(e) => handleFormChange(index, e, e.target.value)}>
                {columnNames
                  ?.sort((a, b) => a.localeCompare(b))
                  .map((col, indx) => (
                    <MenuItem key={indx} value={col}>
                      {col}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid size="grow">
              <TextField
                select
                fullWidth
                size="small"
                name="masterData"
                required
                label={t("Select Master Dataset")}
                value={input.masterData}
                onChange={(e) => handleFormChange(index, e, e.target.value)}>
                {masterdatalist?.map((col, indx) => (
                  <MenuItem key={indx} value={col._id}>
                    {col.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid size="grow">
              <TextField
                fullWidth
                type="number"
                inputProps={{
                  min: 1,
                  max: 100,
                  step: 1,
                }}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  const restrictedKeys = ["Minus", "Equal", "KeyE"];
                  if (restrictedKeys.includes(e.code)) {
                    e.preventDefault();
                  }
                }}
                size="small"
                name="Similarity"
                label={t("Degree of Similarity")}
                required
                value={input.Similarity}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  if (!Number.isNaN(inputValue) && inputValue <= 100) {
                    handleFormChange(index, event, inputValue.trim().length === 0 ? inputValue : Number(inputValue));
                  }
                }}
              />
            </Grid>
            {Object.keys(inputParams).length > 1 && (
              <Grid size={1}>
                <Box sx={{ p: "0 !important" }}>
                  <Typography color="inherit" variant="subtitle1" component="div">
                    <Tooltip title="Delete">
                      <IconButton size="small" onClick={() => removeFields(index)}>
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        ))}
        <Grid size={12}>
          <FormControl>
            <FormLabel component="legend">
              {t("Note")} : {t("Only Master Datasets Uploaded in")}
              <Button onClick={() => setState(true)}>{t("Master Data")}</Button>
              {t("can be accessed here.")}
            </FormLabel>
          </FormControl>
        </Grid>
      </Grid>

      {state && <MasterdataUpload toggleDrawer={toggleDrawer} fetchList={getMasterdatalist} />}
    </>
  );
};

export default Fuzzyinputs;
