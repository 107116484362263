import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomLoadingButton from "../../../CustomComponents/CustomsButtons/CustomLoadingButton";

const AbortModal = ({ showModal, ruleExecuteRequestAbort, setAbortModalData }) => {
  const { t } = useTranslation();
  const [loadingAbort, setLoadingAbort] = useState(false);
  const handleAbort = async () => {
    setLoadingAbort(true);
    await ruleExecuteRequestAbort();
    setLoadingAbort(false);
  };
  return (
    <Dialog className="dialogCus" open={showModal} onClose={() => setAbortModalData({})}>
      <DialogTitle variant="h6" id="alert-dialog-title">
        Are you sure want to Abort ?
      </DialogTitle>
      <DialogActions>
        <CustomLoadingButton
          variant="outlined"
          autoFocus
          disabled={loadingAbort}
          loading={loadingAbort}
          onClick={() => handleAbort()}>
          {t("Yes")}
        </CustomLoadingButton>
        <Button variant="outlined" color="error" size="small" onClick={() => setAbortModalData({})}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AbortModal;
