import React, { useEffect, useState, useCallback, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Box, FormControl, IconButton, MenuItem, Paper, Select, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { useTranslation } from "react-i18next";
import CustomAgGridPagination from "./CustomAgGridPagination";
import { useSelector } from "react-redux";

export const CustomAgGrid = ({
  gridRef,
  closePreview = null,
  setSelected = null,
  setSelectedRowId = null,
  rows,
  headCells,
  context,
  previewId = null,
  onClickRow = null,
  noBorder = false,
  isCursorEnabled = false,
  pagination = true,
}) => {
  const { t } = useTranslation();
  const { user_details } = useSelector((state) => state.auth);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [resetStatus, setResetStatus] = useState([]);

  const defaultColDef = useMemo(() => ({ resizable: true, menuTabs: ["filterMenuTab", "columnsMenuTab"] }), []);

  const customLocaleText = useMemo(
    () => ({
      page: t("Page"),
      to: t("to"),
      of: t("of"),
      more: t("more"),
      first: t("First"),
      previous: t("Previous"),
      next: t("Next"),
      last: t("Last"),
    }),
    [t],
  );

  const gridOptions = useMemo(
    () => ({
      context: context,
      suppressCellFocus: true,
      suppressBrowserResizeObserver: true,
      isRowSelectable: (rowNode) => {
        if (rowNode?.data?.isExecutionRunning > 0) {
          return false;
        }
        return (
          !rowNode?.data?.UsedInTests?.length > 0 &&
          (rowNode?.data?.UserId === user_details?.UserId || user_details?.RoleName === "Admin")
        );
      },
      getRowClass: () => {
        if (isCursorEnabled) {
          return "pointer-cursor";
        }
        return "";
      },
      localeText: customLocaleText,
    }),
    [context, user_details?.UserId, user_details?.RoleName, customLocaleText, isCursorEnabled],
  );

  const rowClassRules = useMemo(() => ({ "preview-highlight": (params) => params?.data?.preview || false }), []);

  useEffect(() => {
    const api = gridRef?.current?.api;
    if (api) {
      api.forEachNode((rowNode) => {
        const data = rowNode.data;
        const isPreview = data.id === previewId || data._id === previewId;
        rowNode.setSelected(isPreview);
      });
    }
  }, [previewId, gridRef]);

  const onSelectionChanged = useCallback(() => {
    const gridApi = gridRef?.current?.api;
    if (gridApi) {
      const currentPage = gridApi.paginationGetCurrentPage();
      const rowModel = gridApi.rowModel;
      const rowsToDisplay = rowModel?.rowsToDisplay || [];
      const selectedRows = rowsToDisplay
        .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
        .filter((node) => node.isSelected());
      const rowIds = selectedRows.map((node) => node.id);
      const selectedData = selectedRows.map((node) => node.data?._id || node.data?.id || node.data?.Id);
      setSelectedRowId?.(rowIds);
      setSelected?.(selectedData);
    }
  }, [gridRef, pageSize, setSelectedRowId, setSelected]);

  const clearFilters = useCallback(() => {
    gridRef.current?.api.setFilterModel(null);
  }, [gridRef]);

  const onFilterChanged = useCallback(() => {
    const filterModel = gridRef.current?.api.getFilterModel();
    setResetStatus(Object.keys(filterModel));
    closePreview?.();
    gridRef.current?.api.hideOverlay();
    gridRef.current?.api.deselectAll();
    if (!gridRef.current?.api?.rowModel?.rowsToDisplay?.length) {
      gridRef.current?.api.showNoRowsOverlay();
    }
  }, [gridRef, closePreview]);

  const onFirstDataRendered = useCallback(() => {
    gridRef?.current?.api?.sizeColumnsToFit();
  }, [gridRef]);

  const onPageSizeChanged = useCallback(
    (e) => {
      const value = e.target.value;
      setPageSize(value);
      gridRef.current?.api?.paginationSetPageSize(Number(value));
    },
    [gridRef],
  );

  const onPaginationChanged = useCallback(
    (e) => {
      e.api.deselectAll();
      if (gridRef.current.api) {
        const Page = gridRef.current.api.paginationGetCurrentPage() + 1;
        setCurrentPage(Page);
        const pages = gridRef.current.api.paginationGetTotalPages();
        setTotalPages(pages);
      }
    },
    [gridRef],
  );

  const translatedHeadCells = useMemo(
    () =>
      headCells.map((column) => ({ ...column, headerName: t(column.headerName), headerTooltip: t(column.headerName) })),
    [headCells, t],
  );

  const onRowClicked = useCallback(
    (e) => {
      onClickRow?.(e.data);
    },
    [onClickRow],
  );

  return (
    <React.Fragment>
      {resetStatus?.length > 0 && (
        <Box className="refreshBtn">
          <IconButton
            color="error"
            size="small"
            variant="outlined"
            onClick={clearFilters}
            disabled={!resetStatus.length}>
            <Tooltip title="Clear Filters" placement="right">
              {!resetStatus.length ? <FilterAltOffIcon fontSize="small" /> : <FilterAltIcon fontSize="small" />}
            </Tooltip>
          </IconButton>
        </Box>
      )}
      <Grid container>
        <Grid size={12}>
          <Box component={Paper} className={`ag-theme-balham ${noBorder && "noBorderRadiusAggrid"}`}>
            <Box position="relative">
              <AgGridReact
                ref={gridRef}
                rowHeight={38}
                rowData={rows}
                onSelectionChanged={onSelectionChanged}
                columnDefs={translatedHeadCells}
                animateRows={true}
                suppressPaginationPanel={true}
                suppressScrollOnNewData={true}
                rowClassRules={rowClassRules}
                defaultColDef={defaultColDef}
                onFilterChanged={onFilterChanged}
                onSortChanged={closePreview}
                rowSelection="multiple"
                gridOptions={gridOptions}
                paginationPageSize={pageSize}
                pagination={true}
                onPaginationChanged={onPaginationChanged}
                onFirstDataRendered={onFirstDataRendered}
                tooltipShowDelay={10}
                onRowClicked={onRowClicked}
              />
              {pagination && (
                <Box>
                  <FormControl className="rowsDisplay" size="small">
                    <Select value={pageSize} onChange={onPageSizeChanged}>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl>
                  <CustomAgGridPagination gridRef={gridRef} currentPage={currentPage} totalPages={totalPages} />
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
