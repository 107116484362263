import { useEffect, useRef } from "react";

export default function CanvasImage({ sourceImage, differences, imgProps, setImgProps = null }) {
  const myCanvas = useRef();
  const context = useRef(null);
  const drawRect = (info, style = {}) => {
    const { x, y, width, height, Label } = info;
    const { borderColor, borderWidth } = style;

    context.current.beginPath();
    context.current.lineWidth = borderWidth;
    context.current.strokeStyle = borderColor;
    context.current.rect(x, y, width, height);
    context.current.font = "bold 30px serif";

    context.current.fillStyle = "#000000";
    // eslint-disable-next-line no-mixed-operators
    context.current.fillText(Label, x + width / 2, y);
    context.current.stroke();
  };
  useEffect(() => {
    const canvas = myCanvas?.current;
    context.current = canvas?.getContext("2d");
    const image = new Image();
    image.src = sourceImage;
    image.onload = () => {
      context?.current?.drawImage(image, 0, 0);
      const props = { width: image?.width, height: image?.height };
      setImgProps(props);
      differences?.forEach((obj) => {
        const r1Style = { borderColor: "rgb(255 0 0 / 52%)", borderWidth: 3 };
        drawRect(obj, r1Style);
      });
    };
    canvas?.scrollIntoView({ behavior: "smooth" });
  }, [sourceImage, differences]);

  return <canvas ref={myCanvas} width={imgProps?.width} height={imgProps?.height} />;
}
