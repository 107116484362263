import { Box, Paper } from "@mui/material";

const InnerHeader = ({ children }) => {
  return (
    <Paper
      className="innerHeaderContainer"
      sx={{ height: "58px", width: "-webkit-fill-available", boxSizing: "border-box" }}>
      <Box className="innerHeader">{children}</Box>
    </Paper>
  );
};

export default InnerHeader;
