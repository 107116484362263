import { Button } from "@mui/material";

const CustomLoadingButton = ({
  loading = false,
  disabled = false,
  variant = "contained",
  loadingPosition = "start",
  size = "small",
  color = "primary",
  onClick,
  startIcon = <></>,
  children,
  ...restProps
}) => {
  const className = color === "primary" && variant === "contained" ? "loader-button" : "";
  return (
    <Button
      disabled={disabled}
      variant={variant}
      className={`custom-button  ${className}`}
      size={size}
      color={color}
      loading={loading}
      loadingPosition={loadingPosition}
      startIcon={startIcon}
      onClick={onClick}
      {...restProps}>
      {children}
    </Button>
  );
};

export default CustomLoadingButton;
