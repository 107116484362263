import { useContext } from "react";
// import appService from "../../services/app.service";

import { Box, IconButton, Tooltip, useTheme, Divider, AppBar, Toolbar, Paper } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Menu as MenuIcon, ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";

import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { ColorModeContext } from "../../theme/theme";
import AccountSettings from "./AccountSettings";
import ServiceStatusPopup from "./ServiceStatus/ServiceStatusPopup";
import { activeClient } from "../../_helpers/Constant";
// import io from "socket.io-client";
// import Notifications from "./Notifications";

const Header = ({ openSideMenu, onClickMenuIcon }) => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  // const socket = io.connect(window._env_?.REACT_APP_SOCKET_HOST, {
  //   path: window._env_?.REACT_APP_SOCKET_PATH,
  //   auth: {
  //     token: `Bearer ${userDetails.Token}`,
  //   },
  // });
  // const [notifications, setNotifications] = useState({ unReadCount: 0, allNotifications: [] });
  // const notificationMarkAsRead = async (data) => {
  //   try {
  //     await appService.NotificationMarkAsRead(data);
  //     setNotifications((prevState) => ({
  //       ...prevState,
  //       unReadCount: 0,
  //     }));
  //   } catch (e) {
  //     console.log(e.message);
  //   }
  // };
  // const notificationList = async () => {
  //   try {
  //     const result = await appService.getNotifications();
  //     setNotifications(result);
  //   } catch (e) {
  //     console.log(e.message);
  //   }
  // };
  // useEffect(() => {
  //   notificationList();
  // }, []);

  // useEffect(() => {
  //   socket?.emit("join_room", userDetails?.UserId);
  //   socket?.on("receive_message", (data) => {
  //     setNotifications((prevState) => ({
  //       ...prevState,
  //       unReadCount: data.body.Count,
  //     }));
  //   });
  // }, [socket]);
  return (
    <AppBar
      enableColorOnDark
      position="fixed"
      open={openSideMenu}
      className="headerCus"
      sx={{ backgroundColor: "transparent !important" }}>
      <Toolbar component={Paper} sx={{ border: 0, boxShadow: 0, borderRadius: 0 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container direction="row" justifyContent="left" alignItems="center">
            <Grid size={4}>
              <Box className="v-center">
                {/* <Box
                  sx={{
                    width: openSideMenu ? "calc(200px - 24px)" : "50px",
                    transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
                    display: "inline-block",
                  }}> */}
                <IconButton sx={{ mr: 2 }} size="small" onClick={onClickMenuIcon} edge="start">
                  {openSideMenu ? <ChevronLeftIcon /> : <MenuIcon />}
                </IconButton>
                {/* </Box> */}
                <Box className="clientLogo">
                  {Boolean(activeClient) && (
                    <img
                      src={`/assets/ClientLogos/${activeClient}/client-logo.png`}
                      height="26px"
                      alt="logo-notavailable"
                    />
                  )}
                </Box>
              </Box>
            </Grid>

            <Grid size={4} textAlign="center"></Grid>
            <Grid size={4} className="headerIcons">
              {/* <Notifications
            notifications={notifications}
            notificationList={notificationList}
            notificationMarkAsRead={notificationMarkAsRead}
          /> */}
              <ServiceStatusPopup />
              <Divider orientation="vertical" variant="string" flexItem style={{ backgroundColor: "#ccc", width: 2 }} />

              <Tooltip arrow placement="top" title={`${theme?.palette?.mode} mode`}>
                <IconButton
                  size="small"
                  sx={{ background: "linear-gradient(45deg, #611EB6, #66A2EE)", color: "#fff" }}
                  onClick={colorMode.toggleColorMode}>
                  {theme?.palette?.mode === "dark" ? (
                    <DarkModeOutlinedIcon fontSize="small" />
                  ) : (
                    <LightModeOutlinedIcon fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>

              <AccountSettings />
            </Grid>
          </Grid>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
