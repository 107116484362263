import { Box, Button, Card, Modal, Typography } from "@mui/material";
import FuzzyReplaceResult from "./FuzzyReplace/FuzzyReplaceResult";
import MasterdataConnectionDetails from "./MasterdataConnectionDetails";
import { useTranslation } from "react-i18next";

const CleaningResultModal = ({ Result, setShowResult }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setShowResult(false);
  };

  return (
    <Modal open={true} onClose={handleClose}>
      <Card>
        <Box className="resultModalContainer">
          <Box className="DCHead">
            <Typography variant="h6" textAlign="center">
              {t("Data Cleaning Result")}
            </Typography>
          </Box>

          {Result?.CleanedResponse?.dataCleaning?.connectionDeatails && (
            <MasterdataConnectionDetails
              connectionDetails={Result?.CleanedResponse?.dataCleaning?.connectionDeatails}
            />
          )}

          {Object.keys(Result).length > 0 && <FuzzyReplaceResult Result={Result} />}

          <Box textAlign="center" mt="16px">
            <Button onClick={handleClose} variant="outlined" size="small" color="error">
              {t("Close")}
            </Button>
          </Box>
        </Box>
      </Card>
    </Modal>
  );
};

export default CleaningResultModal;
