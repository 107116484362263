import { useContext, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ConfirmModel from "./ConfirmModel";
import { SnackbarContext } from "../../App";
import { useTranslation } from "react-i18next";
import { PublicClientApplication } from "@azure/msal-browser";
import { LoginRedirectUrl, activeClient, azureLogin } from "../../_helpers/Constant";
import AuthenticationLoader from "./AuthenticationLoader";
import CustomLoadingButton from "../CustomComponents/CustomsButtons/CustomLoadingButton";
import { fetchKeys } from "../../helpers/encryptionUtils";
import { useDispatch, useSelector } from "react-redux";
import { authServices } from "../../Redux/services/auth.services";
import { setADLoginLoading, setLoginLoading } from "../../Redux/reducers/authSlice";

const Copyright = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ position: "absolute", bottom: 16, width: "100%" }}>
      <Typography variant="body2" color="text.secondary" align="center">
        {t("Copyright")} ©{" "}
        <a href="https://kairostech.com/" target="_blank" rel="noopener noreferrer">
          {t("Kairos Technologies")}
        </a>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </Box>
  );
};

const SignIn = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setSnack } = useContext(SnackbarContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { ADLoginLoading, loginLoading, token } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);

  // const [userData, setUserData] = useState({});
  const handleShowPassword = () => setShowPassword(!showPassword);

  const onSubmitLogin = async (data) => {
    dispatch(authServices.authenticateUser(data));
  };

  const msalConfig = { auth: { clientId: "", authority: "", redirectUri: `${LoginRedirectUrl}login` } };

  let pca = null;

  const initializeMsalInstance = async (clientId, tenantId) => {
    msalConfig.auth.clientId = clientId;
    msalConfig.auth.authority = `https://login.microsoftonline.com/${tenantId}`;
    pca = new PublicClientApplication(msalConfig);
    await pca.initialize();
  };

  const handleLoginButtonClick = async () => {
    dispatch(setLoginLoading(true));
    const azure = await fetchKeys();

    if (azure?.azureClientId && azure?.azureTenentId) {
      try {
        if (!pca) {
          await initializeMsalInstance(azure.azureClientId, azure.azureTenentId);
        }
        await pca.loginRedirect({ scopes: ["User.Read"] });
      } catch (error) {
        if (error.message.includes("interaction_in_progress")) {
          setSnack({ message: t("AD_CACHI_ERROR"), open: true, colour: "warning" });
        } else {
          setSnack({ message: t("AD_LOGIN_ERROR"), open: true, colour: "error" });
        }
      } finally {
        dispatch(setLoginLoading(false));
      }
    } else if (azure?.message?.includes("Network Error") || azure?.message?.includes("Request failed with")) {
      setSnack({ message: t("MultiSecret_Down"), open: true, colour: "error" });
      dispatch(setLoginLoading(false));
    } else {
      setSnack({ message: t("AD_MISSING"), open: true, colour: "error" });
      dispatch(setLoginLoading(false));
    }
    dispatch(setLoginLoading(false));
  };

  useLayoutEffect(() => {
    const handleRedirect = async () => {
      dispatch(setADLoginLoading(true));
      try {
        const { azureClientId, azureTenentId } = await fetchKeys();
        const msalConfig = {
          auth: {
            clientId: azureClientId,
            authority: `https://login.microsoftonline.com/${azureTenentId}`,
            redirectUri: `${LoginRedirectUrl}login`,
          },
        };

        const pca = new PublicClientApplication(msalConfig);
        await pca.initialize();
        const response = await pca.handleRedirectPromise();
        if (response) {
          const userEmail = response.account.username;
          const loginReq = { email: userEmail, password: response?.account?.authorityType };
          await onSubmitLogin(loginReq);
        }
      } catch {
        setSnack({ message: t("AD_LOGIN_ERROR"), open: true, colour: "error" });
        dispatch(setADLoginLoading(false));
      }
      dispatch(setLoginLoading(false));
    };

    if (window.location.hash) {
      handleRedirect();
    }
  }, []);

  if (token) {
    return <Navigate to="/DataSource" />;
  }
  const PwIcon = showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />;
  return (
    <>
      {ADLoginLoading ? (
        <AuthenticationLoader />
      ) : (
        <Grid container component="main" sx={{ height: "100vh" }}>
          <Grid size={7} lg={8}>
            <Box sx={{ position: "relative", height: "100vh", width: "100%" }}>
              <video
                src={`/assets/ClientLogos/${activeClient}/login-video.mp4`}
                style={{ position: "absolute", top: 0, left: 0, height: "100%", width: "100%", objectFit: "cover" }}
                autoPlay
                loop
                muted
              />
            </Box>
          </Grid>

          <Grid size={5} lg={4} sx={{ background: "#F4F1FF", position: "relative" }} component={Paper}>
            <Box height={"100%"} className="center column">
              <img
                src={`/assets/ClientLogos/${activeClient}/app-logo-white.png`}
                width="150"
                alt={"logo-notavailable"}
              />
              <Typography component="h1" variant="h5" sx={{ mt: "15px" }}>
                {t("Sign In")}
              </Typography>
              {!azureLogin ? (
                <Box component="form" sx={{ width: "100%", padding: "0 40px" }} onSubmit={handleSubmit(onSubmitLogin)}>
                  <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label={t("Email Address")}
                      name="email"
                      autoComplete="email"
                      size="small"
                      autoFocus
                      error={errors.email}
                      {...register("email", { required: true })}
                    />

                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      label={t("Password")}
                      variant="outlined"
                      size="small"
                      error={errors.password}
                      {...register("password", { required: true })}
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleShowPassword}>{PwIcon}</IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomLoadingButton
                      type="submit"
                      sx={{ mt: 3, mb: 2, mx: "auto" }}
                      disabled={loginLoading}
                      loading={loginLoading}>
                      {t("Sign In")}
                    </CustomLoadingButton>
                  </Box>
                </Box>
              ) : (
                <CustomLoadingButton
                  sx={{ mt: 3 }}
                  disabled={loginLoading}
                  loading={loginLoading}
                  onClick={handleLoginButtonClick}>
                  Login with Azure AD
                </CustomLoadingButton>
              )}
            </Box>

            <Copyright />
          </Grid>
        </Grid>
      )}

      <ConfirmModel />
    </>
  );
};
export default SignIn;
