import { useState } from "react";

import {
  StepLabel,
  Button,
  Popover,
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Paper,
  InputBase,
  IconButton,
  Radio,
  Stack,
  CircularProgress,
  FormHelperText,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import EditNoteIcon from "@mui/icons-material/EditNote";
import CheckIcon from "@mui/icons-material/Check";
import { Search } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { ColorlibStepIconRoot } from "./StepperStyledComponents";
import CustomSwitchButton from "../../../CustomComponents/CustomSwitchButton";
import CustomCSVIcon from "../../../CustomComponents/Icons/CustomCSVIcon";
import CustomXLSIcon from "../../../CustomComponents/Icons/CustomXLSIcon";
import CustomFolderIcon from "../../../CustomComponents/Icons/CustomFolderIcon";
import FolderPath from "./FolderPath";

const ColorlibStepIcon = ({ active, completed, className, icon }) => (
  <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
    {icon}
  </ColorlibStepIconRoot>
);

const SelectFileName = ({ handleClose }) => {
  const [searchParams, setSearchParams] = useSearchParams({ fileType: "csv" });
  const fileType = searchParams.get("fileType");
  const isFixed = JSON.parse(searchParams.get("isFixed")) || false;
  const fileName = searchParams.get("fileName") || "";

  const validateFileName = (name) => {
    const minLength = 4;
    const maxLength = 50;
    const invalidCharacters = /[^a-zA-Z0-9_\- ]/;

    return name.length >= minLength && name.length <= maxLength && !invalidCharacters.test(name);
  };

  const isFileNameValid = validateFileName(fileName);

  const handleFileTypeChange = (event) => {
    setSearchParams((params) => {
      params.set("fileType", event.target.value);
      return params;
    });
  };

  const handleSwitchChange = (e) => {
    setSearchParams((params) => {
      params.set("isFixed", e.target.checked);
      return params;
    });
  };

  const onChangeFileName = (e) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("fileName", e.target.value || "");
      return newState;
    });
  };

  const onClickAdd = () => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("activeStep", 3);
      return newState;
    });
    !fileType &&
      setSearchParams((params) => {
        params.set("fileType", fileType);
        return params;
      });
    handleClose();
  };

  return (
    <Box sx={{ pb: 3 }} className="fileComponent">
      <Box sx={{ ml: "auto", float: "right", display: "none" }}>
        <CustomSwitchButton
          labels={["Fixed", "Pattern"]}
          size="small"
          checked={isFixed}
          onChange={handleSwitchChange}
        />
      </Box>

      <Paper className="v-center fwFileName">
        <InputBase
          size="small"
          className="searchInput"
          placeholder="Enter File Name"
          value={fileName}
          onChange={onChangeFileName}
        />
      </Paper>

      <FormHelperText error={!isFileNameValid}>
        File name must be 4-50 characters, with only letters, numbers, dashes, underscores, or spaces.
      </FormHelperText>

      <Stack direction="row" spacing={1} my={1}>
        <Box className="v-center">
          <Radio
            sx={{ px: 0.5 }}
            checked={fileType === "csv"}
            onChange={handleFileTypeChange}
            value="csv"
            className="customRadioGroup"
          />
          <CustomCSVIcon />
        </Box>
        <Box className="v-center">
          <Radio
            sx={{ px: 0.5 }}
            checked={fileType === "xlsx"}
            onChange={handleFileTypeChange}
            value="xlsx"
            size="small"
            className="customRadioGroup"
          />
          <CustomXLSIcon />
        </Box>
      </Stack>

      <Button
        sx={{ py: 0, float: "right", fontSize: "12px" }}
        variant="contained"
        size="small"
        onClick={onClickAdd}
        disabled={!isFileNameValid}>
        Add
      </Button>
    </Box>
  );
};

const MenuComponent = ({ label, menuItems, loading, handleSelectOption }) => {
  return (
    <Box className="menuComponent">
      <Paper className="v-center fwSearch">
        <InputBase size="small" className="searchInput" placeholder={"Search  here"} />
        <IconButton sx={{ p: 0 }} size="small">
          <Search fontSize="0.7rem" />
        </IconButton>
      </Paper>

      {loading && <CircularProgress className="flex" sx={{ mx: "auto", mt: 2 }} size={20} color="primary" />}
      <List>
        {menuItems?.map((item, index) => (
          <ListItemButton
            className="v-center"
            sx={{ p: 0, gap: 1 }}
            key={index}
            onClick={() => handleSelectOption(item)}>
            <ListItemIcon sx={{ minWidth: 25 }}>
              {label === "Select Connection" ? (
                <img
                  src={`/assets/uploads/${item.connectionType?.toLowerCase()}.png`}
                  alt={item.connectionName}
                  width={25}
                />
              ) : (
                <CustomFolderIcon />
              )}
            </ListItemIcon>
            <ListItemText primary={label === "Select Connection" ? item.connectionName : item} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
};

const CustomStepIcon = ({
  label,
  icon,
  menuItems,
  handleSelectOption,
  isDisabled,
  loading = false,
  labelComponent = null,
  isLocalPath = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StepLabel StepIconComponent={ColorlibStepIcon} icon={labelComponent ? <CheckIcon /> : icon}>
      <Button
        onClick={handleClick}
        aria-describedby={open ? "step-popover" : undefined}
        endIcon={labelComponent ? <EditNoteIcon /> : <AddIcon />}
        disabled={isDisabled}
        size="small"
        sx={{ textTransform: "none" }}>
        {labelComponent || label}
      </Button>
      <Popover
        id={open ? "step-popover" : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{ "& .MuiPopover-paper": { boxShadow: 3 } }}>
        <Box className="customStepIcon">
          {label === "Select File" ? (
            <SelectFileName handleClose={handleClose} />
          ) : (
            <>
              {!isLocalPath && (
                <MenuComponent
                  label={label}
                  menuItems={menuItems}
                  loading={loading}
                  handleSelectOption={(option) => handleSelectOption(option, handleClose)}
                />
              )}
              {isLocalPath && (
                <FolderPath
                  defaultValue={isLocalPath}
                  handleSubmitFolderPath={(pathName) => handleSelectOption(pathName, handleClose)}
                />
              )}
            </>
          )}
        </Box>
      </Popover>
    </StepLabel>
  );
};

export default CustomStepIcon;
