import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { SnackbarContext } from "../../App";
import { formatDateWithTime } from "../../_helpers/utils";
import { CustomAgGrid } from "../AgGrid";
import CustomAutoComplete from "../CustomComponents/CustomAutoComplete";
import { ListItemUser } from "./ListItemUser";
import InnerHeader from "../Layout/InnerHeader";
import SkeletonLoader from "../SkeletonLoader";
import { roleAttrs } from "./DQGUser_Constants";
import { DQGUserLogHeadcells } from "./DQGUsersHeadcells";
import CustomBackButton from "../CustomComponents/CustomsButtons/CustomBackButton";
import { useDispatch, useSelector } from "react-redux";
import { usersService } from "../../Redux/services/users.service";

export default function UserLogsList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setSnack } = useContext(SnackbarContext);
  const gridRef = useRef();

  const { id } = useParams();
  const { user_details } = useSelector((state) => state.auth);
  const [selectedUser, setSelectedUser] = useState("");

  const [userLogs, setUserLogs] = useState([]);
  const [loadingLogs, setLoadingLogs] = useState(false);

  const { loadingExistingUsers, existingUsers } = useSelector((state) => state.users);

  const fetchUsersList = async () => {
    const userData = {
      UserId: user_details?.UserId,
      OrganisationId: user_details?.OrganisationId,
      UserEmail: user_details?.Email,
    };

    dispatch(usersService.fetchExistingUsers(userData));
  };
  const fetchLogs = async () => {
    setLoadingLogs(true);
    const data = { id: Number(id) };
    try {
      const response = await usersService.getDQGUserLogs(data);
      setUserLogs(response?.ResponseObject);
      setSelectedUser(response?.ResponseObject?.UserDetailLog);
    } catch (error) {
      setSnack({ message: error?.response?.data?.message ?? error.message, open: true, colour: "error" });
    } finally {
      setLoadingLogs(false);
    }
  };

  const onChangeUser = (e, val) => {
    if (val !== null) {
      val?.Id && navigate(`/dqgusers/userlogs/${val?.Id}`);
    }
  };
  useEffect(() => {
    fetchLogs();
  }, [id]);
  if (
    id &&
    user_details?.RoleName &&
    user_details?.RoleName !== "Admin" &&
    Number(id) !== Number(user_details?.UserId)
  ) {
    return <Navigate to="/dqgusers" />;
  }
  return (
    <React.Fragment>
      <InnerHeader>
        <Grid container sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Grid size={6} className="v-center" gap={2}>
            <Link to="/dqgusers" className="custom-link">
              <CustomBackButton>{t("Back")}</CustomBackButton>
            </Link>
            <Typography variant="h6">{t("User Log Data")}</Typography>
          </Grid>
          <Grid size={3}>
            {user_details?.RoleName === "Admin" && (
              <>
                {Object.keys(selectedUser).includes("FirstName", "LastName") && (
                  <CustomAutoComplete
                    loading={loadingExistingUsers}
                    options={existingUsers}
                    placeholder={t("Select User")}
                    value={selectedUser}
                    getOptionLabel={(opt) => `${opt?.FirstName}" "${opt?.LastName}`}
                    onChange={onChangeUser}
                    isOptionEqualToValue={(option, value) => option === value}
                    onOpen={() => (existingUsers?.length > 0 ? null : fetchUsersList())}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      </InnerHeader>
      <Box className="pt74">
        {loadingLogs ? (
          <SkeletonLoader />
        ) : (
          <Box>
            {userLogs?.UserDetailLog?.FirstName && (
              <Grid container className="container" p={3}>
                <Grid size={6}>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}>
                    <ListItemUser
                      title="User Name"
                      value={`${userLogs?.UserDetailLog?.FirstName}" "${userLogs?.UserDetailLog?.LastName}`}
                    />
                    <ListItemUser title="Email" value={userLogs?.UserDetailLog?.Email} />
                    <ListItemUser
                      color={roleAttrs[userLogs?.UserDetailLog?.RoleName]?.color}
                      title="Role"
                      value={userLogs?.UserDetailLog?.RoleName}
                    />
                    <ListItemUser
                      color={userLogs?.UserDetailLog?.IsOnline ? "#52BE80" : "#DC7633"}
                      title="Status"
                      value={userLogs?.UserDetailLog?.IsOnline ? "Online" : "Offline"}
                    />
                  </Box>
                </Grid>
                <Grid size={6} gap={1}>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}>
                    <ListItemUser title="Created" value={formatDateWithTime(userLogs?.UserDetailLog?.CreatedAt)} />
                    <ListItemUser
                      title="Last Logged In"
                      value={formatDateWithTime(userLogs?.UserDetailLog?.LastLogin)}
                    />
                    {/* <ListItemUser
                        title="Logged In IP Address"
                        value={"--"}
                      />
                      <ListItemUser
                        title="Last Logged In Session Duration"
                        value={"--"}
                      /> */}
                  </Box>
                </Grid>
              </Grid>
            )}
            <Grid size={12}>
              {userLogs?.userActivities?.length !== 0 ? (
                <CustomAgGrid gridRef={gridRef} headCells={DQGUserLogHeadcells} rows={userLogs?.userActivities} />
              ) : (
                <Box sx={{ width: "100%", textAlign: "center", height: "60vh" }} className="v-center container">
                  <Typography variant="h5" sx={{ color: "#64748B", m: "auto" }}>
                    {selectedUser?.Id
                      ? "The Selected user does not have any Logs recorded."
                      : "Select any User from Dropdown to View the recorded Logs."}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
}
