import { Box, Typography, Alert, AlertTitle, IconButton, Tooltip, Divider } from "@mui/material";
import InnerHeader from "../../components/Layout/InnerHeader";
import { useEffect, useState, useRef, forwardRef, useContext } from "react";
import appService from "../../services/app.service";
import { timeAgo } from "../../_helpers/Constant";
import MarkChatReadOutlinedIcon from "@mui/icons-material/MarkChatReadOutlined";
import SkeletonLoader from "../SkeletonLoader";
import { SnackbarContext } from "../../App";

const RenderNotificationItem = forwardRef(
  ({ id, label, description, createdAt, createdBy, messageReadStatus, updateMessage }, ref) => (
    <Alert
      icon={false}
      sx={{
        position: "relative",
        bgcolor: "background.paper",
        "& .MuiAlert-message": { flex: 1 },
        borderLeft: !messageReadStatus ? "4px solid #0288d1" : "none",
        "& .MuiAlert-action": { p: 0, color: "#d32f2f" },
      }}
      onClose={() => {
        updateMessage({ IsActive: false, id });
      }}
      ref={ref}>
      <AlertTitle>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant={"h6"}>{label}</Typography>
          <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}>
            <Typography sx={{ opacity: 0.6 }}>{timeAgo(createdAt)}</Typography>
            {!messageReadStatus && (
              <Tooltip arrow title="Mark As Read">
                <IconButton sx={{ py: 0 }} color="primary" size="small">
                  <MarkChatReadOutlinedIcon
                    onClick={() => {
                      updateMessage({ messageReadStatus: true, id });
                    }}
                    fontSize="10px"
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </AlertTitle>

      <Typography sx={{ opacity: 0.6 }}>
        <span dangerouslySetInnerHTML={{ __html: description }} />
      </Typography>

      <Typography sx={{ opacity: 0.8, position: "absolute", bottom: 0, right: 8 }}>
        Created By : <b>{createdBy}</b>
      </Typography>
    </Alert>
  ),
);

RenderNotificationItem.displayName = "RenderNotificationItem";

function AllNotifications() {
  const observer = useRef();
  const { setSnack } = useContext(SnackbarContext);

  const [notifications, setNotifications] = useState({ unReadCount: 0, allNotifications: [], totalCount: 0 });
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const notificationMarkAsRead = async (data) => {
    try {
      return await appService.NotificationMarkAsRead(data);
    } catch {
      return null;
    }
  };

  const updateMessage = (data) => {
    if (Object.prototype.hasOwnProperty.call(data, "IsActive")) {
      notificationMarkAsRead({ messageId: [data.id], isActive: false });
      setNotifications((prev) => ({
        ...prev,
        allNotifications: prev.allNotifications.filter((notification) => notification.id !== data.id),
        totalCount: prev.totalCount - 1,
      }));
    }
    if (Object.prototype.hasOwnProperty.call(data, "messageReadStatus")) {
      notificationMarkAsRead({ messageId: [data.id], messageReadStatus: true });
      setNotifications((prev) => ({
        ...prev,
        allNotifications: prev.allNotifications.map((notification) =>
          notification.id === data.id ? { ...notification, messageReadStatus: true } : notification,
        ),
      }));
    }
    if (Object.prototype.hasOwnProperty.call(data, "NotificationViewedStatus")) {
      notificationMarkAsRead({ messageId: data.messageids, NotificationViewedStatus: true });
    }
  };

  const notificationList = async (pageNumber) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const result = await appService.getNotifications({ pageNumber, pageSize: 10 });
      const newNotifications = result.allNotifications;

      setNotifications((prev) => ({
        ...prev,
        unReadCount: result.unReadCount,
        allNotifications: [...prev.allNotifications, ...newNotifications],
        totalCount: result.totalCount,
      }));

      const messageids = newNotifications
        .filter((notification) => !notification.NotificationViewedStatus)
        .map((el) => el?.id);
      messageids.length > 0 && updateMessage({ messageids, NotificationViewedStatus: false });
    } catch (error) {
      setSnack({ message: error?.response?.data?.message || error.message, open: true, colour: "error" });
    }
    setLoading(false);
  };

  useEffect(() => {
    notificationList(page);
  }, [page]);

  const lastNotificationElement = (node) => {
    if (loading) {
      return;
    }
    if (notifications.allNotifications.length === notifications.totalCount) {
      return;
    }
    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((prevPage) => prevPage + 1);
      }
    });
    if (node) {
      observer.current.observe(node);
    }
  };

  return (
    <>
      <InnerHeader>
        <Typography variant="h6">All Notifications</Typography>
      </InnerHeader>
      <Box sx={{ flex: 1 }} className="pt74">
        <Box sx={{ p: 1.5 }}>
          {(notifications?.allNotifications || []).map((each, index) => (
            <div key={each?.id}>
              <Divider />
              <RenderNotificationItem
                key={each?.id}
                label={each?.messageTitle}
                description={each?.rawMessage}
                createdAt={each?.createdAt}
                messageReadStatus={each?.messageReadStatus}
                createdBy={each?.createdBy}
                userId={each?.userId}
                id={each?.id}
                updateMessage={updateMessage}
                ref={index === notifications.allNotifications.length - 1 ? lastNotificationElement : null}
              />
            </div>
          ))}
          {loading && <SkeletonLoader />}
        </Box>
      </Box>
    </>
  );
}

export default AllNotifications;
