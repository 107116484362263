import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState, Suspense } from "react";
import ApiService from "../../services/app.service";

import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../App";
import InnerHeader from "../Layout/InnerHeader";
import SkeletonLoader from "../SkeletonLoader";
import DashBoardOverview from "./DashBoardOverview";
import DashboardMetrics from "./DashboardMetrics";
import DQRulesOverview from "./DQRulesOverview";
import DBScheduleSources from "./DBScheduleSources";
import TestExecutions from "./Graphs/TestExecutions";
import { useSelector } from "react-redux";

export default function DashBoard() {
  const { t } = useTranslation();

  const { user_details } = useSelector((state) => state.auth);
  const { setSnack } = useContext(SnackbarContext);
  const [loadingDashboard, setLoadingDashboard] = useState(false);
  const [metricsData, setMetricsData] = useState();
  const [metricsDataLoading, setMetricsDataLoading] = useState(false);
  const [tests, setTests] = useState([]);

  const fetchData = async () => {
    setLoadingDashboard(true);
    try {
      const response = await ApiService.getDashboardData();
      setTests(response?.data);
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
    setLoadingDashboard(false);
  };

  const getDashBoardMetricsData = async () => {
    setMetricsDataLoading(true);
    try {
      const { data } = await ApiService.getDashboardMetrics(user_details?.OrganisationId);
      setMetricsData(data);
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
    setMetricsDataLoading(false);
  };

  useEffect(() => {
    fetchData();
    getDashBoardMetricsData();
  }, []);

  return (
    <React.Fragment>
      <InnerHeader>
        <Typography variant="h6" className="upper-case">
          {t("Dashboard")}
        </Typography>
      </InnerHeader>

      <Box className="upcomingTests pt74">
        <DashboardMetrics metricsData={metricsData} metricsDataLoading={metricsDataLoading} />
        {loadingDashboard ? (
          <SkeletonLoader />
        ) : (
          <Box className="flex column" gap={1.5} mt={1.5}>
            <DashBoardOverview tests={tests} />
            <DQRulesOverview tests={tests} />
            <DBScheduleSources tests={tests} />

            <Suspense fallback={<SkeletonLoader />}>{tests?.OverallDataQuality && <TestExecutions />}</Suspense>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
}
