import { Typography } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
const CustomArrowIndicator = ({ value }) => {
  let color;

  if (value === 0) {
    color = "#ccc";
  } else if (value > 0) {
    color = "success.main";
  } else {
    color = "error";
  }

  let icon;

  if (value === 0) {
    icon = <></>; // or null, depending on your needs
  } else if (value > 0) {
    icon = <ArrowUpwardOutlinedIcon fontSize="small" />;
  } else {
    icon = <ArrowDownwardIcon fontSize="small" />;
  }

  return (
    <Typography variant="bold" color={color} ml="auto" display="flex" justifyContent="center" alignItems="center">
      {value} {icon}
    </Typography>
  );
};

export default CustomArrowIndicator;
