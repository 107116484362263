/* eslint-disable no-mixed-operators */
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  Box,
  Typography,
  Table,
  TableBody,
  IconButton,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import PercentageBar from "../Barchart/PercentageBar";
import { getMax } from "../ProfillingListItems";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, getMax([0, Math.ceil(count / rowsPerPage) - 1]));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page">
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function CustomPaginationTable({ rows, perpagerows, count, maxval, heading, missing, head10, tail10 }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(perpagerows || 10);
  const [data, setData] = useState([]);

  const [max, setMax] = useState();

  useEffect(() => {
    if (missing) {
      rows["(missing)"] = missing;
    }

    if (!Array.isArray(rows)) {
      const Data = rows && Object.entries(rows);
      if (head10) {
        setData(Data?.slice(0, 10));
      } else if (tail10) {
        setData(Data?.slice(-10).reverse());
      } else {
        setData(Data);
      }
      rows && setMax(getMax(Object.values(rows)));
    } else {
      setData(rows);
      maxval && setMax(maxval);
    }
  }, [rows, missing]);

  const emptyRows = page > 0 ? getMax([0, (1 + page) * rowsPerPage - data?.length]) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer className="dataproffileTable">
      {heading && <Typography sx={{ fontSize: "13px", marginTop: "10px", p: 1 }}>{heading}</Typography>}
      <Table sx={{ minWidth: 300 }} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <TableCell>Value</TableCell>
            <TableCell align="right">Count</TableCell>
            <TableCell>Frequency (%)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0 ? data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data)?.map(
            ([key, val]) => (
              <TableRow key={key}>
                <TableCell scope="row">{key}</TableCell>
                <TableCell style={{ width: 160 }} align="right">
                  {val}
                </TableCell>
                <TableCell style={{ width: 120 }}>
                  <PercentageBar data={[val]} max={max} count={count} />
                </TableCell>
              </TableRow>
            ),
          )}
          {emptyRows > 0 && (
            <TableRow style={{ height: 34.29 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        {data?.length > rowsPerPage && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={data?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
}
