import { DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Backdrop, Card } from "@mui/material";
import { useTranslation } from "react-i18next";
import { decryptData } from "../../helpers/encryptionUtils";
import { useDispatch, useSelector } from "react-redux";
import { setIsSessionExists, setLoginLoading, setSessionExistsDetails } from "../../Redux/reducers/authSlice";
import { authServices } from "../../Redux/services/auth.services";
import { useContext } from "react";
import { SnackbarContext } from "../../App";

const ConfirmModel = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);

  const { sessionExistsDetails } = useSelector((state) => state.auth);

  const handleClose = () => {
    dispatch(setSessionExistsDetails({}));
  };
  const handleLoginError = async (error, setSnack) => {
    const dc = typeof error?.response?.data === "string" && (await decryptData(error?.response?.data));
    const errMessage =
      dc?.Message ||
      dc?.statusText ||
      (error.message.includes("Network Error") && "AuthNZ Server is currently down! Please contact IT support.");

    setSnack({ message: errMessage, open: true, colour: "error" });
  };

  const Confirm = async () => {
    dispatch(setIsSessionExists(false));
    dispatch(setLoginLoading(true));

    try {
      const reqData = {
        UserId: sessionExistsDetails?.loginRes?.UserId,
        SessionId: sessionExistsDetails?.loginRes?.SessionId,
      };
      const loginPayload = sessionExistsDetails?.loginPayload;
      await authServices.logoutUser(reqData, dispatch);
      dispatch(authServices.authenticateUser(loginPayload));
    } catch (error) {
      handleLoginError(error, setSnack);
    } finally {
      dispatch(setSessionExistsDetails(false));
    }
  };

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={sessionExistsDetails?.isSessionExists || false}
      onClose={handleClose}>
      <Card>
        <DialogTitle>{t("Active Login Session Exists")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("Please logout from your existing session to login here.")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button variant="contained" style={{ backgroundColor: "#AAAAAA" }} onClick={handleClose}>
            {t("Cancel")}
          </Button> */}
          <Button variant="contained" onClick={handleClose}>
            {t("Ok")}
          </Button>
          <Button sx={{ display: "none" }} variant="contained" onClick={Confirm}>
            {t("Confirm")}
          </Button>
        </DialogActions>
      </Card>
    </Backdrop>
  );
};

export default ConfirmModel;
