import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Drawer as MuiDrawer } from "@mui/material";
import Header from "./Header";
import SideMenu from "./SideMenu";

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  borderRight: 0,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  borderRight: 0,
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  minHeight: "52px !important",
}));

const DrawerStyled = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiDrawer-paper": {
    backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Layout = () => {
  const [openSideMenu, setOpenSideMenu] = useState(true);
  const onClickMenuIcon = () => {
    setOpenSideMenu((prevState) => !prevState);
  };

  return (
    <Box>
      <Header openSideMenu={openSideMenu} onClickMenuIcon={onClickMenuIcon} />

      <DrawerStyled variant="permanent" open={openSideMenu} className="sidemenuCus">
        <DrawerHeader />
        <SideMenu openSideMenu={openSideMenu} />
      </DrawerStyled>
    </Box>
  );
};

export default Layout;
