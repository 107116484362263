import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import CustomAutoComplete from "../../CustomComponents/CustomAutoComplete";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

const OrderbyDropdown = ({ isFirstSource }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const orderByKey = isFirstSource ? "firstSourceOrderBy" : "secondSourceOrderBy";
  const orderByColumnsKey = isFirstSource ? "source1OrderByColumns" : "source2OrderByColumns";
  const orderBy = searchParams.get(orderByKey);
  const orderByColumns = useSelector((state) => state.rowComparison?.[orderByColumnsKey]);
  const columnsToValidate = useSelector((state) => state.rowComparison.columnsToValidate);

  const isPrimaryKey = useMemo(
    () =>
      columnsToValidate?.source1?.some((source1Item) => source1Item?.IsKey) ||
      columnsToValidate?.source2?.some((source2Item) => source2Item?.IsKey),
    [columnsToValidate],
  );

  const isCheckedAnyDragItem = useMemo(
    () => columnsToValidate?.source1?.some((col) => col.IsKey) || columnsToValidate?.source2?.some((col) => col.IsKey),
    [columnsToValidate],
  );

  const isDisabled = isCheckedAnyDragItem || isPrimaryKey;

  const onChangeOrderBy = (event, newValue) => {
    if (newValue?.label) {
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set(orderByKey, newValue.label);
        return newState;
      });
    }
  };

  return (
    <CustomAutoComplete
      disabled={isDisabled}
      label={t("Order By")}
      options={orderByColumns || []}
      value={orderByColumns?.find((each) => each?.label === orderBy)}
      getOptionLabel={(option) => option?.label || ""}
      // error={showError}
      onChange={onChangeOrderBy}
      sx={{ width: 180 }}
    />
  );
};

export default OrderbyDropdown;
